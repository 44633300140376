import http from "../../../global/http"
import { DateRange, Invoice } from "../../../model/billing"

export const getOutstandingBalance = async (orgId: string) => {
  try {
    const res = await http.get(
      `/shared/v1/org/${orgId}/search/payments/balance_summary`,
    )
    return res
  } catch (err) {
    return err
  }
}

export const fetchInvoiceHistorybyOrg = async (
  orgId: string,
  payload: DateRange,
) => {
  try {
    console.log("...payload", payload, orgId)
    const res: Invoice[] = []
    const { data } = await http.post(
      `/shared/v1/org/${orgId}/search/payments/invoice/`,
      payload,
    )
    if (data?.result?.length) {
      data.result.map((input) => {
        res.push(new Invoice().Deserialize(input))
      })
    }
    return res
  } catch (err) {
    return err
  }
}
