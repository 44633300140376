import { FC, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import moment from "moment"

import { useAppDispatch, useAppSelector } from "../../../core/app/hooks"
import {
  fetchInvoiceHistory,
  getOutstandingBalancebyOrg,
} from "../../../core/app/slices/billing/billingThunk"
import { RootState } from "../../../core/app/store"
import { Invoice } from "../../../core/model/billing"
import { UI_PERMISSION } from "../../../core/utils/access/permissionConstants"
import { useMyNavigation } from "../../../hooks/useMyNavigation"
import usePermissions from "../../../hooks/userPermission"
import { UButton, UText } from "../../../ui-component"
import {
  Box,
  Container,
  Divider,
  Link,
} from "../../../ui-component/mui.components"

import Invoices from "./Invoice"
import { OutstandingBalance } from "./OutstandingBalance"

export const BillingModule: FC = () => {
  const { hasAny } = usePermissions()
  const dispatch = useAppDispatch()
  const navigate = useMyNavigation()
  const { t } = useTranslation("common")
  const [hideLoadMore, setHideLoadMore] = useState(false)
  const { VITE_INVOICESTARTDATE } = import.meta.env
  const { outstandingBalance, invoices } = useAppSelector(
    (state: RootState) => state.billingService,
  )
  const { user } = useAppSelector((state: RootState) => state.userService)

  const [endDate, setEndDate] = useState(moment())

  const getDateRange = () => {
    let startDate: moment.Moment
    if (
      moment(endDate).format("YYYY-MM") <=
      moment(VITE_INVOICESTARTDATE).add(6, "months").format("YYYY-MM")
    ) {
      startDate = moment(VITE_INVOICESTARTDATE)
      setHideLoadMore(true)
    } else {
      startDate = moment(endDate).subtract(5, "months")
    }
    setEndDate(moment(endDate).subtract(6, "months"))
    const range = {
      start: { year: startDate.year(), month: startDate.month() + 1 },
      end: { year: endDate.year(), month: endDate.month() + 1 },
    }
    dispatch(fetchInvoiceHistory(range))
  }

  useEffect(() => {
    if (!outstandingBalance || user.isAllowToViewAllOrg) {
      dispatch(getOutstandingBalancebyOrg())
    }

    if (!invoices || user.isAllowToViewAllOrg) {
      getDateRange()
    }
  }, [])

  return (
    <Container
      component="main"
      maxWidth="lg"
      id="billingContainer"
      sx={{
        display: "flex",
        maxWidth: "100%",
        flexDirection: "column",
        margin: "48px 0",
        padding: "0 !important",
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 3 }}>
        <UText variant="h4" component="div" sxProp={{ pl: 4 }}>
          {t("billingHistory.title")}
        </UText>
        {hasAny(UI_PERMISSION.UI_BILLING_PRICINGLIST) && (
          <Box component={"div"} sx={{ pr: 4 }}>
            <UButton
              variant={"outlined"}
              btnText={t("Pricing.list")}
              onClickHandler={() => {
                navigate("/billing/pricing")
              }}
            />
          </Box>
        )}
      </Box>
      <Box component="div">
        <OutstandingBalance outstandingBalance={outstandingBalance} />
      </Box>
      <UText
        variant="h5"
        sxProp={{
          mt: 5,
          mb: 1,
          ml: 4,
          display: "flex",
          justifyContent: "start",
        }}
      >
        {t("billingHistory.invoice")}
      </UText>
      <Divider flexItem sx={{ mb: 1, mx: 4 }} />
      <UText color="text.secondary" variant="body2" sxProp={{ ml: 4, mb: -2 }}>
        {t("billingHistory.invoiceNote")}
      </UText>
      {invoices &&
        invoices.length > 0 &&
        invoices.map((invoice: Invoice) => (
          <Box key={invoice.invoice_number} component={"div"} mt={4}>
            <Invoices invoice={invoice} />
          </Box>
        ))}
      {!hideLoadMore ? (
        <Link
          onClick={() => {
            getDateRange()
          }}
          sx={{
            cursor: "pointer",
            mt: 4,
            mb: 6,
            textAlign: "center",
            textDecoration: "none",
          }}
        >
          <UText
            sxProp={{
              flexBasis: "79.8%",
              color: "primary.main",
              textTransform: "uppercase",
              fontWeight: 500,
            }}
            variant={"body1"}
          >
            {t("billingHistory.loadMore")}
          </UText>
        </Link>
      ) : null}
    </Container>
  )
}
