import { FC } from "react"
import { useTranslation } from "react-i18next"

import { UText } from "../../../../components"
import { Grid } from "../../../../components/mui.components"

const EditHeader: FC = () => {
  const { t } = useTranslation("common")

  return (
    <Grid container direction={"row"} spacing={0} sx={{ py: 2 }}>
      <UText variant={"h5"}>{t("userProfile.edit")}</UText>
    </Grid>
  )
}

export default EditHeader
