import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import CloseIcon from "@mui/icons-material/Close"
import {
  Avatar,
  Backdrop,
  Box,
  Button,
  DialogTitle,
  IconButton,
  Alert,
  Grid,
} from "@mui/material"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"

import Scans from "@/modules/Records/Scans/Scans2"
import { PhotosAndXRays } from "@/UDTreat/left/PhotosAndXRaysForCreatingProcess"
import { checkIsThereASpecifiedPhoto } from "@/UDTreat/left/utils"
import OrderForms from "@/UDTreat/USmartForms/OrderForms"
import { useAppSelector, useAppDispatch } from "@/core/app/hooks"
import { setAlert } from "@/core/app/slices/alert/alertSlice"
import { RootState } from "@/core/app/store"
import { UAlert, UButton, UText } from "@/components/index"
import RefinePhotoList from "./RefinePhotoList/RefinePhotoList"
import { setUploadStls } from "../../core/app/slices/records/scans/scansSlice"
import { setPhotoTypeJson } from "../../core/app/slices/records/photograph/photographSlice"
import CloseIconImg from "@/assets/svgs/close.svg"
import { setIsCreateNewRefinement, setWidthAll } from "@/UDTreat/udTreatSlice"
import { resetRxRefinementFormAdd } from "@/core/app/slices/uassist/formSlice"
export const CreateRefinement = ({ onClose }) => {
  const { refinementNum, widthAll } = useAppSelector(
    (state: RootState) => state.udTreatService,
  )
  const dispatch = useAppDispatch()
  const { t } = useTranslation("common")
  const [step, setStep] = useState(0)
  const { uploadSTL } = useAppSelector((state) => state.scansService)
  const { photoTypeJson } = useAppSelector((state) => state.PhotosService)
  const [showAlert, setShowAlert] = useState<{
    isShow: boolean
    message?: string
  }>({ isShow: false })
  const { alertMsg, isError, btnText, btnAction } = useAppSelector(
    (state: RootState) => state.alert,
  )
  const changeToNextStep = () => {
    setShowAlert({ isShow: false })
    if (step === 0) {
      if (!uploadSTL.upper || !uploadSTL.lower) {
        // dispatch(
        //   setAlert({
        //     message: t("records.scans.invalidfileFormat"),
        //     isError: true,
        //   }),
        // )
        setShowAlert({
          isShow: true,
          message: t("records.scans.invalidfileFormat"),
        })
        return
      }
    } else if (step === 1) {
      const ret = checkIsThereASpecifiedPhoto(photoTypeJson as any)
      if (!ret.normalPosition || !ret.xrayPosition) {
        setShowAlert({
          isShow: true,
          message: t("records.dragAndDropDescriptions"),
        })
        return
      }
    }
    setStep(step + 1)
  }

  const getCurrentStepComponent = () => {
    if (step === 0) {
      return <Scans />
    } else if (step === 1) {
      return (
        <PhotosAndXRays
          isZoomoutAbled={true}
          isDragAbled={true}
          isDeleteAbled={true}
          refinementIndex={refinementNum}
        />
      )
    } else if (step === 2) {
      return (
        <Grid container justifyContent={"center"} gap={"45px"}>
          <Grid
            item
            display={"flex"}
            flexDirection={"column"}
            minWidth={"520px"}
            // ref={preview}
            height={"720px"}
            sx={{
              visibility: widthAll ? "visible" : "hidden",
              marginLeft: widthAll ? "0" : "-520px",
            }}
          >
            <RefinePhotoList setSelectedPreview={setSelectedPreview} />
          </Grid>

          <Grid item sx={{ width: 640 }}>
            <OrderForms isAdd={true} setStep={setStep} />
          </Grid>
        </Grid>
      )
    }
  }

  useEffect(() => {
    dispatch(
      setUploadStls({
        upper: null,
        lower: null,
      }),
    )
    dispatch(setPhotoTypeJson(null))
    return () => {
      // clear refinementAdd data
      dispatch(resetRxRefinementFormAdd())
      // close the refinement page
      dispatch(setIsCreateNewRefinement(false))
    }
  }, [])

  // useEffect(() => {
  //   if (alertMsg) {
  //     setShowAlert({isShow:true})
  //   }
  // }, [alertMsg])

  // const closeAlert = () => {
  //   setShowAlert({isShow:false})
  //   dispatch(setAlert({ message: "" }))
  // }
  // img zoom
  const [selectedPreview, setSelectedPreview] = useState("")
  // useEffect(() => {
  //   if (step !== 2 && widthAll) {
  //     dispatch(setWidthAll(false))
  //   }
  // }, [step])
  return (
    <Box
      id="createnewrefinement"
      sx={{
        backgroundColor: "white",
        width: widthAll ? "100%" : "60%",
        height: "100%",
        position: "absolute",
        top: "0",
        right: "0",
        zIndex: "10",
        overflowY: "auto",
        paddingTop: "40px",
      }}
    >
      {/* <DialogTitle id="alert-dialog-title">
        {"Creating A Refinement"}
      </DialogTitle> */}
      <Button
        variant="contained"
        sx={{
          position: "absolute",
          left: 20,
          top: 20,
          // display: step === 2 ? "" : "none",
        }}
        onClick={async () => {
          await dispatch(setWidthAll(!widthAll))
          window.dispatchEvent(new Event("resize"))
        }}
      >
        {widthAll ? `Show Initial` : `View New Scans and PHotos`}
      </Button>
      <IconButton
        aria-label="close"
        onClick={() => {
          onClose()
          if (widthAll) {
            dispatch(setWidthAll(false))
          }
        }}
        sx={{
          position: "absolute",
          right: 12,
          top: 10,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <Box
        id="context-container"
        sx={{
          display: "flex",
          justifyContent: "center",
          minHeight: "400px",
        }}
      >
        {getCurrentStepComponent()}
      </Box>
      <Box
        id="navigator"
        sx={{
          position: "relative",
          margin: "40px",
          display: "flex",
          justifyContent: step === 0 ? "end" : "space-between",
        }}
      >
        {step !== 0 && step !== 2 && (
          <Button
            variant={"outlined"}
            onClick={() => {
              setStep(step - 1 > -1 ? step - 1 : 0)
            }}
            sx={{ width: "92px", height: "36px" }}
          >
            <ChevronLeftIcon fontSize={"small"} sx={{ marginLeft: "3px" }} />
            {t("button.back")}
          </Button>
        )}
        {step !== 2 && (
          <Button
            onClick={() => {
              changeToNextStep()
            }}
            variant="contained"
            sx={{
              right: "10px",
              width: "92px",
              height: "36px",
            }}
          >
            {t("button.next")}
            <ChevronRightIcon sx={{ marginLeft: "3px" }} fontSize={"small"} />
          </Button>
        )}
      </Box>
      {showAlert.isShow && (
        <Alert sx={{ margin: "0px 20px" }} variant="outlined" severity="error">
          {showAlert.message}
        </Alert>
      )}

      {/* {alertMsg && showAlert && (
        <UAlert
          alertContent={
            <>
              <UText
                sxProp={{
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "143%",
                }}
              >
                {alertMsg}
              </UText>
              {!!btnText && !!btnAction && (
                <UButton
                  sxProp={{
                    width: "120px",
                    height: "30px",
                    color: "#ffffff",
                    border: "1px solid #ffffff",
                    borderRadius: "4px",
                    fontSize: "13px",
                    marginLeft: 2,
                    padding: "4px 10px",
                  }}
                  onClickHandler={btnAction}
                  btnText={btnText}
                />
              )}
            </>
          }
          variant={"filled"}
          severity={isError ? "error" : "success"}
          alertProps={{ paddingBottom: "10px" }}
          showAlert={showAlert}
          onClickHandler={closeAlert}
        ></UAlert>
      )} */}
      {selectedPreview && (
        <>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open
          >
            <Box
              display="flex"
              justifyContent="center"
              position="fixed"
              top="120px"
              bottom="120px"
              left="120px"
              right="120px"
              overflow="hidden"
            >
              <Box
                id="backdrop"
                position="relative"
                width="inherit"
                height="inherit"
              >
                <img
                  src={selectedPreview}
                  alt={"fullscreenimg"}
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                  loading="lazy"
                />
                <Box
                  component={"div"}
                  id="closeicon"
                  position="absolute"
                  top="0px"
                  right="0px"
                  sx={{ padding: "8px" }}
                >
                  <IconButton
                    size="medium"
                    sx={{
                      fontSize: "large",
                      backgroundColor: "divider",
                      padding: "0px",
                      ":hover": { backgroundColor: "divider" },
                    }}
                    onClick={() => setSelectedPreview("")}
                  >
                    <Box
                      component={"img"}
                      src={CloseIconImg}
                      alt={"Logo"}
                      loading="lazy"
                      sx={{ cursor: "pointer", padding: "12px" }}
                    />
                  </IconButton>
                </Box>
              </Box>
            </Box>
          </Backdrop>
        </>
      )}
    </Box>
  )
}
