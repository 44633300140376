import { IconButton, Box } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import AddIcon from "@mui/icons-material/Add"
import { UModalPopup } from "@/ui-component"
import { useTranslation } from "react-i18next"
import { useState } from "react"
import RemoveIcon from "@mui/icons-material/Remove"
import { ImgPreview } from "./imgPreview"

const PreviewCustomModal = ({ open, onClose, images }) => {
  const { t } = useTranslation("common")

  const [scale, setScale] = useState(1)

  const handleZoomIn = () => {
    setScale((prev) => Math.min(prev + 0.2, 3))
  }

  const handleZoomOut = () => {
    setScale((prev) => Math.max(prev - 0.2, 1))
  }

  return (
    <>
      <UModalPopup
        isAlert={open}
        title={
          <Box
            component={"div"}
            id="zoomControls"
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: 2,
              height: "100px",
              backgroundColor: "rgba(0, 0, 0, 0.87)",
            }}
          >
            <Box
              sx={{
                background: "rgba(255, 255, 255, 0.15)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: 2,
                height: "48px",
                width: "88px",
                borderRadius: "8px",
              }}
            >
              <IconButton
                size="large"
                sx={{
                  fontSize: "large",
                  padding: "0px",
                }}
                onClick={handleZoomOut}
              >
                <RemoveIcon sx={{ color: "background.default", width: "24px" }} />
              </IconButton>
              <IconButton
                size="large"
                sx={{
                  fontSize: "large",
                  padding: "0px"
                }}
                onClick={handleZoomIn}
              >
                <AddIcon sx={{ color: "background.default", width: "24px" }} />
              </IconButton>
            </Box>

            <IconButton
              size="large"
              sx={{
                fontSize: "large",
                backgroundColor: "divider",
                padding: "0px",
                position: "absolute",
                right: "24px",
                ":hover": { backgroundColor: "divider" },
              }}
              onClick={() => {
                onClose()
                setScale(1)
              }}
            >
              <CloseIcon sx={{ color: "#FFFFFF8F", width: "35px", height: "35px" }} />
            </IconButton>
          </Box>
        }
        sxModalProps={{
          ".MuiDialog-paper": {
            width: "100% !important",
            height: "100% !important",
            maxWidth: "xl",
            backgroundColor: "#000000B2",
          },
          "#titleCntr": {
            display: "block",
          },
        }}
        content={
          <ImgPreview list={images} scale={scale} setScale={setScale}/>
        }
      />
    </>
  )
}

export default PreviewCustomModal
