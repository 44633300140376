import { FC, useEffect, useState } from "react"
import { Navigate, Route, Routes, useParams } from "react-router-dom"

import { ULoading } from "../../components"
import { Box } from "../../components/mui.components"
import { useAppDispatch } from "../../core/app/hooks"
import { getCaseByPatient } from "../../core/app/slices/case/caseThunkApi"
import { resetCase } from "../../core/app/slices/clinical/clinicalSlice"
import { fetchPatientById } from "../../core/app/slices/patients/patientThunkApi"
import { resetPhotoLists } from "../../core/app/slices/records/photograph/photographSlice"
import { resetXrays } from "../../core/app/slices/records/xrays/xraysSlice"
import { updateCurrentOrgId } from "../../core/app/slices/user/userSlice"
import RouteByStatus from "../../routes/RouteByStatus"
import Confirmation from "../Uassist/Summary/Confirmation"

import AttentionBanner from "./Overview/AttentionBanner"
import AddRefinement from "./Overview/components/AddRefinement"
import CourceRefinement from "./Overview/components/CourceRefinement"
import Refinement from "./Overview/components/Refinement"
import Overview from "./Overview/Overview"
import RenderByCaseStatus from "./Overview/RenderByCaseStatus"
import UpdatePatient from "./Overview/UpdatePatient"

const OverviewModule: FC = () => {
  const { patientId, orgId } = useParams()
  const [loading, setLoading] = useState<boolean>(true)

  const dispatch = useAppDispatch()

  useEffect(() => {
    if (!orgId) return
    dispatch(updateCurrentOrgId(orgId))
  }, [dispatch, orgId])

  useEffect(() => {
    dispatch(getCaseByPatient({ patientId: patientId, isCaseArchived: true }))
    dispatch(fetchPatientById({ patientId }))
    setLoading(false)
  }, [patientId])
  //Clean up clincal zipNames, zipList, and other data to avoid entering clincal with errors
  useEffect(() => {
    return () => {
      dispatch(resetCase())
      dispatch(resetXrays())
      dispatch(resetPhotoLists())
    }
  }, [])
  if (loading) {
    return <ULoading isLoading={true} />
  } else {
    return (
      <Box
        maxWidth={"lg"}
        id="overviewlayout"
        sx={{ alignSelf: "center", width: "100%" }}
      >
        <Routes>
          <Route element={<Overview />}>
            <Route path={""} element={<RenderByCaseStatus />} />
            <Route path={"settings"} element={<UpdatePatient />} />
            <Route
              path={"case/:caseId/attentionBanner/*"}
              element={<AttentionBanner />}
            />

            <Route path={"*"} element={<Navigate to={""} replace />} />
          </Route>
          <Route element={<RouteByStatus />}>
            <Route element={<Refinement />}>
              <Route
                path={"case/:caseId/courserefinement/*"}
                element={<CourceRefinement />}
              />
              <Route
                path={"case/:caseId/form/refinement"}
                element={<AddRefinement />}
              />
              <Route
                path={"case/:caseId/refinement/confirmation"}
                element={<Confirmation isRefinement={true} />}
              />
              <Route path={"*"} element={<Navigate to={""} replace />} />
            </Route>
          </Route>
        </Routes>
      </Box>
    )
  }
}

export default OverviewModule
