import type { FC } from "react"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { Box, Typography, Link, Grid, Button } from "@mui/material"
import { useParams } from "react-router-dom"
import successSvg from "../assets/formOk/success.svg"
import DialogBox from "@/modules/Clinical/components/DialogBox/dialogBox"
import { useMyNavigation } from "@/hooks/useMyNavigation"
import { setFormOkPopup } from "../udTreatSlice"
const FormOk: FC = ({ actionType = "" }) => {
  const { t } = useTranslation("common")
  const { caseId } = useParams()
  const navigate = useMyNavigation()
  return (
    <DialogBox
      open={actionType === "success"}
      title={"Thank you"}
      actions={
        <Button
          sx={{
            color: "#fff",
            padding: "8px 14px 8px 22px",
            borderRadius: "4px",
            backgroundColor: "#215ECD",
          }}
          variant="contained"
          onClick={() => {
            navigate("/patients")
          }}
        >
          <span style={{ fontSize: "15px", fontWeight: "500" }}>
            {"go to patient list"}
          </span>
        </Button>
      }
      titleStyle={{ background: "#EDF6F9", fontSize: "26px" }}
      actionStyle={{ background: "#EDF6F9" }}
      content={
        <Box>
          <Typography
            sx={{ marginBottom: "4px", padding: "8px 0" }}
            variant="h6"
          >{`Thank you for submitting your case to uAssist`}</Typography>
          <Grid container>
            <Grid item sx={{ marginRight: "8px" }}>
              <img src={successSvg} />
            </Grid>
            <Grid item>
              <Typography variant="subtitle1">{`Your case has been submitted`}</Typography>
            </Grid>
          </Grid>
          {/* <Link
        href="#"
        variant="body1"
        sx={{ marginLeft: "28px" }}
      >{`Case#${caseId}`}</Link> */}
          <Typography
            sx={{ marginLeft: "28px" }}
          >{`Case#${caseId}`}</Typography>
          <Typography
            variant="body2"
            sx={{ marginTop: "16px", lineHeight: "143%" }}
          >{`Our uAssistants will begin working on your case shortly! Please keep an eye on the case status on the Patient List screen for any updates.`}</Typography>
        </Box>
      }
    />
  )
}
export default FormOk
