import { setRef } from "@mui/material"
import type { PayloadAction } from "@reduxjs/toolkit"
import { createSlice } from "@reduxjs/toolkit"
export function loadUcloud1_1State() {
  const { VITE_APP_TYPE } = import.meta.env
  if (VITE_APP_TYPE) {
    console.log("🚀 ~ loadUcloud1_1State ~ VITE_APP_TYPE:", VITE_APP_TYPE)
    return VITE_APP_TYPE === "ucloud"
  }

  try {
    const serializedState = localStorage.getItem("ucloud1_1")
    if (!serializedState) return true
    return JSON.parse(serializedState) as boolean
  } catch (e) {
    return true
  }
}
export async function saveUcloud1_1State(state: boolean) {
  try {
    const serializedState = JSON.stringify(state)
    localStorage.setItem("ucloud1_1", serializedState)
  } catch (e) {
    /* do nothing */
  }
}
export const udTreatSlice = createSlice({
  name: "udTreatSlice",
  initialState: {
    curCaseMode: "NewCase", // NewCase, UDPlan, UDModi, UDView, WeDesign,
    ucloud1_1: loadUcloud1_1State(), // whether it's ucloud 1.1 else it's ucloud 2.
    curLeftPage: "",
    curRightPage: "",
    workflowMode: "aidesign", // aidesign, chairside, smartrx, retainer, utouch
    showBottomStatusBar: true, // whether to show bottom ms
    bottomStatusMsg: "", // status message
    bottomPrgress: false, // show progress
    updateStaging: false,
    updateSetup: false,
    smartRxShow: false, // status smartRx (new design)
    lockCaseForEdit: "", // "", "Start", "Locked", "LockFailed"
    isCasePreview: true, // case is empty or with only scan / stls
    // For we design only
    showWeStageBar: false,
    showKFEditor: false, // to show key frame editor for weDesign
    curArch: "all", // up, low, all
    currentStep: 0,
    stageShowType: "", //normal, compare
    stageCompareLoadData: false, //false-->true: load
    showTMChart: false,
    caseRAtype: "", // create case choose type: retainer,alinger
    hasUploadSTLs: [false, false], //Whether or not to upload the [upper,lower] stls in the Upload screen
    isNewCaseHasUpload: false, //Scenarios for new cases, whether or not stl is uploaded
    selTooth: 0,
    switchViewEdit: false, // default view only
    openReopenCaseDlg: false,
    switchGum: true,
    planList: [],
    stageDataLen: {
      up: 0,
      low: 0,
    },
    reportData: {
      attachment: {},
      toothlist: [],
      iprData: {},
    },
    uassistRxShow: false,
    isCreateNewRefinement: false,
    refinementNum: 0, //for new refinement
    openHistorySplitView: false,
    // stageObj
    stageObjBySlice: {
      upList: [],
      downList: [],
      up_current: 0,
      down_current: 0,
    },
    // refinement screen control
    widthAll: false,
  },
  reducers: {
    setCurCaseMode(state, action: PayloadAction<string>) {
      state.curCaseMode = action.payload
    },
    setCurLeftPage(state, action: PayloadAction<string>) {
      state.curLeftPage = action.payload
    },
    setCurRightPage(state, action: PayloadAction<string>) {
      state.curRightPage = action.payload
    },
    setWorkflowMode(state, action: PayloadAction<string>) {
      state.workflowMode = action.payload
    },
    setShowBottomStatusBar(state, action: PayloadAction<boolean>) {
      state.showBottomStatusBar = action.payload
    },
    setBottomStatusMsg(state, action: PayloadAction<string>) {
      state.bottomStatusMsg = action.payload
    },
    setBottomProgress(state, action: PayloadAction<boolean>) {
      state.bottomPrgress = action.payload
    },
    setUpdateSetup(state) {
      state.updateSetup = !state.updateSetup
    },
    setUpdateStaging(state) {
      state.updateStaging = !state.updateStaging
    },
    setSmartRxShow(state, action: PayloadAction<boolean>) {
      state.smartRxShow = action.payload
    },
    setUassistRxShow(state, action: PayloadAction<boolean>) {
      state.uassistRxShow = action.payload
    },
    setLockCaseForEdit(state, action: PayloadAction<string>) {
      state.lockCaseForEdit = action.payload
    },
    setIsCasePreview(state, action: PayloadAction<boolean>) {
      state.isCasePreview = action.payload
    },
    setShowWeStageBar(state, action: PayloadAction<boolean>) {
      state.showWeStageBar = action.payload
    },
    setShowKFEditor(state, action: PayloadAction<boolean>) {
      state.showKFEditor = action.payload
    },
    setCurArch(state, action: PayloadAction<string>) {
      state.curArch = action.payload
    },
    setCurrentStep(state, action: PayloadAction<number>) {
      state.currentStep = action.payload
    },
    setStageShowType(state, action: PayloadAction<string>) {
      state.stageShowType = action.payload
    },
    setStageCompareLoadData(state, action: PayloadAction<boolean>) {
      state.stageCompareLoadData = action.payload
    },
    setUcloud1_1(state, action: PayloadAction<boolean>) {
      state.ucloud1_1 = action.payload
      saveUcloud1_1State(action.payload)
    },
    setCaseRAtype(state, action: PayloadAction<string>) {
      state.caseRAtype = action.payload
    },
    setHasUploadSTLs(state, action: PayloadAction<boolean[]>) {
      state.hasUploadSTLs = action.payload
    },
    setIsNewCaseHasUpload(state, action: PayloadAction<boolean>) {
      state.isNewCaseHasUpload = action.payload
    },
    setSelTooth(state, action: PayloadAction<number>) {
      state.selTooth = action.payload
    },
    setSwitchViewEdit(state, action: PayloadAction<boolean>) {
      state.switchViewEdit = action.payload
    },
    setOpenReopenCaseDlg(state, action: PayloadAction<boolean>) {
      state.openReopenCaseDlg = action.payload
    },
    setSwitchGum(state, action: PayloadAction<boolean>) {
      state.switchGum = action.payload
    },
    setPlanList(state, action: PayloadAction<string[]>) {
      const txArr = []
      action.payload.map((i, j) => {
        txArr.push({
          txName: i.trim(),
          index: j + 1,
        })
      })
      state.planList = txArr
    },
    setStageDataLen(state, action: PayloadAction<{ up: number; low: number }>) {
      state.stageDataLen = action.payload
    },
    setReportData(
      state,
      action: PayloadAction<{
        attachment: object
        toothlist: any[]
        iprData: object
      }>,
    ) {
      state.reportData = action.payload
    },
    setIsCreateNewRefinement(state, action: PayloadAction<boolean>) {
      state.isCreateNewRefinement = action.payload
    },
    setRefinementNum(state, action: PayloadAction<number>) {
      state.refinementNum = action.payload
    },
    setOpenHistorySplitView(state, action: PayloadAction<boolean>) {
      state.openHistorySplitView = action.payload
    },
    setStageObjFunc(state, action: PayloadAction<boolean>) {
      state.stageObjBySlice = action.payload
    },
    setWidthAll(state, action: PayloadAction<boolean>) {
      state.widthAll = action.payload
    },
  },
})

export const {
  setHasUploadSTLs,
  setCurCaseMode,
  setCurLeftPage,
  setCurRightPage,
  setWorkflowMode,
  setShowBottomStatusBar,
  setBottomStatusMsg,
  setBottomProgress,
  setUpdateSetup,
  setUpdateStaging,
  setSmartRxShow,
  setUassistRxShow,
  setShowWeStageBar,
  setLockCaseForEdit,
  setIsCasePreview,
  setCurArch,
  setShowKFEditor,
  setCurrentStep,
  setStageShowType,
  setStageCompareLoadData,
  setUcloud1_1,
  setCaseRAtype,
  setIsNewCaseHasUpload,
  setSelTooth,
  setSwitchViewEdit,
  setOpenReopenCaseDlg,
  setSwitchGum,
  setPlanList,
  setStageDataLen,
  setReportData,
  setIsCreateNewRefinement,
  setRefinementNum,
  setOpenHistorySplitView,
  setStageObjFunc,
  setWidthAll,
} = udTreatSlice.actions
