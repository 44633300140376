import { FC } from "react"

import { Box } from "../../../../../../ui-component/mui.components"
import { dentalChartMenuList } from "../../../../../Uassist/DentalPortrait/component/toothObject"

const DentalMenu: FC = () => {
  const _dentalChartMenuList = dentalChartMenuList.slice(
    0,
    dentalChartMenuList.length - 1,
  )
  return (
    <Box
      sx={{
        height: 48,
        display: "flex",
        flexWrap: "wrap",
        marginTop: 2,
      }}
    >
      {_dentalChartMenuList.map((item, index) => {
        if (index % 2 !== 0) {
          return
        }
        const shortName1 = item.substring(
          item.lastIndexOf("(") + 1,
          item.length - 1,
        )
        const name1 = item.substring(0, item.lastIndexOf("("))
        if (index === _dentalChartMenuList.length - 1) {
          return (
            <Box
              key={item}
              sx={{
                display: "flex",
                fontSize: 12,
                fontStyle: "normal",
                fontWeight: 400,
                marginRight: 2,
                padding: "4px 8px",
              }}
            >
              <Box sx={{ color: "#263238", marginRight: 0.5 }}>
                {shortName1}
              </Box>
              <Box
                sx={{
                  color: "rgba(0, 0, 0, 0.60)",
                }}
              >
                {name1}
              </Box>
            </Box>
          )
        }
        const shortName2 = _dentalChartMenuList[index + 1].substring(
          _dentalChartMenuList[index + 1].lastIndexOf("(") + 1,
          _dentalChartMenuList[index + 1].length - 1,
        )
        const name2 = _dentalChartMenuList[index + 1].substring(
          0,
          _dentalChartMenuList[index + 1].lastIndexOf("("),
        )

        return (
          <Box key={item}>
            <Box
              sx={{
                display: "flex",
                fontSize: 12,
                fontStyle: "normal",
                fontWeight: 400,
                marginRight: 2,
                marginBottom: 0.5,
                padding: "4px 8px",
              }}
            >
              <Box sx={{ color: "#263238", marginRight: 0.5 }}>
                {shortName1}
              </Box>
              <Box
                sx={{
                  color: "rgba(0, 0, 0, 0.60)",
                }}
              >
                {name1}
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                fontSize: 12,
                fontStyle: "normal",
                fontWeight: 400,
                marginRight: 2,
                padding: "4px 8px",
              }}
              key={item}
            >
              <Box sx={{ color: "#263238", marginRight: 0.5 }}>
                {shortName2}
              </Box>
              <Box
                sx={{
                  color: "rgba(0, 0, 0, 0.60)",
                }}
              >
                {name2}
              </Box>
            </Box>
          </Box>
        )
      })}
    </Box>
  )
}
export default DentalMenu
