import { useEffect, useState } from "react"
import { flushSync } from "react-dom"
import { Launch } from "@mui/icons-material"
import CloseIcon from "@mui/icons-material/Close"
import EditFilled from "@mui/icons-material/Edit"
import ExpandMoreFilled from "@mui/icons-material/ExpandMore"
import {
  Avatar,
  Backdrop,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  Select,
  Tooltip,
  Typography,
  CircularProgress,
} from "@mui/material"

import { useAppDispatch, useAppSelector } from "@/core/app/hooks"
import { FileItem } from "@/core/app/slices/records/photograph/photograph.type"
import {
  downloadPhotographs1_1,
  downloadPhotoJSON,
  fetchFilesList,
} from "@/core/app/slices/records/photograph/photographThunkApi"

import {
  setIsCreateNewRefinement,
  setRefinementNum,
  setOpenHistorySplitView,
  setStageCompareLoadData,
  setStageShowType,
} from "@/UDTreat/udTreatSlice"
import { downloadProfilePhoto } from "@/core/app/slices/records/xrays"
import { RootState } from "@/core/app/store"
import CaseStatusAction from "@/modules/Patient/Overview/components/CaseStatusAction"
import EditPatientInfoForm from "@/modules/Patient/Overview/components/EditPatientInfoForm"
import Scans from "@/modules/Records/Scans/Scans2"

import { UText } from "../../ui-component"
import { refinementModule } from "../../gluelayer/core/modules/refinement"
import { treatView, caseManagement, viewControlInTreatment, initialfinal } from "@/gluelayer"
//import { setStageShowType } from "@/UDTreat/udTreatSlice"

import { fetchRefinementZipFile } from "@/core/app/slices/clinical/clinicalThunkApi"

const EditUser = ({ open, handleClose, userName }) => {
  const { patientData } = useAppSelector(
    (state: RootState) => state.patientService,
  )
  const dispatch = useAppDispatch()
  useEffect(() => {
    if (patientData?.id) {
      dispatch(
        downloadProfilePhoto({
          patientId: patientData.id,
          fileName: `${patientData.id}.png`,
        }),
      )
    }
  }, [dispatch, patientData])

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle sx={{ backgroundColor: "#E4E8EA" }}>
        <Typography variant="h6">{userName}</Typography>
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent sx={{ padding: "0px" }}>
        <EditPatientInfoForm
          patientData={patientData}
          handleClose={handleClose}
          isFromClinicalView={true}
        ></EditPatientInfoForm>
      </DialogContent>
      {/* <DialogActions sx={{ background: "#E4E8EA" }}>
        <Button onClick={handleClose} size="large">
          CANCEL
        </Button>
        <Button variant="contained" type="submit" form="userinfo" size="large">
          SAVE
        </Button>
      </DialogActions> */}
    </Dialog>
  )
}

const typeNameToShowName = {
  composite: "Composite",
  front_smile: "Smile",
  front_normal: "Front",
  lateral_photo: "Profile",
  upper: "Upper Occlusal",
  lower: "Lower Occlusal",
  front: "Anterior",
  left: "Left",
  right: "Right",
  x_lateral: "Ceph",
  x_panorama: "Pano",
}

const PhotoThumbMap: Record<string, string> = {}
const ImageUrlMap: Record<string, string> = {}
let curRefinementZipFolderNames: string[]
let curSelectedRefinement: string = ""
let curSelectedRefinementData: string = ""
export const PatientNameID = ({
  firstName,
  lastName,
  caseId,
  patientId,
  creatDate,
}) => {
  const [hoverAvatar, sethoverAvatar] = useState(false)
  const [openEditUser, setopenEditUser] = useState(false)
  const dispatch = useAppDispatch()

  const [frontPhoto, setFrontPhoto] = useState()
  const [showHistoryList, setShowHistoryList] = useState(false)

  const [photoURL, setPhotoURL] = useState<string>()

  const [openRefDialog, setOpenRefDialog] = useState<boolean>(false)

  const { profile } = useAppSelector((state: RootState) => state.xrayService)

  const [historyList, setHistoryList] = useState([])
  const [oriCreateDate, setOriCreateDate] = useState("")
  const [currentRefinement, setCurrentRefinement] = useState({
    name: "",
    date: "",
  })
  const [lastRefinement, setLastRefinement] = useState({ name: "", date: "" })
  const [showSplitCloseBtn, setShowSplitCloseBtn] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [showDropdownBtn, setShowDropdownBtn] = useState(false)

  const { refinementReady } = useAppSelector(
    (state: RootState) => state.clinicalService,
  )

  const { refinementZips } = useAppSelector(
    (state: RootState) => state.clinicalService,
  )
  const {
    user: { current_orgId },
  } = useAppSelector((state: RootState) => state.userService)
  const { stageCompareLoadData, isCreateNewRefinement } = useAppSelector(
    (state: RootState) => state.udTreatService,
  )

  useEffect(() => {
    downLoadPhotoJson()

    return () => {
      if (photoURL) URL.revokeObjectURL(photoURL)
      setPhotoURL(null)
      dispatch(setRefinementNum(0))
    }
  }, [])

  const capitalizeFirstLetter = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1)
  }

  useEffect(() => {
    if (creatDate !== "" && caseManagement.isCaseInit() && refinementReady) {
      setShowDropdownBtn(true)
      setOriCreateDate(creatDate)
      const refinementHistoryList = refinementModule.getRefinementDateInfo()
      const orgId = current_orgId

      const firstKey = Object.keys(refinementHistoryList)[0]
      if (firstKey && refinementHistoryList[firstKey] !== "") {
        setCurrentRefinement({
          name: firstKey,
          date: refinementHistoryList[firstKey],
        })
        setLastRefinement({
          name: firstKey,
          date: refinementHistoryList[firstKey],
        })
      } else {
        setCurrentRefinement({ name: "initial plan", date: creatDate })
        setLastRefinement({ name: "initial plan", date: creatDate })
      }

      const tempList = []
      for (const item in refinementHistoryList) {
        if (item !== "original")
          tempList.push({
            name: item,
            date:
              refinementHistoryList[item] !== ""
                ? refinementHistoryList[item]
                : creatDate,
          })
      }
      tempList.push({ name: "initial plan", date: creatDate })
      setHistoryList([...tempList])
      dispatch(setRefinementNum(tempList.length))
      console.log("refinementHistoryList", tempList)
    }
  }, [creatDate, caseManagement.isCaseInit(), refinementReady])

  useEffect(() => {
    if (Object.keys(refinementZips).length > 0) {
      caseManagement
        .AddARefinementHistory(
          curRefinementZipFolderNames,
          refinementZips as Record<string, Blob>,
        )
        .then(() => {
          openRefinementHistory(
            curSelectedRefinement,
            curSelectedRefinementData,
          )
        })
    }
  }, [refinementZips])

  useEffect(() => {
    if (profile) {
      if (profile[0] && profile[0].src) setPhotoURL(`url(${profile[0].src})`)
    }

    if (frontPhoto && !profile[0])
      setPhotoURL(`url(${URL.createObjectURL(frontPhoto)})`)

    if (!frontPhoto && !profile[0]) setPhotoURL(null)
  }, [frontPhoto, profile])

  const downLoadPhotoJson = async () => {
    const fileList = await dispatch(fetchFilesList({ patientId, caseId }))
    const listData = fileList.payload.data
    if (listData.response_code !== 200) {
      console.warn("Fetch flle list false::", listData)
      return
    }

    const typeFileInfo = (listData.result as Array<FileItem>).filter((item) => {
      return (
        item.file_name === "type.json" &&
        item.attachment_type === "photopositiontype"
      )
    })

    console.log("🚀 ~ typeFileInfo ~ typeFileInfo:", typeFileInfo)
    if (typeFileInfo && typeFileInfo.length > 0) {
      const ret = await dispatch(
        downloadPhotoJSON({
          caseId,
          patientId,
        }),
      )
      const retData = ret.payload
      if (retData.data) {
        const typeJson: any = retData.data
        console.log("🚀 ~ downLoadPhoto ~ typeJson:", typeJson)
        // PhotoThumbMap = typeJson.thumbs ? {...typeJson.thumbs} : {};
        downloadFrontPhoto(typeJson)
        // setIsNoData(false);
      }
    }
  }
  const downloadFrontPhoto = async (photoJson) => {
    for (const key in photoJson) {
      if (key === undefined || key === "undefined") continue
      if (photoJson[key] === "front_normal") {
        console.log("front photo name:  " + key)

        const photos = await dispatch(
          downloadPhotographs1_1({ patientId, caseId, fileNames: [key] }),
        )
        console.log("🚀 ~ downloadFrontPhoto ~ photos:", photos)

        const frontPhoto = photos.payload[key]
        console.log("🚀 ~ downloadFrontPhoto ~ frontPhoto:", frontPhoto)
        if (frontPhoto) {
          setFrontPhoto(frontPhoto)
        }
        break // 如果只需要第一个找到的，可以加上这行，否则会输出所有满足条件的键名
      }
    }
  }

  const openRefinementHistory = async (name, date) => {
    const historyName = name === "initial plan" ? "original" : name
    if (currentRefinement.name === lastRefinement.name) {
      treatView.Open(true)
      treatView.RefinementHistory(historyName)
      treatView.SetBackgroundPicture(1, "backgroundvtk.png")
      dispatch(setOpenHistorySplitView(true))
    } else {
      treatView.SwitchRefinementHistory(historyName)
    }
    setShowHistoryList(false)
    setShowSplitCloseBtn(true)
    setCurrentRefinement({ name, date })
    flushSync(() => setIsLoading((pre) => !pre))
    dispatch(setStageShowType("compare"))
    dispatch(setStageCompareLoadData(!stageCompareLoadData))
    viewControlInTreatment.hoverToothToggle(false)
    viewControlInTreatment.setSpacecheckVisibility(false)
    viewControlInTreatment.setOcclusionVisibility(false)
    viewControlInTreatment.setSuperimposeVisibility(false)
    viewControlInTreatment.setGridVisibility(false)
    initialfinal.setInitialFinalEnabled(false)
  }

  const historyItemClick = (name, date) => {
    const wasmName = name === "initial plan" ? "original" : name
    if (name === lastRefinement.name && name === currentRefinement.name) {
      flushSync(() => setIsLoading((pre) => !pre))
      return
    }
    if (name === lastRefinement.name) {
      treatView.Open(false)
      dispatch(setStageShowType("normal"))
      dispatch(setOpenHistorySplitView(false))
      setShowSplitCloseBtn(false)
      setCurrentRefinement({
        name: lastRefinement.name,
        date: lastRefinement.date,
      })
      setShowHistoryList(false)
      flushSync(() => setIsLoading((pre) => !pre))
      return
    }
    const folderNames = refinementModule.getDownloadDataNames(wasmName)

    curRefinementZipFolderNames = []
    let refinementFiles = []
    let AllExisted = true
    for (let j = 0; j < folderNames.size(); j++) {
      const folderName = folderNames.get(j)

      const isExisted = caseManagement.IsRefinementItemExisted(folderName)
      if (isExisted == false) {
        curRefinementZipFolderNames.push(folderName)
        refinementFiles.push(folderName + ".zip")
        AllExisted = false
      }
    }

    if (AllExisted == true) {
      openRefinementHistory(name, date)
    } else {
      curSelectedRefinement = name
      curSelectedRefinementData = date

      dispatch(
        fetchRefinementZipFile({
          orgId: "",
          patientId,
          caseId,
          refinementFiles,
        }),
      )
    }
  }

  const HistoryItem = ({ id, name, date }) => {
    return (
      <Box sx={{ display: "flex" }}>
        <Typography
          variant="caption"
          sx={{
            color: name === lastRefinement.name ? "#9C9C9C" : "#000",
            fontFamily: "Poppins",
            fontSize: "15px",
            fontWeight: "400",
            letterSpacing: "0.46px",
            marginTop: "7px",
            marginBottom: "7px",
            marginLeft: "15px",
            display: "inline-block",
            width: "auto",
            "&:hover": {
              cursor: "pointer",
            },
          }}
          onClick={async () => {
            flushSync(() => setIsLoading((pre) => !pre))
            await new Promise((resolve) => setTimeout(resolve, 0))
            historyItemClick(name, date)
          }}
        >
          {` #` + id + " | " + capitalizeFirstLetter(name) + " - " + date}
        </Typography>
        {/* <Launch
          sx={{
            display: "inline-block",
            position: "absolute",
            right: "15px",
            marginTop: "9px",
            width: 20,
            height: 20,
            color: id === caseId ? "#9C9C9C" : "#000",
            "&:hover": {
              cursor: "pointer",
            },
          }}
        ></Launch> */}
      </Box>
    )
  }
  const HistoryListForm = () => {
    return (
      <FormControl
        sx={{
          width: "331px",
          position: "absolute",
          top: "calc(100% + 8px)",
          left: 80,
          zIndex: 1000,
          background: "#FFF",
          paddingBottom: 1,
          paddingTop: 1,
          borderRadius: "4px",
          boxShadow: 5,
        }}
      >
        {historyList.map((item, index) => {
          return (
            <HistoryItem
              key={"history-item-" + index}
              id={caseId}
              name={item.name}
              date={item.date}
            />
          )
        })}
        {/* <Box
          sx={{ display: "flex", background: "#F5F5F5" }}
          onClick={() => {
            setOpenRefDialog(true)
            setShowHistoryList(false)
            treatView.startSplitScreenToCreateRefinement()
            dispatch(setIsCreateNewRefinement(true))
            console.log("create new refinement2")
          }}
        >
          <Typography
            variant="caption"
            sx={{
              color: "#000",
              fontFamily: "Poppins",
              fontSize: "15px",
              fontWeight: "400",
              letterSpacing: "0.46px",
              marginTop: "7px",
              marginBottom: "5px",
              marginLeft: "15px",
              display: "inline-block",
              width: "auto",
              "&:hover": {
                cursor: "pointer",
              },
            }}
          >
            {"Create new Refinement"}
          </Typography>
        </Box> */}
      </FormControl>
    )
  }
  const closeHistoryCompare = () => {
    treatView.Open(false)
    setShowSplitCloseBtn(false)
    dispatch(setStageShowType("normal"))
    dispatch(setOpenHistorySplitView(false))
    setCurrentRefinement({
      name: lastRefinement.name,
      date: lastRefinement.date,
    })
  }
  return (
    <Box
      sx={{
        display: "flex",
        justifyItems: "center",
        alignItems: "center",
      }}
    >
      <Backdrop
        sx={{
          backgroundColor: "transparent",
          zIndex: (theme) => theme.zIndex.modal + 10,
        }}
        open={isLoading}
      >
        <CircularProgress key={isLoading ? "open" : "closed"}/>
      </Backdrop>
      <Avatar
        variant="circular"
        sx={{
          bgcolor: "#FFF",
          width: 72,
          height: 72,
          fontSize: "32px",
          color: "rgba(0, 0, 0, 0.60)",
          fontweight: "500",
          position: "relative",
          backgroundImage: photoURL ? photoURL : "none",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
        onMouseEnter={() => {
          sethoverAvatar(true)
        }}
        onMouseLeave={() => {
          sethoverAvatar(false)
        }}
      >
        {!photoURL &&
          firstName.substring(0, 1).toUpperCase() +
            lastName.substring(0, 1).toUpperCase()}
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
            position: "absolute",
          }}
          open={hoverAvatar}
          onClick={() => {
            setopenEditUser(true)
          }}
        >
          <IconButton size="medium">
            <EditFilled sx={{ color: "#FFF" }}></EditFilled>
          </IconButton>
        </Backdrop>
      </Avatar>

      <Box
        sx={{
          marginLeft: "12px",
          height: "54px",
          display: isCreateNewRefinement ? "none" : "",
        }}
      >
        <Tooltip
          arrow
          title="Notes: Patient referred by Dr. Newey. This is a long tooltip to show what it would look like if the user enters a lot of text regarding the patient"
          placement="right"
          followCursor
        >
          <Typography
            variant="caption"
            sx={{
              color: "#FFF",
              fontFamily: "Poppins",
              fontSize: "15px",
              fontStyle: "normal",
              fontWeight: "600",
              letterSpacing: "0.46px",
              textTransform: "uppercase",
              "&:hover": { textDecoration: "underline" },
            }}
          >
            {firstName + " " + lastName}
          </Typography>
        </Tooltip>

        <Box>
          <Typography
            variant="caption"
            sx={{
              color: "#FFF",
              fontFamily: "Poppins",
              fontSize: "15px",
              fontStyle: "normal",
              fontWeight: "600",
              letterSpacing: "0.46px",
              textTransform: "uppercase",
            }}
          >
            {showDropdownBtn
              ? ` #` +
                caseId +
                " | " +
                currentRefinement.name +
                " - " +
                currentRefinement.date
              : `#${caseId}`}
          </Typography>
          {showDropdownBtn && (
            <IconButton
              aria-label="more"
              sx={{
                width: 28,
                height: 28,
                transform: showHistoryList ? "scaleY(-1)" : "scaleY(1)",
              }}
              onClick={() => {
                setShowHistoryList(!showHistoryList)
              }}
            >
              <ExpandMoreFilled sx={{ color: "rgba(255, 255, 255, 1)" }} />
            </IconButton>
          )}
          {showHistoryList && <HistoryListForm />}
        </Box>
      </Box>
      {showSplitCloseBtn && (
        <Button
          aria-label="close"
          onClick={closeHistoryCompare}
          sx={{
            background: (theme) => theme.palette.grey[300],
            position: "absolute",
            left: "calc(46.5% - 40px)",
            top: 70,
            width: "81px",
            height: "48px",
            borderRadius: 6,
            boxShadow: 4,
            color: "black",
            "&:hover": {
              backgroundColor: (theme) => theme.palette.grey[500],
            },
          }}
        >
          Close
        </Button>
      )}
      <EditUser
        open={openEditUser}
        handleClose={() => setopenEditUser(false)}
        userName={firstName + " " + lastName}
      ></EditUser>
    </Box>
  )
}
