import { createSlice } from "@reduxjs/toolkit"

import { LABELMAP } from "../../../../modules/Clinical/Treat/column"
import { UI_PERMISSION } from "../../../utils/access/permissionConstants"
import { getRandomInt } from "../../../utils/formatters"

import {
  IClinicalSettings,
  IGetCardPayload,
  IUserPreferences,
  IUserProfile,
  IUserServiceState,
} from "./user.type"
import {
  activateUser,
  createUpdateOrgShippingAddress,
  deleteShippingAddress,
  fetchAddressSuggestions,
  fetchClinicalSettings,
  fetchCountryList,
  fetchHelpDocuments,
  fetchIfuDoc,
  fetchReleaseNotes,
  fetchShippingAddressList,
  fetchStateLists,
  fetchUserPreferences,
  fetchUserProfile,
  forgotPassword,
  getAccountDetail,
  getCreditCardDetails,
  getPermissions,
  getSystemMessage,
  getUDAppExe,
  resetPassword,
  saveCreditCard,
  updateClinicalSettings,
  updateCreditCard,
  updatePassword,
  updateUserPreferences,
  updateUserProfile,
} from "./userApis"

const initialState: IUserServiceState = {
  user: {
    authorized_roles: [],
    global_permissions: [],
    app_permissions: [],
    email: "",
    name: "",
    my_orgId: "", // my own orgId, for Dr. it's same as current_orgId
    current_orgId: "", // this can be changed for uAssist user
    user_id: "",
    uid: "",
    preferences: {} as IUserPreferences,
    isAllowToViewAllOrg: false,
  },
  suggestedAddressData: [],
  userProfile: {
    firstName: "",
    lastName: "",
    username: "",
    user_type: "",
    org_name: "",
    createDate: "",
    updateDate: "",
  } as IUserProfile,
  clinicalSettings: {
    DefinAttachmentTemplate: [],
    CuttingLineCP: 0,
    NumberSystem: "Pedro",
    CuttingLineCC: 0,
    CuttingLineOffset: 0,
    AttachmentStage: 0,
    toothLabel: [],
  } as IClinicalSettings,
  paymentInfo: {} as IGetCardPayload,
  loading: "idle",
  clinicalSettingStatus: "idle",
  clinicalSettingsFetchStatus: false,
  saveOrUpdatecreditCardStatus: "idle",
  states: [],
  countries: [],
  showTokenExpired: "",
  errorMessage: "",
  countryCode: "",
  companyProfile: {
    id: 0,
    name: "",
    orgType: "",
    orgStatus: "",
    orgCreatedOn: "",
    activatedOn: "",
    licenseValidDate: "",
    billingMethod: "",
    invoiceStartDate: "",
    address: [],
    accountOwner: "",
    clinicalEdSpec: "",
  },
  systemBannerMessage: {},
  marketBannerMessage: {},
  orgShippingAddressList: [],
}

export const userServiceSlice = createSlice({
  name: "userService",
  initialState,
  reducers: {
    updateLoggedInUser(state, action) {
      const { claims } = action.payload
      try {
        if (claims) {
          const userObj: IUserServiceState = {
            user: {
              authorized_roles: claims.authorized_roles,
              global_permissions: claims.global_permissions,
              email: claims.email,
              name: claims.name,
              current_orgId: claims.authorized_roles[0].org_id,
              my_orgId: claims.authorized_roles[0].org_id,
              user_id: claims.ulab_userId,
              uid: claims.uid,
            },
          }
          if (
            claims.global_permissions &&
            claims.global_permissions.length > 0
          ) {
            userObj.user.isAllowToViewAllOrg =
              claims.global_permissions.includes(
                UI_PERMISSION.GLOBAL_VIEWALLORGS,
              )
          }

          if (!userObj.user.current_orgId) {
            throw new Error("orgId not found in authorized_roles")
          }
          if (
            state.user.app_permissions &&
            state.user.app_permissions.length > 0
          ) {
            state.user = { ...state.user, ...userObj.user }
          } else {
            state.user = userObj.user
          }
        }
      } catch (err) {
        console.log("error on update logged in user object", err)
      }
    },
    clearLoggedInUser(state) {
      state.user = {
        authorized_roles: [],
        global_permissions: [],
        app_permissions: [],
        email: "",
        name: "",
        my_orgId: "",
        current_orgId: "",
        user_id: "",
        uid: "",
        preferences: undefined,
        isAllowToViewAllOrg: false,
      }
    },
    updateTokenExpired(state, action) {
      state.showTokenExpired = action.payload
    },
    updateCurrentOrgId(state, action) {
      state.user = {
        ...state.user,
        current_orgId: "" + action.payload,
      }
    },
    setErrorMessage(state, action) {
      state.errorMessage = action.payload
    },
    clearAddressSuggestions: (state) => {
      state.suggestedAddressData = []
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUserPreferences.fulfilled, (state, action) => {
      const { result } = action.payload
      if (result != undefined && result.preferences) {
        state.user.preferences = result.preferences
        state.countryCode = result.country_cd
        console.log("get user preference::", result)
      }
    })
    builder.addCase(updateUserPreferences.pending, (state, action) => {
      state.loading = "pending"
    })
    builder.addCase(updateUserPreferences.fulfilled, (state, action) => {
      const { result } = action.payload as any
      if (result != undefined && result.preferences) {
        console.log("update user preference::", result.preferences)
        state.user.preferences = result.preferences
        state.userProfile.profileIconClrCode =
          result.preferences.profileIconColor
      }
      state.loading = "succeeded"
    })
    builder.addCase(updateUserPreferences.rejected, (state, action) => {
      state.loading = "failed"
    })
    builder.addCase(fetchUserProfile.fulfilled, (state, action) => {
      const { result } = action.payload
      if (result) {
        state.userProfile = {
          firstName: result.first_name,
          lastName: result.last_name,
          username: result.username,
          user_type: result.user_type,
          org_name: result.org_name,
          profileIconClrCode: result.preferences?.profileIconColor,
          createDate: result.create_date,
          updateDate: result.update_date,
        }
      }
      state.loading = "succeeded"
    })
    builder.addCase(fetchUserProfile.pending, (state) => {
      state.loading = "pending"
    })
    builder.addCase(fetchUserProfile.rejected, (state) => {
      state.loading = "failed"
    })
    builder.addCase(updateUserProfile.fulfilled, (state, action) => {
      const { result } = action.payload
      state.userProfile = {
        ...state.userProfile,
        firstName: result.first_name,
        lastName: result.last_name,
      }
      state.loading = "succeeded"
    })
    builder.addCase(updateUserProfile.pending, (state, action) => {
      state.loading = "pending"
    })
    builder.addCase(updateUserProfile.rejected, (state, action) => {
      state.loading = "failed"
    })
    builder.addCase(updatePassword.pending, (state) => {
      state.loading = "pending"
    })
    builder.addCase(updatePassword.fulfilled, (state, action) => {
      state.loading = "succeeded"
    })
    builder.addCase(updatePassword.rejected, (state) => {
      state.loading = "failed"
    })
    builder.addCase(resetPassword.pending, (state) => {
      state.loading = "pending"
    })
    builder.addCase(resetPassword.fulfilled, (state, action) => {
      state.loading = "succeeded"
    })
    builder.addCase(resetPassword.rejected, (state) => {
      state.loading = "failed"
    })
    builder.addCase(forgotPassword.pending, (state) => {
      state.loading = "pending"
    })
    builder.addCase(forgotPassword.fulfilled, (state, action) => {
      state.loading = "succeeded"
    })
    builder.addCase(forgotPassword.rejected, (state) => {
      state.loading = "failed"
    })
    builder.addCase(fetchClinicalSettings.pending, (state) => {
      state.loading = "pending"
    })
    builder.addCase(fetchClinicalSettings.fulfilled, (state, action) => {
      state.loading = "succeeded"
      state.clinicalSettingsFetchStatus = true
      if (action.payload) {
        state.clinicalSettings = {
          ...state.clinicalSettings,
          ...action.payload,
          NumberSystem:
            action.payload.NumberSystem?.toLowerCase() === "palmer"
              ? "Pedro"
              : action.payload.NumberSystem,
        }
        state.clinicalSettings.toothLabel =
          LABELMAP[
          state.clinicalSettings.NumberSystem.toLowerCase() === "pedro"
            ? "palmer"
            : state.clinicalSettings.NumberSystem.toLowerCase()
          ]
      }
    })
    builder.addCase(fetchClinicalSettings.rejected, (state) => {
      state.loading = "failed"
      state.clinicalSettingsFetchStatus = false
    })
    builder.addCase(updateClinicalSettings.pending, (state) => {
      state.clinicalSettingStatus = "pending"
    })
    builder.addCase(updateClinicalSettings.fulfilled, (state, action) => {
      state.clinicalSettingStatus = "succeeded"
    })
    builder.addCase(updateClinicalSettings.rejected, (state) => {
      state.clinicalSettingStatus = "failed"
    })
    builder.addCase(fetchCountryList.pending, (state, action) => {
      state.loading = "pending"
    })
    builder.addCase(fetchCountryList.fulfilled, (state, action) => {
      const { result } = action.payload
      const countryList = result?.map((res: any) => {
        return {
          label: res.name,
          id: res.id,
          code: res.code_1,
        }
      })
      state.countries = countryList
      state.loading = "succeeded"
    })
    builder.addCase(fetchCountryList.rejected, (state, action) => {
      state.loading = "failed"
    })
    builder.addCase(fetchStateLists.pending, (state, action) => {
      state.loading = "pending"
    })
    builder.addCase(fetchStateLists.fulfilled, (state, action) => {
      const { result } = action.payload
      const stateList = result?.map((res: any) => {
        return {
          label: res.name,
          id: res.id,
          code: res.short_name,
        }
      })
      state.states = stateList
      state.loading = "succeeded"
    })
    builder.addCase(fetchStateLists.rejected, (state, action) => {
      state.loading = "failed"
    })
    builder.addCase(getCreditCardDetails.rejected, (state, action) => {
      state.loading = "failed"
      state.paymentInfo = {} as IGetCardPayload
    })
    builder.addCase(getCreditCardDetails.pending, (state, action) => {
      state.loading = "pending"
    })
    builder.addCase(getCreditCardDetails.fulfilled, (state, action) => {
      if (action.payload.status === 404) {
        state.paymentInfo = { status: "NOCARD" } as IGetCardPayload
      } else {
        state.paymentInfo = action.payload.result
      }
      state.loading = "succeeded"
    })
    builder.addCase(updateCreditCard.rejected, (state, action) => {
      state.saveOrUpdatecreditCardStatus = "failed"
    })
    builder.addCase(updateCreditCard.pending, (state, action) => {
      state.saveOrUpdatecreditCardStatus = "pending"
    })
    builder.addCase(updateCreditCard.fulfilled, (state, action) => {
      state.saveOrUpdatecreditCardStatus = "succeeded"
    })
    builder.addCase(saveCreditCard.rejected, (state, action) => {
      state.saveOrUpdatecreditCardStatus = "failed"
    })
    builder.addCase(saveCreditCard.pending, (state, action) => {
      state.saveOrUpdatecreditCardStatus = "pending"
    })
    builder.addCase(saveCreditCard.fulfilled, (state, action) => {
      state.saveOrUpdatecreditCardStatus = "succeeded"
    })
    builder.addCase(activateUser.rejected, (state, action) => {
      state.loading = "failed"
    })
    builder.addCase(activateUser.pending, (state, action) => {
      state.loading = "pending"
    })
    builder.addCase(activateUser.fulfilled, (state, action) => {
      state.loading = "succeeded"
    })
    builder.addCase(fetchIfuDoc.rejected, (state, action) => {
      state.loading = "failed"
    })
    builder.addCase(fetchIfuDoc.pending, (state, action) => {
      state.loading = "pending"
    })
    builder.addCase(fetchIfuDoc.fulfilled, (state, action) => {
      state.loading = "succeeded"
    })
    builder.addCase(fetchReleaseNotes.rejected, (state, action) => {
      state.loading = "failed"
    })
    builder.addCase(fetchReleaseNotes.pending, (state, action) => {
      state.loading = "pending"
    })
    builder.addCase(fetchReleaseNotes.fulfilled, (state, action) => {
      state.loading = "succeeded"
    })
    builder.addCase(fetchHelpDocuments.rejected, (state, action) => {
      state.loading = "failed"
    })
    builder.addCase(fetchHelpDocuments.pending, (state, action) => {
      state.loading = "pending"
    })
    builder.addCase(fetchHelpDocuments.fulfilled, (state, action) => {
      state.loading = "succeeded"
    })
    builder.addCase(getAccountDetail.rejected, (state, action) => {
      state.loading = "failed"
    })
    builder.addCase(getAccountDetail.pending, (state, action) => {
      state.loading = "pending"
    })
    builder.addCase(getAccountDetail.fulfilled, (state, action) => {
      state.companyProfile = action.payload
      state.loading = "succeeded"
    })
    // download UD app exe
    builder.addCase(getUDAppExe.pending, (state, action) => {
      state.loading = "pending"
    })
    builder.addCase(getUDAppExe.fulfilled, (state, action) => {
      state.loading = "succeeded"
    })
    builder.addCase(getUDAppExe.rejected, (state, action) => {
      state.loading = "failed"
    })
    // get system message
    builder.addCase(getSystemMessage.pending, (state, action) => {
      state.loading = "pending"
    })
    builder.addCase(getSystemMessage.fulfilled, (state, action) => {
      const bannerList = action.payload
      if (bannerList?.length) {
        const systemBanners =
          bannerList.filter(
            (r) => r.type === "system" && r.status === "active",
          ) || []
        const marketingBanners =
          bannerList.filter(
            (r) => r.type === "marketing" && r.status === "active",
          ) || []
        if (marketingBanners.length > 0) {
          state.marketBannerMessage =
            marketingBanners[getRandomInt(marketingBanners.length)]
        }
        if (systemBanners.length > 0) {
          state.systemBannerMessage =
            systemBanners[getRandomInt(systemBanners.length)]
        }
      }
      state.loading = "succeeded"
    })
    builder.addCase(getSystemMessage.rejected, (state, action) => {
      state.loading = "failed"
    })
    builder.addCase(getPermissions.fulfilled, (state, action) => {
      const { result } = action.payload
      if (result) {
        const { global_permissions, app_permissions } = result
        const permission = [...global_permissions, ...app_permissions]

        if (permission && permission.length > 0) {
          state.user.isAllowToViewAllOrg = permission.includes(
            UI_PERMISSION.GLOBAL_VIEWALLORGS,
          )
        }

        state.user = { app_permissions: permission, ...state.user }
      }
      state.loading = "succeeded"
    })
    builder.addCase(getPermissions.pending, (state) => {
      state.loading = "pending"
    })
    builder.addCase(getPermissions.rejected, (state) => {
      state.loading = "failed"
    })
    // get org shipping address list
    builder.addCase(fetchShippingAddressList.fulfilled, (state, action) => {
      state.orgShippingAddressList = action.payload
      state.loading = "succeeded"
    })
    builder.addCase(fetchShippingAddressList.pending, (state) => {
      state.loading = "pending"
    })
    builder.addCase(fetchShippingAddressList.rejected, (state) => {
      state.loading = "failed"
    })
    // create or update org shipping address
    builder.addCase(createUpdateOrgShippingAddress.pending, (state) => {
      state.loading = "pending"
    })
    builder.addCase(createUpdateOrgShippingAddress.fulfilled, (state) => {
      state.loading = "succeeded"
    })
    builder.addCase(createUpdateOrgShippingAddress.rejected, (state) => {
      state.loading = "failed"
    })
    // delete org shipping address
    builder.addCase(deleteShippingAddress.pending, (state) => {
      state.loading = "pending"
    })
    builder.addCase(deleteShippingAddress.fulfilled, (state) => {
      state.loading = "succeeded"
    })
    builder.addCase(deleteShippingAddress.rejected, (state) => {
      state.loading = "failed"
    })
    builder.addCase(fetchAddressSuggestions.fulfilled, (state, action) => {
      const { result } = action.payload
      if (result) {
        state.suggestedAddressData = result.map((obj, index) => {
          return {
            ...obj,
            key: `${obj.street_address}`,
            street_address: `${obj.street_line}, ${obj.state} ${obj.zipcode}`,
          }
        })
      }
      state.loading = "succeeded"
    })
    builder.addCase(fetchAddressSuggestions.pending, (state) => {
      state.loading = "pending"
    })
    builder.addCase(fetchAddressSuggestions.rejected, (state) => {
      state.loading = "failed"
    })
  },
})

export const {
  updateLoggedInUser,
  clearLoggedInUser,
  updateTokenExpired,
  setErrorMessage,
  updateCurrentOrgId,
  clearAddressSuggestions,
} = userServiceSlice.actions

export default userServiceSlice.reducer
