import { useState, useEffect, useRef } from "react"
import { Backdrop, Box, LinearProgress, Button, Hidden, Paper } from "@mui/material"

import { useParams } from "react-router-dom"
import Toggle from "./Toggle/toggle"
import { useHotkeys } from 'react-hotkeys-hook'
import { useAppDispatch, useAppSelector } from "../../core/app/hooks"
import { RootState } from "../../core/app/store"
import { setStageShowType, setPlanList, setStageCompareLoadData, setCurLeftPage } from "@/UDTreat/udTreatSlice"
import DialogBox from "@/modules/Clinical/components/DialogBox/dialogBox"
import { UButton } from "@/components/index"
import PassIprPopup from "@/modules/Clinical/components/PassIprPopup/passIprPopup"

import {
  initialfinal,
  stageControl,
  toothMovement,
  EBiteJumpLocationType,
  viewControlInTreatment,
  caseManagement,
  history,
  stagingManager,
  treatView,
} from "@/gluelayer"
import { Height } from "@mui/icons-material"
import { number } from "prop-types"
import { current } from "@reduxjs/toolkit"
import ToggleForCompare from "./Toggle/toggleForCompare"
import useLatest from "./StageBar/useLatest"

import { refinementModule } from "@/gluelayer/core/modules/refinement";


const {
  setcalsetupfinishCallback,
  getBoltonReport,
  getAttachmentIPRReport,
  getHasArchType,
  getTreatmentPlanList,
  addTreatment,
  changeTreatment,
  renameTreatment,
  delTx,
} = caseManagement


import {setIsShowProgress,setProgressMsg,setRefinementReady} from "@/core/app/slices/clinical/clinicalSlice"

import {
  lockCaseApi,
  unlockCaseApi,
  submitRefinementFiles,
  uploadCaseZips,
} from "@/core/app/slices/clinical/clinicalThunkApi"

export const BottomToolBar = (props) => {
  const dispatch = useAppDispatch()
  const { stageShowType, stageCompareLoadData, curLeftPage } = useAppSelector(
    (state: RootState) => state.udTreatService,
  )
  const stageShowTypeLatest = useRef(stageShowType)
  stageShowTypeLatest.current = stageShowType
  // ipr or passalinger popup control
  const [hasIprPassiveObj, setHasIprPassiveObj] = useState<{ hasIPR: number; hasPA: number }>({
    hasIPR: 0,
    hasPA: 0,
  })
  // console.info("BottomToolBar init stageShowType:", stageShowType)
  //const [compareOn, setCompareOn] = useState(false)
  const [stageBarWidth, setStageBarWidth] = useState(650)
  const [stageBarCompareWidth_1, setStageBarCompareWidth_1] = useState(650)
  const [stageBarCompareWidth_2, setStageBarCompareWidth_2] = useState(650)
  const [initalShow, setInitalShow] = useState(true)
  const [reportShow, setReportShow] = useState(true)
  const [openMovement, setopenMovement] = useState(true)
  const toggleRef = useRef(null)
  const toggleCompare1Ref = useRef(null)
  const toggleCompare2Ref = useRef(null)
  const [parentWidth, setParentWidth] = useState(0);
  const [parentLeftMagin, setParentLeftMagin] = useState(0);
  const [stageCountPercent, setStageCountPercent] = useState(1.0);
  const { isCreateNewRefinement } = useAppSelector(
    (state: RootState) => state.udTreatService,
  )
  const isCreateNewRefinementLatest = useLatest(isCreateNewRefinement)
  const [stageObj, setStageObj] = useState({
    upList: [],
    downList: [],
    up_current: 0,
    down_current: 0,
  })
  const [plansList, setPlansList] = useState<{ txName: string; index: 1 | 2 }[]>(
    [],
  )
  const [currentPlan, setCurrentPlan] = useState<{
    txName: string
    index: 2 | 1
  }>({ txName: "", index: 1 })
  const [preparation, setPreparation] = useState<boolean>(false)

  // function to reNamePlan
  const renamePlanFunction = (newName: string) => {
    // renameTreatment(currentPlan.index, newName)
    // const txList = getTreatmentPlanList()
    // const txArr = assemblyPlanData(txList)
    // txArr.map((i) => {
    //   if (i.index === currentPlan.index) {
    //     setCurrentPlan(i)
    //   }
    // })
    // setPlansList(txArr)
  }
  // function to deletePlan
  const deletePlanFunction = (plan = currentPlan.index) => {
    // const isDel = delTx(plan)
    // const txList = getTreatmentPlanList()
    // const txArr = assemblyPlanData(txList)
    // setCurrentPlan(txArr[0])
    // setPlansList(txArr)
    // return isDel
  }
  const [attachmentObj, setAttachmentObj] = useState<{
    up: boolean
    down: boolean
  }>({ up: false, down: false })

  //stage bar是否处于折叠状态
  const [isStageFold, setIsStageFold] = useState(true)

  // which refinement key
  const [refinementKey, setRefinementKey] = useState<string>("")

  // Availability of upper and lower jaw
  const [isHasUpAndlow, setIsHasUpAndLow] = useState<boolean>(false)

  const [isEdit, setIsEdit] = useState(true)

  // up\low arch about view show icon bool(aligner and retainer)
  const [arLimitShow, setArLimitShow] = useState<boolean>(false)

  const [stagebarHeight, setStagebarHeight] = useState(0)

  //max refine
  const [maxRefine, setMaxRefine] = useState<number>(0)

  // up and low attachment stage
  const [attachStage, setAttachStage] = useState<{
    upStageIndex: number
    lowerStageIndex: number
  }>({ upStageIndex: 0, lowerStageIndex: 0 })

  // if have bite-jump
  const [hasBite, setHasBite] = useState<boolean>(true)

  // if refienment case and not last refinement
  const [isRANL, setIsRANL] = useState<boolean>(false)

  const { caseId, patientId } = useParams()

  const [msgShow, setmsgShow] = useState(true)


  //useHotkeys('ctrl+i', () => {setStageBarShowType(stageBarShowType === 0 ? 1:0)}, { keycode: 73 })

  // Assembly plan data
  const assemblyPlanData = (planData: string[]) => {
    // const txArr = []
    // planData.map((i, j) => {
    //   txArr.push({
    //     txName: i.trim(),
    //     index: j + 1,
    //   })
    // })
    // return txArr
    const txArr = []
    planData.map((i, j) => {
      txArr.push({
        txName: "Plan "+ (j + 1),
        index: j + 1,
      })
    })
    return txArr
  }

  // get txPlan data
  const getTxPlanFunc = () => {
    const txList = getTreatmentPlanList()
    const txArr = assemblyPlanData(txList)
    setPlansList(txArr)
    if(txArr.length > 0) {
      setCurrentPlan(txArr[0])
    }
    //console.info("currentPlan name index", currentPlan.txName, currentPlan.index)
  }

  const txPlanChangedHandle = () => {
    const txList = getTreatmentPlanList()
    const txArr = assemblyPlanData(txList)
    setPlansList(txArr)
    console.info("before set, currentPlan name index", currentPlan.txName, currentPlan.index)
    if (txList.length > 0) {
      setCurrentPlan(txArr[txList.length - 1])
    }
    console.info("after set, currentPlan name index", txArr[txList.length - 1].txName, txArr[txList.length - 1].index)
  }

  const changeCurrentTxPlan = () => {

  }


  useEffect(() => {
    props.setTreatCurrentPlan(currentPlan)

    if (!preparation) {
      return
    }
    if (!currentPlan.index) {
      return
    }
    changeTreatment(currentPlan.index)
    setopenMovement(false)
    console.info("currentPlan name index", currentPlan.txName, currentPlan.index)
    if((window as any).pulloutCallback()){
      (window as any).pulloutCallback()
    }
    stagingManager.SetGMToFinal()
    if(curLeftPage === "report" || curLeftPage === "tmtable") dispatch(setCurLeftPage(""))
  }, [currentPlan])

  useEffect(() => {
    let tmpPlanList:string[] = []
    for(let i=0; i<plansList.length; i++) {
      tmpPlanList.push(plansList[i].txName)
    }
    dispatch(setPlanList(tmpPlanList))
  }, [plansList])

  useEffect(() => {
    console.info("BottomToolBar stageShowType changed:", stageShowType)
    //for compare
    if (stageShowType === "normal") {
      // treatView.Open(false)
      //dispatch(setStageCompareLoadData(false))
      //setCompareOn(false)
    }
    else if(stageShowType === "compare") {
      // treatView.Open(true)
      // treatView.StartComparison("Plan1", "Plan2")
      //setCompareOn(true)
      //dispatch(setStageCompareLoadData(true))
    }
  }, [stageShowType])

  useEffect(() => {
    console.info("BottomToolBar stageCompareLoadData changed:", stageCompareLoadData)
  }, [stageCompareLoadData])

  const handleResize = () => {
    if(isCreateNewRefinementLatest.current)
    {
      if (props.parentRef.current) {
        const w = props.parentRef.current?.offsetWidth * 0.4
        const leftMargin = 0//props.parentRef.current?.offsetWidth * 0.6
        setParentWidth(w)
        setParentLeftMagin(leftMargin)
        setStageCountPercent(0.4)
      }
    }
    else
    {
      if (props.parentRef.current) {
        setParentWidth(props.parentRef.current?.offsetWidth)
        setParentLeftMagin(0)
        setStageCountPercent(1.0)
      }
    }
  };

  useEffect(() => {
    // handleResize(); // 初始化设置尺寸
    window.addEventListener('resize', handleResize);
 
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [props.parentRef]);

  useEffect(() => {
    console.info("BottomToolBar isCreateNewRefinement changed:", isCreateNewRefinement)
    handleResize();
  }, [isCreateNewRefinement]);//in refinement ui


  useEffect(() => {
    console.info("BottomToolBar hasIprPassiveObj changed:", hasIprPassiveObj.hasIPR, hasIprPassiveObj.hasPA)
  }, [hasIprPassiveObj]);//in refinement ui
  


  const showStageBar = () => {
    stageControl.setBiteJumpType(
      EBiteJumpLocationType["EBiteJumpLocation_End"],
    )
    dispatch(setIsShowProgress(false))
    // ipr and passalinger callback
    toothMovement.setIsExistIPROrPAPromptCallback(
      (IPR: 0 | 1, PA: 0 | 1) => {
        console.info("BottomToolBar before setHasIprPassiveObj:", hasIprPassiveObj.hasIPR, hasIprPassiveObj.hasPA)
        console.info("BottomToolBar after setHasIprPassiveObj:", IPR, PA)
        setHasIprPassiveObj({ hasIPR: IPR, hasPA: PA, })
      })
    setPreparation(true)
    // get tx list
    getTxPlanFunc()
    // console.info("BottomToolBar setStageShow normal")
    dispatch(setStageShowType("normal"))
    // setStageBarShowType(0)
    // //for compare
    // if (stageBarShowType === 0) {
    //   // treatView.Open(false)
    //   setCompareOn(false)
    // }
    // else {
    //   treatView.Open(true)
    //   treatView.StartComparison("Plan1", "Plan1")
    //   setCompareOn(true)
    // }
    stagingManager.setTxPlanChangedCallBack = txPlanChangedHandle
    dispatch(setRefinementReady(true))
  }

  useEffect(() => {
    setcalsetupfinishCallback(() => {

      dispatch(setProgressMsg("Refinement structure conversion."))

      let isOldStructure=caseManagement.IsOldRefinementStructre()

      if(isOldStructure){

    
      dispatch(lockCaseApi({ patientId, caseId })).then(({ payload }) => {
        const { status } = payload
        if (status === "Success") {






          refinementModule.convertRefinementHistory().then((result)=>{

            if(result){
              const dirList=refinementModule.getUploadDirList();
    
              let refinementFolder:string[]=[];
              for(let i=0;i<dirList.size();i++)
              {
                refinementFolder.push(dirList.get(i));
              }
              caseManagement.getRefinementZipFiles(refinementFolder).then((r) => {
    
          
                if (r) {
                      const RefinementFiles = []
                      for (const k in r) {
                        RefinementFiles.push({
                          fileName: k,
                          file: r[k],
                        })
                      }
        
                  dispatch(
                    submitRefinementFiles({
                      RefinementFiles,
                      patientId,
                      caseId,
                    })).then((r) => {
                      const { payload } = r
                  
                      if (RefinementFiles.length===payload.length) {
        
                        caseManagement.getCaseFiles(["bulk10"]).then((rsd10) => {
                          const zips = []
                          if (rsd10) {
                            for (const k in rsd10) {
                              zips.push({
                                fileName: k,
                                file: rsd10[k],
                              })
                            }
                            dispatch(
                              uploadCaseZips({
                                zips,
                                patientId,
                                caseId,
                              }),
                            ).then((res) => {
        
                              dispatch(unlockCaseApi({ patientId, caseId })).then(({ payload }) => {
                                const { status } = payload
                                showStageBar()
                              })
                            })
                          }
                        })
                      }
                      else{
                        showStageBar()
                      }
                    })
  
                  
                }
              })
            }
        })







        }else {
           showStageBar()
        }

      })
    }else{
      showStageBar()
   }


 
    })

    return () => {
      console.log('dealloc BottomToolBar');
      setPreparation(false)
      dispatch(setStageShowType(""))
      // stagingManager.clearWasmStageData()
    };
  }, [])


  const selectStageShowType = () => {
    const stageBarShowType = stageShowTypeLatest.current
    if(stageBarShowType === "") {
      return <></>
    }
    else if (stageBarShowType === "normal") {
      return <Box sx={{
        left: parentLeftMagin + ((parentWidth - stageBarWidth) / 2 < 0 ? 0 : (parentWidth - stageBarWidth) / 2),
        position: "absolute",
        bottom: "15px",
        width: stageBarWidth,
        height: "146px",
        overflow: "hidden",
        background: "transparent",
        zIndex:0

      }}
      >
        <Toggle
          ref={toggleRef}
          preparation={preparation}
          setReportShow={setReportShow}
          setopenMovement={setopenMovement}
          setInitalShow={setInitalShow}
          initalShow={initalShow}
          openMovement={openMovement}
          isReady={true}
          setStageObj={setStageObj}
          planList={plansList}
          setCurrentPlan={setCurrentPlan}
          currentPlan={currentPlan}
          renamePlanFunction={renamePlanFunction}
          deletePlanFunction={deletePlanFunction}
          attachmentObj={attachmentObj}
          setIsStageFold={setIsStageFold}
          refinementKey={refinementKey}
          setRefinementKey={setRefinementKey}
          isHasUpAndlow={isHasUpAndlow}
          isEdit={isEdit}
          arLimitShow={arLimitShow}
          setStagebarHeight={setStagebarHeight}
          maxRefine={maxRefine}
          setMaxRefine={setMaxRefine}
          attachStage={attachStage}
          hasBite={hasBite}
          isRANL={isRANL}
          stageBarWidth={stageBarWidth}
          setStageBarWidth={setStageBarWidth}
          getTxPlans={getTxPlanFunc}
          stageCountPercent={stageCountPercent}
          stageMaxWidth={parentWidth-8}
        >

        </Toggle>



        <PassIprPopup hasObj={hasIprPassiveObj} setHasObj={setHasIprPassiveObj} />

      </Box>
    }
    else if (stageBarShowType === "compare") {
      return <Box sx={{
        left: 0,
        position: "absolute",
        bottom: "15px",
        width: "100%",
        height: "146px",
        overflow: "hidden",
        background: "transparent",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        pointerEvents: "none",
      }}
      >
        <div style={{ width: (parentWidth / 2), height: "1px", display: "flex", flexDirection: "row", justifyContent: "center",}}>
          <ToggleForCompare
            ref={toggleCompare1Ref}
            preparation={preparation}
            setReportShow={setReportShow}
            setopenMovement={setopenMovement}
            setInitalShow={setInitalShow}
            initalShow={initalShow}
            openMovement={openMovement}
            isReady={true}
            setStageObj={setStageObj}
            planList={plansList}
            setCurrentPlan={setCurrentPlan}
            currentPlan={currentPlan}
            renamePlanFunction={renamePlanFunction}
            deletePlanFunction={deletePlanFunction}
            attachmentObj={attachmentObj}
            setIsStageFold={setIsStageFold}
            refinementKey={refinementKey}
            setRefinementKey={setRefinementKey}
            isHasUpAndlow={isHasUpAndlow}
            isEdit={isEdit}
            arLimitShow={arLimitShow}
            setStagebarHeight={setStagebarHeight}
            maxRefine={maxRefine}
            setMaxRefine={setMaxRefine}
            attachStage={attachStage}
            hasBite={hasBite}
            isRANL={isRANL}
            stageBarWidth={stageBarCompareWidth_1}
            setStageBarWidth={setStageBarCompareWidth_1}
            getTxPlans={getTxPlanFunc}
            viewIndex={0}
            onOff={stageCompareLoadData}
            stageMaxWidth={parentWidth / 2 - 8}
          >
          </ToggleForCompare>
        </div>
        
        <div style={{ width: (parentWidth / 2), height: "1px", display: "flex", flexDirection: "row", justifyContent: "center",}}>
          <ToggleForCompare
            ref={toggleCompare2Ref}
            preparation={preparation}
            setReportShow={setReportShow}
            setopenMovement={setopenMovement}
            setInitalShow={setInitalShow}
            initalShow={initalShow}
            openMovement={openMovement}
            isReady={true}
            setStageObj={setStageObj}
            planList={plansList}
            setCurrentPlan={setCurrentPlan}
            currentPlan={currentPlan}
            renamePlanFunction={renamePlanFunction}
            deletePlanFunction={deletePlanFunction}
            attachmentObj={attachmentObj}
            setIsStageFold={setIsStageFold}
            refinementKey={refinementKey}
            setRefinementKey={setRefinementKey}
            isHasUpAndlow={isHasUpAndlow}
            isEdit={isEdit}
            arLimitShow={arLimitShow}
            setStagebarHeight={setStagebarHeight}
            maxRefine={maxRefine}
            setMaxRefine={setMaxRefine}
            attachStage={attachStage}
            hasBite={hasBite}
            isRANL={isRANL}
            stageBarWidth={stageBarCompareWidth_2}
            setStageBarWidth={setStageBarCompareWidth_2}
            getTxPlans={getTxPlanFunc}
            viewIndex={1}
            onOff={stageCompareLoadData}
            stageMaxWidth={parentWidth / 2 - 8}
          >
          </ToggleForCompare>
        </div>
        
      </Box>
    }



  }

  return (
    selectStageShowType()


  )
}
