import { FC, useCallback, useEffect, useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"

import { FormAutoComplete, FormInput } from "../../components"
import { Box, debounce, Grid } from "../../components/mui.components"
import { useAppDispatch, useAppSelector } from "../../core/app/hooks"
import {
  createUpdateOrgShippingAddress,
  fetchAddressSuggestions,
  fetchCountryList,
  fetchShippingAddressList,
  fetchStateLists,
} from "../../core/app/slices/user/userApis"
import { RootState } from "../../core/app/store"
import { IShippingAddressTo } from "../../core/model/interface/IShippingAddress"
import { UButton } from "../../ui-component/components"
import { setAlert } from "@/core/app/slices/alert/alertSlice"
import { AddressAutocomplete } from "@/ui-component/components/UAutoComplete/UAddressAutoComplete"
import { clearAddressSuggestions } from "@/core/app/slices/user/userSlice"
import { validations } from "@/core/utils/validations"

export const AddAddressForm: FC<{
  setOpenAddressForm: (val: boolean) => void
  address: IShippingAddressTo
}> = ({ setOpenAddressForm, address }) => {
  const {
    control,
    formState: { errors },
    trigger,
    setValue,
    handleSubmit,
  } = useForm<any>({ mode: "onSubmit" })
  const { t } = useTranslation("common")
  const dispatch = useAppDispatch()
  const [selectedState, setSelectedState] = useState<string>("")
  const [selectedCountry, setSelectedCountry] = useState("")
  const [addressItem, setAddressItem] = useState<IShippingAddressTo>(address)
  const { countries, states, suggestedAddressData } = useAppSelector(
    (state: RootState) => state.userService,
  )
  const [inputValue, setInputValue] = useState("")
  const [selectedAddress, setSelectedAddress] = useState(null)
  const [stateName, setStateName] = useState(null)

  useEffect(() => {
    if (addressItem?.id) {
      setSelectedState(addressItem.region_name || "")
      setSelectedCountry(addressItem.country_name || "")
      setValue("businessName", addressItem?.business_name || "")
      setValue("line_1", addressItem?.line_1 || "")
      setInputValue(addressItem.line_1 || "")
      setValue("line_2", addressItem?.line_2 || "")
      setValue("city", addressItem?.city || "")
      setValue("region_name", addressItem?.region_name || "")
      setValue("zip_code", addressItem?.zip_code || "")
      setValue("phone", addressItem?.phone || "")
      setValue("country_name", addressItem?.country_name || "")
    }
  }, [addressItem, setValue])

  const onSubmit: SubmitHandler<any> = async (data) => {
    const payload = {
      name: data.businessName,
      line_1: data.line_1,
      line_2: data.line_2 || "",
      city: data.city,
      country: data.country_name,
      region: data.region_name,
      zip_code: data.zip_code,
      phone: data.phone || null,
      type: "Shipping",
    }
    if (addressItem?.id) {
      payload.id = addressItem.id
    }
    dispatch(createUpdateOrgShippingAddress(payload)).then((res) => {
      if (res?.payload?.status === "Success") {
        dispatch(fetchShippingAddressList())
        setOpenAddressForm(false)
      } else {
        dispatch(
          setAlert({
            message: payload?.id
              ? t("uLabAccount.shipping.updateFailed")
              : t("uLabAccount.shipping.createFailed"),
            isError: true,
          }),
        )
      }
    })
  }

  useEffect(() => {
    if (address) {
      setAddressItem(address)
      if (!countries.length) {
        dispatch(fetchCountryList())
      }
      return
    }

    if (countries.length > 0) {
      const country = countries.find((i) => i.label === "USA")
      if (country) {
        dispatch(fetchStateLists({ countryid: country.id }))
        setValue("country_name", "USA")
        setSelectedCountry("USA")
      }
    } else {
      dispatch(fetchCountryList())
    }
  }, [address, dispatch, countries])

  const handleInputChange = (newInputValue: string) => {
    setInputValue(newInputValue)
    setValue("line_1", newInputValue)
    if (!newInputValue) {
      setInputValue("")
      setSelectedAddress(null)
    }
  }

  const handleChange = (event, value) => {
    const selectedObject = suggestedAddressData.find(
      (option) => option.street_address === value,
    )
    const stateObject = states.find(
      (item) => item.code.toString() === selectedObject.state.toString(),
    )
    setStateName(stateObject?.label)
    setSelectedAddress(selectedObject)
    setInputValue(selectedObject?.street_line)
  }

  const setSuggesstionDetails = () => {
    if (selectedAddress) {
      setValue("country_name", "USA")
      setValue("line_1", selectedAddress?.street_line)
      setValue("city", selectedAddress?.city)
      if (stateName) {
        setValue("region_name", stateName)
        setSelectedState(stateName)
      }
      setValue("zip_code", selectedAddress?.zipcode)
    }
  }

  useEffect(() => {
    setSuggesstionDetails()
  }, [selectedAddress])

  return (
    <Grid container direction={"column"}>
      <Box component={"form"} onSubmit={handleSubmit(onSubmit)}>
        <Box sx={{ px: 4, pb: 4, pt: 1 }}>
          <FormInput
            formSxprops={{ mt: 1 }}
            inputLabel={t("uLabAccount.form.field.businessName")}
            fieldName={"businessName"}
            rules={{}}
            errors={errors}
            control={control}
          />
          <AddressAutocomplete
            suggestedAddressData={suggestedAddressData}
            handleInput={handleInputChange}
            handleChange={handleChange}
            inputValue={inputValue}
            fieldName={"line_1"}
            rules={{ required: "uLabAccount.form.error.address" }}
            errors={errors}
            control={control}
            formSxprops={{ mt: 2 }}
          />
          <FormInput
            inputLabel={t("uLabAccount.form.field.line2")}
            fieldName={"line_2"}
            fieldType={"text"}
            rules={{}}
            errors={errors}
            control={control}
            formSxprops={{ mt: 2 }}
          />
          <FormInput
            inputLabel={t("uLabAccount.form.field.city")}
            fieldName={"city"}
            fieldType={"text"}
            rules={{ required: "uLabAccount.form.error.city" }}
            errors={errors}
            control={control}
            formSxprops={{ mt: 2 }}
          />
          <FormAutoComplete
            inputLabel={t("uLabAccount.form.field.state")}
            fieldName={"region_name"}
            isInputLabel={true}
            rules={{
              required: "uLabAccount.form.error.state",
            }}
            options={states}
            errors={errors}
            callback={(e) => {
              if (e) {
                setValue("region_name", e.label)
                setSelectedState(e.label)
              }
              trigger("region_name")
            }}
            control={control}
            defaultValue={selectedState || ""}
          />
          <FormInput
            inputLabel={t("uLabAccount.form.field.zip")}
            fieldName={"zip_code"}
            fieldType={"text"}
            rules={{ required: "uLabAccount.form.error.zip" }}
            errors={errors}
            control={control}
            formSxprops={{ my: 0 }}
          />
          <FormAutoComplete
            formSxprops={{ mt: 2, mb: 1 }}
            inputLabel={t("uLabAccount.form.field.country")}
            fieldName={"country_name"}
            isInputLabel={true}
            rules={{
              required: "uLabAccount.form.error.country",
            }}
            options={countries}
            errors={errors}
            callback={(e) => {
              setValue("country_name", e.label)
              if (e.label === selectedCountry) return
              setValue("region_name", "")
              setSelectedCountry(e.label)
              setSelectedState(null)
              const countryItem = countries.find(
                (item) => item.label === e.label,
              )
              countryItem &&
                dispatch(fetchStateLists({ countryid: countryItem.id }))
              trigger("country_name")
            }}
            control={control}
            defaultValue={selectedCountry || ""}
          />
          <FormInput
            formSxprops={{ mt: 1 }}
            fieldName={"phone"}
            inputLabel={t("uLabAccount.form.field.phone")}
            rules={{
              pattern: validations.phoneNumber.pattern,
            }}
            errors={errors}
            control={control}
          />
        </Box>
        <Grid
          container
          justifyContent={"flex-end"}
          pb={3}
          pt={2}
          px={3}
          gap={3}
          sx={{ backgroundColor: "divider" }}
        >
          <Grid item>
            <UButton
              sxProp={{ width: 69, height: "36px" }}
              variant={"text"}
              btnType={"button"}
              onClickHandler={() => setOpenAddressForm(false)}
              btnText={t("button.cancel")}
            />
          </Grid>
          <Grid item>
            <UButton
              sxProp={{ width: 69, height: "36px" }}
              variant={"contained"}
              btnType={"submit"}
              btnText={t("button.save")}
            />
          </Grid>
        </Grid>
      </Box>
    </Grid>
  )
}
