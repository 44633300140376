import {
  ChangeEvent,
  DragEvent,
  FC,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react"
import UploadFileIcon from "@mui/icons-material/UploadFile"

import { Box, SxProps } from "../../../components/mui.components"
import UButton from "../UButton"
import UText from "../UText"

export interface IUFileBoxProps {
  /**
   * Unique identifier for select
   */
  id: string
  /**
   * Thsi params we can pass suported file types for example ["png", "jpeg", "jpg", "bmp"],
   */
  allowedFileExtensions: string[]
  /**
   * Thsi params we can pass file size to validate ,
   */
  allowedfileSize?: number
  /**
   * Thsi params we can pass file support types like image/png, image/jpeg or image/* ,
   */
  fileAcceptType: string
  /**
   * This params for validate the files is required or not
   */
  isRequired: boolean
  /**
   * This params pass custom messages to the file box
   * messages={{
                  fileNote: "JPG, PNG, BMP (max. 10 MB)",
                  uploadButton: "Click to upload",
                  uploadButtonSuffix: "or drag and drop",

                  invalidfileFormat:
                    "Unsupported file type Please upload a JPG, PNG, BMP (max. 10 MB)",
                  invalidFileSize: "File is too large (max. 10 MB)",
                  invalidFileLimit: `You may only upload up to ${1} photos for a case`,
                }}
   * 
   */
  messages: { [key: string]: string | ReactNode | null }
  /**
   * This params help to limit the File Upload
   */
  fileLimit: number
  /**
   * This params help to receive uploaded file into parent component and return as array of files
   * @returns [Files]
   */
  selectedFile: (file: FileList) => void
  /**
   * This params help to get uploaded files count
   */
  uploadedFiles?: File[]
  /**
   * Thsi params we can pass css properties to modidy layout inside filebox,
   * example {
   * flexDirection: "row",   gap : 2}
   */
  fileBoxContent?: SxProps
  /**
   * Thsi params we can pass box height and width to adjust UI box,
   * example {
   * height: "200px",   width : "500Px"}
   */
  boxSize?: SxProps

  /**
   * This params decides the  allowed the multiple selection
   */
  isMultiple?: boolean
  /**
   * 是否显示图标
   */
  isIcon?: boolean,
  /**
   * is it disabled?
   */
  isDisabled?:boolean
  fileTypeFontSize?: string
}

const UFileBox: FC<IUFileBoxProps> = ({
  id,
  allowedFileExtensions,
  allowedfileSize = 0,
  fileAcceptType,
  selectedFile,
  messages,
  isRequired,
  fileLimit,
  boxSize,
  fileBoxContent,
  isMultiple,
  uploadedFiles,
  isIcon = true,
  isDisabled = false,
  fileTypeFontSize
}) => {
  const [dragActive, setDragActive] = useState<boolean>(false)
  const [filename, setFilename] = useState<string>("")
  const [invalidFile, setInvalidFile] = useState<boolean>(false)
  const [invalidFileSize, setInvalidFileSize] = useState<boolean>(false)
  const [isError, setIsError] = useState<boolean>(false)
  const [fileLimitError, setFileLimitError] = useState<boolean>(false)
  const [required, setRequired] = useState<boolean>(isRequired)

  const inputRef = useRef(null)

  useEffect(() => {
    setRequired(isRequired)
    setIsError(isRequired)
  }, [isRequired])

  const handleFile = (files: FileList) => {
    let error = false

    setInvalidFileSize(error)
    setFileLimitError(error)
    setInvalidFile(error)
    setIsError(error)
    if (isRequired && files.length === 0) {
      error = true
    }
    if (files.length > fileLimit) {
      setFileLimitError(true)
      error = true
    }
    const existingFilesCount = uploadedFiles ? uploadedFiles.length : 0
    const totalFilesCount = existingFilesCount + files.length
    if (
      (uploadedFiles && uploadedFiles.length > fileLimit) ||
      totalFilesCount > fileLimit
    ) {
      setFileLimitError(true)
      error = true
    }
    setIsError(error)
    //file Type and size validation
    if (!error && files.length) {
      for (let limit = 0; limit < files.length; limit++) {
        const file = files[limit]
        let fileExtension = file.type.split("/")[1]
        if (!fileExtension) {
          const splitName = file.name.split(".")
          fileExtension = splitName[splitName.length - 1]
        }
        const fileSizeKiloBytes = file.size / 1024
        if (!allowedFileExtensions.includes(fileExtension.toLowerCase())) {
          error = true
          setInvalidFile(true)
          break
        } else if (
          allowedfileSize !== 0 &&
          fileSizeKiloBytes > allowedfileSize
        ) {
          error = true
          setInvalidFileSize(true)
          break
        }
      }
      setIsError(error)
      if (!error) {
        selectedFile(files)
      }
    }
  }

  // handle drag events
  const handleDrag = function (e: DragEvent<HTMLDivElement>) {
    e.preventDefault()
    e.stopPropagation()
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true)
    } else if (e.type === "dragleave") {
      setDragActive(false)
    }
  }

  // triggers when file is dropped
  const handleDrop = function (e: DragEvent<HTMLDivElement>) {
    e.preventDefault()
    e.stopPropagation()
    setDragActive(false)
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFile(e.dataTransfer.files)
    }
  }

  // triggers when file is selected with click
  const handleChange = function (e: ChangeEvent<HTMLInputElement>) {
    const files = e.target.files
    if (files && files[0]) {
      handleFile(files)
      e.target.value = null
      inputRef.current.value = null
    }
  }

  // triggers the input when the button is clicked
  const onButtonClick = (e) => {
    if (inputRef && inputRef.current) {
      e.preventDefault()
      inputRef.current.click()
    }
  }

  const borderColor = (theme) => {
    if (isError && !dragActive) {
      return {
        background: "rgba(211, 47, 47, 0.04)",
        border: `1px solid ${theme.palette.error.main}`,
      }
    } else if (dragActive) {
      return {
        background: "rgba(33, 150, 243, 0.08)",
        border: `1px dashed ${theme.palette.primary.main}`,
      }
    } else {
      return {
        background: "#FFFFFF 0% 0% no-repeat padding-box",
        border: `1px dashed ${theme.palette.divider}`,
      }
    }
  }

  return (
    <Box
      component={"form"}
      onDragEnter={handleDrag}
      data-testid={`box`}
      sx={[
        (!isDisabled)?
        {
          "&:hover": {
            background: isError
              ? "rgba(211, 47, 47, 0.04)"
              : "rgba(33, 150, 243, 0.08)",
            border: (theme) =>
              isError
                ? `1px solid ${theme.palette.error.main}`
                : `1px dashed ${theme.palette.primary.main}`,
          },
        }:{},
        {
          // mt: 2,
          boxSizing: "border-box",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          alignSelf: "stretch",
          flexGrow: 0,
          position: "relative",
          borderRadius: "4px",
          padding: "24px 16px",
        },
        (theme) => ({ ...borderColor(theme) }),
        { ...boxSize },
      ]}
    >
      <input
        id={id}
        data-testid={id}
        hidden
        multiple={isMultiple}
        ref={inputRef}
        accept={fileAcceptType}
        type="file"
        onChange={handleChange}
      />

      <Box
        component={"div"}
        sx={[
          {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: 1,
          },
          { ...fileBoxContent },
        ]}
      >
        {isIcon && (
          <UploadFileIcon
            fontSize={"small"}
            color={isError ? "error" : "primary"}
            sx={{ width: "24px", height: "24px", margin: 2 }}
          />
        )}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            gap: fileBoxContent ? "4px" : 1,
          }}
        >
          <UText
            variant={"subtitle1"}
            color={"text.primary"}
            sxProp={{ height: "28px", display: "flex", alignItems: "center" }}
          >
            <UButton
              btnText={messages.uploadButton}
              data-testid={"InputField-id"}
              onClickHandler={(e) => onButtonClick(e)}
              variant={"text"}
              disabled={isDisabled}
              sxProp={{
                textDecoration: "underline",
                textDecorationColor: "rgb(33, 94, 205, .4)",
                textTransform: "none",
                fontSize: "16px",
                "&:hover": {
                  backgroundColor: "transparent",
                  textDecoration: "underline",
                },
              }}
            />
            {messages.uploadButtonSuffix}
          </UText>

          <Box sx={{ width: "100%", textAlign: "center", height: "20px" }}>
            {!isError && (
              <UText sxProp={{fontSize: fileTypeFontSize? fileTypeFontSize: "14px"}} variant={"body2"} color={"text.secondary"}>
                {messages.fileNote}
              </UText>
            )}
            {isError && invalidFileSize && (
              <UText variant={"body2"} color={"error.main"}>
                {messages.invalidFileSize}
              </UText>
            )}
            {isError && invalidFile && (
              <UText variant={"body2"} color={"error.main"}>
                {messages.invalidfileFormat}
              </UText>
            )}
            {isError && fileLimitError && (
              <UText variant={"body2"} color={"error.main"}>
                {messages.invalidFileLimit}
              </UText>
            )}
          </Box>
        </Box>
      </Box>

      {dragActive && (
        <Box
          component={"div"}
          sx={{
            position: "absolute",
            width: "100%",
            height: "100%",
          }}
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
        />
      )}
    </Box>
  )
}

UFileBox.defaultProps = {
  fileAcceptType: "image/*",
  boxSize: {},
  fileBoxContent: {},
  fileLimit: 1,
  isMultiple: false,
  uploadedFiles: [],
}

export default UFileBox