import { useEffect, useState } from "react"
import { UText } from "../../ui-component"
import { Box } from "@mui/material"
import { ReactSVG } from "react-svg"
import restoreSvg from "../../assets/images/clinical/restore.svg"
import { toothMovement, globalEvents, GlobalEvents } from "@/gluelayer"
import { getSettingLabel } from "@/core/utils/commonMethod"
import { useAppSelector } from "@/core/app/hooks"
import { RootState } from "../../core/app/store";

const ExtractedToothList = () => {
  const [isShow, setIsShow] = useState(false)
  const [extractedList, setExtractedList] = useState<number[]>([])
  const {
    clinicalSettings: { NumberSystem },
  } = useAppSelector((state: RootState) => state.userService)
  const { openHistorySplitView, switchViewEdit } = useAppSelector(
    (state: RootState) => state.udTreatService)
  const restoreClick = (toothID) => {
    toothMovement.pulloutATooth(toothID)
  }
  useEffect(() => {
    globalEvents.on(GlobalEvents.CASE_INIT_FINISHED, () => {
      const pulloutList = toothMovement.getExtractedToothList()
      setExtractedList(pulloutList)
    });
    (window as any).pulloutCallback = () => {
      const pulloutList = toothMovement.getExtractedToothList()
      setExtractedList(pulloutList)
    }
  }, [])

  useEffect(() => {
    if(extractedList.length > 0) {
      setIsShow(true)
    } else{
      setIsShow(false)
    }
  }, [extractedList])

  const ExtraItem = ({ toothID }) => {
    return (
      <>
        <UText
          sxProp={{
            color: "rgba(0,0,0,1)",
            fontSize: "17px",
            fontWeight: 400,
            display: "inline-block",
            marginRight: "10px",
            marginLeft: "10px",
          }}
          variant={"h6"}
        >
          {"tooth " + getSettingLabel(NumberSystem, toothID)}
        </UText>
        {switchViewEdit && (
          <ReactSVG
            src={restoreSvg}
            onClick={() => restoreClick(toothID) }
            style={{
              overflow: "hidden",
              width: "30px",
              height: "30px",
              display: "inline-block",
              // marginLeft: "10px",
              position: "relative",
              top: "8px",
              marginRight: "10px",
              borderRadius: 3,
            }}
          ></ReactSVG>
        )}
      </>
    )
  }
  return (
    isShow && 
    !openHistorySplitView && (
      <Box
        sx={{
          position: "absolute",
          top: "90px",
          left: "40%",
          // display: "flex",
          // justifycontent: "center",
        }}
      >
        <UText
          sxProp={{
            display: "inline-block",
            color: "rgba(0,0,0,1)",
            fontSize: "20px",
            fontWeight: 550,
            letterSpacing: "0.46px",
            paddingRight: "10px",
          }}
          variant={"body1"}
        >
          {"Extracted —"}
        </UText>
        {extractedList.map((id) => (
          <ExtraItem toothID={id} key={`extraItem${id}`}></ExtraItem>
        ))}
      </Box>
    )
  )
}
export default ExtractedToothList