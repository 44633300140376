import { createSlice } from "@reduxjs/toolkit"

import { FileItem, PhotoObject } from "./photograph.type"
import {
  downloadPhotographs,
  fetchFilesList,
  getS3FileUrlAPI,
  updatePhotoPosition,
  uploadPhotographsInv_1,
  uploadPhotographsInv_2,
  downloadPhotosfromS3,
  downloadPhotographs1_1,
  downloadPhotoJSON,
  uploadThumbnailv1_1,
  uploadPhotographsv1_1,
  uploadPhotoJSON,
  postMessageToWebsocket
} from "./photographThunkApi"

interface IPhotographState {
  loading: "idle" | "pending" | "succeeded" | "failed"
  isPhotoUploading: "idle" | "pending" | "succeeded" | "failed"
  fileList: FileItem[]
  compositePhoto: PhotoObject[]
  individualPhotos: PhotoObject[]
  additionalPhotos: PhotoObject[]
  isDownloading: "idle" | "pending" | "succeeded" | "failed"
  count: number
  individualPhotosCount: number
  individualAdditional: PhotoObject[]
  uploadPhotoS3URL: {
    url: string
    origFilename: string
    destFilename: string
  }[]
  photoTypeJson?: Object
  /**
   * cache for download photos
   * caseID <-> Record<filename,{data:File}>[]
   */
  photosCache: Record<string, Record<string, File>>
}

const initialState: IPhotographState = {
  loading: "idle",
  isPhotoUploading: "idle",
  fileList: [],
  compositePhoto: [],
  individualPhotos: [],
  additionalPhotos: [],
  isDownloading: "idle",
  count: 0,
  individualPhotosCount: 0,
  individualAdditional: [],
  uploadPhotoS3URL: [],
  photosCache: {},
  photoTypeJson: undefined,
}

export const photographServiceSlice = createSlice({
  name: "photographService",
  initialState,
  reducers: {
    setPhotoTypeJson: (state, action) => {
      state.photoTypeJson = action.payload
    },
    resetPhotoLists: (state) => {
      state.compositePhoto = []
      state.individualPhotos = []
      state.additionalPhotos = []
      state.fileList = []
      state.individualAdditional = []
      state.photoTypeJson= undefined
    },
    resetPhotographs: (state) => {
      state.compositePhoto = []
      state.individualPhotos = []
      state.additionalPhotos = []
      state.individualAdditional = []
    },
    resetCount: (state) => {
      state.count = 0
      state.individualPhotosCount = 0
    },
    addAdditionalPhotoCount: (state, action) => {
      state.individualPhotosCount = action.payload
    },
    setDownloadStatus: (state, action) => {
      state.isDownloading = action.payload
    },
    setIndividualPhotos: (state) => {
      const newData = [
        ...state.individualPhotos,
        ...state.individualAdditional,
      ].sort((a, b) => Number(a.sequence) - Number(b.sequence))
      state.individualPhotos = [...newData]
      state.individualAdditional = []
    },
    setAdditionalPhotos: (state, action) => {
      const newData = [...state.additionalPhotos, ...action.payload.fileObj]
      state.additionalPhotos = [...newData]
    },
    removeCompositePhoto: (state) => {
      state.compositePhoto = []
    },
    removeAdditionalPhoto: (state, action) => {
      state.additionalPhotos = [...action.payload.newArray]
    },
    removeIndividualPhotos: (state, action) => {
      state.individualPhotos = [...action.payload.newArray]
    },
    removeIndividualAdditional: (state, action) => {
      const newArrray = state.individualAdditional.filter(
        (obj) => obj.fileName !== action.payload.fileName,
      )
      state.individualAdditional = [...newArrray]
    },
  },
  extraReducers: (builder) => {

    // request AI sort for photos
    builder.addCase(postMessageToWebsocket.pending,(state)=>{
      state.loading = "pending"
    })

    builder.addCase(postMessageToWebsocket.fulfilled, (state, action) => {
      state.loading = "succeeded"
    })
    builder.addCase(postMessageToWebsocket.rejected, (state, action) => {
      state.loading = "failed"
    })

    // uploadphoto and json
    builder.addCase(uploadPhotographsv1_1.pending, (state) => {
      state.loading = "pending"
    })
    builder.addCase(uploadPhotographsv1_1.fulfilled, (state, action) => {
      console.log("upload successful:::",action)
      // get the upload filename ,and cache the file.
      const uploadResps = action.payload;
      const uploadFiles = action.meta.arg.files;
      const filesMap = {}
      const { caseId } = action.meta.arg
      for (let index = 0; index < uploadResps.length; index++) {
        const resp = uploadResps[index];
        if(resp.status === 200 
          &&  (resp.data)
          && (resp.data as any).response_code === 200 
          &&  (resp.data as any).result
        ){
          // uploading is successful
          const uploadFilename = (resp.data as any).result.file_name;
          const type = (resp.data as any).result.mime_type
          const file = uploadFiles[index];
          filesMap[uploadFilename]= new File([file],uploadFilename,{type:file.type});
        }
      }

      if (Object.keys(filesMap).length>0) {
        state.photosCache[caseId] = {
          ...state.photosCache[caseId],
          ...filesMap,
        } as any
      }

      state.loading = "succeeded"
    })
    builder.addCase(uploadPhotographsv1_1.rejected, (state, action) => {
      state.loading = "failed"
    })
    builder.addCase(uploadThumbnailv1_1.pending, (state) => {
      state.loading = "pending"
    })
    builder.addCase(uploadThumbnailv1_1.fulfilled, (state, action) => {

      // get the upload filename ,and cache the file.
      const uploadResps = action.payload;
      const uploadFiles = action.meta.arg.files;
      const filesMap = {}
      const { caseId } = action.meta.arg
      for (let index = 0; index < uploadResps.length; index++) {
        const resp = uploadResps[index];
        if(resp.status === 200 
          &&  (resp.data)
          && (resp.data as any).response_code === 200 
          &&  (resp.data as any).result
        ){
          // uploading is successful
          const uploadFilename = (resp.data as any).result.file_name;
          const type = (resp.data as any).result.mime_type;
          const file = uploadFiles[index];
          filesMap[uploadFilename]= new File([file],uploadFilename,{type:file.type});
        }
      }

      if (Object.keys(filesMap).length>0) {
        state.photosCache[caseId] = {
          ...state.photosCache[caseId],
          ...filesMap,
        } as any
      }

      state.loading = "succeeded"
    })
    builder.addCase(uploadThumbnailv1_1.rejected, (state, action) => {
      state.loading = "failed"
    })

    //uploadPhotoJSON
    builder.addCase(uploadPhotoJSON.pending, (state) => {
      state.loading = "pending"
    })
    builder.addCase(uploadPhotoJSON.fulfilled, (state, action) => {
      state.photoTypeJson = action.payload.jsonObj
      console.log('uploadjson successful!!',state.photoTypeJson)
      state.loading = "succeeded"
    })
    builder.addCase(uploadPhotoJSON.rejected, (state, action) => {
      state.loading = "failed"
    })
    //downloadPhotoJSON
    builder.addCase(downloadPhotoJSON.pending, (state) => {
      state.isDownloading = "pending"
    })
    builder.addCase(downloadPhotoJSON.fulfilled, (state, action) => {
      state.photoTypeJson = action.payload.data
        ? action.payload.data
        : undefined
      state.isDownloading = "succeeded"
    })
    builder.addCase(downloadPhotoJSON.rejected, (state, action) => {
      state.isDownloading = "failed"
    })
    // downloadPhotographs1_1
    builder.addCase(downloadPhotographs1_1.pending, (state) => {
      state.isDownloading = "pending"
    })
    builder.addCase(downloadPhotographs1_1.fulfilled, (state, action) => {
      const payload = action.payload as Record<string, File>
      const { caseId } = action.meta.arg
      if (!(payload instanceof Error)) {
        state.photosCache[caseId] = {
          ...state.photosCache[caseId],
          ...payload,
        } as any
        // console.log("download photos::", state.photosCache[caseId], payload)
        state.isDownloading = "succeeded"
      }
    })
    builder.addCase(downloadPhotographs1_1.rejected, (state, action) => {
      state.isDownloading = "failed"
    })

    // downloadPhotosfromS3
    builder.addCase(downloadPhotosfromS3.pending, (state) => {
      state.isPhotoUploading = "pending"
    })
    builder.addCase(downloadPhotosfromS3.fulfilled, (state, action) => {
      const payload = action.payload
      const { caseId } = action.meta.arg
      if (payload) {
        const fileList = Object.keys(payload).reduce((obj, key) => {
          obj[key] = payload[key] as any
          return obj
        }, {})
        state.photosCache[caseId] = {
          ...state.photosCache[caseId],
          ...fileList,
        } as any
        console.log("download photos::", state.photosCache[caseId])
      }
    })
    builder.addCase(downloadPhotosfromS3.rejected, (state, action) => {
      console.log("rejected")
    })

    // Fetch File list reducers
    builder.addCase(fetchFilesList.pending, (state) => {
      state.loading = "pending"
    })
    builder.addCase(fetchFilesList.fulfilled, (state, action) => {
      const { result } = action.payload.data
      if (result && result.length > 0) {
        state.fileList = [...result]
      }
      state.loading = "succeeded"
    })
    builder.addCase(fetchFilesList.rejected, (state) => {
      state.loading = "failed"
    })
  },
})

export const {
  resetPhotoLists,
  resetPhotographs,
  setIndividualPhotos,
  setAdditionalPhotos,
  removeCompositePhoto,
  removeAdditionalPhoto,
  removeIndividualPhotos,
  setDownloadStatus,
  resetCount,
  addAdditionalPhotoCount,
  removeIndividualAdditional,
  setPhotoTypeJson,
} = photographServiceSlice.actions
