import { useEffect, useState } from "react"
import { Box, Select, Typography, MenuItem } from "@mui/material"
import { refinementModule } from "../../gluelayer/core/modules/refinement";
import { treatView, caseManagement } from "@/gluelayer"
import { useAppSelector } from "@/core/app/hooks"
import { RootState } from "@/core/app/store"

const testHistoryList = {
  original: "1/8/24" ,
  Refinement: "1/8/24" ,
  Retainer: "1/8/24" ,
}
let refinementHistoryList
const RefinementList = (props: any) => {
  const [historyList, setHistoryList] = useState([])
  const [currentRefinement, setCurrentRefinement] = useState({name: "", date: ""})
  const [lastRefinement, setLastRefinement] = useState({name: "", date: ""})
  const [showList, setShowList] = useState(false)
  const { refinementReady } = useAppSelector((state: RootState) => state.clinicalService)
  const capitalizeFirstLetter = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1)
  }
  useEffect(() => {
    if (props.creatDate !== "" && caseManagement.isCaseInit() && refinementReady) {
      setShowList(true)
      const refinementHistoryList = refinementModule.getRefinementDateInfo()
      //use test data for now
      // refinementHistoryList = testHistoryList
      const firstKey = Object.keys(refinementHistoryList)[0]
      if (firstKey && refinementHistoryList[firstKey] !== ""){
        setCurrentRefinement({ name: firstKey, date: refinementHistoryList[firstKey]})
        setLastRefinement({ name: firstKey, date: refinementHistoryList[firstKey]})
      }
      else{
        setCurrentRefinement({ name: "initial plan", date: props.creatDate})
        setLastRefinement({ name: "initial plan", date: props.creatDate})
      }
      const tempList = []
      for (const item in refinementHistoryList) {
        if (item !== "original")
          tempList.push({ name: item, date: refinementHistoryList[item] !== "" ? refinementHistoryList[item] : props.creatDate })
      }
      tempList.push({ name: "initial plan", date: props.creatDate })
      setHistoryList([...tempList])  
      props.onRefinementChange(tempList.length - 1)
      console.log("refinementHistoryList", tempList)
    }
  }, [props.creatDate, caseManagement.isCaseInit(), refinementReady])

  const handleChange = (event) => {
    setCurrentRefinement({ name: event.target.value, date: historyList[event.target.value]})
    let refinementNum = 0
    if(event.target.value !== "initial plan"){
      refinementNum = (event.target.value).match(/\d+/g)[0];
    }
    props.onRefinementChange(refinementNum)
  }
 
  return (
    ( showList && (
      <Box 
        sx={{ 
          display: "flex", 
          flexDirection: "column", 
          width: "331px", 
          marginBottom: "16px",
          boxShadow: "0px 3px 5px -2px #888888",
          ...props.listSX
        }}
      >
        <Select
          onChange={handleChange}
          value={currentRefinement.name}
          sx={{
            height: "30px",
            backgroundColor: "#F5F5F5",
            "& .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
          }}>
          {historyList.map((item) => (
            <MenuItem 
              key={"historyListItem" + item.name} 
              value={item.name} 
              sx={{ 
                color: item.name === currentRefinement.name ? "#9C9C9C" : "#000" 
              }}>
              {` #` + props.caseId + " | " + capitalizeFirstLetter(item.name) + " - " + item.date}
            </MenuItem>
          ))}
        </Select>
      </Box>)
    )
  )
}
export default RefinementList