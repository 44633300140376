import { SvgIcon, SvgIconProps } from "@mui/material"

export const LockMoveIcon =  (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} sx ={{ width: "35px", height: "35px" }}>
      <g dangerouslySetInnerHTML={{ __html: `<?xml version="1.0" encoding="UTF-8"?>
          <!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
          <!-- Creator: CorelDRAW 2020 (64-Bit) -->
          <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" width="100%" height="100%" version="1.1" style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd"
          viewBox="0 0 13.334 13.334"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          xmlns:xodm="http://www.corel.com/coreldraw/odm/2003">
          <defs>
            <style type="text/css">
            <![CDATA[
              .fil0 {fill:none}
              .fil17 {fill:#6E6E6E}
              .fil21 {fill:#6E6E6E}
            ]]>
            </style>
          </defs>
          <g id="Layer_x0020_11">
            <metadata id="CorelCorpID_0Corel-Layer1"/>
            <g id="_1765276504752">
            <rect class="fil0" x="0" y="-0" width="13.334" height="13.334"/>
            <g>
              <path class="fil17" d="M4.28 4.519l0 0c0.019,-1.302 1.08,-2.352 2.387,-2.352 1.301,0 2.358,1.041 2.387,2.335l0.001 0 0 0.053 0 0.732 -0.984 0 0 -0.772c-0.021,-0.757 -0.641,-1.365 -1.404,-1.365 -0.764,0 -1.385,0.61 -1.404,1.369l0 0 0 0.779 -0.983 0 0 -0.743 0 -0.035z"/>
              <path class="fil21" d="M4.146 5.567l5.042 0c0.525,0 0.955,0.43 0.955,0.955l0 3.69c0,0.526 -0.43,0.955 -0.955,0.955l-5.042 0c-0.525,0 -0.955,-0.43 -0.955,-0.955l0 -3.69c0,-0.525 0.43,-0.955 0.955,-0.955zm2.884 3.789c0.004,0.02 0.005,0.041 0.005,0.062 0,0.202 -0.163,0.365 -0.365,0.365 -0.202,0 -0.365,-0.164 -0.365,-0.365 0,-0.021 0.002,-0.042 0.006,-0.062l0 -0.715c-0.193,-0.12 -0.321,-0.334 -0.321,-0.578 0,-0.376 0.305,-0.681 0.681,-0.681 0.376,0 0.681,0.305 0.681,0.681 0,0.244 -0.128,0.458 -0.321,0.578l0 0.715z"/>
            </g>
            </g>
          </g>
          </svg>
          ` }} />
    </SvgIcon>
  )
}
export const UnlockIcon =  (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} sx ={{ width: "35px", height: "35px" }}>
      <g dangerouslySetInnerHTML={{ __html: `<?xml version="1.0" encoding="UTF-8"?>
        <!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
        <!-- Creator: CorelDRAW 2020 (64-Bit) -->
        <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" width="100%" height="100%" version="1.1" style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd"
        viewBox="0 0 13.334 13.334"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        xmlns:xodm="http://www.corel.com/coreldraw/odm/2003">
        <defs>
          <style type="text/css">
          <![CDATA[
            .fil0 {fill:#00AFEF}
            .fil11 {fill:white}
            .fil22 {fill:white}
          ]]>
          </style>
        </defs>
        <g id="Layer_x0020_13">
          <metadata id="CorelCorpID_0Corel-Layer2"/>
          <g id="_1765342695968">
          <rect class="fil0" x="0" y="-0" width="13.334" height="13.334"/>
          <path class="fil11" d="M7.613 4.519l0 0c0.019,-1.302 1.08,-2.352 2.387,-2.352 1.301,0 2.358,1.041 2.387,2.335l0.001 0 0 0.053 0 0.732 -0.984 0 0 -0.772c-0.021,-0.757 -0.641,-1.365 -1.404,-1.365 -0.764,0 -1.385,0.61 -1.404,1.369l0 0 0 0.779 -0.983 0 0 -0.743 0 -0.035z"/>
          <path class="fil22" d="M4.146 5.567l5.042 0c0.525,0 0.955,0.43 0.955,0.955l0 3.69c0,0.526 -0.43,0.955 -0.955,0.955l-5.042 0c-0.525,0 -0.955,-0.43 -0.955,-0.955l0 -3.69c0,-0.525 0.43,-0.955 0.955,-0.955zm2.884 3.789c0.004,0.02 0.005,0.041 0.005,0.062 0,0.202 -0.163,0.365 -0.365,0.365 -0.202,0 -0.365,-0.164 -0.365,-0.365 0,-0.021 0.002,-0.042 0.006,-0.062l0 -0.715c-0.193,-0.12 -0.321,-0.334 -0.321,-0.578 0,-0.376 0.305,-0.681 0.681,-0.681 0.376,0 0.681,0.305 0.681,0.681 0,0.244 -0.128,0.458 -0.321,0.578l0 0.715z"/>
          </g>
        </g>
        </svg>
        `}}></g>
    </SvgIcon>
  )
}
export const SingleToothSuperimposeIcon =  (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} sx ={{ width: "35px", height: "35px" }}>
      <g dangerouslySetInnerHTML={{ __html: `<?xml version="1.0" encoding="UTF-8"?>
      <!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
      <!-- Creator: CorelDRAW 2020 (64-Bit) -->
      <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" width="100%" height="100%" version="1.1" style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd"
      viewBox="0 0 13.334 13.334"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      xmlns:xodm="http://www.corel.com/coreldraw/odm/2003">
      <defs>
        <style type="text/css">
        <![CDATA[
          .str03 {stroke:#6E6E6E;stroke-width:0.667;stroke-miterlimit:2.61313}
          .str14 {stroke:#4D4D4D;stroke-width:0.5;stroke-miterlimit:2.61313;stroke-dasharray:0.500000 0.500000}
          .fil12 {fill:none}
          .fil01 {fill:none}
        ]]>
        </style>
      </defs>
      <g id="Layer_x0020_12">
        <metadata id="CorelCorpID_0Corel-Layer3"/>
        <g id="_1765326301296">
        <path class="fil01 str03" d="M4.138 2.472c0.537,-0.104 1.05,-0.121 1.563,-0.138l0.089 0c0.513,0.017 1.025,0.034 1.563,0.138 0.537,0.104 1.1,0.294 1.308,0.772 0.209,0.477 0.064,1.24 -0.037,1.774 -0.101,0.534 -0.158,0.84 -0.246,1.204 -0.087,0.364 -0.205,0.787 -0.364,1.34 -0.16,0.552 -0.362,1.235 -0.513,1.585 -0.152,0.35 -0.254,0.369 -0.355,0.387l-2.798 0c-0.102,-0.019 -0.203,-0.037 -0.355,-0.387 -0.152,-0.35 -0.354,-1.032 -0.513,-1.585 -0.16,-0.552 -0.277,-0.976 -0.365,-1.34 -0.087,-0.364 -0.144,-0.669 -0.245,-1.204 -0.101,-0.534 -0.246,-1.298 -0.038,-1.774 0.127,-0.291 0.387,-0.474 0.68,-0.594 0.204,-0.084 0.425,-0.138 0.629,-0.177z"/>
        <path class="fil01 str14" d="M5.982 3.939c0.537,-0.104 1.05,-0.121 1.563,-0.138l0.089 0c0.513,0.017 1.025,0.034 1.563,0.138 0.537,0.104 1.1,0.294 1.308,0.772 0.209,0.477 0.064,1.24 -0.037,1.774 -0.101,0.534 -0.158,0.839 -0.246,1.204 -0.087,0.364 -0.205,0.787 -0.364,1.34 -0.16,0.552 -0.362,1.235 -0.513,1.585 -0.152,0.35 -0.254,0.369 -0.355,0.387l-2.798 0c-0.102,-0.019 -0.203,-0.037 -0.355,-0.387 -0.152,-0.35 -0.354,-1.032 -0.513,-1.585 -0.16,-0.552 -0.277,-0.976 -0.365,-1.34 -0.087,-0.364 -0.144,-0.669 -0.246,-1.204 -0.101,-0.534 -0.246,-1.298 -0.037,-1.774 0.127,-0.291 0.387,-0.474 0.68,-0.594 0.204,-0.084 0.425,-0.138 0.628,-0.177z"/>
        <rect class="fil12" x="0" y="-0" width="13.334" height="13.334"/>
        </g>
      </g>
      </svg>
        `}}></g>
    </SvgIcon>
  )
}
export const SingleToothSuperimposeOpenIcon =  (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} sx ={{ width: "35px", height: "35px" }}>
      <g dangerouslySetInnerHTML={{ __html: `<?xml version="1.0" encoding="UTF-8"?>
      <!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
      <!-- Creator: CorelDRAW 2020 (64-Bit) -->
      <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" width="100%" height="100%" version="1.1" style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd"
      viewBox="0 0 13.334 13.334"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      xmlns:xodm="http://www.corel.com/coreldraw/odm/2003">
      <defs>
        <style type="text/css">
        <![CDATA[
          .str04 {stroke:white;stroke-width:0.667;stroke-miterlimit:2.61313}
          .str12 {stroke:white;stroke-width:0.5;stroke-miterlimit:2.61313;stroke-dasharray:0.500000 0.500000}
          .fil14 {fill:none}
          .fil02 {fill:#00AFEF}
        ]]>
        </style>
      </defs>
      <g id="Layer_x0020_14">
        <metadata id="CorelCorpID_0Corel-Layer4"/>
        <g id="_1765333220288">
        <rect class="fil02" x="0" y="-0" width="13.334" height="13.334"/>
        <path class="fil14 str04" d="M4.138 2.472c0.537,-0.104 1.05,-0.121 1.563,-0.138l0.089 0c0.513,0.017 1.025,0.034 1.563,0.138 0.537,0.104 1.1,0.294 1.308,0.772 0.209,0.477 0.064,1.24 -0.037,1.774 -0.101,0.534 -0.158,0.84 -0.246,1.204 -0.087,0.364 -0.205,0.787 -0.364,1.34 -0.16,0.552 -0.362,1.235 -0.513,1.585 -0.152,0.35 -0.254,0.369 -0.355,0.387l-2.798 0c-0.102,-0.019 -0.203,-0.037 -0.355,-0.387 -0.152,-0.35 -0.354,-1.032 -0.513,-1.585 -0.16,-0.552 -0.277,-0.976 -0.365,-1.34 -0.087,-0.364 -0.144,-0.669 -0.245,-1.204 -0.101,-0.534 -0.246,-1.298 -0.038,-1.774 0.127,-0.291 0.387,-0.474 0.68,-0.594 0.204,-0.084 0.425,-0.138 0.629,-0.177z"/>
        <path class="fil14 str12" d="M5.982 3.939c0.537,-0.104 1.05,-0.121 1.563,-0.138l0.089 0c0.513,0.017 1.025,0.034 1.563,0.138 0.537,0.104 1.1,0.294 1.308,0.772 0.209,0.477 0.064,1.24 -0.037,1.774 -0.101,0.534 -0.158,0.839 -0.246,1.204 -0.087,0.364 -0.205,0.787 -0.364,1.34 -0.16,0.552 -0.362,1.235 -0.513,1.585 -0.152,0.35 -0.254,0.369 -0.355,0.387l-2.798 0c-0.102,-0.019 -0.203,-0.037 -0.355,-0.387 -0.152,-0.35 -0.354,-1.032 -0.513,-1.585 -0.16,-0.552 -0.277,-0.976 -0.365,-1.34 -0.087,-0.364 -0.144,-0.669 -0.246,-1.204 -0.101,-0.534 -0.246,-1.298 -0.037,-1.774 0.127,-0.291 0.387,-0.474 0.68,-0.594 0.204,-0.084 0.425,-0.138 0.628,-0.177z"/>
        </g>
      </g>
      </svg>
        `}}></g>
    </SvgIcon>
  )
}
export const ExtractionIcon =  (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} sx ={{ width: "35px", height: "35px" }}>
      <g dangerouslySetInnerHTML={{ __html: `<?xml version="1.0" encoding="UTF-8"?>
  <!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
  <!-- Creator: CorelDRAW 2020 (64-Bit) -->
  <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" width="100%" height="100%" version="1.1" style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd"
  viewBox="0 0 13.334 13.334"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  xmlns:xodm="http://www.corel.com/coreldraw/odm/2003">
  <defs>
    <style type="text/css">
    <![CDATA[
      .str05 {stroke:#6E6E6E;stroke-width:0.667;stroke-miterlimit:2.61313}
      .str2 {stroke:#4D4D4D;stroke-width:0.5;stroke-miterlimit:2.61313}
      .str13 {stroke:#6E6E6E;stroke-width:0.5;stroke-miterlimit:2.61313}
      .fil15 {fill:none}
      .fil03 {fill:none}
    ]]>
    </style>
  </defs>
  <g id="Layer_x0020_15">
    <metadata id="CorelCorpID_0Corel-Layer5"/>
    <g id="_1765342630448">
    <path class="fil03 str05" d="M4.966 3.003c0.569,-0.11 1.111,-0.128 1.654,-0.146l0.094 0c0.543,0.018 1.085,0.036 1.654,0.146 0.569,0.11 1.164,0.312 1.384,0.817 0.221,0.505 0.067,1.313 -0.04,1.878 -0.107,0.565 -0.167,0.889 -0.26,1.274 -0.092,0.385 -0.217,0.833 -0.385,1.418 -0.169,0.585 -0.383,1.307 -0.543,1.677 -0.161,0.371 -0.268,0.39 -0.376,0.409l-2.962 0c-0.107,-0.019 -0.215,-0.039 -0.376,-0.409 -0.161,-0.37 -0.374,-1.093 -0.543,-1.677 -0.169,-0.585 -0.293,-1.032 -0.385,-1.418 -0.093,-0.385 -0.153,-0.709 -0.26,-1.274 -0.107,-0.565 -0.261,-1.373 -0.04,-1.878 0.135,-0.308 0.409,-0.502 0.719,-0.629 0.216,-0.089 0.449,-0.146 0.665,-0.187z"/>
    <g>
      <line class="fil15 str13" x1="1.901" y1="11.429" x2="11.433" y2= "1.905" />
      <line class="fil15 str2" x1="11.433" y1="11.429" x2="1.901" y2= "1.905" />
      <line class="fil15 str13" x1="11.433" y1="11.429" x2="1.901" y2= "1.905" />
    </g>
    <rect class="fil15" x="-0" y="-0" width="13.334" height="13.334"/>
    </g>
  </g>
  </svg>
        `}}></g>
    </SvgIcon>
  )
}
export const ToothResetIcon =  (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} sx ={{ width: "35px", height: "35px" }}>
      <g dangerouslySetInnerHTML={{ __html: `<?xml version="1.0" encoding="UTF-8"?>
<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">
<!-- Creator: CorelDRAW 2020 (64-Bit) -->
<svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" width="100%" height="100%" version="1.1" style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd"
viewBox="0 0 13.334 13.334"
 xmlns:xlink="http://www.w3.org/1999/xlink"
 xmlns:xodm="http://www.corel.com/coreldraw/odm/2003">
 <defs>
  <style type="text/css">
   <![CDATA[
    .str02 {stroke:#6E6E6E;stroke-width:0.787;stroke-miterlimit:2.61313}
    .fil04 {fill:none}
    .fil16 {fill:#6E6E6E}
   ]]>
  </style>
 </defs>
 <g id="Layer_x0020_16">
  <metadata id="CorelCorpID_0Corel-Layer"/>
  <g id="_1765333188608">
   <g>
    <path class="fil04 str02" d="M9.944 7.443c0,1.901 -1.541,3.442 -3.442,3.442 -1.901,0 -3.442,-1.541 -3.442,-3.442 0,-1.901 1.541,-3.442 3.442,-3.442 0.604,0 1.198,0.159 1.721,0.461"/>
    <polygon class="fil16" points="10.481,5.685 9.897,4.119 9.313,2.552 8.248,4.396 7.184,6.241 8.832,5.963 "/>
   </g>
   <rect class="fil04" x="0" y="-0" width="13.334" height="13.334"/>
  </g>
 </g>
</svg>
        `}}></g>
    </SvgIcon>
  )
}