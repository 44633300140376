import { useEffect, useState,useMemo,memo } from "react"
import { useParams } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "@/core/app/hooks"
import { filter, find, isEmpty } from "lodash"
import { LeftPage } from "./LeftPage"
import { PhotosAndXRays } from "./PhotosAndXRays"
import LoadingButton from "@mui/lab/LoadingButton"
import { RootState } from "@/core/app/store"
import { getAccessToken } from "@/core/config/okta.config"
import { CASE_STATUS } from "@/modules/Patient/config/status.config"
import RefinementList from "../../../src/modules/Records/RefinementList"


export const UDPhotos = (props)=>{
  const { caseDetail } = useAppSelector((state: RootState) => state.caseService)
  const [isReadonly] = useState(caseDetail.caseDisposition===CASE_STATUS.PLANNING_IN_PROGRESS?true:false)
  const { patientId, caseId } = useParams()
  const [currentReviewNum, setCurrentReviewNum] = useState<number>(0)

  return <LeftPage
  id="leftpage"
  sx={{
    width: 536,
    Height: "auto",
    zIndex:10,

  }}
  title="Photos and X-rays"
  onClose={props.onClose}
  headersx={{
    height: 72,
  }}
  contentsx={{ padding: "0px 0px" }}
  content={
    <PhotosAndXRays
    isReadOnly={isReadonly}
    refinementIndex={currentReviewNum}
    />
  }
  headContent={
    <RefinementList 
      listSX={{ marginLeft: "25px", marginTop: "-8px" }} 
      caseId={caseId} 
      creatDate={
        caseDetail.createDate?`
        ${caseDetail.createDate.substring(5, 7)}/${caseDetail.createDate.substring(8,10)}/${caseDetail.createDate.substring(2,4)}`
        :""} 
      onRefinementChange={(reviewNum) => 
        setCurrentReviewNum(reviewNum)} />
  }
  >
  </LeftPage>
}