import { FC } from "react"
import { Box, Grid } from "@mui/material"
import { isEmpty } from "lodash"

import low from "../../assets/svgs/lower.svg"
import up from "../../assets/svgs/upper.svg"
import SvgBtn from "../../components/DarkMode/SvgButton/svgbtn"
import { useAppSelector } from "../../core/app/hooks"
import { RootState } from "../../core/app/store"

import { tool, view, viewbox, zoom, zoomin, zoomout } from "./zoom.style"

interface zoomType {
  setisUpper: (arg: boolean) => void
  setzoomValue: (arg: number) => void
  zoomValue: number
  isUpper: boolean
  isLower: boolean
  setisLower: (arg: boolean) => void
}
const Zoom: FC<zoomType> = ({
  setisUpper,
  setzoomValue,
  zoomValue,
  isUpper,
  isLower,
  setisLower,
}) => {
  const {
    caseDetail: { udesign_category },
  } = useAppSelector((state: RootState) => state.caseService)

  const getArchInfo = () => {
    let caseArch = "both",
      UpperType = "A",
      LowerType = "A"

    if (!udesign_category) return { caseArch, UpperType, LowerType }
    UpperType = udesign_category.split("/")[0]

    LowerType = udesign_category.split("/")[1]

    if (UpperType.includes("--")) {
      caseArch = "lower"
    } else if (LowerType.includes("--")) {
      caseArch = "upper"
    }

    return { caseArch, UpperType, LowerType }
  }
  return (
    <Grid
      container
      sx={{
        ...tool,
      }}
    >
      <Grid sx={{ ...view, backgroundColor: "rgba(240,240,240)" }}>
        <SvgBtn
          svg={up}
          clickFun={() => {
            if (!isUpper) {
              setisLower(false)
            }
            setisUpper(!isUpper)
          }}
          isdisable={getArchInfo().caseArch !== "both"}
          boxSx={{
            ...viewbox,
            // "&:hover": {
            //   backgroundColor: "#4E6670",
            // },
            // backgroundColor:
            //   isUpper || getArchInfo().caseArch !== "both"
            //     ? "#455A64"
            //     : "#546E7A",
            // opacity: 1,
            // borderRight: "1px solid #455A64",
            // borderEndStartRadius: "14px",
            // borderStartStartRadius: "14px",
            // borderRadius: "none",
          }}
          afterInjection={(svg) => {
            const path = svg.getElementsByTagName("path")[0]
            if (isUpper || getArchInfo().caseArch !== "both") {
              path?.setAttribute("fill", "#215ECD")
              path?.setAttribute("fill-opacity", "1")
            } else {
              path?.setAttribute("fill", "black")
              path?.setAttribute("fill-opacity", "0.56")
            }
          }}
          svgSx={{
            width: 24,
            height: 24,
          }}
        ></SvgBtn>
        <SvgBtn
          svg={low}
          clickFun={() => {
            if (!isLower) {
              setisUpper(false)
            }
            setisLower(!isLower)
          }}
          isdisable={getArchInfo().caseArch !== "both"}
          boxSx={{
            ...viewbox,
            // "&:hover": {
            //   backgroundColor: "#4E6670",
            // },
            // borderEndEndRadius: "4px",
            // borderStartEndRadius: "4px",
            // backgroundColor:
            //   isLower || getArchInfo().caseArch !== "both"
            //     ? "#455A64"
            //     : "#546E7A",
            // opacity: 1,
            // borderRadius: "none",
          }}
          afterInjection={(svg) => {
            const path = svg.getElementsByTagName("path")[0]
            if (isLower || getArchInfo().caseArch !== "both") {
              path?.setAttribute("fill", "#215ECD")
              path?.setAttribute("fill-opacity", "1")
            } else {
              path?.setAttribute("fill", "black")
              path?.setAttribute("fill-opacity", "0.56")
            }
          }}
          svgSx={{
            width: 24,
            height: 24,
          }}
        ></SvgBtn>
      </Grid>
      <Grid container sx={zoom}>
        <Box
          component={"span"}
          sx={{
            ...zoomin,
            "&:hover": {
              // backgroundColor: zoomValue > 0.25 ? "#4E6670" : "#546E7A",
              opacity: zoomValue < 0.25 ? 1 : 0.8,
            },
            borderEndStartRadius: 8,
            borderStartStartRadius: 8,
            // "&:active": {
            //   backgroundColor: zoomValue > 0.25 ? "#455A64" : "#546E7A",
            // },
            opacity: zoomValue > 0.25 ? 1 : 0.8,
          }}
          onClick={() => {
            if (zoomValue === 0.25) return
            setzoomValue(zoomValue - 0.25 < 0.25 ? 0.25 : zoomValue - 0.25)
          }}
        >
          -
        </Box>
        <Box
          component={"span"}
          sx={{
            ...zoomout,
            "&:hover": {
              // backgroundColor: zoomValue < 2 ? "#4E6670" : "#546E7A",
              opacity: zoomValue < 0.25 ? 1 : 0.8,
            },
            borderEndEndRadius: 8,
            borderStartEndRadius: 8,
            // "&:active": {
            //   backgroundColor: zoomValue < 2 ? "#455A64" : "#546E7A",
            // },
            opacity: zoomValue < 4 ? 1 : 0.8,
          }}
          onClick={() => {
            if (zoomValue === 4) return
            setzoomValue(zoomValue + 0.25 > 4 ? 4 : zoomValue + 0.25)
          }}
        >
          +
        </Box>
      </Grid>
    </Grid>
  )
}
export default Zoom
