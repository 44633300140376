import { FC, ReactElement } from "react"
import React from "react"

import { DialogContent, DialogTitle, Grid, SxProps } from "../../mui.components"

import { BootstrapDialog } from "./UModalPopup.style"

export interface ImodalProps {
  /**
   * Title for the modal
   */
  title?: ReactElement | string

  /**
   * Content for modal which can be a string or a react element
   */
  content: ReactElement | string

  /**
   * boolean value to indicate whether to show the modal
   */
  isAlert: boolean

  /**
   * Array of react elements
   */
  btnList?: Array<ReactElement>
  /**
   * The system prop that allows defining system overrides as well as additional CSS styles. See the `sx` page for more details.
   * Use the following example for reference:
   * <UImagePreview
   *  sxModalProps={
   *  ".MuiDialog-paper": {...css} --> To edit Modal Container css properties
   *  "#titleCntr": {...css}, --> To edit Title Container css properties
   *  "#contentCntr": {...css}, --> To edit Content Container css properties
   *  "#btnListCntr": {...css}, --> To edit Button List Container css properties
   * }
   * ></UImagePreview>
   */
  sxModalProps?: SxProps
}

const UModalPopup: FC<ImodalProps> = ({
  title,
  content,
  isAlert,
  btnList,
  sxModalProps,
}) => {
  return (
    <BootstrapDialog open={isAlert} sx={{ ...sxModalProps }}>
      {title && <DialogTitle id="titleCntr">{title}</DialogTitle>}
      <DialogContent id="contentCntr">{content}</DialogContent>
      <Grid
        id="btnListCntr"
        container
        flexDirection={"row"}
        justifyContent={"flex-end"}
      >
        {btnList &&
          btnList.length > 0 &&
          btnList.map((item: React.ReactNode, index: number) => {
            return (
              <Grid item key={index}>
                {item}
              </Grid>
            )
          })}
      </Grid>
    </BootstrapDialog>
  )
}

export default UModalPopup
