import { createAsyncThunk } from "@reduxjs/toolkit"
import oktaAuthClient from "@/core/config/okta.config"
import { getAccessToken } from "@/core/config/okta.config"
import * as v2Apis from "../../v2/v2.service"

import {
  downloadPhotographFiles,
  fetchFileList,
  getS3FileUrl,
  updatePhotoPositionAPI,
  uploadPhoto,
  uploadPhotographFiles,
  uploadFiles,
  uploadPhotoJSONAPI,
  uploadThumbnails,
  downloadPhotoJSONAPI,
  uploadOnePhotoFile,
  deletePhotographFile,
} from "./photograph.service"
import {
  fetchFileParams,
  fileDownloadParams,
  IPhotosDownloadParams,
  fileUploadParams,
  updatePhotoPositionParams,
  fileArrayUploadParams,
  uploadPhotoTypeParams,
  IUploadFilesCommonParams,
  IPhotosDeleteParams,
  IAIRequetParams
} from "./photograph.type"

export const uploadPhotographsInv_1 = createAsyncThunk(
  "PhotosService/uploadPhotographs",
  async (
    {
      patientId,
      caseId,
      formData,
      fileName,
      onFileProcesscallback,
    }: fileUploadParams,
    { rejectWithValue, getState },
  ) => {
    const orgId = getState().userService.user.current_orgId
    try {
      return await uploadOnePhotoFile(
        orgId,
        patientId,
        caseId,
        fileName,
        formData,
        onFileProcesscallback,
      )
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

interface UploadFormDatas{
  formData:FormData,
  fileName:string
}


export const uploadCaseFiles = createAsyncThunk(
  "PhotosService/uploadCaseFiles",
  async(
    {
      patientId,
      caseId,
      files,
      callback}:IUploadFilesCommonParams,
    {rejectWithValue, getState}
  ) =>{
    const orgId = getState().userService.user.current_orgId
    try{
      return uploadFiles({
        patientId,
        caseId,
        files,
        orgId,
        callback
      })
    }catch(err){
      return rejectWithValue(err)
    }
  }
)

export const uploadThumbnailv1_1 = createAsyncThunk(
  "PhotosService/uploadThumbnailArray",
  async({
    patientId,
    caseId,
    files,
    callback,
    refinementIndex=0
  }:IUploadFilesCommonParams,
  {rejectWithValue, getState}) =>{
    const orgId = getState().userService.user.current_orgId
    try{
      const ret = uploadThumbnails({
        patientId,
        caseId,
        files,
        orgId,
        callback,
        refinementIndex
      })
      return ret
    }catch(err){
      return rejectWithValue(err)
    }

  }
)

export const uploadPhotographsv1_1 = createAsyncThunk(
  "PhotosService/uploadPhotographArray",
  async (
    {
      patientId,
      caseId,
      files,
      callback,
      refinementIndex=0
    }: IUploadFilesCommonParams,
    { rejectWithValue, getState },
  ) => {
    const orgId = getState().userService.user.current_orgId

    const ret = await uploadPhotographFiles({
      orgId,
      patientId,
      caseId,
      files,
      refinementIndex,
      callback
    })
    return ret;
  },
)

export const uploadPhotoJSON = createAsyncThunk(
  "PhotosService/uploadPhotoJSON",
  async (
    { patientId, caseId, jsonObj,callback,refinementIndex}: uploadPhotoTypeParams,
    { rejectWithValue, getState },
  )=>{

    const orgId = getState().userService.user.current_orgId
    try {
      const blob = new Blob([JSON.stringify(jsonObj)], {
        type: "application/json",
      })
      const file = new File([blob], `type${refinementIndex?refinementIndex:''}.json`, {
        type: "application/json",
        lastModified: Date.now(),
      })
      const ret = await uploadPhotoJSONAPI(orgId, patientId, caseId, file,refinementIndex,callback)
      return {...ret,jsonObj}
    } catch (err) {
      return rejectWithValue(err)
    }
  }
)

export const downloadPhotoJSON = createAsyncThunk(
  "PhotosService/downloadPhotoJSON",
  async(
    { patientId, caseId, callback,refinementIndex }: any,
    { rejectWithValue, getState },
  ) =>{
    const orgId = getState().userService.user.current_orgId
    try {
      return await downloadPhotoJSONAPI(orgId, patientId, caseId,refinementIndex,callback)
    } catch (err) {
      return rejectWithValue(err)
    }

  }
)

export const downloadPhotographs1_1 = createAsyncThunk<Record<string, File>,IPhotosDownloadParams>(
  "PhotosService/downloadPhotographs1_1",
  async (
    { patientId, caseId, fileNames,refinementIndex=0 },
    { rejectWithValue, getState },
  ) => {


    const checkFileType = (file:File):File=>{
      let ret:File;
      const types = ['.png','.jpg','.jpeg','.bmp']
      if (file.type==="") {
        let postfix;
        let type=""
        for (const it of types) {
          if (file.name.includes(it)) {
            postfix = it;
            break;
          }
        }
        switch(postfix){
          case '.png':
            type='image/png';
            break;
          case '.jpg':
          case '.jpeg':
            type='image/jpeg';
            break;
          case '.bmp':
            type='image/bmp';
            break;
          default:
            type=''
        }

        ret = new File([file],file.name,{type})
        console.log('%%%%%%%',file.name,type,postfix)
      }else{
        ret = file
        console.log('%%%%%%%22',file)
      }

      return ret;


    }

    const downloadPhotosOrGetFromCache= async (filenames:string[])=>{
      let needDownloadFilenames = [];
      const retFileList:Record<string,File>= {}
      const photosCache = (getState() as any).PhotosService.photosCache
      const orgId = (getState() as any).userService.user.current_orgId
      console.log("<downloadOrGetFromCache:>",filenames);
      // find out what needs download?
      const cache = photosCache[caseId]
      if (cache) {
        for (let fileName of filenames) {
          if (cache[fileName]) {
            continue
          }else{
            needDownloadFilenames.push(fileName);
          }
        }
      }else{
        needDownloadFilenames = [...filenames]
      }
    
      // download if need
      const needDownloadPhotos:v2Apis.IPhotoInfo[] = []
      for (const key of needDownloadFilenames) {
        needDownloadPhotos.push({
          category:"photographs",
          filename:key
        })
      }
    
      let downloadFileList={};
      if (needDownloadPhotos.length>0) {
        const fileNames = needDownloadPhotos.map(item=>item.filename)

       // here need download from the remote
        const ret:any = await Promise.all(fileNames.map(async fileName=>{
          return await downloadPhotographFiles(orgId, patientId, caseId, fileName,refinementIndex)
        }))
        //const ret = await dispatch(downloadPhotosfromS3({caseId,photos:needDownloadPhotos}))

        const downloadFiles = ret as File[];
        if(downloadFiles && downloadFiles.length>0){
          for (let i = 0; i < downloadFiles.length; i++) {
            const file = downloadFiles[i];
            downloadFileList[file.name] = checkFileType(file);
          }
        }
        console.log('need download photos:::',ret,downloadFileList)
        //downloadFileList = ret.payload as Record<string,{data:File,status:string}>;
      }
    
      // get files from cache or download result.
      // filename <-> file
      for (let name of filenames) {
        if (cache && cache[name]) {
          retFileList[name] = cache[name]
        }else if(downloadFileList && downloadFileList[name]){
          retFileList[name] = downloadFileList[name]
        }
      }
      console.log('<download result :::>',retFileList)
      return retFileList;
    }

    try{
      const ret = await downloadPhotosOrGetFromCache(fileNames)
      return ret;
    }catch(err){
      return rejectWithValue(err)
    }

  },
)

export const deletePhotographs = createAsyncThunk<File[],IPhotosDeleteParams>(
  "PhotosServoce/deletePhotographs",
  async(
    { patientId, caseId, fileNames },
    { rejectWithValue, getState },
  )=>{
    const orgId = (getState() as any).user.current_orgId
    try{
      const ret = Promise.all(fileNames.map(async fileName=>{
        return await deletePhotographFile(orgId, patientId, caseId, fileName)
      }))
    }catch(err){
      return rejectWithValue(err)
    }
  }
)



export const downloadPhotographs = createAsyncThunk<any,fileDownloadParams>(
  "PhotosService/downloadPhotographs",
  async (
    { patientId, caseId, fileName },
    { rejectWithValue, getState },
  ) => {
    const orgId = (getState() as any).userService.user.current_orgId
    try {
      return await downloadPhotographFiles(orgId, patientId, caseId, fileName)
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const fetchFilesList = createAsyncThunk<any,fetchFileParams>(
  "PhotosService/fetchFileList",
  async (
    { patientId, caseId },
    { rejectWithValue, getState },
  ) => {
    const orgId = (getState() as any).userService.user.current_orgId
    try {
      return await fetchFileList(orgId, patientId, caseId)
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const updatePhotoPosition = createAsyncThunk<any,updatePhotoPositionParams>(
  "PhotosService/updatePhotoPosition",
  async (
    { patientId, caseId, payload },
    { rejectWithValue, getState },
  ) => {
    const orgId = (getState() as any).userService.user.current_orgId
    try {
      return await updatePhotoPositionAPI(orgId, patientId, caseId, payload)
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const getS3FileUrlAPI = createAsyncThunk<any,{caseId, casePhoto}>(
  "PhotosService/getS3FileUrl",
  async ({ caseId, casePhoto }, { rejectWithValue }) => {
    try {
      return await getS3FileUrl({ caseId, casePhoto })
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const uploadPhotographsInv_2 = createAsyncThunk<any,{url, file}>(
  "PhotosService/uploadPhoto",
  async ({ url, file }, { rejectWithValue }) => {
    try {
      console.log("upload:::>>>>", file, url)
      return await uploadPhoto({ url, file })
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

/**
 * Request AI calculation for photo types
 */
export const postMessageToWebsocket = createAsyncThunk<Object,IAIRequetParams>(
  "PhotosService/postMessageToWebsocket",
  async({orgId,patientId,caseId,imageNameList},{rejectWithValue}) =>{
    try{
      const { VITE_WEBSOCKET_URL } = import.meta.env
      let isStartAiProcess = false
      return new Promise(async (resolve, reject) => {
        const renewToken = await oktaAuthClient.token.renewTokens();
        await oktaAuthClient.tokenManager.setTokens(renewToken)
        const token = getAccessToken();
        // setIsAIloading(true)
        let connect = false
        const ws = new WebSocket(
          `${VITE_WEBSOCKET_URL}/patient-mgmt/v1/org/${orgId}/patients/${patientId}/txplans/${caseId}/ai-clinical-photo`,
          "4a793be7-51f0-4d51-892f-11022e413e94",
        )
        const sendContext = JSON.stringify({
          type: "inference",
          body: {
            case_id: Number(caseId),
            token: token,
            algorithm: "photo2",
            input: {
              org_id: orgId,
              patient_id: patientId,
              photo_name: imageNameList
            },
          },
        })
        ws.onopen = function () {
          ws.send(sendContext)
          connect = true
        }
        ws.onmessage = function ({ data }) {
          console.log("AIProcessOnMessage::", data)
          try{
            const {
              body: { result, output, detail, accept_task },
            } = JSON.parse(data)
            // deal system busy
            if (detail === "_INFERENCE_BEGIN") {
              isStartAiProcess = true
            } else if (accept_task && !isStartAiProcess){
              ws.send(sendContext)
            }
            if (result !== 1) return
            // filter
            const aiResult = Object.keys(output).reduce((prev,key)=>{
              if (typeof output[key] === "string") {
                prev[key] = output[key]
              }
              return prev
            },{})
            const removeDuplicate = Object.keys(aiResult).reduce(
              (prev, key) => {
                const value = aiResult[key]
                if (!prev.values.has(value)) {
                  prev.values.add(value);
                  prev.result[key]=value;
                } else{
                  prev.result[key]=""
                }
                return prev
              },{
                values: new Set(),
                result: {}}
              )
    
            const jsonData = { ...removeDuplicate.result};
            console.log('jsonData:', jsonData);
            ws.close()
            resolve(jsonData as any)
          } catch (err){
            console.error('handle AI message error:', err, data)
            reject(null)
          }
        }
      })
    }catch(err){
      return rejectWithValue(err)
    }
  }
)



export const uploadPhotosToS3 = createAsyncThunk(
  "utils/uploadPhotosToS3",
  async (
    params: { caseId; files?: Record<string, File> },
    { rejectWithValue, getState },
  ) => {
    try {
      const response = await v2Apis.uploadFilesToS3(
        params.caseId,
        undefined,
        params.files,
      )
      return response
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const downloadPhotosfromS3 = createAsyncThunk<
  Record<string, string | Blob>,
  { caseId: string; photos: v2Apis.IPhotoInfo[] }
>("PhotosService/downloadPhotosfromS3", async (params, { rejectWithValue }) => {
  try {
    const response = await v2Apis.requestS3Url({
      caseId: params.caseId,
      urlType: "download",
      casePhoto: params.photos,
    })
    if (response.code === 0 && response.msg === "success") {
      const respData = response.data
      console.log("respdata:::", respData)
      const result: Record<string, string | Blob | File> = {}
      let ret: Promise<File | null>[] = []
      for (let index = 0; index < respData.casePhoto.length; index++) {
        ret.push(
          new Promise(async (resolve, reject) => {
            const s3urlres = respData.casePhoto[index]
            let resRet = await v2Apis.downloadS3Resource(s3urlres)
            if (resRet instanceof Error) {
              resolve(null)
            } else {
              if (resRet.data instanceof Blob) {
                resRet = new File([resRet.data], s3urlres.destFilename)
              } else {
                // json obj
                resRet = resRet.data
              }
              resolve(resRet)
              result[s3urlres.destFilename] = resRet
            }
          }),
        )
      }

      await Promise.all(ret)

      return result
    }
  } catch (err) {
    return rejectWithValue(err)
  }
})
