import { FC, useEffect, useState } from "react"

import { Box } from "../../../../components/mui.components"
import { useAppDispatch } from "../../../../core/app/hooks"
import { getCustomBoxFiles } from "../../../../core/app/slices/customPackaging"
import { ICustomPackaging } from "../../../../core/model/interface/ICustomPackaging"

const CustomImagePreview: FC<{ customPackagingInfo: ICustomPackaging }> = ({
  customPackagingInfo,
}) => {
  const dispatch = useAppDispatch()
  const [imgUrl, setImgUrl] = useState<string>("")

  useEffect(() => {
    const { topLink, sideLink, accountId } = customPackagingInfo
    if (topLink || sideLink) {
      const imageType = topLink ? "top" : "side"

      dispatch(getCustomBoxFiles({ accountId, imageType })).then((res) => {
        if (res.payload.status === 200) {
          const blob = res.payload.data
          const imgUrl = URL.createObjectURL(blob)
          setImgUrl(imgUrl)
        }
      })
    }
  }, [dispatch, customPackagingInfo])
  return (
    <Box>
      {imgUrl && (
        <Box
          sx={{ height: "124px", width: "124px", objectFit: "contain" , display:"flex"}}
          component="img"
          src={imgUrl}
          alt={customPackagingInfo.customPackagingName}
        />
      )}
    </Box>
  )
}

export default CustomImagePreview
