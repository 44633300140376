import { FC } from "react"
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material"
interface Type {
  showPrompt: boolean | (() => void)
  cancelNavigation: boolean | (() => void)
  setisSubmitModification: (arg: boolean) => void
  setisSubmitReply
  isSubmitModification
  uploadAllData
  confirmNavigation
  isEdit: boolean
}
const MessagePopup: FC<Type> = ({
  cancelNavigation,
  showPrompt,
  setisSubmitModification,
  setisSubmitReply,
  isSubmitModification,
  uploadAllData,
  confirmNavigation,
  isEdit
}) => (
  <Dialog open={showPrompt}>
    <Box sx={{ width: 444 }}>
      <DialogTitle>
        {!isSubmitModification
          ? "Modification request hasn’t been completed yet."
          : "Reply has not been submitted"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {!isSubmitModification
            ? "Closing the case now will lose your request. Are you sure you want to close the case?"
            : "Your reply will be lost if you close the case now. Are you sure you want to close the case?"}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            cancelNavigation()
          }}
        >
          CANCEL
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            if (isEdit) {
              uploadAllData({
                callback: () => {
                  !isSubmitModification
                    ? setisSubmitModification(true)
                    : setisSubmitReply(true)
                  confirmNavigation()
                },
              })
            } else {
              !isSubmitModification
                ? setisSubmitModification(true)
                : setisSubmitReply(true)
              confirmNavigation()
            }


          }}
          autoFocus
        >
          CLOSE
        </Button>
      </DialogActions>
    </Box>
  </Dialog>
)

export default MessagePopup
