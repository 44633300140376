import { useState, useEffect } from "react"
import { Button, Typography } from "@mui/material"
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material"
import { fetchBannerData } from "@/core/app/slices/banners/bannerThunkApi"
import { useParams } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "@/core/app/hooks"
import { RootState } from "@/core/app/store"
import { CASE_STATUS } from "@/modules/Patient/config/status.config"
export const UDBanner = ({ open, onOK }) => {
  const dispatch = useAppDispatch()
  const { patientId, caseId } = useParams()
  const [warningSubject, setWarningSubject] = useState("")
  const [warningContent, setWarningContent] = useState("")
  const [openBanner, setOpenBanner] = useState(false)

  const { caseDetail } = useAppSelector((state: RootState) => state.caseService)

  useEffect(() => {
    if (
      caseDetail.caseDisposition === CASE_STATUS.ATTENTION_NEEDED ||
      caseDetail.caseDisposition === CASE_STATUS.ATTENTION_NEEDED_REFINEMENT ||
      caseDetail.caseDisposition === CASE_STATUS.REJECTED
    ) {
      dispatch(fetchBannerData({ patientId, caseId })).then((data) => {
        if (
          data.payload &&
          data.payload.warning_subject &&
          CASE_STATUS.REJECTED !== caseDetail.caseDisposition
        ) {
          setWarningSubject(data.payload.warning_subject)
          setWarningContent(data.payload.content)
          setOpenBanner(true)
        }
      })
    }
  }, [caseDetail])

  return (
    <Dialog open={openBanner}>
      <DialogTitle>Warning .... </DialogTitle>
      <DialogContent>
        <Typography variant="body1">{warningSubject}</Typography>
        <Typography variant="body2">{warningContent}</Typography>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => {
            setOpenBanner(false)
          }}
        >
          Got it!
        </Button>
      </DialogActions>
    </Dialog>
  )
}
