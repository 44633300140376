/**
 *  call this component when close case and not order case
 */
import React, { FC, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { caseManagement } from "@/gluelayer"

import { useAppDispatch, useAppSelector } from "../../../../core/app/hooks"
import { updateCaseById } from "../../../../core/app/slices/case/caseThunkApi"
import {
  unlockCaseApi,
  uploadCaseZips,
} from "../../../../core/app/slices/clinical/clinicalThunkApi"
import { RootState } from "../../../../core/app/store"

interface noPopupType {
  isActive: boolean
  showPrompt: boolean | (() => void)
  confirmNavigation: boolean | (() => void)
  uploadAllData
  isClickOrder: boolean
}
const NoPopup: FC<noPopupType> = ({
  isActive,
  showPrompt,
  confirmNavigation,
  uploadAllData,
  isClickOrder,
}) => {
  const dispatch = useAppDispatch()
  const { caseId, patientId } = useParams()

  useEffect(() => {
    if (isActive && showPrompt)
      uploadAllData({
        callback: () => {
          // if (!isClickOrder) {
          dispatch(unlockCaseApi({ patientId, caseId })).then(() => {
            confirmNavigation()
          })
          // }
        },
      })
  }, [isActive, showPrompt])

  return <></>
}
export default NoPopup
