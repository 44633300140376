import { globalEvents, GlobalEvents } from "../../../utils/globalevents"

import "../../modules/toothcorrections/toothcorrectiondispatcher"
import { resourceManager } from "../resourcemanager/resourcemgr"
import { resourcesSynchronization } from "../resourcemanager/resourcessynchronization"
import { sagemakerModule } from "../sagemaker/sagemakerModule"
import type { ESetupType } from "../../common/commontypes"
import { Event } from "../../../utils/event"
// import { TemporaryTest } from "../../temporarytest";
import {
  IWASMStateController,
  IWASMModule,
  IWasmMouthModule,
  IWASMModuleManager,
  IWASMWrapInstance,
  EArchType,
  IWASMStageController,
  IWASMTreatView,
} from "../../common/types"
/**
 * init function for WASM
 * return the wasm instance module
 */
declare let datamodel: (wasmoptions: any) => Promise<void>

export const enum WASMLifeCircleEventType {
  LoadWasmFinished = "LoadWasmFinished",
  InitFinished = "InitFinished",
  CloseCase = "CloseCase",
  SetUpFinished = "SetUpFinished",
  BeforeSetUpFinish = "BeforeSetUpFinish",
  NonePresetUp = "NonePresetUp",
  PresetupEnd = "PresetupEnd",
}

export const enum StageEventType {
  UPDATESTAGE_EVENT = "UpdateStage",
}

// (window as any).wasmDebug = false;

let showwasmLogFlag = sessionStorage.getItem("ShowWasmLog")
if (!showwasmLogFlag) {
  sessionStorage.setItem("ShowWasmLog", "false")
  showwasmLogFlag = sessionStorage.getItem("ShowWasmLog")
}

const isShowWasmLog = showwasmLogFlag === "true" ? true : false

class WasmModule {
  private _wrapInstance: any = {}
  private _module: any = undefined
  private _ulabwinIns: any = undefined
  private _statusController
  private _stagingcontoler
  private _treatView
  private _mouthModel
  private _moduleManager
  private _isWasmInit = false
  private _isOpenCaseReady = false
  moduleOpenCaseReady?: () => void
  calsetupfinishCallback?: () => void
  private _event: Event = new Event()

  get event() {
    return this._event
  }

  public isPresetup = false
  public caseType: ESetupType = 8
  public versionType = 0

  get caseSetupType() {
    return this.caseType
  }
  set caseSetupType(type) {
    this.caseType = type
  }
  get mouthModel(): IWasmMouthModule {
    return this._mouthModel
  }

  /**
   *
   * 获取archModel,
   * 或者用于判断是否有上下颌，如果如果返回null则说明没有上颌或者下颌
   *
   * @param type
   */
  getArchModel(type: EArchType) {
    if (type === EArchType.UpArch) {
      return this.mouthModel.GetArchModel(this.module.ArchType.UpArch)
    }

    if (type === EArchType.LowArch) {
      return this.mouthModel.GetArchModel(this.module.ArchType.DownArch)
    }
  }

  get stagingcontoler(): IWASMStageController {
    return this._stagingcontoler
  }

  get treatView(): IWASMTreatView {
    return this._moduleManager.GetModuleTreatView()
  }

  get ulabwinIns() {
    return this._ulabwinIns
  }

  get wrapInstance(): IWASMWrapInstance {
    return this._wrapInstance
  }

  get statusController(): IWASMStateController {
    return this._statusController
  }

  get module(): IWASMModule {
    return this._module
  }

  get moduleManager(): IWASMModuleManager {
    return this._moduleManager
  }

  /**
   * wasm module是否初始化
   */
  get isWASMInit() {
    return this._isWasmInit
  }

  /**
   * 加载的case是否初始化完成
   */
  get isInit() {
    return this._isOpenCaseReady
  }

  /**
   * 销毁当前打开case
   * @returns
   */
  delete() {
    if (!this._isOpenCaseReady) return
    resourcesSynchronization.clear()

    if (this.module && this.module._cancel_em_loop) {
      this.module._cancel_em_loop()
    }

    // this._module.getULabWinIns().delete();
    this._module.getWrapperIns().delete()

    // resourcesSynchronization.ismkdirdone = false;
    console.log("canvas :", this._module.canvas)
    if (this._module.canvas) {
      this._module.canvas.removeEventListener(
        "webglcontextlost",
        this.contextlostprevent,
        false,
      )
      this._module.canvas.removeEventListener(
        "mouseover",
        this.webglMouseOver,
        false,
      )
    }

    this._mouthModel = null
    this._isOpenCaseReady = false
    this._module.canvas = null
    this._stagingcontoler = null

    this.event.fire(WASMLifeCircleEventType.CloseCase)
  }

  setWasmModule(inst: any) {
    this._module = inst
    this._isWasmInit = true
  }

  /**
   * opencase后，各个模块打开之前调用
   * 调用时间在initFinishedCallback之前
   */
  onLoadCaseCallback() {
    console.log("ashuaizzz🚀 ~ onLoadCaseCallback:")
    this._wrapInstance = this._module.getWrapperIns()
    this._ulabwinIns = this._module.getULabWinIns()
    this._mouthModel = this._ulabwinIns.getMouthModelIns()
    this._statusController = this._ulabwinIns.getStatusController()
    this._stagingcontoler = this._ulabwinIns.getStageContoller()
    this._moduleManager = this._ulabwinIns.getModuleManager()
    console.log("1111 _module :", this._module)
    console.log("1111 _wrapInstance :", this._wrapInstance)
    console.log("1111 _ulabwinIns :", this._ulabwinIns)
    console.log("1111 _mouthModel :", this._mouthModel)
    console.log("1111 _statusController :", this._statusController)
    console.log("1111 _stagingcontoler :", this._stagingcontoler)
    console.log("1111 _moduleManager :", this._moduleManager)

    // 每次加载完成记录 各bulk的版本信息
    resourcesSynchronization.saveDataVersionInfo()
    globalEvents.fire(GlobalEvents.WASM_MODULE_LOADOK)
  }

  initCallback() {
    ;(window as any).onLoadCaseCallback = this.onLoadCaseCallback.bind(this)
    ;(window as any).initFinishedCallback = this.initFinishedCallback.bind(this)
  }

  /**
   * only way of init WASM
   * @param canvas
   * @param callback
   */
  async initWASM(canvas, callback?:(module:IWASMModule)=>void) {
    // if not init module do it else change canvas only
    try {
      if (!this.module) {
        // load wasm file and init
        await this.wasmInit(canvas, async () => {
          if (resourceManager.getResourceStatic().size === 0) {
            await resourceManager.downloadStaticResource()
          }
          // write static resource into virtual file system.
          await resourcesSynchronization.writeStaticResourceToVirtualFileSystem()

          this.initCallback()
          sagemakerModule.initCallback()
          if (callback) {
            await callback(this.module)
          }
        })
      } else if (this._module) {
        this._module.canvas = this.webglcontextlost(canvas)
        sagemakerModule.initCallback()
        if (callback) {
          await callback(this.module)
        }
      }
    } catch (e) {
      console.log(e)
      // throw e;
    }
  }

  private isWASMIniting = false;
  async wasmInit(canvasRef, action) {
    if (this.isWASMIniting) {
      return;
    }
    this.isWASMIniting = true;
    const modell = {
      canvas: this.webglcontextlost(canvasRef), // canvas and
      arguments: ["/test"], // All udesign files are stored in test folder.
      mainScriptUrlOrBlob: "UDesignCommonDataModel.js", // Set correct js file.
      print: this.wasmLog.bind(this), // disable printf and std::cout for C++
      printErr: this.logErr.bind(this), // show only error
      onRuntimeInitialized: function () {
        console.log("WASMModule initialized")
      },
    }

    if (this._module) {
      this._module.canvas = modell.canvas
      await action()
    } else {
      this._module = await datamodel(modell) // await datamodel initialized
      this._isWasmInit = true
      await action()
    }
    this.isWASMIniting = false;
  }
  wasmLog = (...args) => {
    if (isShowWasmLog) {
      console.log(...args)
    }
  }

  /**
   * for connect wasm err function
   * @param msg
   */
  logErr(msg: string) {
    console.warn(msg)
  }

  getWasmObj() {
    return {
      _ulabwinIns: this._ulabwinIns,
      _wrapInstance: this._wrapInstance,
    }
  }

  /**
   * MouthModel模块初始化
   */
  initFinishedCallback() {
    this._isOpenCaseReady = true
    this._statusController = this._ulabwinIns.getStatusController()
    this._statusController.SetToAnteriorView()

    globalEvents.fire(GlobalEvents.CASE_INIT_FINISHED)
    if (this.moduleOpenCaseReady) this.moduleOpenCaseReady()

    this._event.fire(WASMLifeCircleEventType.InitFinished)

    const biteRampModule = wasmModule.moduleManager.GetBiteRampModule()
    console.log("ashuaizzz🚀 ~ biteRampModule:", biteRampModule)

    if (!this.isPresetup) {
      if (this.calsetupfinishCallback) this.calsetupfinishCallback()
      this._event.fire(WASMLifeCircleEventType.NonePresetUp)
    } else {
      this._event.fire(WASMLifeCircleEventType.PresetupEnd)

      if (this.calsetupfinishCallback) this.calsetupfinishCallback()
    }
    const module = wasmModule.ulabwinIns.getModuleManager();
    if (module) {
      module.GetUndoOrRedoModule().CleanState();
    }
  }

  async changeCanvasForWasm(
    newCanvas: HTMLCanvasElement,
    callback: () => void,
  ) {
    ;(window as any).changeCanvasCallback = callback ?? (() => {})
    this.statusController.SetChangeRenderWindowCB("window.changeCanvasCallback")

    this._module._terminate()

    this._module.canvas = newCanvas
    this._statusController.ChangeRenderWindow()
  }

  webglcontextlost(canvasRef) {
    canvasRef.addEventListener(
      "webglcontextlost",
      this.contextlostprevent,
      false,
    )
    canvasRef.addEventListener(
      "mouseover",
      this.webglMouseOver(canvasRef),
      false,
    )
    return canvasRef
  }
  webglMouseOver(canvasRef) {
    canvasRef.focus()
  }
  contextlostprevent(e) {
    console.error("WebGL context lost. You will need to reload the page.")
    e.preventDefault()
  }

  cancelemloop() {
    if (this.module && this.module._cancel_em_loop) {
      this.module._cancel_em_loop()
    }
  }

  // /**
  //  *
  //  * 销毁
  //  */
  // remove() {
  //   console.log("delete: ");
  //   // disPatchPanelDataCallback(false, true);
  //   if (this._module) this._module.cancel_em_loop();

  //   resourcesSynchronization.clear();

  //   if (this._module.canvas)
  //     this._module.canvas.removeEventListener(
  //       "webglcontextlost",
  //       this.contextlostprevent,
  //       false
  //     );
  //   if (this._module.canvas)
  //     this._module.canvas.removeEventListener(
  //       "mouseover",
  //       this.webglMouseOver,
  //       false
  //     );
  //   this._mouthModel = null;
  //   this._module = null;
  //   this._isOpenCaseReady = false;
  // }
  // /**
  //  * 回调,当WASM内stage长度发生变化的时候触发，
  //  * 例如移牙或者节点变化
  //  * @param steps0
  //  * @param steps1
  //  *   */
  // async updateStagesCallback(arch: number, index: number) {
  //   // const toothIds = this._mouthModel.GetArchModel(this._module.ArchType.UpArch).GetToothIds();
  //   // const values = this._ulabwinIns.GetReportResult(this._module.ArchType.UpArch, 0);
  //   // const size = toothIds.size();
  //   // const allReportArrays: any[] = new Array<any>();
  //   // for (let i = 0; i < size; i++) {
  //   //   const value: any = {};
  //   //   value.id = toothIds.get(i);
  //   //   const distanceRotate: number[] = [];
  //   //   for (let l = 0; l < 6; l++) {
  //   //     distanceRotate[l] = values.get(i * 6 + l);
  //   //   }
  //   //   value.distanceRotate = distanceRotate;
  //   //   console.log('arch0: ', value.id, distanceRotate);
  //   //   allReportArrays[value.id] = value;
  //   // }
  //   console.log("updateStagesCallback", arch, index);
  //   stagingManager.updateStageCallback(arch, index);
  //   report.update();
  //   // disPatchPanelDataCallback();
  //   iprModule.updateIPRVisibility();
  //   this._event.fire(StageEventType.UPDATESTAGE_EVENT);
  // }

  // dialogCallback(show: boolean) {
  //   // disPatchPanelDataCallback(show, true);
  // }

  // toothNotAllowMoveCallback() {
  //   // toothMovementwarningHelpSinTips();
  // }

  // setEndallCallback() {
  //   (window as any).setupReady = {};
  //   (window as any).setupReady.endAllCallback = this.setEndMessageCallback.bind(this);
  //   if (this.module.getUNewPreSetUpWin()) this.module.getUNewPreSetUpWin().EndCopaDemoCallCB('setupReady.endAllCallback');
  // }
  // setEndMessageCallback(show: number, type: number) {
  //   let isVisible = false;
  //   if (show === 1) isVisible = true;

  //   if (stagingManager.setupTypeAndStage)
  //     stagingManager.setupTypeAndStage(
  //       type,
  //       Math.max(
  //         stagingManager.wasmStageData.keypointsUpMaxStep,
  //         stagingManager.wasmStageData.keypointsDownMaxStep
  //       )
  //     );
  //   const visible: IAttachmentIPRVisible = {
  //     isHasAttachmentData: isVisible,
  //     isHasIPRData: this._statusController.HasCollision(),
  //   };
  //   if (attachmentModule.attachmentiprVisibility)
  //     attachmentModule.attachmentiprVisibility(visible);
  // }

  // toothMoveTypesCallback() {
  //   this._stagingcontoler.SetCanMoveTooth(stagingManager.isStageIndexToothMoveTypes(isUpTooth(panelData.toothId) ? 0 : 1));
  // }
}
export const wasmModule = new WasmModule()
