import { Box, Grid, IconButton } from "@/components/mui.components"
import { KeyboardArrowLeft, KeyboardArrowRight } from "@/ui-component/mui.icons"
import { FC, useState } from "react"

export const ImgPreview: FC<{
  list: string[]
  scale: number
  setScale: (val: number) => void
}> = ({ list, scale, setScale }) => {
  const [ImgIndex, setImgIndex] = useState(0)
  return (
    <Grid
      container
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <Grid item xs={1}>
        <IconButton
          onClick={() => {
            setScale(1)
            setImgIndex(ImgIndex - 1)
          }}
          disabled={ImgIndex === 0}
        >
          <KeyboardArrowLeft
            fontSize="large"
            sx={{
              height: "35px",
              width: "35px",
              color: ImgIndex === 0 ? "grey" : "#fff",
            }}
          />
        </IconButton>
      </Grid>

      <Grid item xs={10} sx={{ overflow: "auto", maxHeight: "70vh" }}>
        <Box sx={{ my: "24px" }}>
          <img
            src={list[ImgIndex]}
            alt={"box-preview"}
            style={{
              width: "100%",
              height: "100%",
              maxHeight: "70vh",
              objectFit: "contain",
              transform: `scale(${scale})`,
              transition: "transform 0.3s ease-in-out",
              transformOrigin: "top left",
            }}
            loading="lazy"
          />
        </Box>
      </Grid>
      <Grid item xs={1} sx={{ display: "flex", justifyContent: "flex-end" }}>
        <IconButton
          onClick={() => {
            setScale(1)
            setImgIndex(ImgIndex + 1)
          }}
          disabled={ImgIndex + 1 === list?.length}
        >
          <KeyboardArrowRight
            fontSize="large"
            sx={{
              height: "35px",
              width: "35px",
              color: ImgIndex + 1 === list?.length ? "grey" : "#fff",
            }}
          />
        </IconButton>
      </Grid>
    </Grid>
  )
}
