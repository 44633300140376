import { FC, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import { useAppDispatch, useAppSelector } from "../../../core/app/hooks"
import {
  getCustomPackagingList,
  getCustomBoxFiles,
} from "../../../core/app/slices/customPackaging/customPackagingThunkApi"
import { RootState } from "../../../core/app/store"
import { UText } from "../../../ui-component"
import {
  Backdrop,
  Box,
  Grid,
  Paper,
} from "../../../ui-component/mui.components"
import UTable from "../../Patient/PatientList/components/UTable/UTable"

import { MY_CUSTOM_PACKAGING_TABLE_CONFIG } from "./config"
import PreviewCustomModal from "./PreviewCustomModal"

export const MyCustomPackaging: FC = () => {
  const { t } = useTranslation("common")
  const dispatch = useAppDispatch()
  const [open, setOpen] = useState(false)
  const [previewUrl, setPreviewUrl] = useState([])
  const { myCustomPackagingList, customListFetching } = useAppSelector(
    (state: RootState) => state.customPackagingService,
  )

  useEffect(() => {
    dispatch(getCustomPackagingList())
  }, [])

  const handleClose = () => {
    setOpen(false)
  }

  const onRowClick = (row) => {
    if (row && row.status === 6) {
      const fetchImages = async () => {
        try {
          const boxImage = dispatch(
            getCustomBoxFiles({ accountId: row?.accountId, imageType: "box" }),
          )
          const topImage = dispatch(
            getCustomBoxFiles({ accountId: row?.accountId, imageType: "top" }),
          )
          const sideImage = dispatch(
            getCustomBoxFiles({ accountId: row?.accountId, imageType: "side" }),
          )

          const [boxRes, topRes, sideRes] = await Promise.allSettled([
            boxImage,
            topImage,
            sideImage,
          ])

          const imageUrls = []
          if (
            boxRes.status === "fulfilled" &&
            boxRes.value.payload.status === 200
          ) {
            const boxUrl = URL.createObjectURL(boxRes.value.payload.data)
            imageUrls.push(boxUrl)
          }

          if (
            topRes.status === "fulfilled" &&
            topRes.value.payload.status === 200
          ) {
            const topUrl = URL.createObjectURL(topRes.value.payload.data)
            imageUrls.push(topUrl)
          }

          if (
            sideRes.status === "fulfilled" &&
            sideRes.value.payload.status === 200
          ) {
            const sideUrl = URL.createObjectURL(sideRes.value.payload.data)
            imageUrls.push(sideUrl)
          }

          if (imageUrls.length > 0) {
            setPreviewUrl(imageUrls)
            setOpen(true)
          } else {
            console.log("No images available for preview.")
          }
        } catch (error) {
          console.error("Failed to fetch images", error)
        }
      }

      fetchImages()
    }
  }

  return (
    <>
      <Backdrop
        open={open}
        sx={{
          padding: "70px",
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
      >
        <PreviewCustomModal
          open={open}
          onClose={handleClose}
          images={previewUrl}
        />
      </Backdrop>
      <Grid item container justifyContent={"center"}>
        <Paper
          elevation={0}
          sx={{ borderRadius: 4, width: "100%", minHeight: "100px" }}
        >
          <Box
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"start"}
            pb={2}
            ml={4}
            mr={4}
          >
            <UText variant={"h6"} sxProp={{ pt: 2, pb: 2 }}>
              {"My custom packaging"}
            </UText>
            <UTable
              columnData={myCustomPackagingList}
              onRowClick={onRowClick}
              isFetching={customListFetching}
              columnDef={MY_CUSTOM_PACKAGING_TABLE_CONFIG}
              name={"My Packaging"}
              sxTableProps={{
                body: {
                  "&.MuiTableRow-root:hover": {
                    backgroundColor: "#2196F30A",
                  },
                },
              }}
              enableFilters={false}
              enableSearch={false}
              hidePagination
              hideMenu
            ></UTable>
          </Box>
        </Paper>
      </Grid>
    </>
  )
}
