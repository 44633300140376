import { createAsyncThunk } from "@reduxjs/toolkit"

import {
  forcelockCaseSever,
  getCaseByPatient,
  getCaseInfo,
  getFileInfo,
  getFileZips,
  downloadRefinementFile,
  uploadRefinementFiles,
  getIsRead,
  getMessage,
  lockCaseSever,
  postMessage,
  postModiMessage,
  unlockCaseSever,
  updateMessage,
  uploadZips,
} from "./clinical.service"
import {
  clinicalRequestBasicType,
  getZipsType,
  getRefinementType,
  messageBody,
  UploadZips,
  UploadRefinementFiles,
  IWasmInitOption
} from "./clinical.types"
import { caseManagement } from "@/gluelayer"
import * as lodash from "lodash"


export const fetchFileListWeDesign = createAsyncThunk(
  "clinicalService/fetchFileListWeDesign",
  async (
    { orgId, patientId, caseId, fileNames }: clinicalRequestBasicType,
    { rejectWithValue, getState },
  ) => {
    try {
      return await getFileInfo({ orgId, patientId, caseId })
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const fetchFileZipsWeDesign = createAsyncThunk(
  "clinicalService/fetchFileZips",
  async (
    { orgId, patientId, caseId, zipNames }: getZipsType,
    { rejectWithValue, getState },
  ) => {
    try {
      return await getFileZips({ orgId, patientId, caseId, zipNames })
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)


export const fetchFileList = createAsyncThunk(
  "clinicalService/fetchFileList",
  async (
    { patientId, caseId, fileNames }: clinicalRequestBasicType,
    { rejectWithValue, getState },
  ) => {
    const orgId = getState().userService.user.current_orgId
    try {
      return await getFileInfo({ orgId, patientId, caseId })
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const getTreatmentInfos = createAsyncThunk(
  "clinicalService/getTreatmentInfos",
  async ({ patientId }: any, { rejectWithValue, getState }) => {
    const orgId = getState().userService.user.current_orgId
    try {
      return await getCaseByPatient(orgId, patientId)
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const fetchRefinementJsonFile = createAsyncThunk(
  "clinicalService/fetchRefinementJsonFile",
  async (
    { patientId, caseId, refinementFiles }: getRefinementType,
    { rejectWithValue, getState },
  ) => {
    const orgId = getState().userService.user.current_orgId
    try {
      return await downloadRefinementFile({ orgId, patientId, caseId, refinementFiles})
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const fetchRefinementZipFile = createAsyncThunk(
  "clinicalService/fetchRefinementZipFile",
  async (
    { patientId, caseId, refinementFiles }: getRefinementType,
    { rejectWithValue, getState },
  ) => {
    const orgId = getState().userService.user.current_orgId
    try {
      return await downloadRefinementFile({ orgId, patientId, caseId, refinementFiles})
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const submitRefinementFiles = createAsyncThunk(
  "clinicalService/submitRefinementFiles",
  async (
    { patientId, caseId, RefinementFiles, callback }: UploadRefinementFiles,
    { rejectWithValue, getState },
  ) => {
    const orgId = getState().userService.user.current_orgId
    try {
      return await uploadRefinementFiles({ orgId, patientId, caseId, RefinementFiles })
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)



export const fetchFileZips = createAsyncThunk(
  "clinicalService/fetchFileZips",
  async (
    { patientId, caseId, zipNames }: getZipsType,
    { rejectWithValue, getState },
  ) => {
    const orgId = getState().userService.user.current_orgId
    try {
      return await getFileZips({ orgId, patientId, caseId, zipNames })
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)


export const uploadCaseZips = createAsyncThunk(
  "clinicalService/uploadFileZips",
  async (
    { patientId, caseId, zips, callback }: UploadZips,
    { rejectWithValue, getState },
  ) => {
    const orgId = getState().userService.user.current_orgId
    try {
      return await uploadZips({ orgId, patientId, caseId, zips })
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const postMessageApi = createAsyncThunk(
  "clinicalService/postMessage",
  async (
    { patientId, caseId, subject, txName, content }: messageBody,
    { rejectWithValue, getState },
  ) => {
    const orgId = getState().userService.user.current_orgId
    try {
      return await postMessage({
        orgId,
        patientId,
        caseId,
        subject,
        txName,
        content,
      })
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const postModiApi = createAsyncThunk(
  "clinicalService/postModiApi",
  async (
    {
      patientId,
      caseId,
      subject,
      txName,
      content,
      is_modification_requested,
    }: messageBody,
    { rejectWithValue, getState },
  ) => {
    const orgId = getState().userService.user.current_orgId
    try {
      return await postModiMessage({
        orgId,
        patientId,
        caseId,
        subject,
        txName,
        content,
        is_modification_requested,
      })
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const getMessageApi = createAsyncThunk(
  "clinicalService/getMessage",
  async (
    { patientId, caseId }: clinicalRequestBasicType,
    { rejectWithValue, getState },
  ) => {
    const orgId = getState().userService.user.current_orgId
    try {
      return await getMessage({
        orgId,
        patientId,
        caseId,
      })
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const getIsReadApi = createAsyncThunk(
  "clinicalService/getIsRead",
  async (
    { patientId, caseId }: clinicalRequestBasicType,
    { rejectWithValue, getState },
  ) => {
    const orgId = getState().userService.user.current_orgId
    try {
      return await getIsRead({
        orgId,
        patientId,
        caseId,
      })
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const getCaseInfoApi = createAsyncThunk(
  "clinicalService/getCaseInfo",
  async (
    { patientId, caseId }: clinicalRequestBasicType,
    { rejectWithValue, getState },
  ) => {
    const orgId = getState()?.userService?.user?.current_orgId
    try {
      return await getCaseInfo({
        orgId,
        patientId,
        caseId,
      })
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const lockCaseApi = createAsyncThunk(
  "clinicalService/lockCaseApi",
  async (
    { patientId, caseId }: clinicalRequestBasicType,
    { rejectWithValue, getState },
  ) => {
    const orgId = getState()?.userService?.user?.current_orgId
    try {
      return await lockCaseSever({
        orgId,
        patientId,
        caseId,
      })
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const unlockCaseApi = createAsyncThunk(
  "clinicalService/unlockCaseApi",
  async (
    { patientId, caseId, isForceUnlock }: clinicalRequestBasicType,
    { rejectWithValue, getState },
  ) => {
    const orgId = getState()?.userService?.user?.current_orgId
    try {
      return await unlockCaseSever({
        orgId,
        patientId,
        caseId,
        isForceUnlock
      })
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const forcelockCaseApi = createAsyncThunk(
  "clinicalService/forcelockCaseApi",
  async (
    { patientId, caseId }: clinicalRequestBasicType,
    { rejectWithValue, getState },
  ) => {
    const orgId = getState()?.userService?.user?.current_orgId
    try {
      return await forcelockCaseSever({
        orgId,
        patientId,
        caseId,
      })
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)

export const updateMessageApi = createAsyncThunk(
  "clinicalService/updateMessage",
  async (
    { patientId, caseId, messageId, cb, ...payload }: messageBody,
    { rejectWithValue, getState },
  ) => {
    const orgId = getState().userService.user.current_orgId
    try {
      return await updateMessage({
        orgId,
        patientId,
        caseId,
        messageId,
        ...payload,
      })
    } catch (err) {
      return rejectWithValue(err)
    }
  },
)


export const initializeWASM = createAsyncThunk(
  "clinicalService/initializeWASM",
  async (
    {canvas }:IWasmInitOption,
    { rejectWithValue, getState },
  )=>{
    return caseManagement.initWasm(canvas,()=>{
      console.log('wasminit ok!');
    })
  }
)