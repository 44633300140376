import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { Box } from "@mui/material"

import { useAppDispatch } from "../../core/app/hooks"
import { getAccountDetail } from "../../core/app/slices/user/userApis"
import { UText } from "../../ui-component"

import ULabCompanyProfile from "./components/ULabCompanyProfile"
import ULabContactInfo from "./components/ULabContactInfo"
import { AddOrgAddress } from "./AddOrgAddress"
import { UI_PERMISSION } from "@/core/utils/access/permissionConstants"
import usePermissions from "@/hooks/userPermission"
import { useParams } from "react-router-dom"
import { updateCurrentOrgId } from "@/core/app/slices/user/userSlice"

const ULabAccountContent = () => {
  const { t } = useTranslation("common")
  const dispatch = useAppDispatch()
  const { orgId } = useParams()
  const { hasAny } = usePermissions()

  useEffect(() => {
    dispatch(getAccountDetail())
  }, [dispatch])

  useEffect(() => {
    if (!orgId) return
    dispatch(updateCurrentOrgId(orgId))
  }, [dispatch, orgId])

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        pb: 8,
        gap: 5,
      }}
    >
      <UText
        sxProp={{
          padding: "48px 8px 0px 8px",
          alignSelf: "flex-start",
        }}
        variant={"h4"}
      >
        {t("uLabAccount.title")}
      </UText>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 3,
          width: "100%",
        }}
      >
        <ULabContactInfo />
      </Box>
      <ULabCompanyProfile />
      {hasAny(UI_PERMISSION.UI_ACCOUNT_SHIPPINGADDRESS_VIEW) && (
        <AddOrgAddress />
      )}
    </Box>
  )
}

export default ULabAccountContent
