import { FC, useMemo } from "react"
import { Trans, useTranslation } from "react-i18next"
import CheckIcon from "@mui/icons-material/Check"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined"

import { UButton, UText } from "../../../../components"
import {
  Alert,
  Box,
  Grid,
  Tooltip,
} from "../../../../components/mui.components"
import { useAppDispatch, useAppSelector } from "../../../../core/app/hooks"
import { updateSelectedBundle } from "../../../../core/app/slices/order"
import { RootState } from "../../../../core/app/store"
import { IBundleInfo } from "../../../../core/model/interface/IQuotation"
import { formatCurrency } from "../../../../core/utils/formatters"

import ExtraAlaCarte from "./ExtraAlaCarte"

const BundleTitleAlert: FC<{
  isCurrentBundle: boolean
  isBundleCompleted: boolean
}> = ({ isCurrentBundle, isBundleCompleted }) => {
  const text = useMemo(() => {
    if (isBundleCompleted) return "Completed"
    if (isCurrentBundle) return "Current Bundle"
  }, [isCurrentBundle, isBundleCompleted])

  const severity = useMemo(() => {
    if (isBundleCompleted) return "info"
    else if (isCurrentBundle) return "success"
    else {
      return "success"
    }
  }, [isCurrentBundle, isBundleCompleted])

  return (
    <>
      {(isCurrentBundle || isBundleCompleted) && (
        <Alert
          icon={
            <CheckCircleIcon
              fontSize="small"
              sx={{
                color: isBundleCompleted
                  ? "info.main"
                  : isCurrentBundle && "success.main",
              }}
            />
          }
          severity={severity}
          sx={{
            fontWeight: 500,
            color: isBundleCompleted
              ? "info.main"
              : isCurrentBundle && "success.main",
            alignItems: "center",
            height: "56px",
          }}
        >
          {text}
        </Alert>
      )}
    </>
  )
}

const BundleCardTitle: FC<{
  isCurrentBundle: boolean
  isBundleCompleted: boolean
  isSelectedBundle: boolean
  bundleName: string
  bundleType: "RETAINER" | "ALIGNER"
}> = ({
  isCurrentBundle,
  isBundleCompleted,
  isSelectedBundle,
  bundleName,
  bundleType,
}) => {
  return (
    <>
      <BundleTitleAlert
        isCurrentBundle={isCurrentBundle}
        isBundleCompleted={isBundleCompleted}
      />
      <Box
        component={"div"}
        sx={{
          p: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <UText
          color={
            bundleType === "ALIGNER"
              ? "rgba(48, 79, 254, 1)"
              : "rgba(0, 191, 165, 1)"
          }
          sxProp={{ fontWeight: "500" }}
          variant={"h5"}
        >
          {bundleName}
        </UText>
        {!isCurrentBundle && !isBundleCompleted && isSelectedBundle && (
          <CheckCircleIcon fontSize={"small"} sx={{ color: "success.main" }} />
        )}
      </Box>
    </>
  )
}

const BundleBody: FC<{
  isCurrentBundle: boolean
  isBundleCompleted: boolean
  isSelectedBundle: boolean
  currency: string
  bundle: IBundleInfo
  bundleType: "RETAINER" | "ALIGNER"
  countryCode: string
}> = ({
  isCurrentBundle,
  isBundleCompleted,
  isSelectedBundle,
  bundle,
  currency,
  bundleType,
  countryCode,
}) => {
  const isRetainerBundle =
    bundle?.name?.toLowerCase()?.includes("retainer") ?? false
  const { t } = useTranslation()

  return (
    <Box
      component={"div"}
      sx={{
        p: 2,
        background:
          isSelectedBundle &&
          !isBundleCompleted &&
          !isCurrentBundle &&
          "#D7DDD7",
      }}
    >
      <Grid sx={{ display: "flex" }}>
        <UText variant={"h3"}>
          {formatCurrency(bundle.description.price, currency, countryCode)}
        </UText>
        <UText
          variant={"body2"}
          sxProp={{
            ml: 1,
            mt: "10px",
            color: "rgba(0, 0, 0, 0.6)",
          }}
        >
          {currency} <br /> {t("bundle.type.priceText")}
        </UText>
      </Grid>
      <Box sx={{ marginTop: 2 }}>
        {bundleType && (
          <>
            <Box sx={{ display: "flex", alignItems: "center", px: 2 }}>
              <CheckIcon
                sx={{ mr: 2, color: "rgba(48, 79, 254, 1)" }}
                fontSize={"small"}
              />
              <UText
                variant={"caption"}
                sxProp={{ fontWeight: 700, my: "4px" }}
              >
                {isRetainerBundle
                  ? t("bundle.type.limitRetainer", {
                      val: bundle.description?.retainer?.included,
                    })
                  : bundle.name === "Comprehensive"
                  ? t("bundle.type.unlimitedAligner")
                  : t("bundle.type.limitAligner", {
                      val: bundle.description?.aligner?.included,
                    })}
              </UText>
            </Box>

            <Box sx={{ display: "flex", alignItems: "center", px: 2 }}>
              <CheckIcon
                sx={{ mr: 2, color: "rgba(48, 79, 254, 1)" }}
                fontSize={"small"}
              />
              <UText variant={"caption"} sxProp={{ my: "4px" }}>
                {t("bundle.type.alacarteOptions")}
              </UText>
              <Tooltip
                placement="right"
                title={t("bundle.alacarteTooltip")}
                arrow
                componentsProps={{
                  tooltip: {
                    sx: {
                      fontSize: "10px",
                      lineHeight: "140%",
                      width: "219px",
                      letterSpacing: 0,
                      backgroundColor: "#616161E5",
                    },
                  },
                }}
              >
                <InfoOutlinedIcon
                  fontSize={"small"}
                  sx={{
                    color: "info.main",
                    width: "20px",
                    height: "20px",
                    ml: 2,
                  }}
                />
              </Tooltip>
            </Box>
            {![
              "4 retainer",
              "2 retainer",
              "4 retainers",
              "2 retainers",
            ].includes(bundle.name.toLowerCase()) && (
              <Box sx={{ display: "flex", alignItems: "center", px: 2 }}>
                <CheckIcon
                  sx={{ mr: 2, color: "rgba(48, 79, 254, 1)" }}
                  fontSize={"small"}
                />
                <UText variant="caption" sxProp={{ my: "4px" }}>
                  {countryCode === "AU" ||
                  countryCode === "NZ" ||
                  bundle.name === "Finishing"
                    ? bundle.description.template.included + " "
                    : null}
                  {countryCode === "AU" ||
                  countryCode === "NZ" ||
                  bundle.name === "Finishing"
                    ? t("bundle.type.template").toLowerCase()
                    : t("bundle.type.template")}
                </UText>
              </Box>
            )}

            {!isRetainerBundle && (
              <Box sx={{ display: "flex", alignItems: "center", px: 2 }}>
                <CheckIcon
                  sx={{ mr: 2, color: "rgba(48, 79, 254, 1)" }}
                  fontSize={"small"}
                />
                <UText variant={"caption"} sxProp={{ my: "4px" }}>
                  {bundle.description.retainer.included}{" "}
                  {t("bundle.type.retainer")}
                </UText>
                {bundle.name === "Finishing" && (
                  <Tooltip
                    placement="right"
                    title={t("bundle.finishingretainers")}
                    arrow
                    componentsProps={{
                      tooltip: {
                        sx: {
                          fontSize: "10px",
                          lineHeight: "140%",
                          letterSpacing: 0,
                          backgroundColor: "#616161E5",
                        },
                      },
                    }}
                  >
                    <InfoOutlinedIcon
                      fontSize={"small"}
                      sx={{
                        color: "info.main",
                        width: "20px",
                        height: "20px",
                        ml: 2,
                      }}
                    />
                  </Tooltip>
                )}
              </Box>
            )}
            <Box sx={{ display: "flex", alignItems: "center", px: 2 }}>
              <CheckIcon
                sx={{ mr: 2, color: "rgba(48, 79, 254, 1)" }}
                fontSize={"small"}
              />
              <UText variant={"caption"} sxProp={{ my: "4px" }}>
                {isRetainerBundle
                  ? t("bundle.type.uassistRetainerService")
                  : t("bundle.type.uassistService")}
              </UText>
              {!isRetainerBundle && (
                <Tooltip
                  placement="right"
                  componentsProps={{
                    tooltip: {
                      sx: {
                        fontSize: "10px",
                        lineHeight: "140%",
                        width: "266px",
                        letterSpacing: 0,
                        backgroundColor: "#616161E5",
                      },
                    },
                  }}
                  title={
                    <Trans
                      components={{
                        br: <br />,
                      }}
                    >
                      {"bundle.uassistTooltip"}
                    </Trans>
                  }
                  arrow
                >
                  <InfoOutlinedIcon
                    fontSize={"small"}
                    sx={{
                      color: "info.main",
                      width: "24px",
                      height: "20px",
                      ml: 2,
                    }}
                  />
                </Tooltip>
              )}
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", px: 2 }}>
              <CheckIcon
                sx={{ mr: 2, color: "rgba(48, 79, 254, 1)" }}
                fontSize={"small"}
              />
              <UText variant={"caption"} sxProp={{ my: "4px" }}>
                {isRetainerBundle
                  ? t("bundle.type.premiumShipping")
                  : t("bundle.type.standardShipping")}
              </UText>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", px: 2 }}>
              <CheckIcon
                sx={{ mr: 2, color: "rgba(48, 79, 254, 1)" }}
                fontSize={"small"}
              />
              <UText variant={"caption"} sxProp={{ my: "4px" }}>
                {t("bundle.type.customPackaging")}
              </UText>
            </Box>
          </>
        )}

        {bundle.alacarte && countryCode && (
          <ExtraAlaCarte
            bundle={bundle}
            currency={currency}
            countryCode={countryCode}
          />
        )}
      </Box>
    </Box>
  )
}

const BundleCard: FC<{
  bundle: IBundleInfo
  currency: string
  currentBundle: string
  isBundleCompleted: boolean
  selectedBundle: string
  bundleType: "RETAINER" | "ALIGNER"
}> = ({
  bundle,
  currentBundle,
  isBundleCompleted,
  currency,
  selectedBundle,
  bundleType,
}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { countryCode } = useAppSelector(
    (state: RootState) => state.userService,
  )
  const { bundlesPurchasedForCase } = useAppSelector(
    (state: RootState) => state.orderService,
  )

  const isCurrentBundle = useMemo(() => {
    if (currentBundle?.length > 0) {
      if (["4 retainer", "2 retainer"].includes(bundle.name.toLowerCase())) {
        return ["4 retainers", "2 retainers"].includes(
          bundle.name.toLowerCase(),
        )
      } else {
        return (
          currentBundle.length &&
          bundle?.name?.length &&
          currentBundle.toLowerCase() === bundle.name.toLowerCase() && bundlesPurchasedForCase
        )
      }
    }
  }, [currentBundle, bundle.name])

  return (
    <Grid
      item
      sx={{
        width: "268px",
        border:
          bundle.sku_name === selectedBundle &&
          !isBundleCompleted &&
          !isCurrentBundle
            ? "1px solid rgba(46, 125, 50, 0.5)"
            : "1px solid rgba(0, 0, 0, 0.56)",
        borderRadius: "4px",
        height: "fit-content",
      }}
    >
      <BundleCardTitle
        isCurrentBundle={isCurrentBundle}
        isBundleCompleted={isBundleCompleted}
        isSelectedBundle={bundle.sku_name === selectedBundle}
        bundleName={bundle.name}
        bundleType={bundleType}
      />

      {countryCode && bundle.description && (
        <BundleBody
          isCurrentBundle={isCurrentBundle}
          isBundleCompleted={isBundleCompleted}
          isSelectedBundle={bundle.sku_name === selectedBundle}
          currency={currency}
          bundle={bundle}
          bundleType={bundleType}
          countryCode={countryCode}
        />
      )}

      {!isBundleCompleted && !isCurrentBundle && (
        <Box
          sx={{
            background: bundle.sku_name === selectedBundle && "#D7DDD7",
          }}
        >
          <UButton
            variant={"contained"}
            btnType={"button"}
            btnText={t("bundle.button.select")}
            sxProp={{ m: 1, width: "252px" }}
            onClickHandler={() => {
              dispatch(updateSelectedBundle(bundle))
            }}
            disabled={selectedBundle === bundle.sku_name}
          />
        </Box>
      )}
    </Grid>
  )
}

export default BundleCard
