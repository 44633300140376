import { useEffect, useState } from "react"
import { Box, Select, Typography, MenuItem } from "@mui/material"
import { IconButton, Backdrop } from "../../../components/mui.components"
import {
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from "../../../ui-component/mui.icons"
import Preview from "@/components/Preview/preview2"
import "./RefinePhotoList.scss"
import { RootState } from "@/core/app/store"
import { useAppSelector, useAppDispatch } from "@/core/app/hooks"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router"
import stl from "../../../assets/images/cube-outline.svg"
import { downloadPhotographs1_1 } from "@/core/app/slices/records/photograph/photographThunkApi"
import Slider from "react-slick"
import { UImagePreview } from "../../../components"

import zoomOut from "@/assets/svgs/arrow-expand-all.svg"

interface ArrowProps {
  onClick: () => void
  currentSlide: number
  slideCount: number
}
const NextArrow: FC<ArrowProps> = ({ onClick, currentSlide, slideCount }) => {
  return (
    <Box component={"div"} className="next-arrow">
      <IconButton
        onClick={onClick}
        disabled={currentSlide + 3 === slideCount}
        sx={{
          position: "absolute",
          right: "44px",
          top: "50%",
          transform: "translateY(-50%)",
          p: 0,
        }}
      >
        <KeyboardArrowRight
          fontSize="large"
          sx={{ height: "35px", width: "35px" }}
        />
      </IconButton>
    </Box>
  )
}

const PrevArrow: FC<ArrowProps> = ({ onClick, currentSlide }) => {
  return (
    <Box component={"div"} className="prev-arrow">
      <IconButton
        onClick={onClick}
        disabled={currentSlide === 0}
        sx={{
          position: "absolute",
          left: "48px",
          top: "50%",
          transform: "translateY(-50%)",
          p: 0,
        }}
      >
        <KeyboardArrowLeft
          fontSize="large"
          sx={{ height: "35px", width: "35px" }}
        />
      </IconButton>
    </Box>
  )
}
const RefinePhotoList = ({ setSelectedPreview }) => {
  const { caseId, patientId } = useParams()
  const dispatch = useAppDispatch()
  const { t } = useTranslation("common")
  const [upperStl, setUpperStl] = useState({ data: null, action: "init" })
  const [lowerStl, setLowerStl] = useState({ data: null, action: "init" })
  const { uploadSTL } = useAppSelector((state) => state.scansService)

  const { photoTypeJson } = useAppSelector(
    (state: RootState) => state.PhotosService,
  )
  const [pictureFileList, setPictureFileList] = useState([])
  // get picture files
  const getPicFile = async (fileTmp) => {
    const ret = await dispatch(
      downloadPhotographs1_1({
        caseId,
        patientId,
        fileNames: fileTmp,
      }),
    )

    const fileList = ret.payload || {}
    const arr = []
    for (const key in fileList) {
      arr.push({ name: key, file: fileList[key] })
    }
    setPictureFileList(arr)
  }
  useEffect(() => {
    if (!photoTypeJson) {
      return
    }
    const photoNameTmp = []
    for (const key in photoTypeJson) {
      if (key !== "thumbs") {
        photoNameTmp.push(key)
      }
    }
    if (photoNameTmp.length) {
      getPicFile(photoNameTmp)
    }
  }, [photoTypeJson])
  useEffect(() => {
    const { upper, lower } = uploadSTL
    if (upper) {
      setUpperStl({
        data: upper,
        action: "stl",
      })
    }
    if (lower) {
      setLowerStl({
        data: uploadSTL.lower,
        action: "stl",
      })
    }
  }, [uploadSTL])

  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  }
  const [isStlPreview, setIsstlPreview] = useState<boolean>(true)
  const [prevImage, setPrevImage] = useState<File[]>([])
  const [selectedImage, setSelectedImage] = useState<string>("")
  const [currentImage, setCurrentImage] = useState<number>(-1)
  const handleImageClick = async (image: string, index: number) => {
    if (index === -1) {
      setIsstlPreview(true)
      setPrevImage([])
    } else {
      const response = await fetch(image)
      const blob = await response.blob()
      const file = new File([blob], `Image`, { type: blob.type })
      setSelectedImage(image)
      setPrevImage([file])
      setIsstlPreview(false)
    }
    setCurrentImage(index)
  }
  return (
    <Box id="refineContainer" className={"refineContainer"}>
      <Box className={"preview"}>
        <Box
          id="3dcontainer"
          sx={{
            background: "#333",
            display: isStlPreview ? "" : "none",
          }}
          className={"d3container"}
        >
          <Preview upperStl={upperStl} lowerStl={lowerStl} />
        </Box>
        <Box sx={{ position: "absolute", top: "0", left: "0" }}>
          {!isStlPreview && (
            <>
              <UImagePreview
                imageHeight={520}
                imageWidth={520}
                files={prevImage.map((img) => {
                  return {
                    src: URL.createObjectURL(img),
                    name: img.name,
                  }
                })}
              />
              <IconButton
                onClick={() => setSelectedPreview(selectedImage)}
                className="zoom"
                sx={{
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                  backgroundColor: "#EEE",
                  border: "1px solid",
                  borderColor: "divider",
                  borderRadius: "4px",
                  zIndex: 1,
                  display: "none",
                  padding: "7px",
                }}
              >
                <Box
                  component={"img"}
                  src={zoomOut}
                  alt={"zoomout"}
                  loading="lazy"
                  sx={{ cursor: "pointer" }}
                />
              </IconButton>
            </>
          )}
        </Box>
      </Box>
      {/* stl or image slider */}
      <Box sx={{ display: "flex" }}>
        <Box
          component={"div"}
          id="imagecontainer"
          sx={{
            width: "85px",
            height: "85px",
            borderRadius: "4px",
            outline:
              currentImage === -1
                ? "3px solid #1E88E5"
                : "3px solid transparent",
            display: "inline-block",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "30px",
          }}
          onClick={() => handleImageClick(stl, -1)}
        >
          <Box
            id="imagebox"
            sx={{
              width: "85px",
              height: "85px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#455A64",
              borderRadius: "4px",
            }}
          >
            <img
              style={{
                height: "30px",
                width: "30px",
              }}
              src={stl}
              alt={"stl"}
            />
            <Box component={"div"} className="preview-text">
              {t("uassist.stlPreview")}
            </Box>
          </Box>
        </Box>
        <Box
          id="slick"
          component={"div"}
          sx={{
            // height: "100px",
            mt: "2px",
            display: "inline-block",
            // alignItems: "center",
            // justifyContent: "center",
            // width: "350px !important",
            marginTop: "27px",
            flexGrow: 1,
          }}
        >
          <Slider className={"sliderBox"} {...settings}>
            {pictureFileList.map((file, index) => (
              <Box
                className={"slick-slider"}
                key={index}
                sx={{ p: "3px", m: 0, width: "100px", height: "100px" }}
              >
                <img
                  style={{
                    objectFit: "contain",
                    borderRadius: "4px",
                    border: "3px solid",
                    borderColor:
                      currentImage === index ? "#1E88E5" : "transparent",
                  }}
                  src={URL.createObjectURL(file.file)}
                  alt={file.name}
                  onClick={() => {
                    handleImageClick(URL.createObjectURL(file.file), index)
                  }}
                />
              </Box>
            ))}
          </Slider>
        </Box>
      </Box>
    </Box>
  )
}
export default RefinePhotoList
