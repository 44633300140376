import { FC, ReactNode, RefObject } from "react"
import {
  Control,
  Controller,
  FieldErrors,
  RegisterOptions,
} from "react-hook-form"
import { useTranslation } from "react-i18next"
import { SxProps } from "@mui/system"

import { UTextField } from "../index"
import { FormControl, FormHelperText, InputLabel } from "../mui.components"
interface IFormInput {
  inputLabel?: string
  placeholder?: string
  helperText?: string | null
  fieldName: string
  rules?: RegisterOptions
  errors: FieldErrors
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: any
  onInputChange?: (value: string, newValue: string) => string
  onBlurChange?: () => void
  onFocusChange?: () => void
  endAdornmentIcon?: ReactNode | null
  fieldType?: "text" | "password"
  formSxprops?: SxProps
  inputRef?: RefObject<HTMLInputElement>
  multiline?: boolean
  rows?: number
  maxRows?: number
  disabled?: boolean
}

const FormInput: FC<IFormInput> = ({
  fieldType,
  fieldName,
  inputLabel,
  placeholder,
  rules,
  errors,
  control,
  onInputChange,
  onBlurChange,
  onFocusChange,
  helperText,
  endAdornmentIcon,
  formSxprops,
  inputRef,
  multiline,
  rows,
  maxRows,
  disabled,
}) => {
  const { t } = useTranslation("common")
  const hasError = !!(errors && errors[fieldName])
  const errorMsg = errors[fieldName]?.message?.toString()

  return (
    <FormControl
      fullWidth
      size={"medium"}
      sx={{ ...formSxprops }}
      error={hasError}
    >
      <Controller
        control={control}
        name={fieldName}
        rules={rules}
        render={({ field: { onChange, name, value } }) => (
          <UTextField
            fieldType={fieldType}
            inputLabel={t(inputLabel)}
            inputRef={inputRef}
            placeholder={(placeholder && t(placeholder)) || ""}
            fieldName={name}
            value={value || ""}
            onInputchange={(newVal) => {
              onInputChange
                ? onChange(onInputChange(value, newVal))
                : onChange(newVal)
            }}
            endAdornmentIcon={endAdornmentIcon}
            onBlurChange={onBlurChange}
            onFocusChange={onFocusChange}
            hasError={hasError}
            multiline={multiline}
            rows={rows}
            maxRows={maxRows}
            disabled={disabled}
          />
        )}
      />
      {helperText && (
        <FormHelperText id={`${fieldName}-helper-text`}>
          {t(helperText)}
        </FormHelperText>
      )}
      {hasError && errorMsg && (
        <FormHelperText id={`${fieldName}-error`}>{t(errorMsg)}</FormHelperText>
      )}
    </FormControl>
  )
}

FormInput.defaultProps = {
  helperText: null,
  endAdornmentIcon: null,
  fieldType: "text",
  formSxprops: {
    my: 2,
  },
  placeholder: "",
}

export default FormInput
