import { createTheme } from "@mui/material"

declare module "@mui/material/styles" {
  interface Theme {
    transBkColor: {
      light: string
    }
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    transBkColor?: {
      light?: string
    }
  }
}

export const lightTheme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#215ECD",
      light: "#80deea",
      dark: "#1E88E5",
    },
    secondary: {
      main: "#f50057",
    },
    background: {
      paper: "white",
    },
    info: {
      main: "#0288D1",
    },
  },
  transBkColor: {
    light: "rgba(255,255,255,0.85)",
  },
  typography: {
    fontFamily: "Poppins",
    fontSize: 16,
    h1: {
      fontSize: 96,
      lineHeight: 1.16,
      fontWeight: 300,
      letterSpacing: "-1.5px",
    },
    h2: {
      fontSize: 60,
      lineHeight: 1.2,
      fontWeight: 300,
      letterSpacing: "-0.5px",
    },
    h3: {
      fontSize: 48,
      fontWeight: 400,
      lineHeight: 1.16,
    },
    h4: {
      fontSize: 34,
      fontWeight: 400,
      lineHeight: 1.23,
      letterSpacing: "0.25px",
    },
    h5: {
      fontSize: 24,
      lineHeight: 1.33,
      fontWeight: 400,
    },
    h6: {
      fontSize: 20,
      fontWeight: 500,
      lineHeight: 1.6,
      letterSpacing: "0.15px",
    },
    subtitle1: {
      fontSize: 16,
      fontWeight: 400,
      lineHeight: 1.75,
      letterSpacing: "0.15px",
    },
    subtitle2: {
      fontSize: 14,
      lineHeight: 1.57,
      fontWeight: 500,
      letterSpacing: "0.1px",
    },
    body1: {
      fontSize: 16,
      fontWeight: 400,
      lineHeight: 1.5,
      letterSpacing: "0.15px",
    },
    body2: {
      fontSize: 14,
      fontWeight: 400,
      lineHeight: 1.43,
      letterSpacing: "0.17px",
    },
    caption: {
      fontSize: 12,
      fontWeight: 400,
      lineHeight: 1.66,
      letterSpacing: "0.4px",
    },
    overline: {
      fontSize: 12,
      lineHeight: 2.66,
      fontWeight: 400,
      letterSpacing: "1px",
      textTransform: "uppercase",
    },
    button: {
      fontSize: 14,
      fontWeight: 500,
      lineHeight: 1.5,
    },
  },
})
export const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#b2ebf2",
      light: "#fff",
      dark: "#1E88E5",
    },
    secondary: {
      main: "#f50057",
    },
    background: {
      default: "#616161",
      paper: "#757575",
    },
  },
  transBkColor: {
    light: "rgba(128,128,128,0.85)",
  },
})
