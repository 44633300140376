import { FC, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import ExternalLink from "../../../assets/images/clinical/ExternalLink.svg"
import { useAppDispatch } from "../../../core/app/hooks"
import {
  fetchIfuDoc,
  fetchReleaseNotes,
} from "../../../core/app/slices/user/userApis"
import { UText } from "../../../ui-component"
import { Box, Divider, Link } from "../../../ui-component/mui.components"
import { convertBlobToPdfUrl } from "../../Patient/PatientList/util/commonUtil"

const ReleaseNotes: FC = () => {
  const { t } = useTranslation("common")
  const [releaseNotes, setReleaseNotes] = useState([])
  const dispatch = useAppDispatch()

  useEffect(() => {
    const notes = []
    for (let i = 0; i < 1; i++) {
      notes.push({
        date: t(`releaseNotes.releaseNotes.release${i}.date`),
        version: t(`releaseNotes.releaseNotes.release${i}.version`),
      })
    }
    setReleaseNotes(notes)
  }, [])

  const downLoadIFU = () => {
    dispatch(fetchIfuDoc()).then((res: any) => {
      const link = document.createElement("a")
      link.href = convertBlobToPdfUrl(res.payload)
      // Open the downloaded PDF in a new tab
      window.open(link.href, "_blank")
    })
  }

  const getReleaseNotes = () => {
    dispatch(fetchReleaseNotes()).then((res: any) => {
      const link = document.createElement("a")
      link.href = convertBlobToPdfUrl(res.payload)
      // Open the downloaded PDF in a new tab
      window.open(link.href, "_blank")
    })
  }

  return (
    <Box
      data-testid={"releaseNotes"}
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          paddingY: 2,
        }}
      >
        <UText variant={"h4"}>{t("releaseNotes.title")}</UText>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          boxShadow: 1,
          padding: "40px 80px",
          width: "79.6%",
          alignSelf: "center",
          backgroundColor: "background.default",
          borderRadius: 2,
          gap: 0.5,
          marginBottom: 6,
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: 4,
            marginBottom: "28px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              flexBasis: "50%",
            }}
          >
            <UText
              sxProp={{
                marginTop: "20px",
              }}
              color={"text.primary"}
              variant={"h5"}
            >
              {t("releaseNotes.recommendedDisplay.title")}
            </UText>
            <UText
              sxProp={{
                color: "#000",
              }}
              variant={"body1"}
            >
              {t("releaseNotes.recommendedDisplay.content")}
            </UText>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              flexBasis: "50%",
            }}
          >
            <UText
              sxProp={{
                marginTop: "20px",
              }}
              color={"text.primary"}
              variant={"h5"}
            >
              {t("releaseNotes.instructionsForUse.title")}
            </UText>
            <Box
              sx={{
                display: "flex",
                gap: 1,
              }}
            >
              <Link  href="https://www.ulabsystems.com/ifu/" target="_blank" rel="noopener noreferrer" sx={{
                display: "flex",
                gap: 1,
              }}>
                <UText
                  variant={"body1"}
                  color={"primary.main"}
                  sxProp={{
                    textDecoration: "underline",
                    display: "flex",
                    fontWeight: 500,
                  }}
                >
                  {t("releaseNotes.instructionsForUse.link")}
                </UText>
                <Box
                  component={"img"}
                  sx={{
                    color: "text.secondary",
                  }}
                  src={ExternalLink}
                  alt={"External Link"}
                />
              </Link>
            </Box>
          </Box>
        </Box>
        <Divider />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 0.5,
          }}
        >
          <UText
            sxProp={{
              display: "flex",
              marginTop: "20px",
            }}
            color={"text.primary"}
            variant={"h5"}
          >
            {t("releaseNotes.releaseNotes.title")}
          </UText>
          {releaseNotes?.map((note, index) => {
            return (
              <Box
                key={"releaseNotes-" + index}
                sx={{
                  display: "flex",
                  gap: 3,
                  paddingY: 1,
                }}
              >
                <UText
                  sxProp={{
                    flexBasis: "20.2%",
                  }}
                  variant={"body1"}
                >
                  {note.date}
                </UText>
                <Link
                  onClick={getReleaseNotes}
                  sx={{
                    cursor: "pointer",
                    flexBasis: "79.8%",
                    color: "primary.main",
                    textDecoration: "underline",
                    fontWeight: 500,
                  }}
                >
                  {/* <UText
                    sxProp={{
                      flexBasis: "79.8%",
                      color: "primary.main",
                      textDecoration: "underline",
                      fontWeight: 500,
                    }}
                    variant={"body1"}
                  > */}
                  {note.version}
                  {/* </UText> */}
                </Link>
              </Box>
            )
          })}
        </Box>
      </Box>
    </Box>
  )
}

export default ReleaseNotes
