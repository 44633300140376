import http from "../../../global/http"
import Case from "../../../model/Case"
import { ICase } from "../../../model/interface/ICase"

export const updateCaseStatusById = async (
  orgId: string,
  patientId: string,
  caseId: string,
  payload: any,
) => {
  try {
    const { data } = await http.patch(
      `/shared/v1/org/${orgId}/orchestration/patients/${patientId}/txplans/${caseId}/`,
      payload,
    )
    return data
  } catch (err) {
    return err
  }
}

export const unarchiveCaseStatus = async (
  orgId: string,
  patientId: string,
  caseId: string,
) => {
  try {
    const { data } = await http.patch(
      `/patient-mgmt/v2/org/${orgId}/patients/${patientId}/txplans/${caseId}/unarchive`,
    )
    return data
  } catch (err) {
    return err
  }
}

export const getCaseByCaseId = async (
  orgId: string,
  patientId: string,
  caseId: string,
) => {
  try {
    let caseObj: ICase = {} as ICase
    const { data } = await http.get(
      `/patient-mgmt/v2/org/${orgId}/patients/${patientId}/txplans/${caseId}`,
    )
    if (data && data.response_code === 200 && data.result) {
      caseObj = new Case().Deserialize(data.result)
    }
    console.log("getCaseInformation", caseObj)
    return caseObj
  } catch (err) {
    return err
  }
}

export const getCaseByPatientId = async (orgId: string, patientId: string, isCaseArchived?: boolean) => {
  try {
    const cases: ICase[] = []
    const archiveQuery = isCaseArchived ? "?list_for=patient_overview" : ""
    const { data } = await http.get(
      `/patient-mgmt/v2/org/${orgId}/patients/${patientId}/txplans${archiveQuery}`,
    )
    if (
      data &&
      data.response_code === 200 &&
      data.result &&
      data.result.length > 0
    ) {
      data.result.forEach((r) => {
        cases.push(new Case().Deserialize(r))
      })
    }

    return cases
  } catch (err) {
    return err
  }
}

export const updateCaseByCaseId = async (
  orgId: string,
  patientId: string,
  caseId: string,
  payload: any,
) => {
  try {
    const { data } = await http.put(
      `/patient-mgmt/v2/org/${orgId}/patients/${patientId}/txplans/${caseId}`,
      payload,
    )
    let caseObj: ICase = {} as ICase
    if (data && data.response_code === 200 && data.result) {
      caseObj = new Case().Deserialize(data.result)
    }
    return caseObj
  } catch (err) {
    return err
  }
}

export const downloadCaseZip = async (
  orgId: string,
  patientId: string | number,
  caseId: string | number,
) => {
  const url = `/patient-mgmt/v2/org/${orgId}/patients/${patientId}/txplans/${caseId}/zip/case.zip`
  try {
    const res = await http.get(url, {
      responseType: "blob",
    })
    return res
  } catch (err) {
    return err
  }
}
