import { Children, useEffect, useRef, useState } from "react"
import CloseIcon from "@mui/icons-material/CloseOutlined"
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardProps,
  IconButton,
  Slide,
} from "@mui/material"

import { useAppDispatch, useAppSelector } from "@/core/app/hooks"
import { RootState } from "@/core/app/store"
import { caseManagement, wasmModule } from "@/gluelayer"
import BoltonPanel from "@/modules/Clinical/components/BoltonPanel/boltonPanel"
import Movement from "@/modules/Clinical/components/Movement/movement"
import Report from "@/modules/Clinical/components/Report/report"
import Message from "@/modules/Clinical/components/SideBar/message"
import CaseNote from "@/modules/Clinical/components/CaseNote/caseNote"

import { UploadSTL } from "../components/UploadSTL"
import { UploadSTLV2 } from "../components/UploadSTLV2"
import { LRPhotos } from "../LocalRecord/LRPhotos"
import { LRStls } from "../LocalRecord/LRStls"
import { UDTMTable } from "../TMTable/UDTMTable"
import { UDMessage } from "../UDMessage/UDMessage"
import { UDuAssist } from "../UDMessage/UDuAssit"
import { UDReport } from "../UDReport/UDReport"
import { setCurLeftPage, setReportData } from "../udTreatSlice"

import { LeftPage } from "./LeftPage"
import { UDBolton } from "./UDBolton"
import { UDCreateCase } from "./UDCreateCase"
import { UDDentalChart } from "./UDDentalChart"
import { UDPhotos } from "./UDPhotos"
import { ScreeshotReport } from "@/modules/Clinical/components/ReportPDF/screenshotReport"

export function LeftPages(props: any) {
  const {
    isEdit,
    isSubmitModification,
    setisSubmitModification,
    showPrompt,
    confirmNavigation,
    cancelNavigation,
    updateCaseFun,
    setisSubmitReply,
    isSubmitReply,
  } = props
  const { curLeftPage, reportData, stageObjBySlice } = useAppSelector(
    (state: RootState) => state.udTreatService,
  )
  const dispatch = useAppDispatch()
  const curPage = useRef("")
  useEffect(() => {}, [curLeftPage])
  const [stageObj, setStageObj] = useState({
    upList: [],
    downList: [],
    up_current: 0,
    down_current: 0,
  })
  const onClosePage = () => {
    dispatch(setCurLeftPage(""))
  }
  // Report show or hidden
  const [reportShow, setReportShow] = useState<boolean>(false)
  const [openMovement, setopenMovement] = useState(false)
  const [refinementKey, setRefinementKey] = useState<string>("")
  const [boltonPanelShow, setBoltonPanelShow] = useState<boolean>(false)
  const reportPdfRef = useRef(null)
  const openOrUploadReportPdf = (status: "open" | "upload") => {
    reportPdfRef?.current.uploadReportPDF(status)
  }
  const [boltonData, setBoltonData] = useState(null)
  const getBoltonFunc = () => {
    const bolton = caseManagement.getBoltonReport() || null
    setBoltonData(bolton)
  }
  useEffect(() => {
    if (!wasmModule.isInit) return
    setReportShow(curLeftPage == "report")
    setopenMovement(curLeftPage === "tmtable")
    if (curLeftPage === "bolton") {
      getBoltonFunc()
      setBoltonPanelShow(true)
    }
    curPage.current = curLeftPage
    if (curLeftPage !== "report") return
    const { attachment, toothlist, ipr, firstStage } =
      caseManagement.getAttachmentIPRReport()
    const { lowlist, uplist } = toothlist
    const list = lowlist.concat(uplist)
    dispatch(
      setReportData({
        attachment: attachment,
        toothlist: list,
        iprData: ipr,
      }),
    )
  }, [curLeftPage])
  function getPage(pageName: string) {
    switch (pageName) {
      case "createcase":
        // dispatch(setCaseOpenStatus("emptycase"))
        return <UploadSTL onClose={onClosePage} />
        break
      case "createcasev2":
        // dispatch(setCaseOpenStatus("emptycase"))
        return <UploadSTLV2 onClose={onClosePage} />
        break
      case "records":
        return <UDPhotos onClose={onClosePage} />
        break
      case "dentalchart":
        return <UDDentalChart onClose={onClosePage} />
        break
      case "uassist":
        return (
          <Message
            isEdit={isEdit}
            isSubmitModification={isSubmitModification}
            setisSubmitModification={setisSubmitModification}
            showPrompt={showPrompt}
            confirmNavigation={confirmNavigation}
            cancelNavigation={cancelNavigation}
            updateCaseFun={updateCaseFun}
            setisSubmitReply={setisSubmitReply}
            isSubmitReply={isSubmitReply}
            onClosePage={onClosePage}
          />
        )

      case "report":
        return (
          // <UDReport
          //   onClose={onClosePage}
          //   reportShow={reportShow}
          //   toothlist={reportData.toothlist}
          //   attachment={reportData.attachment}
          //   setReportShow={setReportShow}
          //   iprData={reportData.iprData}
          //   openReportFunc={openOrUploadReportPdf}
          // />
          <>
            <Report
              reportShow={reportShow}
              toothlist={reportData.toothlist}
              attachment={reportData.attachment}
              setReportShow={() => {
                setReportShow(false)
                onClosePage()
              }}
              iprData={reportData.iprData}
              openReportFunc={openOrUploadReportPdf}
              preparation={false}
            />
            <ScreeshotReport
              isReady={true}
              attachment={reportData.attachment}
              toothlist={reportData.toothlist}
              iprData={reportData.iprData}
              ref={reportPdfRef}
            />
          </>
        )
        break
      case "tmtable":
        return (
          <Movement
            open={openMovement}
            setOpen={() => {
              setopenMovement(false)
              onClosePage()
            }}
            refinementKey={refinementKey}
            stageObj={stageObjBySlice}
          ></Movement>
        )
        break
      case "bolton":
        return (
          <BoltonPanel
            data={boltonData}
            setBoltonPanelShow={() => {
              setBoltonPanelShow(false)
              onClosePage()
            }}
            boltonPanelShow={boltonPanelShow}
            closeToothSize={() => {
              // TODO
            }}
          />
        )
        break
      case "lrphotos":
        return <LRPhotos onClose={onClosePage} />
        break
      case "lrstls":
        return <LRStls onClose={onClosePage} />
        break
      case "notes":
        return <CaseNote onClosePage={onClosePage} />
        break
    }
    return <></>
  }

  return curLeftPage && getPage(curLeftPage)
}
