import { FC, useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { debounce } from "lodash"

import { UButton, UText } from "../../../components"
import { Grid } from "../../../components/mui.components"
import { useAppDispatch, useAppSelector } from "../../../core/app/hooks"
import { resetCasedata } from "../../../core/app/slices/case/caseSlice"
import {
  getCaseFilesByCaseId,
  updateCaseStatus,
} from "../../../core/app/slices/case/caseThunkApi"
import { setRouteFrom } from "../../../core/app/slices/ConfigSlice"
import {
  addNewPatient,
  fetchPatientList,
} from "../../../core/app/slices/patients"
import { PatientQueryparams } from "../../../core/app/slices/patients/patient.types"
import { setData } from "../../../core/app/slices/patients/patientSlice"
import { updateCurrentOrgId } from "../../../core/app/slices/user/userSlice"
import { RootState } from "../../../core/app/store"
import { IPatientList } from "../../../core/model/interface/IPatient"
import { UI_PERMISSION } from "../../../core/utils/access/permissionConstants"
import { useMyNavigation } from "../../../hooks/useMyNavigation"
import usePermissions from "../../../hooks/userPermission"
// UFlowChange
import { UFlowPatientList } from "../../../wasm3d/CommonPages/UFlowPatientList"
import { CASE_STATUS } from "../config/status.config"
import CaseActionPopup from "../Overview/components/CaseActionPopup"
import { navigateTo } from "../utils"

import { IChips } from "./components/UTable/table"
import UTable from "./components/UTable/UTable"
import {
  defaultQuryParams,
  QUERY_PARAMS_STORAGE_KEY,
  tableData,
} from "./patientTable/table.config"
import { downloadZipFile } from "./util/commonUtil"
import { resetProfilePhoto } from "@/core/app/slices/records/xrays/xraysSlice"
import { resetPrescriptionSchema, resetSchema } from "@/core/app/slices/uassist/formSlice"

const PatientList: FC = () => {
  const { hasAny } = usePermissions()
  let queryParams: any = localStorage.getItem(QUERY_PARAMS_STORAGE_KEY)
  if (queryParams) {
    queryParams = JSON.parse(queryParams)
  }
  const [tableQueryParams, setTableQueryParams] = useState<PatientQueryparams>(
    queryParams || defaultQuryParams,
  )
  const [filterType, setFilterType] = useState<"" | "filter">("")
  const [patientId, setPatientId] = useState<string>("")
  const [caseId, setCaseId] = useState<string>("")
  const [isAlertOpen, setIsAlertOpen] = useState<
    | "OPEN_CASE_ARCHIVE_MODEL"
    | "OPEN_UNARCHIVE_PATIENT_MODEL"
    | "OPEN_CANCEL_MODEL"
    | "UPDATE_STATUS_DELIVERED_TO_IN_PROGRESS"
    | "UPDATE_STATUS_IN_PROGRESS_TO_COMPLETE"
    | "OPEN_ARCHIVE_PATIENT_MODEL"
    | "OPEN_WELCOME_MODAL"
    | "addNewCase"
    | "UNLOCK_CASE"
    | ""
  >("")
  const dispatch = useAppDispatch()
  const { orgId } = useParams()
  const { patients, loading, paging } = useAppSelector(
    (state: RootState) => state.patientService,
  )

  const { t } = useTranslation("common")
  const navigate = useMyNavigation()

  const { weSmileUI } = useAppSelector(
    (state: RootState) => state.wasm3DServiceSlice,
  )

  const { preferences } = useAppSelector(
    (state: RootState) => state.userService.user,
  )

  const onRowClick = (row) => {
    navigate(`/overview/patient/${row.patientId}`)
  }

  // useEffect(() => {
  //   const canvasElement = document.createElement("canvas")
  //   dispatch(initializeWASM({canvas:canvasElement}));
  // }, [])

  useEffect(() => {
    dispatch(resetCasedata())
    dispatch(resetProfilePhoto())
    dispatch(setRouteFrom(""))
    localStorage.removeItem("routeFrom")
    if (!orgId) return
    dispatch(updateCurrentOrgId(orgId))
  }, [dispatch, orgId])

  useEffect(() => {
    if (tableQueryParams) {
      const isFilterSelected = checkIfFilterSelected(tableQueryParams.fields)
      setFilterType(isFilterSelected ? "filter" : "")
      dispatch(
        fetchPatientList({
          patientQueryparams: {
            ...tableQueryParams,
          },
          filterType: filterType,
        }),
      )
    }
  }, [tableQueryParams])

  const checkIfFilterSelected = (fields) => {
    if (fields) {
      return Object.keys(fields).some((field) => {
        if (
          typeof fields[field] === "string" ||
          typeof fields[field] === "object"
        ) {
          return !!fields[field].length
        } else if (
          typeof fields[field] === "number" ||
          typeof fields[field] === "undefined"
        ) {
          return !!fields[field]
        }
      })
    }
  }

  const onFilterUpdate = (fields) => {
    setTableQueryParams((previousParams) => ({
      ...previousParams,
      per_page: defaultQuryParams.per_page,
      page_no: defaultQuryParams.page_no,
      fields: {
        ...tableQueryParams.fields,
        ...fields,
      },
    }))
  }

  const refreshTable = () => {
    const isFilterSelected = checkIfFilterSelected(tableQueryParams.fields)
    setFilterType(isFilterSelected ? "filter" : "")
    dispatch(
      fetchPatientList({
        patientQueryparams: {
          ...tableQueryParams,
        },
        filterType: filterType,
      }),
    )
  }

  const throttleOnSearch = useCallback(
    debounce((value) => {
      setTableQueryParams((prevVal) => ({
        ...prevVal,
        per_page: defaultQuryParams.per_page,
        page_no: defaultQuryParams.page_no,
        fields: {
          ...prevVal.fields,
          search_text: value,
        },
      }))
    }, 500),
    [],
  )

  const onDeleteChip = (chip: IChips) => {
    if (chip.type === "assigned_to") {
      const assigned_to = tableQueryParams.fields.assigned_to.filter(
        (element) => element !== chip.deleteBy,
      )

      setTableQueryParams({
        ...tableQueryParams,
        page_no: defaultQuryParams.page_no,
        fields: {
          ...tableQueryParams.fields,
          assigned_to,
        },
      })
    }
    if (chip.type === "status") {
      const status = tableQueryParams.fields.status.filter(
        (element) => element !== chip.deleteBy,
      )
      setTableQueryParams({
        ...tableQueryParams,
        page_no: defaultQuryParams.page_no,
        fields: {
          ...tableQueryParams.fields,
          status,
        },
      })
    }
    if (chip.type === "statusUpdated") {
      setTableQueryParams({
        ...tableQueryParams,
        page_no: defaultQuryParams.page_no,
        fields: {
          ...tableQueryParams.fields,
          status_updated: "",
        },
      })
    }
    if (chip.type === "show_archived_patients") {
      setTableQueryParams({
        ...tableQueryParams,
        page_no: defaultQuryParams.page_no,
        fields: {
          ...tableQueryParams.fields,
          show_archived_patients: 0,
        },
      })
    }
  }

  const checkforActiveCase = (row: IPatientList) => {
    const payload = {
      firstname: row.firstName,
      lastname: row.lastName,
      dob: row.dob,
      patient_id: row.patientId,
      assigned_to: row.assignedTo || null,
    }
    dispatch(addNewPatient(payload)).then((res) => {
      if (res.payload?.data?.status === "Success") {
        dispatch(setData({ newPatientAPI: "idle" }))
        navigate(`/overview/patient/${row.patientId}`)
      } else {
        setIsAlertOpen("addNewCase")
      }
    })
  }

  const downloadCaseFiles = (patientId: number, caseId: number) => {
    dispatch(
      getCaseFilesByCaseId({ patientId: patientId, caseId: caseId }),
    ).then((res) => {
      downloadZipFile(res.payload.data, caseId)
    })
  }


  const executeAction = (action: string, row: IPatientList) => {
    dispatch(setRouteFrom("PATIENT_LIST_MODULE"))
    switch (action) {
      case "OPEN_RECORDS":
        navigateTo("RECORDS", navigate, row.patientId, row.caseId)
        break
      case "OPEN_PRESCRIPTION_FORM":
        dispatch(resetPrescriptionSchema())
        dispatch(resetSchema())
        navigateTo("PRESCRIPTION", navigate, row.patientId, row.caseId, row.udesign_json)
        break
      case "OPEN_SUMMARY":
        navigateTo("SUMMARY", navigate, row.patientId, row.caseId)
        break
      case "OPEN_RETAINER_FORM":
        navigateTo("RETAINER", navigate, row.patientId, row.caseId)
        break
      case "START_REFINEMENT":
        navigateTo("REFINEMENT", navigate, row.patientId, row.caseId)
        break
      case "OPEN_TREAT_PAGE":
        navigateTo("TREAT", navigate, row.patientId, row.caseId)
        break
      case "OPEN_UNARCHIVE_PATIENT_MODEL":
        setPatientId(row.patientId + "")
        setIsAlertOpen(action)
        break
      case "ADD_NEW_CASE_MODEL":
        dispatch(setRouteFrom(""))
        checkforActiveCase(row)
        break
      case "OPEN_ARCHIVE_PATIENT_MODEL":
      case "OPEN_CASE_ARCHIVE_MODEL":
      case "OPEN_CANCEL_MODEL":
      case "OPEN_CASE_UNARCHIVE_MODEL":
        setPatientId(row.patientId + "")
        setCaseId(row.caseId + "")
        setIsAlertOpen(action)
        break
      case "DOWNLOAD_FILES":
        downloadCaseFiles(row.patientId, row.caseId)
        break
      case "UNLOCK_CASE":
        setPatientId(row.patientId + "")
        setCaseId(row.caseId + "")
        setIsAlertOpen(action)
        break
      case "UPDATE_STATUS_DELIVERED_TO_IN_PROGRESS":
        dispatch(
          updateCaseStatus({
            patientId: row.patientId.toString(),
            caseId: row.caseId.toString(),
            payload: {
              case_disposition: CASE_STATUS.TREATMENT_IN_PROGRESS,
            },
          }),
        ).then((res) => {
          if (res && res.payload && res.payload.response_code === 200) {
            onFilterUpdate({ ...tableQueryParams.fields })
          }
        })
        break
      case "UPDATE_STATUS_IN_PROGRESS_TO_COMPLETE":
        dispatch(
          updateCaseStatus({
            patientId: row.patientId.toString(),
            caseId: row.caseId.toString(),
            payload: {
              case_disposition: CASE_STATUS.TREATMENT_COMPLETE,
            },
          }),
        ).then((res) => {
          if (res && res.payload && res.payload.response_code === 200) {
            onFilterUpdate({ ...tableQueryParams.fields })
          }
        })
        break
      default:
        console.log(action, "..action")
    }
  }

  const onSearch = (value: string) => {
    throttleOnSearch(value)
  }
  const handleChangePage = (newpage: number) => {
    setTableQueryParams({
      ...tableQueryParams,
      page_no: newpage,
    })
  }

  const onClearAllFilter = () => {
    setTableQueryParams({
      ...defaultQuryParams,
      fields: {
        search_text: "",
      },
    })
  }

  const handleRowsPerPageChange = (newPageSize: number) => {
    setTableQueryParams({
      ...tableQueryParams,
      per_page: newPageSize,
      page_no: 1,
    })
  }

  const sortHandler = (value: string) => {
    setTableQueryParams({
      ...tableQueryParams,
      per_page: defaultQuryParams.per_page,
      page_no: defaultQuryParams.page_no,
      sort_field: value,
      sort_type:
        tableQueryParams.sort_field === value &&
        tableQueryParams.sort_type === "asc"
          ? "desc"
          : "asc",
    })
  }

  useEffect(() => {
    if (preferences && !preferences?.on_show_welcome_modal) {
      setIsAlertOpen("OPEN_WELCOME_MODAL")
    }
  }, [preferences])

  return (
    <>
      <Grid container item display={"flex"} sx={{ mt: 3 }} py={"16px"}>
        <Grid item xs={6}>
          <UText variant={"h4"}>{t("patientList.title")}</UText>
        </Grid>
        {hasAny(UI_PERMISSION.UI_PATIENTS_CREATE) && (
          <Grid item xs={6} display={"flex"} justifyContent={"end"}>
            <UButton
              variant={"contained"}
              btnType={"button"}
              btnText={t("records.button.addNewPatient")}
              onClickHandler={() => {
                navigate("/patients/new")
              }}
            />
          </Grid>
        )}
      </Grid>
      <UFlowPatientList />
      <Grid item container justifyContent={"center"}>
        {hasAny(UI_PERMISSION.UI_PATIENTS_VIEW) && tableQueryParams && (
          <UTable
            filter={tableQueryParams}
            columnData={patients}
            columnDef={tableData.columnDef}
            name={"Patient List"}
            onRowClick={onRowClick}
            onFilterUpdate={onFilterUpdate}
            isFetching={loading}
            paging={paging}
            enableSearch={true}
            onSearch={onSearch}
            onClearAllFilter={onClearAllFilter}
            onDeleteChip={onDeleteChip}
            search={tableQueryParams?.fields?.search_text || ""}
            filterType={filterType}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleRowsPerPageChange}
            sorting={{
              key: tableQueryParams.sort_field,
              direction: tableQueryParams.sort_type,
            }}
            sortHandler={sortHandler}
            onMenuBtnClick={executeAction}
            sxTableProps={{
              body: {
                "&.MuiTableRow-root:hover": {
                  backgroundColor: "#2196F30A",
                },
              },
            }}
          ></UTable>
        )}
      </Grid>
      <CaseActionPopup
        isReloadCallBack={refreshTable}
        isAlertOpen={isAlertOpen}
        setIsAlertOpen={setIsAlertOpen}
        rowPatientId={patientId}
        caseId={caseId}
      />
    </>
  )
}

export default PatientList
