export const UI_PERMISSION = {
  UI_PATIENTS_VIEW: "ui.patients.view",
  UI_PATIENTS_CREATE: "ui.patients.create",
  UI_PATIENTS_UPDATE: "ui.patients.update",
  UI_TXPLANS_CREATE: "ui.txplans.create",
  UI_TXPLANS_ARCHIVE: "ui.txplans.archive",
  UI_TXPLANS_UNARCHIVE: "ui.txplans.unarchive",
  UI_TXPLANS_UPDATESTATUS: "ui.txplans.updatestatus",
  UI_TXPLANS_DOWNLOAD: "ui.txplans.download",
  UI_TXPLANS_CLINICALVIEW: "ui.txplans.clinicalview",
  UI_TXPLANS_CLINICALUPDATE: "ui.txplans.clinicalupdate",
  UI_ORDERS_CREATEUASSIST: "ui.orders.createuassist",
  UI_ORDERS_CREATE: "ui.orders.create",
  UI_ORDERS_VIEW: "ui.orders.view",
  UI_BILLING_VIEW: "ui.billing.view",
  UI_BILLING_DOWNLOAD: "ui.billing.download",
  UI_BILLING_PRICINGLIST: "ui.billing.pricinglist",
  UI_PROFILE_VIEW: "ui.profile.view",
  UI_PROFILE_UPDATE: "ui.profile.update",
  UI_PROFILE_UPDATEPASSWORD: "ui.profile.updatepassword",
  UI_PROFILE_VIEWPAYMENT: "ui.profile.viewpayment",
  UI_PROFILE_UPDATEPAYMENT: "ui.profile.updatepayment",
  UI_CLINICALSETTING_VIEW: "ui.clinicalsetting.view",
  UI_CLINICALSETTING_UPDATE: "ui.clinicalsetting.update",
  UI_SMARTRXTEMPLATE_VIEW: "ui.smartrxtemplate.view",
  UI_SMARTRXTEMPLATE_UPDATE: "ui.smartrxtemplate.update",
  UI_ACCOUNT_VIEW: "ui.account.view",
  UI_ACCOUNT_SHIPPINGADDRESS_VIEW: "ui.account.shippingaddresses.view",
  UI_ACCOUNT_SHIPPINGADDRESS_CREATE: "ui.account.shippingaddresses.create",
  UI_ACCOUNT_SHIPPINGADDRESS_UPDATE: "ui.account.shippingaddresses.update",
  UI_ACCOUNT_SHIPPINGADDRESS_DELETE: "ui.account.shippingaddresses.delete",
  UI_CUSTOMBOX_VIEW: "ui.custombox.view",
  UI_CUSTOMBOX_CREATE: "ui.custombox.create",
  UI_UDESIGNAPP_DOWNLOAD: "ui.udesignapp.download",
  UI_UNLOCK_CASE: "ui.txplans.unlock",

  UI_PATIENTS_UNARCHIVE: "ui.patients.unarchive",
  UI_PATIENTS_ARCHIVE: "ui.patients.archive",
  GLOBAL_VIEWALLORGS: "global.viewallorgs",
}
