import { FC, useEffect, useState } from "react"
import { PhotosAndXRays } from "@/UDTreat/left/PhotosAndXRaysForCreatingProcess"

const Photograph: FC<{
  setPhotographErrors: (value: boolean) => void
  setIsDragEmpty: (value: boolean) => void
  isAlertOpen: boolean
  setIsAlertOpen: (value: boolean) => void
  setIsDragOpen: (Value: boolean) => void
  isDragOpen: boolean
  title: string
  description?: string
  isRefinement?: boolean
  isShowRecords?: boolean
  postMessageUpdate?: () => void
}> = ({
  setPhotographErrors,
}) => {

  useEffect(() => {
    return () => {
      setPhotographErrors(false)
    }
  }, [])
  return (
    <PhotosAndXRays
      isZoomoutAbled={true}
      isDragAbled={true}
      isDeleteAbled={true}
    />
  )
}

export default Photograph
