import { useEffect, useState } from "react"
import { Box, Button, Stack, ToggleButton } from "@mui/material"
import { biteRampModule, wasmModule } from "@/gluelayer"

const BiteRampButtons = () => {
  // button overlay
  const [openBiteOverlay, setOpenBiteOverlay] = useState<boolean>(false)
  // all plane position and isShow
  const [planeObj, setPlaneObj] = useState<{
    x: number
    y: number
    isShow: boolean
  }>({ x: 0, y: 0, isShow: false })

  useEffect(() => {
    if (!wasmModule.isInit) return

    biteRampModule.onButtonPosChangeCallback(
      (x: number, y: number, isShow: boolean) => {
        setPlaneObj({ x, y, isShow })
      },
    )
    if (planeObj.isShow) {
      setOpenBiteOverlay(true)
    }
  }, [wasmModule.isInit])
  return (
    <Box
      id="biteBox"
      sx={{
        position: "absolute",
        display: planeObj.isShow ? "block" : "none",
        left: planeObj.x,
        bottom: planeObj.y,
      }}
    >
      <Button
        color="inherit"
        variant="contained"
        size="small"
        onClick={() => {
          biteRampModule.syncBiteRampHeightToPlane()
        }}
      >
        Sync
      </Button>
    </Box>
  )
}
export default BiteRampButtons
