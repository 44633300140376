import { Box, Stack, IconButton, Grid, Input, Typography, Button, Paper, experimentalStyled as styled, Hidden } from "@mui/material";
import { FC, useEffect, useState, useRef, useImperativeHandle } from "react";
import React from "react";
import { 
  stagingManager,
  stageControl,
  EBiteJumpLocationType,
 } from "@/gluelayer"

import TriangleIcon from "./triangleIcon";
import StageAddIcon from "./stageAddIcon";
import { visibility } from "html2canvas/dist/types/css/property-descriptors/visibility";
import ReactDOM from 'react-dom';
import StageItem from "./stageItem";
import StageFoldItem from "./stageFoldItem";
import StageGmButton from "./stageGmButton";
import DialogBox from "@/modules/Clinical/components/DialogBox/dialogBox"
import { UButton } from "@/components/index"
import { isNodeContains } from "./stageCommon";

const ButtonItem = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(0),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const StageArch = (props) => {

  // const [stageCurrentStep, setStageCurrentStep] = useState(props.currentStep);
  // const [stageCurrentNodeType, setStageCurrentNodeType] = useState([]);
  const [stageList, setStageList] = useState([]);
  // const [isCurrentStepChangedByClick, setIsCurrentStepChangedByClick] = useState(false);
  //const [isGmClicked, setIsGmClicked] = useState(false);
  //const [gmPercent, setGmPercent] = useState(0.0);
  const stepRef = useRef({})
  const singleStageWidth = 23
  const StageDiv = 4
  const singleStageHeight = 27
  // const [actualStageWidth, setActualStageWidth] = useState<number>(singleStageWidth)
  const [gmUiLocation, setGmUiLocation] = useState("end")//start end
  const [showDeleteNodeWarning, setShowDeleteNodeWarning] = useState(false);
  const [showDeletePassiveAlignerNodeWarning, setShowDeletePassiveAlignerNodeWarning] = useState(false);


  useImperativeHandle(props.onRef, () => ({
    getStagePositionX,
    getStageStepByPositionX,
    setCurrentStep,
    // calcMaxArchWidth,
  }))

  const callBackStepRef = (e: HTMLElement, stepIndex: number) => {
    stepRef.current["step_" + stepIndex] = e
  }

  const setCurrentStep = (step: number) => {
    // setStageCurrentStep(step)

  }

  const changeCurrentStep = (iStep) => {
    //if(isCurrentStepChangedByClick) {
    // props.stepChangeHandler(props.archType, iStep)
    //}
    //setStageCurrentStep(iStep);
  }

  const stageButtonClickHandle = (item, index, itemArray) => {
    //getStagePosition(index)
    // setIsCurrentStepChangedByClick(true)
    // console.info(
    //   "=====isCurrentStepChangedByClick: ",
    //   isCurrentStepChangedByClick,
    //   props.archName,
    // )
    // changeCurrentStep(index)
    props.stepChangeHandler(props.archType, index)
  }

  const getStagePositionX = (stepIndex: number) => {
    //var rect = element.getBoundingClientRect();
    //return { x: rect.left, y: rect.top };
    const e = stepRef.current["step_" + stepIndex]
    if (e === undefined || e === null) {
      return 0
    }
    const rect = e.getBoundingClientRect()
    const middleX = rect.left + (rect.width / 2.0)

    const parentE = e.offsetParent
    let parentELeft = 0
    if (parentE !== undefined && parentE !== null) {
      const parentRect = parentE.getBoundingClientRect()
      parentELeft = parentRect.left
    }

    //console.info("=====getStagePositionX: ", middleX);
    return middleX - parentELeft
  }

  const getStageStepByPositionX = (positionX: number) => {
    for (let i = 0; i < props.nodes.length; i++) {
      const xMiddle = getStagePositionX(i)
      if (
        xMiddle - (singleStageWidth + StageDiv) / 2 <= positionX &&
        positionX <= xMiddle + (singleStageWidth + StageDiv) / 2
      ) {
        return i
      }
    }
    return -1
  }

  const addNodeHandler = () => {
    console.info(
      props.archName + " arch addNodeHandler, index:" + props.currentStep,
    )

    if(props.uiShowType == "passive_aligner")
    {
      if (!isNodeContains(stageList[props.currentStep].nodeTypes, 14)) {
        console.info(
          "=====add passive_aligner node: ",
          props.archName,
          props.currentStep,
          props.nodes.length - 1,
        )
        stagingManager.addPassiveNode(
          props.archType === 0,
          props.currentStep,
        )
      } else {
        // console.info("=====delete passive_aligner node: ", props.archName, props.currentStep)
        // stagingManager.deletePassiveNode(props.archType === 0, props.currentStep)
        setShowDeletePassiveAlignerNodeWarning(true)
      }
    }
    else
    {
      if (!isNodeContains(stageList[props.currentStep].nodeTypes, 0)) {
        console.info(
          "=====add node: ",
          props.archName,
          props.currentStep,
          props.nodes.length - 1,
        )
        stagingManager.addNormalNode(
          props.archType === 0,
          props.currentStep,
          props.currentStep === props.nodes.length - 1,
        )
      } else {
        //promp message box warning
        // const messageBox = React.createElement(DialogBox, {});
        // ReactDOM.render(messageBox, document.getElementById('treat_root_box'));
        // return
        setShowDeleteNodeWarning(true)

        // console.info("=====delete node: ", props.archName, props.currentStep)
        // stagingManager.deleteKeyNode(props.archType === 0, props.currentStep)
      }
    }
  }

  const judgeWhichItem = (item, index, itemArray) => {
    if (props.countOfFoldGroup === 1) {
      return (
        <StageItem
          archType={props.archType}
          index={index}
          stageCurrentStep={props.currentStep}
          isNodeContains={isNodeContains}
          item={item}
          itemArray={itemArray}
          addNodeHandler={addNodeHandler}
          stageButtonClickHandle={stageButtonClickHandle}
          stageDiv={StageDiv}
          singleStageWidth={singleStageWidth}
          singleStageHeight={singleStageHeight}
          countOfFoldGroup={props.countOfFoldGroup}
          actualStageWidth={props.actualSingleStageWidth}
          uiShowType={props.uiShowType}
          viewEdit={props.viewEdit}
          upQuick3Index={props.upQuick3Index}
          downQuick3Index={props.downQuick3Index}
        />
      )
    } else if (props.countOfFoldGroup > 1) {
      const remainder = index % props.countOfFoldGroup
      const quotient = Math.floor(index / props.countOfFoldGroup)
      if (
        index === 0 ||
        index === stageList.length - 1 ||
        (remainder === 0 &&
          quotient * props.countOfFoldGroup + 1 !== stageList.length - 1)
      ) {
        return (
          <StageItem
            archType={props.archType}
            index={index}
            stageCurrentStep={props.currentStep}
            isNodeContains={isNodeContains}
            item={item}
            itemArray={itemArray}
            addNodeHandler={addNodeHandler}
            stageButtonClickHandle={stageButtonClickHandle}
            stageDiv={StageDiv}
            singleStageWidth={singleStageWidth}
            singleStageHeight={singleStageHeight}
            countOfFoldGroup={props.countOfFoldGroup}
            actualStageWidth={props.actualSingleStageWidth}
            uiShowType={props.uiShowType}
            viewEdit={props.viewEdit}
            upQuick3Index={props.upQuick3Index}
            downQuick3Index={props.downQuick3Index}
          />
        )
      }
      // else if(remainder === 0 && (quotient*props.countOfFoldGroup + 1) === (stageList.length - 1)) {
      //   return <StageFoldItem
      //     archType={props.archType}
      //     index={index}
      //     stageCurrentStep={stageCurrentStep}
      //     isNodeContains={isNodeContains}
      //     item={item}
      //     itemArray={itemArray}
      //     addNodeHandler={addNodeHandler}
      //     stageButtonClickHandle={stageButtonClickHandle}
      //     stageDiv={StageDiv}
      //     singleStageWidth={singleStageWidth}
      //     singleStageHeight={singleStageHeight}
      //     scaleHeight={4}
      //     countOfFoldGroup={props.countOfFoldGroup}
      //     isBigNode={true} />
      // }
      else {
        return (
          <StageFoldItem
            archType={props.archType}
            index={index}
            stageCurrentStep={props.currentStep}
            isNodeContains={isNodeContains}
            item={item}
            itemArray={itemArray}
            addNodeHandler={addNodeHandler}
            stageButtonClickHandle={stageButtonClickHandle}
            stageDiv={StageDiv}
            singleStageWidth={singleStageWidth}
            singleStageHeight={singleStageHeight}
            scaleHeight={4}
            countOfFoldGroup={props.countOfFoldGroup}
            actualStageWidth={props.actualSingleStageWidth}
            uiShowType={props.uiShowType}
            isBigNode={false}
            viewEdit={props.viewEdit}
            upQuick3Index={props.upQuick3Index}
            downQuick3Index={props.downQuick3Index}
          />
        )
      }
    }
  }

  const loadStageUi = () => {
    return stageList.map((item, index, itemArray) => {
      return (
        <Stack
          ref={(e: HTMLElement) => {
            callBackStepRef(e, index)
          }}
          direction="row"
          spacing={0}
          key={item.indexStr}
        >
          {judgeWhichItem(item, index, itemArray)}
        </Stack>
      )
    })
  }

  const loadStageData = () => {
    console.info(
      "loadStageData=====nodes count",
      props.nodes.length,
      props.archName,
    )
    var newStageList = []
    for (var i = 0; i < props.nodes.length; i++) {
      //console.info("=====", props.nodes[i].stepindex, props.nodes[i].name, props.nodes[i].nodeType);
      newStageList.push({
        indexStr: props.nodes[i].name,
        nodeTypes: props.nodes[i].nodeType,
      })
    }

    console.info("=====set stageList", props.archName, newStageList.length)
    setStageList(newStageList)

    console.info("=====stageCurrentStep: ", props.archName, props.currentStep)
    // setIsCurrentStepChangedByClick(false)
  }

  // const calcStageWidth = () => {
  //   if (props.countOfFoldGroup === 1) {
  //     setActualStageWidth(singleStageWidth)
  //   } else if (props.countOfFoldGroup === 5) {
  //     setActualStageWidth(singleStageWidth - 6)
  //   } else if (props.countOfFoldGroup === 10) {
  //     setActualStageWidth(singleStageWidth - 10)
  //   } else if (props.countOfFoldGroup === 20) {
  //     setActualStageWidth(singleStageWidth - 10)
  //   }
  // }

  const loadGmButton = (isFront) => {
    if (stageList.length <= 0 || !props.isDownArchBiteMoved()) {
      return <></>
    }

    if (props.archType === 0) {
      if (
        (isFront && gmUiLocation === "start")
      ) {
        return <div style={{ width: 60 }} />
      } else {
        return <></>
      }
    } else {
      if (isFront && gmUiLocation === "start") {
        return (
          <div style={{ marginTop: 9, height: 9 + 32 }}>
            <StageGmButton
              width={60}
              height={singleStageHeight}
              percent={props.gmPercent}
              onClick={props.gmFrontButtonClickHandle}
            ></StageGmButton>
          </div>
        )
      } else if (!isFront && gmUiLocation === "end") {
        return (
          <div style={{ marginTop: 9, height: 9 + 32 }}>
            <StageGmButton
              width={60}
              height={singleStageHeight}
              percent={props.gmPercent}
              onClick={props.gmBackButtonClickHandle}
            ></StageGmButton>
          </div>
        )
      }
    }
  }

  useEffect(() => {
    // calcStageWidth()
  }, [props.countOfFoldGroup])

  useEffect(() => {
    loadStageData()
  },
    []
  );

  // useEffect(() => {
  //   if(stageCurrentStep === -1) {
  //     props.setDefaultStep(props.archType, stageList.length - 1)
  //   }
  //   console.info("arch type:", props.archType, " stageCurrentStep:", stageCurrentStep)
  //   // stepChangeHandler(archType, stageCurrentStep)
  // }, [stageCurrentStep]);

  useEffect(() => {
    loadStageData()
  },
    [props.nodes]
  );

  useEffect(() => {
    // 这里的代码会在每次渲染后执行
    // console.info("stage arch:" + props.archType + "render finished: " + stageList.length)
    props.loadFinished(props.archType)
  });

  return (
    <Box>
      <Stack direction="row" spacing={0}>
        {loadGmButton(true)}
        
        <Box sx={{width: 3 + ((singleStageWidth - props.actualSingleStageWidth) / 2), height: 49}}></Box>
        {loadStageUi()}
        <Box sx={{width: 3 + ((singleStageWidth - props.actualSingleStageWidth) / 2), height: 49}}></Box>

        {loadGmButton(false)}
        
      </Stack>
      <DialogBox open={showDeleteNodeWarning} title="Warning" content={<>{"Do you want to delete the keyframe?"}</>} actions=
      {<>
          <UButton
            onClickHandler={ () => {
              setShowDeleteNodeWarning(false)
              console.info("=====delete node: ", props.archName, props.currentStep)
              stagingManager.deleteKeyNode(props.archType === 0, props.currentStep)
            }}
            variant={"text"}
            btnText={("Yes")}
          />
          <UButton
            onClickHandler={ () => {
              setShowDeleteNodeWarning(false)
            }}
            variant={"text"}
            btnText={("Cancel")}
          />
        </>
      }
      >
      </DialogBox>
      <DialogBox open={showDeletePassiveAlignerNodeWarning} title="Warning" content={<>{"Do you want to delete passive aligner frame?"}</>} actions=
      {<>
          <UButton
            onClickHandler={ () => {
              setShowDeletePassiveAlignerNodeWarning(false)
              console.info("=====delete passive aligner node: ", props.archName, props.currentStep)
              stagingManager.deletePassiveNode(props.archType === 0, props.currentStep)
            }}
            variant={"text"}
            btnText={("Yes")}
          />
          <UButton
            onClickHandler={ () => {
              setShowDeletePassiveAlignerNodeWarning(false)
            }}
            variant={"text"}
            btnText={("Cancel")}
          />
        </>
      }
      >
      </DialogBox>
    </Box>
  );
}

export default StageArch;