import {
  wasmModule,
  StageEventType,
  WASMLifeCircleEventType,
  stagingManager,
} from "..";
import { GlobalEvents, globalEvents } from "../../../utils";
import { report } from "../report/report";
import { trimToolModule } from "../trimtools/trimtool";
import {
  attachmentModule,
  IAttachmentIPRVisible,
} from "../attachment/attachmentModule";
import { displayModule } from "../displaymodule/displaymodule";
import { iprModule } from "../ipr/iprModule";
import { faccAndEdgeEditor } from "../toothcorrections/faccedge";

import { gdsPlusSetup } from "../setupmode/gdsPlus";
import { configModule } from "../configmodule/configmodule";

function bindEvents() {
  globalEvents.on(GlobalEvents.ON_ATTACHMENT_BUTTON_CLICKED, isOpen => {
    // 增加attachment之前得开启功能 结束关闭功能
    console.log(">>>ON_ATTACHMENT_BUTTON_CLICKED", isOpen);
    attachmentModule.openAttachmentModule(isOpen);
  });
  globalEvents.on(GlobalEvents.ON_TRIMING_CLICKED, isOpen => {
    console.log(">>>ON_TRIMING_BUTTON_CLICKED", isOpen);
    let ret;
    if (isOpen) {
      ret = trimToolModule?.start(isOpen);
    } else {
      ret = trimToolModule.close();
    }

    console.log("ret:::1", ret);
  });

  globalEvents.on(GlobalEvents.ON_TRIMING_MODE_CHANGED, isTrimLine => {
    // console.log('>>>ON_TRIMING_MODE_CHANGED', isTrimLine);
    let ret;
    if (isTrimLine) {
      trimToolModule.close();
      ret = trimToolModule?.start(true);
    } else {
      trimToolModule.close();
      ret = trimToolModule?.startSurface(true);
    }

    console.log("ret:::2", ret);
  });
}

/**
 * 回调,当WASM内stage长度发生变化的时候触发，
 * 例如移牙或者节点变化
 * @param steps0
 * @param steps1
 *   */
function updateStageCallback(arch: number, index: number) {
  stagingManager.updateStageCallback(arch, index);
  report.update();
  iprModule.updateIPRVisibility();
  wasmModule.event.fire(StageEventType.UPDATESTAGE_EVENT);
}

function updateGMProgressCallBack(percent : number) {
  //console.info("updateGMProgressCallBack: " + percent);
  if(stagingManager.setGMProgressCallBack) {
    stagingManager.setGMProgressCallBack(percent);
  }
}

function updateTxPlanChangedCallBack() {
  if(stagingManager.setTxPlanChangedCallBack) {
    stagingManager.setTxPlanChangedCallBack();
  }
}

function updateTxPlanDataChangedCallBack() {
  if(stagingManager.setTxPlanDataChangedCallBack) {
    stagingManager.setTxPlanDataChangedCallBack();
  }
}

function updateTreatViewProcessCallBack(viewID : number, percent : number) {
  //console.info("updateTreatViewProcessCallBack: " + percent);
  if(viewID === 0)
  {
    if(stagingManager.setGM0ProgressCallBack) {
      stagingManager.setGM0ProgressCallBack(viewID, percent);
    }
  }
  else if(viewID === 1)
  {
    if(stagingManager.setGM1ProgressCallBack) {
      stagingManager.setGM1ProgressCallBack(viewID, percent);
    }
  }
}

function onSelectAttachmentCallback() {
  attachmentModule.onSelectedAttachment();
}

function onDeleteAttachmentCallback(title, info) {
  console.log("onDeleteAttachmentCallback");
  attachmentModule.deleteSelectedAttachment();
}

function onResetAttachmentButtonCallback(title, info) {
  attachmentModule.onResetAttachmentSelectButton();
}

function onArchTypeChange() {
  // WASM 控制上下颌显示状态
}

/**
 * attachment error message回调接口
 */
function onTipPopup(title: string, info: string) {
  // WASM 控制上下颌显示状态
  attachmentModule.onErrorMsgHandle(title, info);
}

function onHoveringToothCallback(arch, toothId, x, y, hoverstr: string) {
  // console.log("onHoveringTooth  :", arch, toothId, x, y, hoverstr);
  displayModule.hoverToothProperties(toothId, arch, [x, y], hoverstr);
}

// run setup process: 1.SetupCOPAAuto  2.AttachAttachmentsAutomatically
//                  3.onEndCopaDemoCall()  4.SaveAutoSetUpResult()
function runAutoSetup() {
  const setEndMessageCallback = (show: number, type: number) => {
    let isVisible = false;
    if (show === 1) isVisible = true;

    if (stagingManager.setupTypeAndStage)
      stagingManager.setupTypeAndStage(
        type,
        Math.max(
          stagingManager.wasmStageData.keypointsUpMaxStep,
          stagingManager.wasmStageData.keypointsDownMaxStep
        )
      );
    const visible: IAttachmentIPRVisible = {
      isHasAttachmentData: isVisible,
      isHasIPRData: wasmModule.statusController.HasCollision(),
    };
    if (attachmentModule.attachmentiprVisibility)
      attachmentModule.attachmentiprVisibility(visible);
  };

  console.log("start run AutoSetup");
  // ----------1.SetupCOPAAuto--------
  gdsPlusSetup.SwitchAutoSetupModule(true);
  const autosetup = gdsPlusSetup.getAutoSetupModule();
  wasmModule.caseSetupType = autosetup.COPAAuto();
  gdsPlusSetup.SwitchAutoSetupModule(false);
  wasmModule.event.fire(WASMLifeCircleEventType.BeforeSetUpFinish);
  let isHasAttachment = false;
  if (wasmModule.caseType === 9) {
    // ----------2.AttachAttachmentsAutomatically--------
    wasmModule.moduleManager.SwitchAttachmentModule(true);
    const moduelAttachment = wasmModule.moduleManager.GetAttachmentModule();
    if (moduelAttachment) {
      moduelAttachment.CleanAllAttachment();
      for (let i = 0; i < 2; i++) {
        moduelAttachment.AddAllAttachment(i as 0 | 1);
      }

      wasmModule.moduleManager.SwitchAttachmentModule(false);
    }
    isHasAttachment = true;
  }

  wasmModule.event.fire(WASMLifeCircleEventType.SetUpFinished);
  // ----------3.SaveAutoSetUpResult()--------
  wasmModule.stagingcontoler.SaveAutoSetUpResult();
  console.log("setup finished");
  // ----------4.onEndCopaDemoCall()--------
  setEndMessageCallback(isHasAttachment ? 1 : 0, wasmModule.caseType); // (old,Replace：setCompleteAttachmentsAutomaticallyCallback)
  // 仅用于QA临时测试
  // const temporaryTest = new TemporaryTest();
}

globalEvents.on(GlobalEvents.WASM_MODULE_LOADOK, () => {
  const windowObj = window as any;
  // (window as any).initFinishedCallback = this.initFinishedCallback.bind(this);
  windowObj.updateStagesCallback = updateStageCallback;
  windowObj.updateGMProgress = updateGMProgressCallBack;
  windowObj.txPlanChanged = updateTxPlanChangedCallBack;
  windowObj.txPlanDataChanged = updateTxPlanDataChangedCallBack;
  windowObj.onSelectAttachment = onSelectAttachmentCallback;
  windowObj.onDeleteAttachment = onDeleteAttachmentCallback;
  windowObj.onTipPopup = onTipPopup;
  windowObj.changeArchType = onArchTypeChange;
  windowObj.faccBtnStateCallback =
    faccAndEdgeEditor.showEditBtn.bind(faccAndEdgeEditor);
  windowObj.onResetAttachmentSelectButton = onResetAttachmentButtonCallback;
  windowObj.onHoveringToothCallback = onHoveringToothCallback;
  windowObj.OnTreatGMPlay = updateTreatViewProcessCallBack;

  windowObj.OnBiteRampBtnPosChange = () => {};

  configModule.setWidgetColor();
  configModule.setSpecialToothColor();
});

globalEvents.on(GlobalEvents.CASE_INIT_FINISHED, () => {
  stagingManager.initFinishedupdateStageCallback();
  report.update();
  bindEvents();
  trimToolModule.initModule();

  attachmentModule.openAttachmentModule(true);

  displayModule.setHoverToothToggle(true);
  configModule.setGridColor();
});

globalEvents.on(WASMLifeCircleEventType.PresetupEnd, () => {
  runAutoSetup();
});
