import type { FC } from "react"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { toothMovement } from "@/gluelayer"

import UButton from "../../../../ui-component/components/UButton/UButton"
import DialogBox from "../DialogBox/dialogBox"
const PassIprPopup: FC = ({
  hasObj,
  setHasObj,
}: {
  hasObj: { hasIPR: number; hasPA: number }
  setHasObj: (arg: { hasIPR: number; hasPA: number }) => void
}) => {
  const { t } = useTranslation("common")
  //hasObj.hasIPR || hasObj.hasPA

  return (
    <DialogBox
      open={hasObj.hasPA || hasObj.hasIPR}
      title={t("treat.passIprPopup.title")}
      content={
        <p style={{ margin: "0", fontSize: "14px" }}>
          {hasObj.hasIPR && hasObj.hasPA
            ? `${t("treat.passIprPopup.fullcontent")}`
            : `${t("treat.passIprPopup.content")}${
                hasObj.hasPA ? "passive aligner" : "IPR"
              }`}
        </p>
      }
      actions={
        <>
          <UButton
            onClickHandler={() => {
              toothMovement.dealWithExistIprOrPAPrompt(false)
              setHasObj({ hasIPR: 0, hasPA: 0 })
            }}
            variant={"text"}
            btnText={"Cancel"}
          />
          <UButton
            onClickHandler={() => {
              toothMovement.dealWithExistIprOrPAPrompt(true)
              setHasObj({ hasIPR: 0, hasPA: 0 })
            }}
            variant={"contained"}
            btnText={t("treat.lockPopUp.okbtn")}
          />
        </>
      }
    />
  )
}
export default PassIprPopup
