import { FC, useEffect, useState } from "react"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
} from "@mui/material"

import { useAppSelector } from "../../../../core/app/hooks"
import { RootState } from "../../../../core/app/store"
import { UText } from "../../../../ui-component"

const OrderPopup: FC<{
  isClickOrder: boolean
  setisClickOrder: (arg: boolean) => void
  planList: { txName: string; index: number }[]
  uploadReportPdf: () => void
  currentPlan: {
    txName: string
    index: 2 | 1
  }
  confirmNavigation: boolean | (() => void)
  cancelNavigation: boolean | (() => void)
  deletePlanFunction
  lockFunc: (callback?: () => void) => void
  uploadAllData
  setispopupAfterOrder
}> = ({
  isClickOrder,
  setisClickOrder,
  planList,
  currentPlan,
  confirmNavigation,
  cancelNavigation,
  deletePlanFunction,
  lockFunc,
  uploadAllData,
  setispopupAfterOrder
}) => {

    const { caseDetail: { caseDisposition: case_disposition, txplanName: txplan_name, txplanSubname: txplan_subname } } = useAppSelector((state: RootState) => state.caseService)
    const [isDelPlan, setisDelPlan] = useState({
      action: false,
      plan: "",
    })
    /**
     * post to BE when you assign order case to someone
     */
    const assignFun = ({ activePlan, delPlan }) => {
      uploadAllData({
        txname: activePlan,
        txsubname: delPlan,
        callback: () => {
          setisClickOrder(false)
          setispopupAfterOrder(false)
          setisDelPlan({ action: false, plan: "" })
          confirmNavigation()
        },
      })
    }

    useEffect(() => {
      if (planList.length < 2) return
      setisDelPlan({
        action: false,
        plan:
          currentPlan.index === planList[0].index
            ? planList[1].txName
            : planList[0].txName,
      })
    }, [planList, currentPlan])

    return (
      <Dialog open={isClickOrder}>
        <DialogTitle>Choose a single treatment plan prior to ordering</DialogTitle>
        <DialogContent>Please delete one of the treatment plans by clicking the X next to the plan name before ordering (you must be in edit mode to do this.)</DialogContent>
        <DialogActions><Button onClick={() => {
          setisClickOrder(false)
          setispopupAfterOrder(false)
          cancelNavigation()
        }}>ok</Button></DialogActions>
      </Dialog>
    )
    // return (
    //   <>
    //     <Dialog open={isClickOrder && case_disposition === "PLACE_ORDER"}>
    //       <Paper sx={{ width: 444 }}>
    //         <DialogTitle>
    //           <UText variant={"h6"}>Want to change selected plan?</UText>
    //         </DialogTitle>
    //         <DialogContent>
    //           <UText variant={"body2"}>
    //             {"<" +
    //               txplan_name +
    //               "> is selected and assigned for order. Do you want to change to <" +
    //               txplan_subname +
    //               ">?"}
    //           </UText>
    //         </DialogContent>
    //         <DialogActions>
    //           <Button
    //             onClick={() => {
    //               setisDelPlan({ action: true, plan: txplan_subname })
    //             }}
    //             sx={{
    //               textTransform: "uppercase",
    //               color: "#90CAF9",
    //             }}
    //           >
    //             NO,CANCEL
    //           </Button>
    //           <Button
    //             onClick={() => {
    //               setisDelPlan({ action: true, plan: txplan_name })
    //             }}
    //             autoFocus
    //             sx={{
    //               textTransform: "uppercase",
    //             }}
    //             size="medium"
    //             color="primary"
    //             variant="contained"
    //           >
    //             yes,change
    //           </Button>
    //         </DialogActions>
    //       </Paper>
    //     </Dialog>
    //     <Dialog
    //       open={
    //         (isClickOrder &&
    //           (case_disposition === "REVIEW_PLAN" ||
    //             case_disposition === "REVIEW_MODIFICATION")) ||
    //         isDelPlan.action
    //       }
    //     >
    //       <Paper sx={{ width: 444 }}>
    //         <DialogTitle>
    //           <UText variant={"h6"}>Do you want to make</UText>
    //           {
    //             <UText
    //               variant={"h5"}
    //               color="#90CAF9"
    //               sxProp={{ margin: "0 5px" }}
    //             >
    //               {planList[0]?.txName === isDelPlan.plan
    //                 ? planList[1]?.txName
    //                 : planList[0]?.txName}
    //             </UText>
    //           }
    //           <UText variant={"h6"}> your active plan?</UText>
    //         </DialogTitle>
    //         <DialogContent>
    //           <UText variant={"body2"}>
    //             Other plans will be deleted and CANNOT be recovered.
    //           </UText>
    //         </DialogContent>
    //         <DialogActions>
    //           <Button
    //             onClick={() => {
    //               setisClickOrder()
    //               setisDelPlan({ action: false, plan: "" })
    //               cancelNavigation()
    //             }}
    //             sx={{
    //               textTransform: "uppercase",
    //               color: "#90CAF9",
    //             }}
    //           >
    //             CANCEL
    //           </Button>
    //           <Button
    //             onClick={() => {
    //               let delplan, activeplan
    //               if (planList[0].txName === isDelPlan.plan) {
    //                 delplan = planList[0]
    //                 activeplan = planList[1]
    //               } else {
    //                 delplan = planList[1]
    //                 activeplan = planList[0]
    //               }
    //               const isDel = deletePlanFunction(delplan.index)
    //               if (isDel)
    //                 lockFunc(() => {
    //                   assignFun({
    //                     activePlan: activeplan.txName,
    //                     delPlan: "",
    //                   })
    //                 })
    //             }}
    //             autoFocus
    //             sx={{
    //               textTransform: "uppercase",
    //             }}
    //             size="medium"
    //             color="primary"
    //             variant="text"
    //           >
    //             yes, continue
    //           </Button>
    //         </DialogActions>
    //       </Paper>
    //     </Dialog>
    //   </>
    // )
  }
export default OrderPopup
