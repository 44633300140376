import { FC, useState } from "react"
import { Trans, useTranslation } from "react-i18next"
import AddIcon from "@mui/icons-material/Add"

import { useAppSelector } from "../../../core/app/hooks"
import { RootState } from "../../../core/app/store"
import { UI_PERMISSION } from "../../../core/utils/access/permissionConstants"
import { formatCurrencyWithDecimals } from "../../../core/utils/formatters"
import usePermissions from "../../../hooks/userPermission"
import { UButton, UText } from "../../../ui-component"
import {
  Box,
  Container,
  Grid,
  Paper,
} from "../../../ui-component/mui.components"
import customImg from "../assets/customboxes-bg.jpg"

import NewPackackingModal from "./NewPackagingModal"

const customPackagingNotes = [
  {
    key: "text1",
  },
  {
    key: "text2",
  },
  {
    key: "text3",
  },
]

export const AddNewPackaging: FC = () => {
  const { hasAny } = usePermissions()
  const { t } = useTranslation("common")
  const [showAddModal, setShowAddModal] = useState(false)
  const [topImage, setTopImage] = useState(undefined)
  const [sideImage, setSideImage] = useState(undefined)

  const { customPackaging } = useAppSelector(
    (state: RootState) => state.orderService,
  )

  return (
    <>
      <Grid item container justifyContent={"center"}>
        <Paper
          elevation={0}
          sx={{ borderRadius: 4, width: "100%", minHeight: "100px" }}
        >
          <Container
            component="div"
            maxWidth={"lg"}
            sx={{
              display: "flex",
              flexDirection: "row",
              padding: "32px 64px !important",
              justifyContent: "space-between",
              gap: 10,
            }}
          >
            <Box sx={{ minWidth: "65%", maxWidth: "760px" }}>
              {customPackagingNotes?.map((eachNote) => (
                <UText
                  key={eachNote.key}
                  variant={"body2"}
                  sxProp={{ pt: 2, pb: 2, color: "#777777" }}
                >
                  <Trans
                    components={{
                      newLine: <br />,
                    }}
                  >
                    {t(`customPackaging.${eachNote?.key}`, {
                      setupAmt: formatCurrencyWithDecimals(
                        customPackaging.setupFee,
                      ),
                      setupCharge: formatCurrencyWithDecimals(
                        customPackaging.unitCharge,
                      ),
                    })}{" "}
                  </Trans>
                </UText>
              ))}
              {hasAny(UI_PERMISSION.UI_CUSTOMBOX_CREATE) && (
                <Box component={"div"}>
                  <UButton
                    startIcon={<AddIcon />}
                    sxProp={{ mt: 2, textTransform: "none" }}
                    variant={"contained"}
                    btnType={"submit"}
                    btnText={t("customPackaging.addBtn")}
                    onClickHandler={() => {
                      setShowAddModal(true)
                      setTopImage(undefined)
                      setSideImage(undefined)
                    }}
                  />
                </Box>
              )}
            </Box>
            <Box>
              <Box
                component={"img"}
                src={customImg}
                alt={"custom-box-img"}
                sx={{ height: "286px", alignSelf: "stretch", mb: 1 }}
              />
            </Box>
          </Container>
        </Paper>
      </Grid>
      {
        <NewPackackingModal
          showAddModal={showAddModal}
          setShowAddModal={setShowAddModal}
          setTopImage={setTopImage}
          setSideImage={setSideImage}
          topImage={topImage}
          sideImage={sideImage}
        />
      }
    </>
  )
}
