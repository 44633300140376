import { FC, useEffect, useRef, useState } from "react"

import moment from "moment-timezone"
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { LeftPage } from "@/UDTreat/left/LeftPage"
import { useParams } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "@/core/app/hooks"
import { RootState } from "@/core/app/store"
import { updateCaseById } from "@/core/app/slices/case"
import { Box, IconButton, InputBase, Paper, Typography } from "@/components/mui.components";
import { useTranslation } from "react-i18next";

interface DoctorNote {
  Date: string
  Description: string
  User: string
  Role: number
}
interface NoteProps {
  note: DoctorNote; 
}
type DoctorNotes = Record<string, DoctorNote>

const CaseNote: FC<{
  onClosePage: () => void
}> = ({ onClosePage }) => {
  const { patientId, caseId } = useParams()
  const dispatch = useAppDispatch()
  const { caseDetail } = useAppSelector((state: RootState) => state.caseService)
  const [doctorNotes, setDoctorNotes] = useState<DoctorNotes>(null)
  const noteEnd = useRef(null)
  const { t } = useTranslation("common")


  const [inputValue, setinputValue] = useState("")

  useEffect(() => {
    if (caseDetail?.doctor_note) {
      const parsedData: DoctorNotes = JSON.parse(caseDetail?.doctor_note)
      setDoctorNotes(parsedData)
    }
  }, [caseDetail])
  useEffect(() => {
    if (noteEnd && noteEnd.current) {
      noteEnd.current.scrollTop = noteEnd.current.scrollHeight
    }
  }, [doctorNotes])

  const getCurrentTimeByTimeZoom = (time: string) => {
    if (!time) return
    const current_time = moment
      .tz(time, Intl.DateTimeFormat().resolvedOptions().timeZone)
      .format("MM/DD/YY hh:mm A")
    return current_time
  }

  const EmptyComponent = (
    <Box
      id="no-casenote"
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: "32px 8px",
        height: "100%",
        marginBottom: 14.5,
      }}
    >
      <Box sx={{ marginTop: 3, padding: 1.3, color: "#000" }}>
        <Box
          sx={{
            marginBottom: 1.3,
            fontSize: 20,
            fontWeight: 500,
            textAlign: "center",
          }}
        >{t("caseNote.noCaseNote")}
        </Box>
      </Box>
    </Box>
  )

  const NoteComponent:FC<NoteProps> = ({ note }) => {
    const { Date, Description } = note
    return (
      <>
        <Box
          sx={{
            width: "100%",
            paddingBottom: "20px",
          }}
        >
          <Box id="casenote-time">
            <Typography variant="caption" color={"rgba(0, 0, 0, 0.87)"}>
              {getCurrentTimeByTimeZoom(Date)}
            </Typography>
          </Box>

          <Box
            id="casenote-text"
            sx={{
              padding: "16px",
              background: "#f0f0f0",
              borderRadius: 0.5,
              width: "100%",
            }}
          >
            <Box
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: "",
                "-webkit-box-orient": "vertical",
                color: "rgba(0, 0, 0, 0.87)",
              }}
            >
              {Description}
            </Box>
          </Box>
        </Box>
      </>
    )
  }

  return (
    <LeftPage
      sx={{
        Width: "536px",
        position: "relative",
        zIndex: 1000,
      }}
      title={t("caseNote.title")}
      headContent={
        <Typography variant="body2" sx={{ margin: "0 25px 20px" }}>
          {t("caseNote.titleContent")}
        </Typography>
      }
      onClose={onClosePage}
      contentsx={
        {
          paddingTop: "0 !important",
          paddingBottom: "0 !important",
        }
      }
      content={
        <>
          {!doctorNotes && EmptyComponent}
          {doctorNotes &&
          <Box
          id="casenote"
          sx={{
            width: "100%",
            overflow: "auto",
            flex: "1 1 auto",
            "::-webkit-scrollbar": { display: "none" },
            maxHeight: window.innerHeight - 450,
            minHeight: 400
          }}
          ref={noteEnd}

        >
          {Object.values(doctorNotes).map((note, i) => (
              <NoteComponent note={note} key={note.Date + i} />
            ))}
        </Box>
        }
        </>
      }
      actions={
        <Box id="casenote-foot" sx={{ padding: "16px 32px" }}>
          <Paper
            sx={{
              p: "8px",
              display: "flex",
              alignItems: "center",
              width: "100%",
            }}
          >
            <InputBase
              sx={{
                width: "100%",
              }}
              placeholder={"Enter case notes here"}
              disabled={false}
              value={inputValue}
              onChange={({ target: { value } }) => {
                setinputValue(value)
              }}
            />

            <IconButton
              aria-label="directions"
              disabled={inputValue.trim() === ""}
              onClick={() => {
                if (inputValue.trim() === "") return
                dispatch(
                  updateCaseById({
                    patientId: patientId,
                    caseId: caseId,
                    payload: {
                      notes: inputValue,
                    },
                  }),
                )
                setinputValue("")
              }}
            >
              <ArrowUpwardIcon />
            </IconButton>
          </Paper>
        </Box>
      }
    />
  )
}

export default CaseNote
