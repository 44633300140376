import React from 'react';
import { useRef, useEffect, useState, useImperativeHandle } from 'react';
import { Box, Button, Paper, Slider, Tooltip } from "@mui/material";
import ToolButton from "../ToolBar/toolButton"
import savePng from "../../assets/bottomToolBar/stage/save_stage.png";
import noSavePng from "../../assets/bottomToolBar/stage/no_save_stage.png";
import closeNormalPng from "../../assets/bottomToolBar/stage/close_normal.png";
import closeHoverPng from "../../assets/bottomToolBar/stage/close_hover.png";
import addTabPng from "../../assets/bottomToolBar/stage/add_tab.png";

import { Font } from '@react-pdf/renderer';
import ImageButton from './ImageButton';

import {
  initialfinal,
  stageControl,
  toothMovement,
  EBiteJumpLocationType,
  viewControlInTreatment,
  caseManagement,
  history,
  stagingManager,
} from "@/gluelayer"

const {
  getTxDataChanged,
  setTxDataChanged,
  saveCurrentTx,
} = caseManagement

const StageTxTab = (props) => {
  const [txDataChangedFlag, setTxDataChangedFlag] = useState(false);

  const saveClickedHandle = (index) => {
    if(txDataChangedFlag) {
      console.info("saveClickedHandle", index)
      if(index === 0) {

      } else if(index === 1) {
  
      }

      saveCurrentTx()
    }
  }

  const deleteClickedHandle = (index) => {
    console.info("deleteClickedHandle", index)
    if(index === 0) {
      props.deleteTreatmentPlan(1)
    } else if(index === 1) {
      props.deleteTreatmentPlan(2)
    }
  }

  const addTabClickedHandle = () => {
    console.info("addTabClickedHandle")
    props.addTreatmetPlan()
  }

  const txPlanDataChangedHandle = () => {
    const isTxDataChanged = getTxDataChanged()
    console.info("txPlanDataChangedHandle:", isTxDataChanged)
    setTxDataChangedFlag(isTxDataChanged)
  }

  useEffect(() => {
    stagingManager.setTxPlanDataChangedCallBack = txPlanDataChangedHandle
  }, [])
  
  return <div style={{display: "flex", 
       flexDirection: "row",
       width: "240px",
       height: "24px",
       position: "absolute", 
       top: "6px",
       background: "transparent",
       border: "none",
  }}>
    {props.planList.map((i, j) => {
      return (
        <Box 
          key={j}
          sx={{
          width: props.currentPlan.index === i.index ? "100px" : "60px",
          height: "24px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          userSelect: "none",
          background: props.currentPlan.index === i.index ? "#FFFFFFCC" : "#FFFFFF77",
          borderTopLeftRadius: "8px",
          borderTopRightRadius: "8px",
        }} onClick={() => {
          props.setCurrentPlan(i)
        }}>
          <div style={{ width: "60px", fontSize: "12px", display: "flex", justifyContent: "center",}}>{`${i.txName}`}</div>

          {props.currentPlan.index === i.index ? <Box sx={{ width: "5px", height: "1px" }}></Box> : <></>}
          
          {/* {
            !props.hideSave ?
              (props.currentPlan.index === i.index ?
                <ImageButton
                  width={"15px"}
                  height={"15px"}
                  backgroundSrc={savePng}
                  backgroundSrcClicked={noSavePng}
                  clicked={!txDataChangedFlag}
                  onClick={() => { saveClickedHandle(j) }}
                  enableHover={false}
                  visibility={"hidden"}>

                </ImageButton>
                :
                <></>
              )
              :
              <></>
          } */}

          {props.currentPlan.index === i.index ? <Box sx={{ width: "5px", height: "1px" }}></Box> : <></>}

          {
            !props.hideRemove ?
              (props.currentPlan.index === i.index ?
                <ImageButton
                  width={"15px"}
                  height={"15px"}
                  backgroundSrc={closeNormalPng}
                  backgroundSrcClicked={closeHoverPng}
                  backgroundSrcHovered={closeHoverPng}
                  clicked={false}
                  onClick={() => { deleteClickedHandle(j) }}
                  enableHover={true}
                  visibility={props.viewEdit ? "" : "hidden"}>

                </ImageButton>
                :
                <></>
              )
              :
              <></>
          }
        </Box>
      )
    })}

    {
      !props.hideAdd ? 
      (props.planList.length === 2 ? 
        <></> 
        :
        <Box sx={{
          height: "20px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          userSelect: "none",
          background: "transparent",

        }}
        >
          <ImageButton
            width={"26px"}
            height={"15px"}
            backgroundSrc={addTabPng}
            backgroundSrcClicked={addTabPng}
            clicked={false}
            onClick={addTabClickedHandle}
            enableHover={false}
            visibility={props.viewEdit ? "" : "hidden"}
          >

          </ImageButton>

        </Box>
      )
      :
      <></>
    }

  </div>

};

export default StageTxTab;