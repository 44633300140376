import { FC, useCallback, useEffect, useState } from "react"
import { Actions, JsonSchema } from "@jsonforms/core"
import { useJsonForms, withJsonFormsControlProps } from "@jsonforms/react"
import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Select,
} from "@mui/material"

import { UText } from "../../../ui-component"

export const UMultipleCheckbox: FC<{
  path: string
  errors: string
  label: string
  schema: JsonSchema
  data: object
  handleChange: (path: string, value: any) => void
}> = ({ path, label, schema, data, handleChange }) => {
  const [value, setValue] = useState<object>({})
  const { core, dispatch } = useJsonForms()
  const [errors, setErrors] = useState("")
  const [isError, setIsError] = useState<boolean>(true)
  const [showcheckboxes, setShowcheckboxes] = useState<boolean>(false)
  const initialCheckboxes = {
    upper_right: [],
    lower_right: [],
    upper_left: [],
    lower_left: [],
  }
  const rangeArray = [1, 2, 3, 4, 5, 6, 7]
  const [selectedCheckboxes, setSelectedCheckboxes] =
    useState(initialCheckboxes)

  const handleCheckboxChange = (index: number, e) => {
    try {
      if (isNaN(index) || index < 0) {
        console.error("Invalid index value:", index)
        return
      }

      const { name: key } = e.target

      if (!key || !(key in selectedCheckboxes)) {
        console.error("Invalid checkbox name:", key)
        return
      }

      const currentList = selectedCheckboxes[key] || []
      if (!Array.isArray(currentList)) {
        console.error("Expected an array for the key:", key)
        return
      }

      // Update only the selected checkbox list
      const updatedList = currentList.includes(index)
        ? currentList.filter((i) => i !== index)
        : [...currentList, index]

      handleChange(path, {
        ...data,
        bite_ramp: {
          ...selectedCheckboxes,
          [key]: updatedList,
        },
      })
    } catch (error) {
      console.error("Error handling checkbox change:", error)
    }
  }

  useEffect(() => {
    if (!data) return
    if (data.bite_ramp) {
      const isBiteRampActive = Object.values(data.bite_ramp).some(
        (arr) => arr.length > 0,
      )
      if (isBiteRampActive) setShowcheckboxes(true)
      setSelectedCheckboxes(data.bite_ramp)
    }
  }, [data])

  useEffect(() => {
    const hasValues = () => {
      const isBiteRampActive = Object.values(data.bite_ramp).some(
        (arr) => arr.length > 0,
      )
      return isBiteRampActive
    }
    const hasNoData = data && hasValues()
    setIsError(hasNoData)
    if (core?.schema.required?.indexOf(path) === -1) return
    if (hasNoData) {
      updateErrors("This is a required field")
    } else {
      updateErrors("")
    }
  }, [data])

  const updateErrors = (message: string) => {
    setErrors(message)
    dispatch &&
      dispatch(
        Actions.updateErrors([
          {
            instancePath: "/" + path,
            message,
            schemaPath: "",
            keyword: "",
            params: {},
          },
        ]),
      )
  }

  const renderTeethCheckboxes = (
    row: "upper" | "lower",
    side: "left" | "right",
  ) => {
    const range = side === "right" ? rangeArray.reverse() : rangeArray
    return (
      <FormGroup row>
        {range.map((_, index) => {
          const mappedIndex =
            side === "right" ? rangeArray.length - index : index + 1

          return (
            <FormControlLabel
              key={`${side}-${row}-${mappedIndex}`}
              control={
                <Checkbox
                  sx={{ ".MuiCheckbox-root": { p: 0 } }}
                  name={`${row}_${side}`}
                  checked={
                    (selectedCheckboxes &&
                      selectedCheckboxes[`${row}_${side}`]?.includes(
                        mappedIndex,
                      )) ||
                    false
                  }
                  onChange={(e) => handleCheckboxChange(mappedIndex, e)}
                />
              }
              label=""
              sx={{ width: "35px", m: 0, p: 0 }}
            />
          )
        })}
      </FormGroup>
    )
  }

  return (
    <>
      <Box sx={{ display: "flex", flexDirection: "row", my: 1, pl: 1 }}>
        <UText
          variant={"body2"}
          sxProp={{
            minWidth: 200,
            display: "flex",
            flexDirection: "column",
            color:
              errors && core?.validationMode === "ValidateAndShow"
                ? "error.main"
                : "text.secondary",
          }}
        >
          {label}
        </UText>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <FormControlLabel
            sx={{ pl: 1 }}
            labelPlacement="end"
            control={
              <Checkbox
                checked={showcheckboxes}
                onChange={(e) => {
                  setShowcheckboxes(!showcheckboxes)
                }}
                name={"Yes"}
              />
            }
            label={"Yes"}
          />

          {isError && core?.validationMode === "ValidateAndShow" && (
            <UText
              variant={"caption"}
              sxProp={{ marginLeft: 1, color: "error.main" }}
            >
              {errors}
            </UText>
          )}
        </Box>
      </Box>
      {showcheckboxes && (
        <>
          <UText variant={"body1"} sxProp={{ color: "text.secondary", pl: 2 }}>
            Pick the teeth to add/remove bite ramp
          </UText>
          <Box sx={{ display: "flex", alignItems: "center", my: 2 }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                mx: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <UText variant={"body1"}>R</UText>
                <Box sx={{ display: "flex", justifyContent: "center", pl: 2 }}>
                  {rangeArray
                    .slice()
                    .reverse()
                    .map((num) => (
                      <UText
                        key={`left-number-${num}`}
                        variant="body1"
                        sxProp={{ width: "35px", pl: 1 }}
                      >
                        {num}
                      </UText>
                    ))}
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  mt: 1,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <UText variant="body1">{"U"}</UText>
                  {renderTeethCheckboxes("upper", "right")}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <UText variant="body1">{"L"}</UText>
                  {renderTeethCheckboxes("lower", "right")}
                </Box>
              </Box>
            </Box>

            <Divider orientation="vertical" flexItem sx={{ mx: 0 }} />

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                mx: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {rangeArray.map((num) => (
                  <UText
                    key={`right-number-${num}`}
                    variant="body1"
                    sxProp={{ width: "35px", pl: 1 }}
                  >
                    {num}
                  </UText>
                ))}
                <UText variant={"body1"} sxProp={{ ml: 2 }}>
                  L
                </UText>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  mt: 1,
                  ml: "-44px",
                }}
              >
                {renderTeethCheckboxes("upper", "left")}
                {renderTeethCheckboxes("lower", "left")}
              </Box>
            </Box>
          </Box>
        </>
      )}
    </>
  )
}

export default withJsonFormsControlProps(UMultipleCheckbox)
