const BANNER_INFO = [
  {
    status: "DELIVERED",
    buttonTxt: "CHANGE STATUS",
    message: "Change status to Treatment In Progress?",
  },
  {
    status: "TREATMENT_IN_PROGRESS",
    buttonTxt: "CHANGE STATUS",
    message: "Change status to Treatment Complete?",
  },
  {
    status: "SHIPPED",
    buttonTxt: "CHANGE STATUS",
    message: "Change status to Treatment In Progress?",
  },
]

export const getInfoBanner = (text: string) => {
  return BANNER_INFO.find((value) => text.includes(value.status))
}

const KEYS = [
  {
    key: "Case On Hold - Please Upload New Photos",
    buttonTxt: "UPLOAD PHOTOS",
    buttonIcon: "upload",
    routeTo: ["photograph"],
    width: "150px",
    message: "Photos added",
    contentTitle: "Photos needed: ",
  },
  {
    key: "On Hold - New photos needed",
    buttonTxt: "UPLOAD PHOTOS",
    buttonIcon: "upload",
    routeTo: ["photograph"],
    width: "150px",
    message: "Photos added",
    contentTitle: "Photos needed: ",
  },
  {
    key: "Case On Hold - Please Upload New Scans",
    buttonTxt: "UPLOAD SCANS",
    buttonIcon: "upload",
    routeTo: ["scans"],
    width: "145px",
    message: "Scans added",
    contentTitle: "Scans needed: ",
  },
  {
    key: "On Hold - New scans needed",
    buttonTxt: "UPLOAD SCANS",
    buttonIcon: "upload",
    routeTo: ["scans"],
    width: "145px",
    message: "Scans added",
    contentTitle: "Scans needed: ",
  },
  {
    key: "Case On Hold - Please Upload New X-Rays",
    buttonTxt: "UPLOAD X-RAYS",
    buttonIcon: "upload",
    routeTo: ["photograph"],
    width: "150px",
    message: "X-Rays added",
    contentTitle: "X-Rays needed: ",
  },
  {
    key: "On Hold - New X-Rays needed",
    buttonTxt: "UPLOAD X-RAYS",
    buttonIcon: "upload",
    routeTo: ["photograph"],
    width: "150px",
    message: "X-Rays added",
    contentTitle: "X-Rays needed: ",
  },
  {
    key: "Case On Hold - Scans and Images Do Not Match",
    buttonTxt: "OPEN PATIENT RECORDS",
    buttonIcon: "upload",
    routeTo: ["scans", "photograph"],
    width: "200px",
    message: "Record added",
  },
  {
    key: "Case On Hold - Please Review uAssist Message",
    buttonTxt: "OPEN CASE",
    buttonIcon: "open",
    routeTo: [],
    width: "120px",
  },
  {
    key: "Case On Hold - New Uploads Needed",
    buttonTxt: "UPLOAD",
    buttonIcon: "upload",
    routeTo: ["scans", "photograph"],
    width: "100px",
    message: "Record added",
  },
  {
    key: "Case On Hold - Need Clarification of Rx Form",
    buttonTxt: "OPEN RX FORM",
    buttonIcon: "upload",
    routeTo: ["RxForm", "SummaryView"],
    width: "150px",
    message: "Rx Form updated",
  },
  {
    key: "On Hold - Rx Form needs review",
    buttonTxt: "OPEN RX FORM",
    buttonIcon: "upload",
    routeTo: ["RxForm", "SummaryView"],
    width: "150px",
    message: "Rx Form updated",
  },
  {
    key: "Case On Hold - Please Upload New Photos or Review RX Form",
    buttonTxt: "UPLOAD",
    buttonIcon: "upload",
    routeTo: ["photograph", "RxForm", "SummaryView"],
    width: "150px",
    message: "Photograph and RxForm updated",
  },
]

export const getAttentionBannerButton = (text: string) => {
  if (!text) return
  return KEYS.find((value) => text === value.key)
}
