/**
 * local case data path
 */
export const LOCAL_CASEDATAPATH = "testcasedata/shaoguangyu_casedata";

/**
 * data dir
 */
export const SETING_DATA = "";
export const SETING_DATA0 = "Setting Data0";
export const SETING_DATA1 = "Setting Data1";
export const SETING_DATA2 = "Setting Data2";
export const SETING_DATA10 = "Setting Data10";
export const SETING_DATA_RAW = "Raw";
export const SETING_DATA_PHOTO = "Photo";

/**
 * gum model name
 */
export const GUM_L_MTC = "gum_l.mtc";
export const GUM_U_MTC = "gum_u.mtc";
/**
 * attachment infomation per stage
 */
export const ATTACHMENT_INFO_UP = "staging_final_trans_up.atm"; // 可忽略文件
export const ATTACHMENT_INFO_DOWN = "staging_final_trans_down.atm"; // 可忽略文件
export const ATTACHMENT_INFO_UP_2 = "staging_final_trans_up1.atm"; // 可忽略文件
export const ATTACHMENT_INFO_DOWN_2 = "staging_final_trans_down1.atm"; // 可忽略文件
/**
 * tooth pos and rotation info per stage
 *
 */
export const ARCH_TYPE_INFO = "ArchType.json"; // 可忽略文件
// export const TOOTH_STAGING_INFO = 'ToothStagingInfo.json';
/**
 *  tooth pos and rotation matrix per stage
 */
export const UP_ARCH_CUSTOM_STAGING_1 = "UpArchCustomStaging_1.dat"; // 可忽略文件
export const DOWN_ARCH_CUSTOM_STAGING_1 = "DownArchCustomStaging_1.dat"; // 可忽略文件
export const UP_ARCH_CUSTOM_STAGING_2 = "UpArchCustomStaging_2.dat"; // 可忽略文件
export const DOWN_ARCH_CUSTOM_STAGING_2 = "DownArchCustomStaging_2.dat"; // 可忽略文件
/**
 * transform tooth coordinates to it's center
 */
export const TOOTH_LOCAL_XFM = "ToothLocalXFM.dat"; // 不管是否标识必须存在

export const TOOTH_LOCAL_GUMMODEL_L = "gumModel_l.gum";
export const TOOTH_LOCAL_GUMMODEL_U = "gumModel_u.gum";
export const TOOTH_LOCAL_ARCHMODEL_U = "arch_u.mtc";
export const TOOTH_LOCAL_ARCHMODEL_L = "arch_l.mtc";
export const TOOTH_LOCAL_DATAVERSION = "DataVersion.txt";
export const TOOTH_LOCAL_SHIELDMODEL_L = "shield_l.shieldWithVersionNum";
export const TOOTH_LOCAL_SHIELDMODEL_U = "shield_u.shieldWithVersionNum";
export const TOOTH_LOCAL_PULLOUT = "ToothPullOutFlag.dat"; // 可忽略文件

// export const LOWER_ARCH_MOVE_DATA = 'lowerArchMovement.data'; ///
// export const UP_ARCH_MOVE_DATA = 'upArchMovement.data'; ///
// export const TOOTH_1_MTC = 'tooth1.mtc'; ///
// export const TOOTH_2_MTC = 'tooth2.mtc'; ///
export const REMARKS = "Remarks.json"; // 可忽略文件
export const PHOTOJSON = "photo.json"; // 可忽略文件

// export const ARCH_L_MTC = 'arch_l.mtc'; ///
// export const MTC_SIZE = 32;
/**
 *牙齿信息archTeeth_u.teethdata
 *牙齿信息teethProperty_u.data
 *牙齿特征点ToothFeatureInfo_u.data
 */
export const ARCH_TEETH_U = "archTeeth_u.teethdata";
export const ARCH_TEETH_L = "archTeeth_l.teethdata";
export const TEETH_PROPERTY_U = "teethProperty_u.data";
export const TEETH_PROPERTY_L = "teethProperty_l.data";
export const TOOTH_FEATURE_INFO_U = "ToothFeatureInfo_u.data";
export const TOOTH_FEATURE_INFO_D = "ToothFeatureInfo_d.data";

export const ARCH_BEFORE_SEGMENT_L = "arch_beforeSegment_l.mtc"; ///
export const ARCH_BEFORE_SEGMENT_U = "arch_beforeSegment_u.mtc"; ///

export const ARCH_L = "arch_l.mtc"; ///
export const ARCH_U = "arch_u.mtc"; ///

export const LABEL_INFO_D = "labelInfo_d.data"; ///
export const LABEL_INFO_U = "labelInfo_u.data"; ///

export const TEETH_POINT_TYPE_L = "teethPointType_l.data";
export const TEETH_POINT_TYPE_U = "teethPointType_u.data";

export const TEETH_GUM_LINES_U = "teethgumlines_up.txt";
const TEETH_GUM_LINES_L = "teethgumlines_down.txt";
// export const TOOTH_LOCAL_XFM = "ToothLocalXFM.dat";
// export const TOOTH_PULLOUT_FLAG = 'ToothPullOutFlag.dat';

export const ARCH_TYPE_D = "archType_d.archtype"; ///
export const ARCH_TYPE_U = "archType_u.archtype"; ///

export const MODULE_CONTROL = "ModuleControl.data"; ///
export const DATA_VERSION = "DataVersion.txt"; /// //不管是否标识必须存在

// export const TOOTH_X = 'toothX.mtc(X=No)';
export const IPR_DATA = "IPR_data.txt"; // 可忽略文件
export const IPR_DATA_1 = "IPRData_1.txt"; // 可忽略文件
export const IPR_DATA_2 = "IPRData_2.txt"; // 可忽略文件
export const IDB_DATA = "idb.data"; // 可忽略文件
export const ATTACHMENT_STL = "AttachmentLib"; // 可忽略文件
export const ENVIRONMENT = "EnvironmentLight.jpg"; // 可忽略文件
export const IDB_STL = "A.stl"; // 可忽略文件
export const REGISTER_STL = "buttons"; // 可忽略文件
export const IDB_PITTS = "Wu (Pitts).json"; // 可忽略文件
/**
 *
 * Retouch特有
 */
export const RETOUCH_PATHLIST = "pathList.txt";
export const RETOUCH_HISTORY = "Setting Data10/RetouchHistory";
export const RETOUCH_DOWN_STAGE_FILE = "DownArchCustomStaging.dat"; // 可忽略文件
export const RETOUCH_UP_STAGE_FILE = "UpArchCustomStaging.dat"; // 可忽略文件
export const RETOUCH_UP_FEATURE_INFO = "ToothFeatureInfo_u.data";
export const RETOUCH_D_FEATURE_INFO = "ToothFeatureInfo_d.data";
export const TOOTH_UPLOCAL_XFM = "UpArchToothLocalXFM.dat"; // 上颌标识文件
export const TOOTH_DOWNLOCAL_XFM = "DownArchToothLocalXFM.dat"; // 下颌标识文件
export const TOOTH_UPOLD_INITIAL = "UpArchToothTransFromOldToNewInitial.dat";
export const TOOTH_DownOLD_INITIAL =
  "DownArchToothTransFromOldToNewInitial.dat";

/**
 * wasm需求D2
 */
// export const D2_INFO = 'info';
export const SHAPE_PARAS = "ShapeAnalysisCompensationParas.txt";
export const LABEINFO_U = "labelInfo_u.data";
export const LABEINFO_D = "labelInfo_d.data";
export const UP_ARCH_STAGING_RESULT_1 = "UpArchStagingResult_1.dat";
export const DOWN_ARCH_STAGING_RESULT_1 = "DownArchStagingResult_1.dat";
// export const ARCH_TYPE = 'ArchType.json';
export const STAGING_FINAL_TRANS_UP = "staging_final_trans_up.atm";
export const STAGING_FINAL_TRANS_DOWN = "staging_final_trans_down.atm";
export const GDSINITINFO = "GDSInitInfo";
export const ITINFODATA = "info.dat";
export const MODIFICATION = "modification.json";
export const TMP_ELASTICCONTROLPOINTS = "tmp_elasticControlPoints.json";
export const LOWERARCHCUSTOM = "LowerArchCustomResult_1.dat";
export const UPERARCHCUSTOM = "UpArchCustomResult_1.dat";
/**
 * wasm需求D1
 */
export const ZIPINFO_D = "zipInfo_d.data";
export const ZIPINFO_u = "zipInfo_u.data";
export const TEETH_GUM_LINES_DOWN = "teethgumlines_down.txt";
export const TEETH_GUM_LINES_UP = "teethgumlines_up.txt";
export const CUSTOM_STAG_EXIST = "CustomStagingExist.dat";
/**
 * wasm需求
 */
export const STORAGE = "storage.version.json";

// export const COMMDATA = {
//   [LOWERARCHCUSTOM]: {
//     name: ['storage'],
//     reqType: 'blob',
//     setting: SETING_DATA2,
//   },
//   [UPERARCHCUSTOM]: {
//     name: ['storage'],
//     reqType: 'blob',
//     setting: SETING_DATA2,
//   },
//   [TMP_ELASTICCONTROLPOINTS]: {
//     name: ['storage'],
//     reqType: 'json',
//     setting: SETING_DATA2,
//   },
//   [MODIFICATION]: {
//     name: ['storage'],
//     reqType: 'json',
//     setting: SETING_DATA2,
//   },
//   [ITINFODATA]: {
//     name: ['storage'],
//     reqType: 'blob',
//     setting: SETING_DATA2,
//   },
//   [GDSINITINFO]: {
//     name: ['storage'],
//     reqType: 'blob',
//     setting: SETING_DATA2,
//   },
//   [MODULE_CONTROL]: {
//     name: ['storage'],
//     reqType: 'blob',
//     setting: SETING_DATA2,
//   },
//   [ARCH_TYPE_D]: {
//     name: ['storage'],
//     reqType: 'blob',
//     setting: SETING_DATA10,
//   },
//   [ARCH_TYPE_U]: {
//     name: ['storage'],
//     reqType: 'blob',
//     setting: SETING_DATA10,
//   },
//   [LABEL_INFO_D]: {
//     name: ['storage'],
//     reqType: 'blob',
//     setting: SETING_DATA2,
//   },
//   [LABEL_INFO_U]: {
//     name: ['storage'],
//     reqType: 'blob',
//     setting: SETING_DATA2,
//   },
//   [ARCH_BEFORE_SEGMENT_L]: {
//     name: ['storage'],
//     reqType: 'blob',
//     setting: SETING_DATA0,
//   },
//   [ARCH_BEFORE_SEGMENT_U]: {
//     name: ['storage'],
//     reqType: 'blob',
//     setting: SETING_DATA0,
//   },
//   [STORAGE]: {
//     name: ['storage'],
//     reqType: 'json',
//     setting: SETING_DATA,
//   },
//   [CUSTOM_STAG_EXIST]: {
//     name: ['customstagexist'],
//     reqType: 'blob',
//     setting: SETING_DATA1,
//   },
//   [TEETH_GUM_LINES_UP]: {
//     name: ['gumlinesu'],
//     reqType: 'blob',
//     setting: SETING_DATA1,
//   },
//   [TEETH_GUM_LINES_DOWN]: {
//     name: ['gumlinesd'],
//     reqType: 'blob',
//     setting: SETING_DATA1,
//   },
//   [ZIPINFO_D]: {
//     name: ['zipinfd'],
//     reqType: 'blob',
//     setting: SETING_DATA1,
//   },
//   [ZIPINFO_u]: {
//     name: ['zipinfu'],
//     reqType: 'blob',
//     setting: SETING_DATA1,
//   },
//   [STAGING_FINAL_TRANS_UP]: {
//     name: ['stagingfinaltransup'],
//     reqType: 'blob',
//     setting: SETING_DATA2,
//   },
//   [STAGING_FINAL_TRANS_DOWN]: {
//     name: ['stagingfinaltransdown'],
//     reqType: 'blob',
//     setting: SETING_DATA2,
//   },
//   [DOWN_ARCH_STAGING_RESULT_1]: {
//     name: ['DownArchStagingResult1'],
//     reqType: 'blob',
//     setting: SETING_DATA2,
//   },
//   [UP_ARCH_STAGING_RESULT_1]: {
//     name: ['UpArchStagingResult1'],
//     reqType: 'blob',
//     setting: SETING_DATA2,
//   },
//   [LABEINFO_D]: {
//     name: ['labelInfod'],
//     reqType: 'blob',
//     setting: SETING_DATA2,
//   },
//   [LABEINFO_U]: {
//     name: ['labelInfou'],
//     reqType: 'blob',
//     setting: SETING_DATA2,
//   },
//   [SHAPE_PARAS]: {
//     name: ['ShapeAnalysisCompensationParas'],
//     reqType: 'blob',
//     setting: SETING_DATA2,
//   },

//   [IPR_DATA]: {
//     name: ['iprData'],
//     reqType: 'blob',
//     setting: SETING_DATA2,
//   },
//   [IDB_DATA]: {
//     name: ['idbData'],
//     reqType: 'blob',
//     setting: SETING_DATA2,
//   },
//   [IPR_DATA_1]: {
//     name: ['iprData1'],
//     reqType: 'blob',
//     setting: SETING_DATA2,
//   },
//   [IPR_DATA_2]: {
//     name: ['iprData2'],
//     reqType: 'blob',
//     setting: SETING_DATA2,
//   },
//   [GUM_L_MTC]: {
//     name: ['modelFiles'],
//     reqType: 'blob',
//     setting: SETING_DATA1,
//   },
//   [GUM_U_MTC]: {
//     name: ['modelFiles'],
//     reqType: 'blob',
//     setting: SETING_DATA1,
//   },
//   [ATTACHMENT_INFO_UP]: {
//     name: ['attachmentsInfo', 'up'],
//     reqType: 'blob',
//     setting: SETING_DATA2,
//   },
//   [ATTACHMENT_INFO_DOWN]: {
//     name: ['attachmentsInfo', 'down'],
//     reqType: 'blob',
//     setting: SETING_DATA2,
//   },
//   [ATTACHMENT_INFO_UP_2]: {
//     name: ['attachmentsInfo2', 'up'],
//     reqType: 'blob',
//     setting: SETING_DATA2,
//   },
//   [ATTACHMENT_INFO_DOWN_2]: {
//     name: ['attachmentsInfo2', 'down'],
//     reqType: 'blob',
//     setting: SETING_DATA2,
//   },
//   [UP_ARCH_CUSTOM_STAGING_1]: {
//     name: ['archstaging', 'uparchstage'],
//     reqType: 'blob',
//     setting: SETING_DATA2,
//   },
//   [DOWN_ARCH_CUSTOM_STAGING_1]: {
//     name: ['archstaging', 'donwarchstage'],
//     reqType: 'blob',
//     setting: SETING_DATA2,
//   },
//   [UP_ARCH_CUSTOM_STAGING_2]: {
//     name: ['archstaging2', 'uparchstage'],
//     reqType: 'blob',
//     setting: SETING_DATA2,
//   },
//   [DOWN_ARCH_CUSTOM_STAGING_2]: {
//     name: ['archstaging2', 'donwarchstage'],
//     reqType: 'blob',
//     setting: SETING_DATA2,
//   },
//   [TOOTH_LOCAL_XFM]: {
//     name: ['localxfm'],
//     reqType: 'blob',
//     setting: SETING_DATA2,
//   },
//   [TOOTH_LOCAL_GUMMODEL_L]: {
//     name: ['gumModels', 'gumModels_l'],
//     reqType: 'blob',
//     setting: SETING_DATA1,
//   },
//   [TOOTH_LOCAL_GUMMODEL_U]: {
//     name: ['gumModels', 'gumModels_u'],
//     reqType: 'blob',
//     setting: SETING_DATA1,
//   },
//   [TEETH_GUM_LINES_U]: {
//     name: ['teethgumlines_up'],
//     reqType: 'text',
//     setting: SETING_DATA1,
//   },
//   // [LABEINFO_D]: {
//   //   name: ['teethgumlines_down'],
//   //   reqType: 'text',
//   //   setting: SETING_DATA1,
//   // },
//   [TOOTH_LOCAL_ARCHMODEL_U]: {
//     name: ['archModels'],
//     reqType: 'blob',
//     setting: SETING_DATA0,
//   },
//   [TOOTH_LOCAL_ARCHMODEL_L]: {
//     name: ['archModels'],
//     reqType: 'blob',
//     setting: SETING_DATA0,
//   },
//   [TOOTH_LOCAL_DATAVERSION]: {
//     name: ['dataVersion'],
//     reqType: 'text',
//     setting: SETING_DATA2,
//   },
//   [TOOTH_LOCAL_SHIELDMODEL_L]: {
//     name: ['shield', 'shield_l'],
//     reqType: 'blob',
//     setting: SETING_DATA1,
//   },
//   [TOOTH_LOCAL_SHIELDMODEL_U]: {
//     name: ['shield', 'shield_u'],
//     reqType: 'blob',
//     setting: SETING_DATA1,
//   },
//   [TOOTH_LOCAL_PULLOUT]: {
//     name: ['pullOut'],
//     reqType: 'blob',
//     setting: SETING_DATA2,
//   },
//   [REMARKS]: {
//     name: ['remarks'],
//     reqType: 'json',
//     setting: SETING_DATA2,
//   },
//   [PHOTOJSON]: {
//     name: ['photoJSON'],
//     reqType: 'json',
//     setting: SETING_DATA2,
//   },
//   [ARCH_TYPE_INFO]: {
//     name: ['archType'],
//     reqType: 'json',
//     setting: SETING_DATA2,
//   },
//   [ARCH_TEETH_U]: {
//     name: ['archTeeth_u'],
//     reqType: 'blob',
//     setting: SETING_DATA1,
//   },
//   [ARCH_TEETH_L]: {
//     name: ['archTeeth_l'],
//     reqType: 'blob',
//     setting: SETING_DATA1,
//   },
//   [TEETH_PROPERTY_U]: {
//     name: ['teethProperty_u'],
//     reqType: 'blob',
//     setting: SETING_DATA2,
//   },
//   [TEETH_PROPERTY_L]: {
//     name: ['teethProperty_l'],
//     reqType: 'blob',
//     setting: SETING_DATA2,
//   },
//   [TOOTH_FEATURE_INFO_U]: {
//     name: ['toothFeatureInfo_u'],
//     reqType: 'blob',
//     setting: SETING_DATA2,
//   },
//   [TOOTH_FEATURE_INFO_D]: {
//     name: ['toothFeatureInfo_d'],
//     reqType: 'blob',
//     setting: SETING_DATA2,
//   },
//   [TEETH_POINT_TYPE_L]: {
//     name: ['teethPointType_l'],
//     reqType: 'blob',
//     setting: SETING_DATA2,
//   },
//   [TEETH_POINT_TYPE_U]: {
//     name: ['teethPointType_u'],
//     reqType: 'blob',
//     setting: SETING_DATA2,
//   },
// /**
//  * register static resource of attachments
//  */
// [ATTACHMENT_STL]: {
//   name: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 24, 26,27,28],
//   reqType: 'blob',
//   setting: `./testcasedata/AttachmentLib`,
// },
// [IDB_STL]: {
//   name: [
//     '946.2001 UR1 12 5 0_Rev ',
//     '946.2002 UL1 12 5 0_Rev ',
//     '946.2003 UR2 6 9 0_Rev ',
//     '946.2004 UL2 6 9 0_Rev ',
//     '946.2007 UR3 HK 9 5 0_Rev ',
//     '946.2008 UL3 HK 9 5 0_Rev ',
//     '946.2011 UR4-5 HK -11 2 0_Rev ',
//     '946.2012 UL4-5 HK -11 2 0_Rev ',
//     '946.2013 L1-2 -6 0 0_Rev ',
//     '946.2016 LR3 HK 9 5 0_Rev ',
//     '946.2017 LL3 HK 9 5 0_Rev ',
//     '946.2020 LR4 HK -12 2 0_Rev ',
//     '946.2021 LL4 HK -12 2 0_Rev ',
//     '946.2024 LR5 HK -17 2 0_Rev ',
//     '946.2025 LL5 HK -17 2 0_Rev ',
//     '947.2101 UR6-7 HK 21 -27 0 9_Rev ',
//     '947.2102 UL6-7 HK 21 -27 0 9_Rev ',
//     '947.2107 LR6-7 HK 21 -22 0 3_Rev ',
//     '947.2108 LL6-7 HK 21 -22 0 3_Rev ',
//   ],
//   reqType: 'blob',
//   setting: `./testcasedata/idb`,
// },
// /**
//  * register static resource of UI stl files
//  */
// [REGISTER_STL]: {
//   name: [
//     'sanjiao1', // 小三角
//     'sanjiao2', // 小三角的边框
//     'sanjiao3', // 梯形
//     'sanjiao4', // 大三角的边框
//     'yuanhu4', // 圆弧
//     'yuanhuleft1',
//     'yuanhuleft2',
//     'yuanhuleft3',
//     'yuanhuleft4',
//     'yuanhuright1',
//     'yuanhuright2',
//     'yuanhuright3',
//     'yuanhuright4',
//     'translate',
//     'Delete',
//     'cone',
//     'torus',
//   ],
//   reqType: 'blob',
//   setting: `./testcasedata/buttons`,
// },
// [IDB_PITTS]: {
//   name: ['pitts'],
//   reqType: 'json',
//   setting: './testcasedata/idb/',
// },
// };

export const LOCALCOMMDATA = {
  /**
   * register static resource of attachments
   */
  [ATTACHMENT_STL]: {
    name: [
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "10",
      "11",
      "12",
      "13",
      "14",
      "15",
      "16",
      "17",
      "18",
      "19",
      "20",
      "24",
      "26",
      "27",
      "28",
      "103Dbutton",
      "103DbuttonMirror",
      "183Dbutton",
      "183DbuttonMirror",
      "cake",
      "EquTriangle",
      "vertical3",
      "vertical4",
      "vertical5",
    ],
    reqType: "blob",
    setting: `./AttachmentLib`,
  },
  /**
   * register static resource of UI stl files
   */
  [REGISTER_STL]: {
    name: [
      "rotate3_5_left",
      "rotate3_5_right",
      "rotate5_left",
      "rotate5_right",
      "translate",
      "translate_sphere",
    ],
    reqType: "blob",
    setting: `./buttons`,
  },
  [ENVIRONMENT]: {
    name: [0],
    reqType: "blob",
    setting: `./`,
  },
  // [IDB_STL]: {
  //   name: [
  //     '946.2001 UR1 12 5 0_Rev ',
  //     '946.2002 UL1 12 5 0_Rev ',
  //     '946.2003 UR2 6 9 0_Rev ',
  //     '946.2004 UL2 6 9 0_Rev ',
  //     '946.2007 UR3 HK 9 5 0_Rev ',
  //     '946.2008 UL3 HK 9 5 0_Rev ',
  //     '946.2011 UR4-5 HK -11 2 0_Rev ',
  //     '946.2012 UL4-5 HK -11 2 0_Rev ',
  //     '946.2013 L1-2 -6 0 0_Rev ',
  //     '946.2016 LR3 HK 9 5 0_Rev ',
  //     '946.2017 LL3 HK 9 5 0_Rev ',
  //     '946.2020 LR4 HK -12 2 0_Rev ',
  //     '946.2021 LL4 HK -12 2 0_Rev ',
  //     '946.2024 LR5 HK -17 2 0_Rev ',
  //     '946.2025 LL5 HK -17 2 0_Rev ',
  //     '947.2101 UR6-7 HK 21 -27 0 9_Rev ',
  //     '947.2102 UL6-7 HK 21 -27 0 9_Rev ',
  //     '947.2107 LR6-7 HK 21 -22 0 3_Rev ',
  //     '947.2108 LL6-7 HK 21 -22 0 3_Rev ',
  //   ],
  //   reqType: 'blob',
  //   setting: `./testcasedata/idb`,
  // },
  // [IDB_PITTS]: {
  //   name: ['pitts'],
  //   reqType: 'json',
  //   setting: './testcasedata/idb/',
  // },
};

export const FS_BASEPATH = "/test/case";

export const FS_MKDIR = [
  "/test",
  `/pre`,
  `/pre/orien`,
  `/test/pre`,
  `/test/resource`,
  `/test/resource/FeatureAdjButton`,
  `/test/resource/AttachmentLib`,
  `/test/resource/buttons`,
  `/test/resource/buttons/AutoRemovalPngs`,
  `/test/resource/toothModels`,
  `/test/resource/AppParameterSettings`,
  `/test/resource/AppParameterSettings/StandardTeeth`,
  `/test/resource/AppParameterSettings/StandardTeeth/Raw`,
  `/test/resource/AppParameterSettings/StandardTeeth/Photo`,
  `/test/resource/AppParameterSettings/StandardTeeth/Setting Data0`,
  `/test/resource/AppParameterSettings/StandardTeeth/Setting Data1`,
  `/test/resource/AppParameterSettings/StandardTeeth/Setting Data2`,
  `/test/resource/AppParameterSettings/StandardTeeth/Setting Data2/BackupFiles`,
  `/test/resource/AppParameterSettings/StandardTeeth/Setting Data10`,


  `/test/resource/BiteRampLib`,
  `/test/resource/Pictures`,
  `/test/resource/DomeLib`,
  `/test/case`,
  `/test/case/Setting Data0`,
  `/test/case/Setting Data1`,
  `/test/case/Setting Data2`,
  `/test/case/Setting Data2/LogDir`,
  `/test/case/Setting Data2/IPRscreenshots`,
  `/test/case/Setting Data2/biteRampData`,
  `/test/case/Setting Data2/biteRampData/InfoData0`,
  `/test/case/Setting Data2/biteRampData/InfoData1`,
  `/test/case/Setting Data2/PonticLib`,
  `/test/case/Setting Data2/PonticLib/MirrorToothLib`,
  `/test/case/Setting Data2/PonticLib/StandardToothLib`,

  `/test/case/Setting Data10`,
  `/test/case/Setting Data10/refinement`,
  `/test/case/Raw`,
  `/test/case/Photo`,
];

export const ELASTIC_CONTROL_POINTS_JSON = `${SETING_DATA2}/elasticControlPoints.json`;

export const SCANS_PHOTO = [
  `/pre/orien/snap_both.png`,
  `/pre/orien/snap_l.png`,
  `/pre/orien/snap_u.png`,
];
export const SCREEN_SHOT = [
  `/test/case/Setting Data2/initStageActor.png`,
  `/test/case/Setting Data2/finalStageActor.png`,
];
export const FS_FILEZIP = ["bulk0", `bulk1`, `bulk2`, `bulk10`, `raw`, `photo`];
export const DOWNLOAD_FILEZIP = ["bulk0", `bulk1`, `bulk2`, `bulk10`];
export const FS_RESOURCE_TO_CASE = [
  "resource/Setting Data0",
  `resource/Setting Data1`,
  `resource/Setting Data2`,
  `resource/Setting Data10`,
  `resource/Raw`,
  `resource/Photo`,
];
export const FS_RESOURCE_TO_CASEFILE = ["/test/resource/storage.version.json"];
