import React, { FC, useEffect, useMemo, useRef, useState } from "react"
import { FieldValues, SubmitHandler, useForm } from "react-hook-form"
import { Trans, useTranslation } from "react-i18next"

import { FormInput, UButton, UText } from "../../../components"
import {
  Box,
  Button,
  Divider,
  Grid,
  TextField,
} from "../../../components/mui.components"
import { useAppDispatch, useAppSelector } from "../../../core/app/hooks"
import { setAlert } from "../../../core/app/slices/alert/alertSlice"
import { resetSchema } from "../../../core/app/slices/uassist/formSlice"
import {
  fetchUserProfile,
  updateUserProfile,
} from "../../../core/app/slices/user/userApis"
import { UI_PERMISSION } from "../../../core/utils/access/permissionConstants"
import usePermissions from "../../../hooks/userPermission"
import { getContactInfo } from "../../contact.config"

import PaymentDetails from "./paymentDetails"
import ResetPassword from "./resetPassword"
import UploadProfilePic from "./uploadProfilePic"

export const renderUserInfoSection = (label: string, value: string) => {
  return (
    <Box>
      <UText
        variant={"body1"}
        component={"div"}
        sxProp={{ color: "text.secondary", pb: "4px", pt: 3 }}
      >
        {label}
      </UText>
      <UText variant={"body1"} component={"div"} sxProp={{ color: "#000" }}>
        {value}
      </UText>
    </Box>
  )
}
const UserProfile: FC = () => {
  const { hasAny } = usePermissions()
  const { t } = useTranslation("common")
  const {
    control,
    formState: { errors },
    getFieldState,
    trigger,
    setValue,
    watch,
    handleSubmit,
    getValues,
  } = useForm({ mode: "onSubmit" })

  const [editUserInfo, setEditUserInfo] = useState<boolean>(false)
  const dispatch = useAppDispatch()
  const { userProfile, loading, countryCode, countries, paymentInfo } =
    useAppSelector((state) => state.userService)

  const contactInfo = useMemo(() => getContactInfo(countryCode), [countryCode])

  useEffect(() => {
    dispatch(resetSchema())
    dispatch(fetchUserProfile())
  }, [])

  useEffect(() => {
    setValue("firstName", userProfile?.firstName)
    setValue("lastName", userProfile?.lastName)
  }, [userProfile])

  const onSubmit: SubmitHandler<FieldValues> = (data: any) => {
    dispatch(
      updateUserProfile({
        first_name: data.firstName,
        last_name: data.lastName,
      }),
    ).then(() => {
      if (loading === "succeeded") {
        dispatch(setAlert({ message: t("userProfile.createSuccess") }))
        setEditUserInfo(false)
      } else if (loading === "failed") {
        dispatch(setAlert({ message: t("userProfile.createFailed") }))
      }
    })
  }

  const renderUserInformation = () => {
    if (!editUserInfo) {
      return (
        <>
          {renderUserInfoSection(
            t("userProfile.userDettail.name"),
            `${userProfile?.firstName} ${userProfile?.lastName}`,
          )}
        </>
      )
    } else {
      return (
        <>
          <UText
            variant={"body1"}
            component={"div"}
            sxProp={{ color: "text.secondary", pt: 3 }}
          >
            {t("userProfile.userDettail.name")}
          </UText>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              gap: "8px",
            }}
          >
            <FormInput
              formSxprops={{ mt: 2, mb: 0 }}
              inputLabel={"First"}
              fieldName={"firstName"}
              fieldType={"text"}
              rules={{ required: "formfieldErrors.firstnamerequired" }}
              onBlurChange={() => {
                if (watch("firstName").replace(/\s/g, "") === "") {
                  setValue("firstName", "")
                }
              }}
              errors={errors}
              control={control}
            />
            <FormInput
              formSxprops={{ mt: 2, mb: 0 }}
              inputLabel={"Last"}
              fieldName={"lastName"}
              fieldType={"text"}
              rules={{ required: "formfieldErrors.larstnamerequired" }}
              errors={errors}
              control={control}
            />
          </Box>
        </>
      )
    }
  }

  return (
    <Grid sx={{ width: "600px", p: 4 }}>
      <UText
        variant={"h4"}
        component={"div"}
        sxProp={{ textAlign: "center", pb: 2 }}
      >
        {t("userProfile.title")}
      </UText>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <UText
          variant={"h6"}
          component={"div"}
          sxProp={{ textAlign: "center", pb: 1 }}
        >
          {t("userProfile.info")}
        </UText>
        {hasAny(UI_PERMISSION.UI_PROFILE_UPDATE) && !editUserInfo && (
          <Button
            variant={"text"}
            sx={{
              minWidth: "auto",
              height: "auto",
              width: "auto",
              lineHeight: "22px",
              py: "4px",
              px: "5px",
            }}
            onClick={() => setEditUserInfo(true)}
          >
            {t("userProfile.edit")}
          </Button>
        )}
      </Box>
      <Box component={"form"} onSubmit={handleSubmit(onSubmit)}>
        <UploadProfilePic
          editUserInfo={editUserInfo}
          setEditUserInfo={setEditUserInfo}
        />
        {renderUserInformation()}
        {renderUserInfoSection(
          t("userProfile.userDettail.username"),
          userProfile?.username as string,
        )}
        {renderUserInfoSection(
          t("userProfile.userDettail.company"),
          userProfile?.org_name as string,
        )}
        {renderUserInfoSection(
          t("userProfile.userDettail.type"),
          userProfile?.user_type as string,
        )}
        {hasAny(UI_PERMISSION.UI_PROFILE_UPDATE) && editUserInfo && (
          <Box sx={{ display: "flex", gap: "8px", mt: 3 }}>
            <UButton
              variant={"outlined"}
              btnText={t("button.cancel")}
              key={"yes"}
              sxProp={{ minWidth: 58, height: 36 }}
              onClickHandler={() => {
                setValue("firstName", userProfile?.firstName)
                setValue("lastName", userProfile?.lastName)
                setEditUserInfo(false)
              }}
            />
            <UButton
              variant={"contained"}
              btnText={t("button.save")}
              btnType={"submit"}
              key={"no"}
              sxProp={{ minWidth: 54, height: 36 }}
            />
          </Box>
        )}
      </Box>
      {hasAny(UI_PERMISSION.UI_PROFILE_UPDATEPASSWORD) && (
        <>
          <Divider sx={{ my: 4 }} />
          <Password />
        </>
      )}
      {paymentInfo?.status !== "NO_CC_PROMPT" &&
        hasAny(UI_PERMISSION.UI_PROFILE_VIEWPAYMENT) && (
          <>
            <Divider sx={{ my: 4 }} />
            <PaymentDetails />
          </>
        )}
      <Divider sx={{ my: 4 }} />
      <Box>
        <UText variant={"h6"} component={"div"}>
          {t("userProfile.lang")}
        </UText>
        <Box>
          <UText
            variant={"body1"}
            component={"div"}
            sxProp={{ color: "text.secondary", pb: "4px", pt: 1 }}
          >
            {t("userProfile.prefLang")}
          </UText>
          <UText variant={"body1"} component={"div"} sxProp={{ color: "#000" }}>
            {t("userProfile.eng")}
          </UText>
        </Box>
        {renderUserInfoSection(
          t("userProfile.cn"),
          countries &&
            countries.find(
              (country) => country.code === countryCode && countryCode,
            )?.label,
        )}
      </Box>
      <UText
        color="text.secondary"
        variant={"body2"}
        component={"div"}
        sxProp={{ mt: 3 }}
      >
        <Trans
          values={{
            phone: contactInfo.phone,
          }}
        >
          {"userProfile.userNote"}
        </Trans>
      </UText>
    </Grid>
  )
}

const Password: FC = () => {
  const { t } = useTranslation("common")
  const [editPassword, setEditPassword] = useState<boolean>(false)

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <UText
          variant={"h6"}
          component={"div"}
          sxProp={{ textAlign: "center" }}
        >
          {t("userProfile.password")}
        </UText>
        {!editPassword && (
          <Button
            variant={"text"}
            sx={{
              minWidth: "auto",
              height: "auto",
              width: "auto",
              lineHeight: "22px",
              py: "4px",
              px: "5px",
            }}
            onClick={() => setEditPassword(true)}
          >
            {t("userProfile.edit")}
          </Button>
        )}
      </Box>
      {editPassword && <ResetPassword setEditPassword={setEditPassword} />}
    </>
  )
}

export default UserProfile
