import { ChangeEvent, FC, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import {
  Box,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
} from "../../../../components/mui.components"
import { useAppDispatch, useAppSelector } from "../../../../core/app/hooks"
import { updateSelectedShippingMethode } from "../../../../core/app/slices/order"
import { RootState } from "../../../../core/app/store"
import { formatCurrency } from "../../../../core/utils/formatters"
import { UText } from "../../../../ui-component"
import Banner from "../../../Patient/Overview/components/banner"

export interface IDelivery {
  title: string
  text1: string
  text2: string
  text3: string
  amount: string
}

export interface IDeliveryServiceProps {
  isReviewOrder: boolean
}

const DeliveryService: FC<IDeliveryServiceProps> = ({ isReviewOrder }) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const { countryCode } = useAppSelector(
    (state: RootState) => state.userService,
  )
  const [deliveryService, setDeliveryService] = useState([])
  const { caseDetail } = useAppSelector((state: RootState) => state.caseService)
  const {
    shippingMethod,
    selectedBundle,
    currentOrder,
    alignerOrderPayload,
    shippingMethods,
  } = useAppSelector((state: RootState) => state.orderService)

  useEffect(() => {
    console.log("...shippingMethods..", shippingMethods)
    if (shippingMethods) {
      if (isReviewOrder) {
        const obj = shippingMethods.filter(
          (o) => o.type?.toLowerCase() === shippingMethod?.toLowerCase(),
        )
        setDeliveryService(obj)
      } else {
        setDeliveryService(shippingMethods)
        if (shippingMethods.length === 1) {
          dispatch(updateSelectedShippingMethode(shippingMethods[0]?.type))
        }
      }
    }
  }, [shippingMethods])

  const handleDeliveryServiceChange = (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    dispatch(
      updateSelectedShippingMethode((event.target as HTMLInputElement).value),
    )
  }
  const getShippingPrice = (price: number): string => {
    return price === 0
      ? "Included"
      : formatCurrency(price, currentOrder.currency, countryCode)
  }

  const checkBundleLimit = () => {
    const remainingCount = selectedBundle?.description

    if (!remainingCount) {
      return false
    }

    const alignerPayload = alignerOrderPayload?.stage.aligners
    const retainerPayload = alignerOrderPayload?.stage.retainers
    const templatesPayload = alignerOrderPayload?.stage.templates

    if (
      remainingCount.aligner?.included - remainingCount.aligner?.consumed <
        alignerPayload.up.length + alignerPayload.low.length ||
      remainingCount.retainer?.included - remainingCount.retainer?.consumed <
        retainerPayload.up + retainerPayload.low ||
      remainingCount.retainer?.included - remainingCount.retainer?.consumed <
        templatesPayload.up + templatesPayload.low
    ) {
      return true
    } else {
      return false
    }
  }

  const showWarningBanner = () => {
    if (
      (isReviewOrder &&
        currentOrder?.alacarte?.pricing_option &&
        currentOrder?.bundle?.pricing_option) ||
      (!isReviewOrder && selectedBundle && checkBundleLimit())
    ) {
      return true
    } else {
      return false
    }
  }

  return (
    <Paper elevation={0} sx={{ padding: "16px", borderRadius: 4 }}>
      <Box>
        <UText component={"div"} variant={"subtitle2"} sxProp={{ mb: 2 }}>
          {t("overview.order.shippingPacking.deliveryService")}
        </UText>
        <RadioGroup
          aria-labelledby="shipping_method-group"
          name="shipping_method"
          onChange={handleDeliveryServiceChange}
        >
          {deliveryService.length > 0 &&
            deliveryService.map((item) => (
              <Box
                key={item.id}
                sx={{ borderBottom: "1px solid #E0E0E0", pb: 2 }}
              >
                <Box sx={{ mx: isReviewOrder ? 2 : 3 }}>
                  <Grid container sx={{ alignItems: "center" }}>
                    <Grid item md={10}>
                      {!isReviewOrder ? (
                        <Box>
                          <FormControlLabel
                            value={item.type}
                            checked={
                              shippingMethod?.toLowerCase() ===
                              item.type.toLowerCase()
                            }
                            sx={{
                              "> .MuiFormControlLabel-label": {
                                fontWeight: "400 !important",
                              },
                            }}
                            control={<Radio />}
                            label={item.type}
                          />
                        </Box>
                      ) : (
                        <Box>{item.type}</Box>
                      )}
                    </Grid>
                    <Grid
                      item
                      md={2}
                      sx={{
                        display: "flex",
                        justifyContent: "end",
                      }}
                    >
                      <UText sxProp={{ p: "12px" }} variant={"subtitle2"}>
                        {getShippingPrice(Number(item.price))}
                      </UText>
                    </Grid>
                  </Grid>
                  <Box
                    sx={{
                      color: "#464646",
                      fontSize: "12px",
                      ml: isReviewOrder ? 1 : 5,
                      mt: !isReviewOrder ? "-10px" : "0px",
                    }}
                  >
                    <li>
                      {t("overview.order.shippingPacking.deliverySubTex", {
                        week: item.schedule_patient_weeks,
                      })}
                    </li>
                  </Box>
                </Box>
              </Box>
            ))}
        </RadioGroup>
        <Box sx={{ mt: 2 }}>
          <UText variant={"caption"} sxProp={{ color: "text.secondary" }}>
            {t("overview.order.shippingPacking.deliveryNote")}
          </UText>
        </Box>
        {showWarningBanner() && (
          <Box sx={{ mt: 2 }}>
            <Banner
              severity="warning"
              bannerContent={t("bundle.banner.seperateShipmentsWarning")}
            />
          </Box>
        )}
        {(countryCode === "AU" || countryCode === "NZ") && (
          <Box
            sx={{
              mt: 2,
              ".MuiAlert-root": {
                alignItems: "flex-start",
              },
              ".MuiAlert-icon": {
                mt: "2px",
              },
            }}
          >
            <Banner
              severity="warning"
              bannerContent={t("bundle.banner.anzShippingSurage")}
            />
          </Box>
        )}
      </Box>
    </Paper>
  )
}

export default DeliveryService
