import { useEffect, useState } from "react"
import { ReactSVG } from "react-svg"
import CreateCaseIcon from "@mui/icons-material/AddCircleOutline"
import BoltonIcon from "../assets/left/ruler.svg"
import RxIcon from '../assets/left/rx.svg';
import InsertPhoto from "@mui/icons-material/InsertPhoto"
import ChatIcon from '@mui/icons-material/Chat';
import PhotoIcon from "@mui/icons-material/PhotoOutlined"
import LRPhotosIcon from "@mui/icons-material/PhotoAlbum"
import LRStlIcon from "@mui/icons-material/AddOutlined"
import ReportIcon from "@mui/icons-material/PictureAsPdf"
import ArticleIcon from '@mui/icons-material/Article';
import AssignmentIcon from '@mui/icons-material/Assignment';
import TableChartIcon from '@mui/icons-material/TableChart';
import EditCalendarOutlinedIcon from "@mui/icons-material/EditCalendarOutlined"
import {
  Badge,
  Box,
  ToggleButton,
  Paper,
  Stack,
  ToggleButtonGroup,
  Tooltip,
} from "@mui/material"

import { useAppDispatch, useAppSelector } from "@/core/app/hooks"
import { RootState } from "@/core/app/store"

import Tooth from "../assets/left/tooth-outline.svg"
import { setCurLeftPage } from "../udTreatSlice"
import { useTheme } from "@mui/material/styles"
import { setSmartRxShow } from "../udTreatSlice"
export function LeftToolBar(props: any) {
  const theme = useTheme()
  const {
    isCasePreview,
    curCaseMode,
    curLeftPage,
    curRightPage,
    ucloud1_1,
    isNewCaseHasUpload,
    smartRxShow,
    openHistorySplitView
  } = useAppSelector((state: RootState) => state.udTreatService)
  const { caseDetail } = useAppSelector((state: RootState) => state.caseService)
  const [toolName, SetToolName] = useState("")
  const dispatch = useAppDispatch()
  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    nextTool: string,
  ) => {
    if (nextTool === "smartRx") {
      if (smartRxShow) {
        dispatch(setSmartRxShow(false))
        dispatch(setCurLeftPage(""))
      } else {
        dispatch(setSmartRxShow(true))
        dispatch(setCurLeftPage("smartRx"))
      }
    } else {
      if (smartRxShow) {
        dispatch(setSmartRxShow(false))
        dispatch(setCurLeftPage(""))
      }
    }
    SetToolName(nextTool)
    dispatch(setCurLeftPage(nextTool))
  }
  const [selPhoto, setSelPhoto] = useState(false)

  const NewCaseBtns = () => {
    return (
      <ToggleButtonGroup
        orientation="vertical"
        value={toolName}
        exclusive
        onChange={handleChange}
      >
        {!ucloud1_1 && (
          <Tooltip title="Upload Scan V2" placement="right">
            <ToggleButton sx={{ border: "none" }} value="createcasev2">
              <CreateCaseIcon />
            </ToggleButton>
          </Tooltip>
        )}
        {!ucloud1_1 && (
          <Tooltip title="uAssist" placement="right">
            <ToggleButton sx={{ border: "none" }} value="uassist">
              <ChatIcon />
            </ToggleButton>
          </Tooltip>
        )}
        {(isNewCaseHasUpload ||
          caseDetail?.udesign_json?.includes("upperUploaded")) && (
            <PatientInfoBtnGroup />
          )}
      </ToggleButtonGroup>
    )
  }
  const AllBtns = () => {
    return (
      <ToggleButtonGroup
        orientation="vertical"
        value={toolName}
        exclusive
        onChange={handleChange}
      >
        <Tooltip title="Upload Scan V2" placement="right">
          <ToggleButton sx={{ border: "none" }} value="createcasev2">
            <CreateCaseIcon />
          </ToggleButton>
        </Tooltip>
        <Tooltip title="uAssist" placement="right">
          <ToggleButton sx={{ border: "none" }} value="uassist">
            <ChatIcon />
          </ToggleButton>
        </Tooltip>
        <PatientInfoBtnGroup />
        <Tooltip title="Bolton Analysis" placement="right">
          <ToggleButton sx={{ border: "none" }} value="bolton">
            <BoltonIcon />
          </ToggleButton>
        </Tooltip>
        <Tooltip title="IPR/Aux Report" placement="right">
          <ToggleButton sx={{ border: "none" }}
            onClick={() => {
              if (curLeftPage === "report") {
                handleChange(undefined, "")
              } else {
                handleChange(undefined, "report")
              }
            }}
            value="report"
          >
            <AssignmentIcon />
          </ToggleButton>
        </Tooltip>
        <Tooltip title="Tooth Movement Table" placement="right">
          <ToggleButton sx={{ border: "none" }} value="tmtable">
            <TableChartIcon />
          </ToggleButton>
        </Tooltip>
        <Tooltip title="Local Photos" placement="right">
          <ToggleButton sx={{ border: "none" }} value="lrphotos">
            <LRPhotosIcon />
          </ToggleButton>
        </Tooltip>
        <Tooltip title="Local STLs" placement="right">
          <ToggleButton sx={{ border: "none" }} value="lrstls">
            <LRStlIcon />
          </ToggleButton>
        </Tooltip>
      </ToggleButtonGroup>
    )
  }

  const ModiBtns = () => {
    return (
      <ToggleButtonGroup
        orientation="vertical"
        value={toolName}
        exclusive
        onChange={handleChange}
      >
        <Tooltip title="uAssist" placement="right">
          <ToggleButton sx={{ border: "none" }} value="uassist">
            <ChatIcon />
          </ToggleButton>
        </Tooltip>
        <PatientInfoBtnGroup />
        <Tooltip title="IPR/Aux Report" placement="right">
          <ToggleButton sx={{ border: "none" }}
            onClick={() => {
              if (curLeftPage === "report") {
                handleChange(undefined, "")
              } else {
                handleChange(undefined, "report")
              }
            }}
            value="report"
          >
            <AssignmentIcon />
          </ToggleButton>
        </Tooltip>
        <Tooltip title="Tooth Movement Table" placement="right">
          <ToggleButton sx={{ border: "none" }} value="tmtable">
            <TableChartIcon />
          </ToggleButton>
        </Tooltip>
      </ToggleButtonGroup>
    )
  }
  const ViewBtns = () => {
    return (
      <ToggleButtonGroup
        orientation="vertical"
        value={toolName}
        exclusive
        onChange={handleChange}
      >
        <Tooltip title="uAssist" placement="right">
          <ToggleButton sx={{ border: "none" }} value="uassist">
            <ChatIcon />
          </ToggleButton>
        </Tooltip>
        <PatientInfoBtnGroup />
        <Tooltip title="IPR/Aux Report" placement="right">
          <ToggleButton sx={{ border: "none" }}
            onClick={() => {
              if (curLeftPage === "report") {
                handleChange(undefined, "")
              } else {
                handleChange(undefined, "report")
              }
            }}
            value="report"
          >
            <AssignmentIcon />
          </ToggleButton>
        </Tooltip>
        <Tooltip title="Tooth Movement Table" placement="right">
          <ToggleButton sx={{ border: "none" }} value="tmtable">
            <TableChartIcon />
          </ToggleButton>
        </Tooltip>
      </ToggleButtonGroup>
    )
  }

  useEffect(() => {
    if (caseDetail.caseDisposition === "ATTENTION_NEEDED") {
      handleChange(undefined, "uassist")
    }
  }, [caseDetail.caseDisposition])

  const splitViewBtns = [
    {
      title: "uAssist", value: "uassist", icon: <ChatIcon color={curLeftPage === "uassist" ? "primary" : "inherit"} />,
      onClick: () => { handleChange(undefined, "uassist") },
      isHide: caseDetail.caseDisposition === "SUBMIT_TO_UASSIST" || caseDetail.caseDisposition === "SUBMITTED_TO_UASSIST" || caseDetail.caseDisposition === "PLANNING_IN_PROGRESS",
      isDisable: false
    },
    {
      title: "Smart Rx", value: "smartRx", icon: <ReactSVG
        src={RxIcon}
        afterInjection={(svg) => {
          const path = svg.getElementsByTagName("path")[0]
          if (curLeftPage === "smartRx") {
            path?.setAttribute("fill", "#215ECD")
            path?.setAttribute("fill-opacity", "1")
          } else {
            path?.setAttribute("fill", "black")
            path?.setAttribute("fill-opacity", "0.56")
          }
        }}
      ></ReactSVG>,
      onClick: () => { handleChange(undefined, "smartRx") },
      isHide: false,
      isDisable: false
    }, {
      title: "Photos and X-rays", value: "records",
      icon: <InsertPhoto color={curLeftPage === "records" ? "primary" : "inherit"} />,
      onClick: () => {
        if (curLeftPage === "records") {
          handleChange(undefined, "")
          return
        }
        handleChange(undefined, "records")
      },
      isHide: false,
      isDisable: false
    },
  ]

  const LeftBtnColumn = [
    {
      title: "uAssist", value: "uassist", icon: <ChatIcon color={curLeftPage === "uassist" ? "primary" : "inherit"} />,
      onClick: () => { handleChange(undefined, "uassist") },
      isHide: caseDetail.caseDisposition === "SUBMIT_TO_UASSIST" || caseDetail.caseDisposition === "SUBMITTED_TO_UASSIST" || caseDetail.caseDisposition === "PLANNING_IN_PROGRESS",
      isDisable: false
    },
    {
      title: "Upload Scan", value: "createcase", icon: <CreateCaseIcon color={curLeftPage === "createcase" ? "primary" : "inherit"} />, onClick: () => {
        if (curLeftPage === "createcase") {
          handleChange(undefined, "")
          return
        }
        handleChange(undefined, "createcase")
      },
      isHide: caseDetail.caseDisposition !== "SUBMITTED_TO_UASSIST" && caseDetail.caseDisposition !== "SUBMIT_TO_UASSIST",
      isDisable: false
    },
    {
      title: "Smart Rx", value: "smartRx", icon: <ReactSVG
        src={RxIcon}
        afterInjection={(svg) => {
          const path = svg.getElementsByTagName("path")[0]
          if (smartRxShow) {
            path?.setAttribute("fill", "#215ECD")
            path?.setAttribute("fill-opacity", "1")
          } else {
            path?.setAttribute("fill", "black")
            path?.setAttribute("fill-opacity", "0.56")
          }
        }}
      ></ReactSVG>,
      onClick: () => { handleChange(undefined, "smartRx") },
      isHide: false,
      isDisable: false
    }, {
      title: "Photos and X-rays", value: "records",
      icon: <InsertPhoto color={curLeftPage === "records" ? "primary" : "inherit"} />,
      onClick: () => {
        if (curLeftPage === "records") {
          handleChange(undefined, "")
          return
        }
        handleChange(undefined, "records")
      },
      isHide: false,
      isDisable: false
    }, {
      title: "IPR/Aux Report", value: "report",
      icon: <AssignmentIcon color={curLeftPage === "report" ? "primary" : "inherit"} />,
      onClick: () => {
        if (curLeftPage === "report") {
          handleChange(undefined, "")
          return
        }
        handleChange(undefined, "report")
      },
      isHide: caseDetail.caseDisposition === "SUBMIT_TO_UASSIST" || caseDetail.caseDisposition === "SUBMITTED_TO_UASSIST" || caseDetail.caseDisposition === "PLANNING_IN_PROGRESS",
      isDisable: false
    }, {
      title: "Tooth Movement Table", value: "tmtable",
      icon: <TableChartIcon color={curLeftPage === "tmtable" ? "primary" : "inherit"} />,
      onClick: () => {
        if (curLeftPage === "tmtable") {
          handleChange(undefined, "")
          return
        }
        handleChange(undefined, "tmtable")
      },
      isHide: caseDetail.caseDisposition === "SUBMIT_TO_UASSIST" || caseDetail.caseDisposition === "SUBMITTED_TO_UASSIST" || caseDetail.caseDisposition === "PLANNING_IN_PROGRESS",
      isDisable: false
    }, {
      title: "Bolton Analysis", value: "bolton",
      icon: <ReactSVG
        src={BoltonIcon}
        afterInjection={(svg) => {
          const path = svg.getElementsByTagName("path")[0]
          if (curLeftPage === "bolton") {
            path?.setAttribute("fill", "#215ECD")
            path?.setAttribute("fill-opacity", "1")
          } else {
            path?.setAttribute("fill", "black")
            path?.setAttribute("fill-opacity", "0.56")
          }
        }}
      ></ReactSVG>,
      onClick: () => {
        if (curLeftPage === "bolton") {
          handleChange(undefined, "")
          return
        }
        handleChange(undefined, "bolton")
      },
      isHide: caseDetail.caseDisposition === "SUBMIT_TO_UASSIST" || caseDetail.caseDisposition === "SUBMITTED_TO_UASSIST" || caseDetail.caseDisposition === "PLANNING_IN_PROGRESS",
      isDisable: false
    }
  ]
  const UcloudButtons = () => {
    return (
      <ToggleButtonGroup
        orientation="vertical"
        value={curLeftPage}
        exclusive
        onChange={handleChange}
      >
        {
          openHistorySplitView ?
            splitViewBtns.map(({ title, icon, onClick, isHide, isDisable, value }, index) => {
              if (isHide) return
              return (
                <Tooltip title={title} placement="right" key={index}>
                  <ToggleButton sx={{ border: "none" }}
                    value={value}
                    onClick={onClick}
                    disabled={isDisable}

                  >
                    {icon}
                  </ToggleButton>
                </Tooltip>
              )
            })
            : LeftBtnColumn.map(({ title, icon, onClick, isHide, isDisable, value }, index) => {
              if (isHide) return
              return (
                <Tooltip title={title} placement="right" key={index}>
                  <ToggleButton sx={{ border: "none" }}
                    value={value}
                    size="medium"
                    onClick={onClick}
                    disabled={isDisable}

                  >
                    {icon}
                  </ToggleButton>
                </Tooltip>
              )
            })
        }
      </ToggleButtonGroup>
    )
  }

  const PatientInfoBtnGroup = () => {
    return (
      <>
        <Tooltip title="Photos and X-rays" placement="right">
          <ToggleButton sx={{ border: "none" }}
            value={"records"}
            size="medium"
            onClick={() => {
              if (curLeftPage === "records") {
                handleChange(undefined, "")
                return
              }
              handleChange(undefined, "records")
            }}
          >
            {/* <Badge
              sx={{
                width: 8,
                height: 8,
                position: "absolute",
                right: 4,
                top: 4,
                borderRadius: "100px",
                background: "#C62828 ",
              }}
            /> */}
            <InsertPhoto
              color={curLeftPage === "records" ? "primary" : "inherit"}
            />
          </ToggleButton>
        </Tooltip>
        <Tooltip title="Dental Chart" placement="right">
          <ToggleButton sx={{ border: "none" }}
            value={"dentalchart"}
            size="medium"
            // sx={{ marginTop: 1 }}
            onClick={() => {
              handleChange(undefined, "dentalchart")
            }}
          >
            {/* <Badge
              sx={{
                width: 8,
                height: 8,
                position: "absolute",
                right: 4,
                top: 4,
                borderRadius: "100px",
                background: "#C62828 ",
              }}
            /> */}
            <ReactSVG
              src={Tooth}
              afterInjection={(svg) => {
                const path = svg.getElementsByTagName("path")[0]
                if (curLeftPage === "dentalchart") {
                  path?.setAttribute("fill", "#215ECD")
                  path?.setAttribute("fill-opacity", "1")
                } else {
                  path?.setAttribute("fill", "black")
                  path?.setAttribute("fill-opacity", "0.56")
                }
              }}
            ></ReactSVG>
          </ToggleButton>
        </Tooltip>
        <Tooltip title="Smart Rx" placement="right">
          <ToggleButton sx={{ border: "none" }}
            onClick={() => {
              handleChange(undefined, "smartRx")
            }}
            value="smartRx"
          >
            <EditCalendarOutlinedIcon />
          </ToggleButton>
        </Tooltip>
      </>
    )
  }
  return (

    <Box
      sx={{
        width: "48px",

        left: 28,
        top: 104,
        maxHeight: `calc(100% - 114px)`,
        overflow: "auto",
        "::-webkit-scrollbar": {
          width: "1px",
        },
        position: "absolute",
      }}
    >
      <Stack direction="column" sx={{ backgroundColor: theme.transBkColor.light, borderRadius: "8px", }}>
        {ucloud1_1 ? (
          <UcloudButtons />
        ) : curCaseMode == "NewCase" ? (
          <NewCaseBtns />
        ) : curCaseMode == "UDPlan" ? (
          <AllBtns />
        ) : curCaseMode == "UDModi" ? (
          <ModiBtns />
        ) : curCaseMode == "UDView" ? (
          <ViewBtns />
        ) : curCaseMode == "WeDesign" ? (
          <AllBtns />
        ) : (
          <></>
        )}
      </Stack>
      <Stack direction="column" sx={{ backgroundColor: theme.transBkColor.light, marginTop: 1, borderRadius: "8px", }}>
        {!openHistorySplitView &&
          (caseDetail.caseDisposition === "SUBMIT_TO_UASSIST" ||
            caseDetail.caseDisposition === "SUBMITTED_TO_UASSIST" ||
            caseDetail.caseDisposition === "PLANNING_IN_PROGRESS" ||
            caseDetail.caseDisposition === "ATTENTION_NEEDED") &&
          <Tooltip title="Dental Chart" placement="right" sx={{ marginTop: 1 }}>
            <ToggleButton sx={{ border: "none" }}
              value={"dentalchart"}
              size="medium"
              // sx={{ marginTop: 1 }}
              onClick={() => {
                if (curLeftPage === "dentalchart") {
                  handleChange(undefined, "")
                  return
                }
                handleChange(undefined, "dentalchart")
              }}

            >
              <ReactSVG
                src={Tooth}
                afterInjection={(svg) => {
                  const path = svg.getElementsByTagName("path")[0]
                  if (curLeftPage === "dentalchart") {
                    path?.setAttribute("fill", "#215ECD")
                    path?.setAttribute("fill-opacity", "1")
                  } else {
                    path?.setAttribute("fill", "black")
                    path?.setAttribute("fill-opacity", "0.56")
                  }
                }}
              ></ReactSVG>
            </ToggleButton>
          </Tooltip>}
      </Stack >
      <Stack direction="column" sx={{ backgroundColor: theme.transBkColor.light, marginTop: 1, borderRadius: "8px", }}>
        <Tooltip title="Notes" placement="right">
          <ToggleButton sx={{ border: "none" }} value={"notes"}>
            <ArticleIcon color={curLeftPage === "notes" ? "primary" : "inherit"} onClick={() => {
              if (curLeftPage === "notes") {
                handleChange(undefined, "")
                return
              }
              handleChange(undefined, "notes")
            }} />
          </ToggleButton>
        </Tooltip>
      </Stack >
    </Box>
  )
}
