import { ChangeEvent, FC, useEffect, useState } from "react"
import { Actions, ControlProps } from "@jsonforms/core"
import { withJsonFormsControlProps } from "@jsonforms/react"
import { useJsonForms } from "@jsonforms/react"
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputAdornment,
  OutlinedInput,
  Radio,
  RadioGroup,
} from "@mui/material"
import { Box } from "@mui/system"

import { UText } from "../../../ui-component"

interface InputProps {
  width: number
  key: string
  const: string
  errorMsg: string
  multiline?: boolean
  minRows?: number
  isNum?: boolean
  height?: string
  startLabel?: string
  endLabel?: string
  showInSameLine?: boolean
  startAdornment?: string
  placeholder?: string
  isOptional?: boolean
  defaultValue?: string
  name?: string
}

interface CheckboxProps {
  key: string
  const: string
  checkboxes: []
}

const URadioGroup: FC<ControlProps> = (props) => {
  const { schema, path, label, data, visible, handleChange } = props
  const { core, dispatch } = useJsonForms()
  const [errors, setErrors] = useState("")

  const input = schema?.input as InputProps
  const checkboxGroup = schema?.checkboxGroup as CheckboxProps

  const handleRadioInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const val = (event.target as HTMLInputElement).value
    let temp
    if (input && data) {
      temp = { ...data }
      input.const === val ? (temp[input.key] = "") : delete temp[input.key]
    }
    if (checkboxGroup && data) {
      temp = { ...data }
      checkboxGroup.const === val
        ? (temp[checkboxGroup.key] = [])
        : delete temp[checkboxGroup.key]
    }
    schema.type === "string"
      ? handleChange(path, val)
      : handleChange(path, { ...temp, value: val })
  }

  const handleCheckboxChange = (checkbox: any) => {
    const index = data[checkboxGroup.key].indexOf(checkbox.value)
    const newData = { ...data }
    if (checkbox.input && checkbox.input.const === checkbox.value) {
      if (index !== -1) {
        delete newData[checkbox.input.key]
      } else {
        newData[checkbox.input.key] = ""
      }
    }
    handleChange(path, {
      ...newData,
      [checkboxGroup.key]:
        index === -1
          ? [...newData[checkboxGroup.key], checkbox.value]
          : newData[checkboxGroup.key].filter(
              (item: string) => item !== checkbox.value,
            ),
    })
  }

  const inputFeild = (input: InputProps, value: any) => {
    return (
      <Box
        sx={{
          ml: input.showInSameLine ? "0" : "34px",
          display: "flex",
          alignItems: "baseline",
          gap: "10px",
          justifyContent: "start",
          maxWidth: schema.isFlexColumn ? "100%" : 310,
        }}
      >
        {input.startLabel && (
          <UText variant={"body1"}>{input.startLabel}</UText>
        )}
        <OutlinedInput
          fullWidth
          multiline={input.multiline}
          id={label}
          startAdornment={
            input.startAdornment ? (
              <InputAdornment position="start">
                {input.startAdornment}
              </InputAdornment>
            ) : (
              <></>
            )
          }
          sx={{
            padding: input.startAdornment ? "0 0 0 10px" : "0 12px",
            width: schema.isFlexColumn ? "100%" : input.width,
            minHeight: 40,
            height: input.height ? input.height : "",
            input: {
              padding: "0",
              textAlign: input.isNum ? "end" : "",
            },
          }}
          disabled={
            (typeof value === "string" && value !== input.const) ||
            !value.includes(input.const)
          }
          minRows={input.minRows}
          maxRows={4}
          placeholder={input.placeholder}
          value={data[input.key] || input.defaultValue || input.name || ""}
          onChange={(e) => {
            handleChange(path, {
              ...data,
              [input.key ? input.key : input.name]: input.isNum
                ? e.target.value.replace(/[^-.\d]/g, "")
                : e.target.value,
            })
          }}
        />
        {input.endLabel && <UText variant="body1">{input.endLabel}</UText>}
        {input.errorMsg && (
          <FormHelperText sx={{ marginLeft: 0, color: "error.main" }}>
            {input.errorMsg}
          </FormHelperText>
        )}
      </Box>
    )
  }

  const validateCustomField = () => {
    if (core?.schema.required?.indexOf(path) === -1 || !visible) {
      if (!visible) {
        handleChange(path, schema.type === "string" ? "" : { value: "" })
        updateErrors("")
      }
      return
    }
    const type = schema.type

    if (type === "string") {
      updateErrors(data ? "" : "This is a required field")
    } else {
      updateErrors(data.value ? "" : "This is a required field")
      if (input) {
        // eslint-disable-next-line no-prototype-builtins
        data.hasOwnProperty(input.key) &&
          !input.isOptional &&
          updateErrors(data[input.key] === "" ? "This is a required field" : "")
      }
      if (checkboxGroup) {
        // eslint-disable-next-line no-prototype-builtins
        if (data.hasOwnProperty(checkboxGroup.key)) {
          if (data[checkboxGroup.key].length === 0) {
            updateErrors("This is a required field")
          } else {
            const index = checkboxGroup.checkboxes.findIndex((obj) =>
              // eslint-disable-next-line no-prototype-builtins
              obj.hasOwnProperty("input"),
            )

            updateErrors(
              data[checkboxGroup.key].indexOf(
                checkboxGroup.checkboxes[index].value,
              ) > -1 && data[checkboxGroup.checkboxes[index].input.key] === ""
                ? "This is a required field"
                : "",
            )
          }
        }
      }
    }
  }

  const updateErrors = (message: string) => {
    setErrors(message)
    dispatch &&
      dispatch(
        Actions.updateErrors([
          {
            instancePath: "/" + path,
            message,
            schemaPath: "",
            keyword: "",
            params: {},
          },
        ]),
      )
  }

  useEffect(() => {
    validateCustomField()
  }, [data, core?.validationMode, visible])

  return (
    <>
      {visible && (
        <FormControl
          error={!!(errors && core?.validationMode === "ValidateAndShow")}
          sx={{
            my: 1,
            display: "flex",
            flexDirection: schema.isFlexColumn ? "columns" : "row",
            pl: 1,
          }}
        >
          <UText
            variant={"body2"}
            sxProp={{
              width: schema.isFlexColumn ? "100%" : 200,
              marginTop: 1,
              fontSize: schema.fontSize ? schema.fontSize : "14px",
              color:
                errors && core?.validationMode === "ValidateAndShow"
                  ? "error.main"
                  : "text.secondary",
            }}
          >
            {label}
          </UText>
          <RadioGroup
            aria-labelledby={`${path}-label`}
            name={`${path}`}
            value={schema.type === "string" ? data : data?.value}
            sx={{
              pl: 1,
              width: schema.isFlexColumn ? "100%" : "350px",
            }}
            onChange={handleRadioInputChange}
          >
            {schema.radioOptions &&
              schema.radioOptions.map((v, index) => {
                return (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      flexDirection: input?.showInSameLine ? "row" : "column",
                    }}
                  >
                    {v === "Upload New Scans" && (
                      <UText
                        variant={"body2"}
                        sxProp={{ pl: "35px", color: "text.disabled" }}
                      >
                        {"Select this option if your case is tracking"}
                      </UText>
                    )}
                    <FormControlLabel
                      sx={{
                        mr: input?.showInSameLine ? "0" : 2,
                        alignItems: "flex-start",
                        ".MuiFormControlLabel-label": { paddingTop: "12px" },
                      }}
                      key={index}
                      value={v}
                      control={<Radio />}
                      label={
                        input?.showInSameLine && input.const === v ? "" : v
                      }
                    />
                    {input &&
                      input.const === v &&
                      (input?.showInSameLine || (data && data.value === v)) &&
                      inputFeild(input, data.value)}

                    {checkboxGroup &&
                      checkboxGroup.const === v &&
                      data &&
                      data.value === v &&
                      checkboxGroup.checkboxes.map((item: any) => {
                        return (
                          <Box key={item.value}>
                            <FormControlLabel
                              sx={{ display: "flex", ml: 2 }}
                              control={
                                <Checkbox
                                  checked={
                                    data[checkboxGroup.key].indexOf(
                                      item.value,
                                    ) > -1
                                  }
                                  onChange={() => {
                                    handleCheckboxChange(item)
                                  }}
                                  name={item.value}
                                />
                              }
                              label={
                                <span
                                  style={{
                                    width: "310px",
                                    display: "inline-flex",
                                  }}
                                >
                                  {item.label}
                                </span>
                              }
                            />
                            <Box component={"div"} sx={{ ml: "26px" }}>
                              {item.input &&
                                data[checkboxGroup.key].indexOf(item.value) >
                                  -1 &&
                                inputFeild(item.input, data[checkboxGroup.key])}
                            </Box>
                          </Box>
                        )
                      })}
                  </Box>
                )
              })}
            {errors && core?.validationMode === "ValidateAndShow" && (
              <FormHelperText sx={{ marginLeft: 0 }}>{errors}</FormHelperText>
            )}
          </RadioGroup>
        </FormControl>
      )}
    </>
  )
}

export default withJsonFormsControlProps(URadioGroup)
