import { FC } from "react"
import { useTranslation } from "react-i18next"

import { Box } from "../../components/mui.components"
import { UText } from "../../ui-component"

export const PricingHeader: FC<{
  pricingDate: { startDate: string; endDate: string }
}> = ({ pricingDate }) => {
  const { t } = useTranslation("common")

  return (
    <>
      <Box
        sx={{
          pt: 6,
          px: 9,
          width: "100%",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between",pb:1 }}>
          <UText variant="h4" color="text.primary">
            {t("Pricing.title")}
          </UText>
          <UText variant="body2" color="text.secondary">
            {t("Pricing.effectiveDate", {
              startDate: pricingDate?.startDate,
              endDate: pricingDate?.endDate,
            })}
          </UText>
        </Box>
        <UText variant="h6" color="text.secondary">
          {t("Pricing.note")}
        </UText>
      </Box>
    </>
  )
}
