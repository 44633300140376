import { useState, useRef, useEffect } from "react"
import {
  Box,
  Card,
  IconButton,
  CardContent,
  CardHeader,
  CardActions,
  Paper,
  Button,
  Typography,
} from "@mui/material"
import { Slide } from "@mui/material"
import CloseIcon from "@mui/icons-material/CloseOutlined"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import Prescription from "../USmartForms/Prescription"
import Retainer from "../USmartForms/Retainer"
import type { FC } from "react"
import { useTranslation } from "react-i18next"
import { useAppDispatch, useAppSelector } from "@/core/app/hooks"
import { RootState } from "@/core/app/store"
import { setCurLeftPage } from "../udTreatSlice"
import { fetchFormLists } from "@/core/app/slices/uassist/formThunkApi"
import { useMyNavigation } from "@/hooks/useMyNavigation"
import WarnBox from "../USmartForms/WarnBox"
import RefinementList from "../../../src/modules/Records/RefinementList"
import { useParams } from "react-router-dom"
import OrderForms from "../USmartForms/OrderForms"
import { checkIsThereASpecifiedPhoto } from "../left/utils"
import DialogBox from "@/modules/Clinical/components/DialogBox/dialogBox"
import { UButton } from "@/ui-component/components/index"
import FormOk from "../USmartForms/FormOk"
const canEdit = ["SUBMITTED_TO_UASSIST", "FROM_UDESIGN"]
const canEditDontSubmit = ["SUBMITTED_TO_UASSIST"]
// const canEdit = [
//   "SUBMITTED_TO_UASSIST",
//   "FROM_UDESIGN",
//   "SUBMITTED_TO_UASSIST_REFINEMENT",
// ]
const SmartRx: FC = ({
  smartRxShow,
  setSmartRxShow,
  needToSubmit,
  styleObj = {},
  unlockFunc = () => {},
}) => {
  const { photoTypeJson } = useAppSelector(
    (state: RootState) => state.PhotosService,
  )
  const { caseDetail } = useAppSelector((state: RootState) => state.caseService)
  const { caseDisposition } = caseDetail
  const navigate = useMyNavigation()
  const smartRef = useRef("")
  const [upperArchType, setUpperArchType] = useState<number>(null)
  const [lowerArchType, setLowerArchType] = useState<number>(null)
  const { t } = useTranslation("common")
  const dispatch = useAppDispatch()
  const { caseRAtype } = useAppSelector(
    (state: RootState) => state.udTreatService,
  )
  const { formTemplateList } = useAppSelector(
    (state: RootState) => state.formService,
  )
  const { caseId } = useParams()
  // bottom content type----edit or review or success
  const [actionType, setActionType] = useState<string>("edit")
  // review smartRX signal
  const [reviewNum, setReviewNum] = useState<number>(0)
  const [currentReviewNum, setCurrentReviewNum] = useState<number>(0)
  useEffect(() => {
    if (!smartRxShow) {
      setReviewNum(0)
      if (needToSubmit) {
        if (canEdit.includes(caseDisposition)) {
          setActionType("edit")
        }
      } else {
        if (canEditDontSubmit.includes(caseDisposition)) {
          setActionType("edit")
        }
      }
    }
  }, [smartRxShow])
  useEffect(() => {
    !formTemplateList.length && dispatch(fetchFormLists())
    // if (!fileList.length) dispatch(fetchFilesList({ patientId, caseId }))
  }, [dispatch])
  const [isRetainer, setIsRetainer] = useState<boolean>(false)

  useEffect(() => {
    if (
      caseDetail &&
      caseDetail.udesign_category &&
      !caseDetail?.udesign_category?.includes("A")
    ) {
      setIsRetainer(true)
    }
  }, [caseDetail?.udesign_category])
  useEffect(() => {
    if (actionType !== "success") {
      if (needToSubmit) {
        setActionType(canEdit.includes(caseDisposition) ? "edit" : "review")
      } else {
        setActionType(
          canEditDontSubmit.includes(caseDisposition) ? "edit" : "review",
        )
      }
    }
  }, [caseDisposition])
  useEffect(() => {
    return () => {
      dispatch(setSmartRxShow(false))
    }
  }, [])
  const [photoPopup, setPhotoPopup] = useState<boolean>(false)
  return needToSubmit && actionType === "success" ? (
    <FormOk actionType={actionType} />
  ) : (
    smartRef && smartRxShow && (
      <Paper
        sx={{
          top: 104,
          width: "auto",
          maxWidth: "640px",
          minWidth: "536px",
          maxHeight: `calc(100% - 114px)`,
          position: "absolute",
          overflow: "auto",
          zIndex: 1,
          ...styleObj,
        }}
        elevation={6}
      >
        <Card>
          <CardHeader
            sx={{
              padding: "24px 10px 16px 32px",
              backgroundColor: "#EDF6F9",
              borderRadius: "8px",
              "& .MuiTypography-root": {
                fontSize: "24px",
                fontWeight: 500,
              },
            }}
            action={
              actionType !== "success" ? (
                <IconButton
                  aria-label="settings"
                  sx={{
                    width: 24,
                    height: 24,
                    right: "10px",
                  }}
                  onClick={() => {
                    dispatch(setSmartRxShow(false))
                    if (!needToSubmit) {
                      dispatch(setCurLeftPage(""))
                    }
                  }}
                >
                  <CloseIcon />
                </IconButton>
              ) : (
                <></>
              )
            }
            title={
              <div>
                <Typography>
                  {actionType === "edit"
                    ? "uAssist Prescription"
                    : actionType === "review"
                    ? "Review Prescription"
                    : "Thank you"}
                </Typography>
                <Typography sx={{}}>
                  {!needToSubmit && (
                    <RefinementList
                      listSX={{ marginTop: "10px" }}
                      caseId={caseId}
                      creatDate={
                        caseDetail.createDate
                          ? `${caseDetail.createDate.substring(
                              5,
                              7,
                            )}/${caseDetail.createDate.substring(
                              8,
                              10,
                            )}/${caseDetail.createDate.substring(2, 4)}`
                          : ""
                      }
                      onRefinementChange={(reviewNum) =>
                        setCurrentReviewNum(reviewNum)
                      }
                    />
                  )}
                </Typography>
              </div>
            }
          />
          <CardContent
            sx={{
              padding: "24px 32px",
              // height: actionType === "success" ? "288px" : "500px",
              maxHeight: `calc(100%)`,
              overflowY: "auto",
              "::-webkit-scrollbar": {
                width: "5px",
              },
            }}
          >
            {
              <>
                {/* <WarnBox /> */}
                {/* {actionType === "review" && (
                  <Box
                    sx={{
                      width: "100%",
                      padding: "8px",
                      height: "40px",
                      marginBottom: "8px",
                      backgroundColor: "#eee",
                      fontSize: "12px",
                      color: "rgba(0,0,0,0.6)",
                      lineHeight: "24px",
                    }}
                  >
                    {"REVIW PRESCRIPTION"}
                  </Box>
                )} */}
                {currentReviewNum === 0 ? (
                  isRetainer ? (
                    <Retainer
                      needToSubmit={needToSubmit}
                      setUpperArchType={setUpperArchType}
                      setLowerArchType={setLowerArchType}
                      reviewNum={reviewNum}
                      setActionType={setActionType}
                      actionType={actionType}
                      unlockFunc={unlockFunc}
                    />
                  ) : (
                    <Prescription
                      needToSubmit={needToSubmit}
                      setUpperArchType={setUpperArchType}
                      setLowerArchType={setLowerArchType}
                      reviewNum={reviewNum}
                      setActionType={setActionType}
                      actionType={actionType}
                      unlockFunc={unlockFunc}
                    />
                    // <OrderForms />
                  )
                ) : (
                  <OrderForms
                    needToSubmit={needToSubmit}
                    reviewNum={reviewNum}
                    setActionType={setActionType}
                    actionType={actionType}
                    currentReviewNum={currentReviewNum}
                    isAdd={false}
                  />
                )}
              </>
            }
          </CardContent>
          <CardActions
            sx={{
              width: "100%",
              padding: "16px 32px 24px 24px",
              backgroundColor: "#EDF6F9",
              display: needToSubmit
                ? canEdit.includes(caseDisposition)
                  ? "flex"
                  : "none"
                : canEditDontSubmit.includes(caseDisposition)
                ? "flex"
                : "none",
              justifyContent: "end",
            }}
          >
            {actionType === "edit" ? (
              <Button
                sx={{
                  color: "#fff",
                  padding: "8px 14px 8px 22px",
                  borderRadius: "4px",
                  backgroundColor: "#215ECD",
                }}
                variant="contained"
                onClick={() => {
                  // 提交uassit的表单要判断照片是否具备条件，不具备需要弹窗
                  if (needToSubmit) {
                    const { normalPosition, xrayPosition } =
                      checkIsThereASpecifiedPhoto(photoTypeJson)
                    if (normalPosition && xrayPosition) {
                      setReviewNum(reviewNum + 1)
                    } else {
                      setPhotoPopup(true)
                    }
                  } else {
                    setReviewNum(reviewNum + 1)
                  }
                }}
              >
                <span style={{ fontSize: "15px", fontWeight: "500" }}>
                  {"review"}
                </span>
                <ChevronRightIcon
                  sx={{ marginLeft: "8px", width: "24px", height: "24px" }}
                />
              </Button>
            ) : actionType === "review" ? (
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Button
                  sx={{
                    padding: "8px 11px 8px 8px",
                    borderRadius: "4px",
                    color: "#215ECD",
                  }}
                  variant="text"
                  onClick={() => {
                    setActionType("edit")
                  }}
                >
                  <ChevronLeftIcon
                    sx={{ marginRight: "8px", width: "24px", height: "24px" }}
                  />
                  <span style={{ fontSize: "15px", fontWeight: "500" }}>
                    {"edit"}
                  </span>
                </Button>
                <Box>
                  {/* <Button
                    sx={{
                      padding: "8px 11px 8px 11px",
                      borderRadius: "4px",
                      color: "#215ECD",
                      marginRight: "10px",
                    }}
                    variant="text"
                    onClick={() => {
                      console.log("view ai setup")
                    }}
                  >
                    <span style={{ fontSize: "15px", fontWeight: "500" }}>
                      {"view ai setup"}
                    </span>
                  </Button> */}
                  {needToSubmit && (
                    <Button
                      sx={{
                        color: "#fff",
                        padding: "8px 14px 8px 22px",
                        borderRadius: "4px",
                        backgroundColor: "#215ECD",
                      }}
                      variant="contained"
                      onClick={() => {
                        const { normalPosition, xrayPosition } =
                          checkIsThereASpecifiedPhoto(photoTypeJson)
                        if (normalPosition && xrayPosition) {
                          setReviewNum(reviewNum + 1)
                        } else {
                          setPhotoPopup(true)
                        }
                      }}
                    >
                      <span style={{ fontSize: "15px", fontWeight: "500" }}>
                        {"submit"}
                      </span>
                      <ChevronRightIcon
                        sx={{
                          marginLeft: "8px",
                          height: "24px",
                          width: "24px",
                        }}
                      />
                    </Button>
                  )}
                </Box>
              </Box>
            ) : (
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <Box>
                  {/* <Button
                    sx={{
                      padding: "8px 11px 8px 11px",
                      borderRadius: "4px",
                      color: "#215ECD",
                      marginRight: "10px",
                    }}
                    variant="text"
                    onClick={() => {
                      dispatch(setSmartRxShow(false))
                      if (!needToSubmit) {
                        dispatch(setCurLeftPage(""))
                      }
                    }}
                  >
                    <span style={{ fontSize: "15px", fontWeight: "500" }}>
                      {"close"}
                    </span>
                  </Button> */}
                  <Button
                    sx={{
                      color: "#fff",
                      padding: "8px 14px 8px 22px",
                      borderRadius: "4px",
                      backgroundColor: "#215ECD",
                    }}
                    variant="contained"
                    onClick={() => {
                      navigate("/patients")
                    }}
                  >
                    <span style={{ fontSize: "15px", fontWeight: "500" }}>
                      {"go to patient list"}
                    </span>
                  </Button>
                </Box>
              </Box>
            )}
          </CardActions>
        </Card>
        <DialogBox
          open={photoPopup}
          title={"Please upload photo"}
          content={"please upload photos····"}
          actions={
            <UButton
              onClickHandler={() => {
                setPhotoPopup(false)
              }}
              variant={"contained"}
              btnText={t("treat.lockPopUp.okbtn")}
            />
          }
        />
      </Paper>
    )
  )
}
export default SmartRx
