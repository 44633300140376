import { FC, useEffect, useMemo, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import { Box, Button, Grid } from "@mui/material"
import {
  Document,
  Image,
  Page,
  pdf,
  StyleSheet,
  View,
} from "@react-pdf/renderer"
import html2canvas from "html2canvas"

import DentalChart from "@/components/DentalChart/DentalChart"
import NavigationBlockPopup from "@/components/NavigationBlockPopup/NavigationBlockPopup"
import { useAppDispatch, useAppSelector } from "@/core/app/hooks"
import { setAlert } from "@/core/app/slices/alert/alertSlice"
import {
  getCaseById,
  updateCaseById,
  updateCaseStatus,
} from "@/core/app/slices/case/caseThunkApi"
import { resetAction } from "@/core/app/slices/clinical/clinicalSlice"
import { setIsRouteChangeBlocked } from "@/core/app/slices/navigationPopup/navigationPopupSlice"
import { uploadDentalchart } from "@/core/app/slices/records/xrays/xraysThunkApi"
import { RootState } from "@/core/app/store"
import { useCallbackPrompt } from "@/hooks/useCallbackPrompt"
import { useMyNavigation } from "@/hooks/useMyNavigation"
import { UButton, UModalPopup, UText } from "@/ui-component"
import { CASE_STATUS } from "@/modules/Patient/config/status.config"
import Menu from "./component/Menu"

const styles = StyleSheet.create({
  page: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
})

const exportComponentAsPDF = (
  componentRef: React.RefObject<HTMLDivElement>,
  dispatch: any,
  patientId: string,
  caseId: string,
) => {
  if (!componentRef.current) {
    console.warn("the component which is exported to PDF is null !")
    return
  }
  const width = componentRef.current?.clientWidth
  html2canvas(componentRef.current).then(async (canvas) => {
    const imgData = canvas.toDataURL("image/png")
    const MakePDFComponent = () => {
      return (
        <Document>
          <Page style={styles.page} size="letter">
            <View>
              <Image
                style={{ width: width, height: "auto" }}
                source={imgData}
              ></Image>
            </View>
          </Page>
        </Document>
      )
    }
    const blob = await pdf(MakePDFComponent()).toBlob()
    const newFile = new File([blob], `dental_chart.png`, {
      type: "image/png",
    })
    const formData = new FormData()
    formData.append(
      "attachment",
      new File([blob], newFile.name, {
        type: "image/png",
      }),
    )
    formData.append("original_file_name", "dental_chart.png")
    dispatch(
      uploadDentalchart({
        file: blob,
        patientId: patientId,
        caseId: caseId,
        formData: formData,
        fileName: newFile.name,
      }),
    )
  })
}

const UDdentalComponent: FC<{ isView?: boolean }> = ({ isView = false }) => {
  const [renderTime, setRenderTime] = useState<number>(0)
  const [menuProps, setMenuProps] = useState<{
    open: boolean
    position: number[]
  }>({ open: false, position: [0, 0] })

  const [appliedTeeth, setappliedTeeth] = useState<
    { teeth: string; checked: string }[]
  >([])

  const [selectedTeethId, setselectedTeethId] = useState<string>("")
  const navigate = useMyNavigation()
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const { patientId, caseId } = useParams()
  const { caseDetail } = useAppSelector((state: RootState) => state.caseService)
  const { current_orgId } = useAppSelector(
    (state: RootState) => state.userService.user,
  )

  const { isRouteChangeBlocked } = useAppSelector(
    (state: RootState) => state.navigationPopup,
  )
  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(isRouteChangeBlocked)
  useEffect(() => {
    dispatch(setIsRouteChangeBlocked({ isRouteChangeBlocked: false }))
    fetchDentalChart()
    return () => {
      setMenuProps({ open: false, position: [0, 0] })
      dispatch(resetAction())
    }
  }, [])

  useEffect(() => {
    if (renderTime > 0) {
      uploadDentalChart()
    }
  }, [appliedTeeth, renderTime])

  const fetchDentalChart = async () => {
    dispatch(getCaseById({ patientId, caseId }))
      .then((res) => {
        if (res.payload && res.payload.dental_chart_data) {
          setappliedTeeth(JSON.parse(res.payload.dental_chart_data))
        }
      })
      .then(() => {
        setRenderTime(renderTime + 1)
      })
  }

  const uploadDentalChart = async () => {
    const jsondata = JSON.stringify(appliedTeeth)
    dispatch(
      updateCaseById({
        patientId,
        caseId,
        payload: {
          dental_chart_data: jsondata,
        },
      }),
    )
  }

  const contentToPDF = useRef(null)

  const onButtonNextClick = () => {
    dispatch(setIsRouteChangeBlocked({ isRouteChangeBlocked: false }))
    exportComponentAsPDF(contentToPDF, dispatch, patientId, caseId)
    uploadDentalChart()

    if (caseDetail.caseDisposition === CASE_STATUS.FILL_OUT_RX_FORM) {
      navigate(`/uassist/patient/${patientId}/case/${caseId}/ordertype`)
    } else if (
      !caseDetail.caseDisposition ||
      caseDetail.caseDisposition === CASE_STATUS.COMPLETE_PATIENT_RECORDS
    ) {
      dispatch(
        updateCaseStatus({
          patientId,
          caseId,
          payload: { case_disposition: CASE_STATUS.FILL_OUT_RX_FORM },
        }),
      ).then((res) => {
        dispatch(
          setAlert({
            message: t("records.dentalchart.success"),
            btnText: t("records.dentalchart.btnText"),
            btnAction: () => navigate(`/overview/patient/${patientId}`),
          }),
        )
        // dispatch(getCaseById({ patientId, caseId }))
        navigate(`/uassist/patient/${patientId}/case/${caseId}/ordertype`)
      })
    } else {
      navigate(`/uassist/patient/${patientId}/case/${caseId}/ordertype`)
    }
  }

  const nextBtnText = useMemo(() => {
    return (
      <>
        {t("button.next")}
        <ChevronRightIcon fontSize={"small"} sx={{ marginLeft: "3px" }} />
      </>
    )
  }, [caseDetail])

  return (
    <>
      <Grid
        sx={{
          width: "100%",
          //   padding: 5,
          borderRadius: 4,
          backgroundColor: "#fff",
        }}
      >
        <Box
          sx={{
            width: "100%",
            flexDirection: "column",
            alignItems: "center",
            display: "flex",
          }}
          ref={contentToPDF}
        >
          <Box sx={{ textAlign: "center" }}>
            <UText
              sxProp={{
                color: "rgba(0, 0, 0, 0.6)",
                display: "block",
              }}
              variant={"subtitle1"}
            >
              Select tooth indication if needed
            </UText>
            <UText
              variant={"subtitle2"}
              sxProp={{
                color: "#616161",
                fontSize: 12,
                fontStyle: "italic",
                fontWeight: 400,
                letterSpacing: 0.4,
                display: "block",
              }}
            >
              For example: Crown, Implant or Extract
            </UText>
          </Box>
          <DentalChart
            setMenuProps={setMenuProps}
            appliedTeeth={appliedTeeth}
            selectedTeethId={selectedTeethId}
            setselectedTeethId={setselectedTeethId}
            isView={isView}
          ></DentalChart>
          <Menu
            menuProps={menuProps}
            setMenuProps={setMenuProps}
            appliedTeeth={appliedTeeth}
            selectedTeethId={selectedTeethId}
            setappliedTeeth={setappliedTeeth}
            setselectedTeethId={setselectedTeethId}
          ></Menu>
        </Box>
        {/* <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Button
            variant="outlined"
            onClick={() => {
              navigate(`/records/patient/${patientId}/case/${caseId}/xray`)
            }}
            sx={{ width: "96px", height: "36px" }}
          >
            <ChevronLeftIcon fontSize={"small"} sx={{ marginRight: "3px" }} />
            {t("button.back")}
          </Button>
          <Button
            variant="contained"
            onClick={onButtonNextClick}
            sx={{ width: "96px", height: "36px" }}
          >
            {nextBtnText}
          </Button>
        </Box> */}
      </Grid>
      <NavigationBlockPopup
        cancelNavigation={cancelNavigation}
        confirmNavigation={confirmNavigation}
        showPrompt={showPrompt}
      />
    </>
  )
}

export default UDdentalComponent
