import {
  Autocomplete,
  FormControl,
  FormHelperText,
  TextField,
} from "@mui/material"
import { SxProps } from "@mui/system"
import { FC, useCallback } from "react"
import { Control, Controller, FieldErrors, FieldValues } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { debounce } from "lodash"
import { clearAddressSuggestions } from "@/core/app/slices/user/userSlice"
import { fetchAddressSuggestions } from "@/core/app/slices/user/userApis"
import { useAppDispatch } from "@/core/app/hooks"

export interface IAddressAutoCompleteProps {
  suggestedAddressData: any
  handleInput: (newInputValue: any) => void
  handleChange: (event: any, value: any) => void
  inputValue: string
  fieldName: string
  rules: Record<string, any>
  errors: FieldErrors<FieldValues>
  control: Control<FieldValues>
  formSxprops: SxProps
  paymentValue?: string
}

export const AddressAutocomplete: FC<IAddressAutoCompleteProps> = ({
  suggestedAddressData,
  handleInput,
  handleChange,
  inputValue,
  fieldName,
  rules,
  errors,
  control,
  formSxprops,
}) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation("common")
  const hasError = !!errors?.[fieldName]
  const errorMsg = errors[fieldName]?.message?.toString()

  const throttleOnSearch = useCallback(
    debounce((value: string) => {
      if (value.length >=3) {
        dispatch(fetchAddressSuggestions(value))
      } else {
        dispatch(clearAddressSuggestions())
      }
    }, 300),
    [],
  )

  return (
    <FormControl
      fullWidth
      size={"medium"}
      sx={{ ...formSxprops }}
      error={hasError}
    >
      <Controller
        name={fieldName}
        errors={errors}
        control={control}
        rules={rules}
        render={({ field }) => (
          <Autocomplete
            sx={{
              ...formSxprops,
              "& input::placeholder": {
                color: hasError ? "error.main" : "",
                opacity: hasError ? "100%" : "",
              },
            }}
            freeSolo
            disableClearable={true}
            options={suggestedAddressData.map(
              (option) => option.street_address,
            )}
            onInputChange={(i) => {
              if (i?.target) {
                handleInput(i.target.value)
                throttleOnSearch(i.target.value)
              }
            }}
            onBlur={() => {
              dispatch(clearAddressSuggestions())
            }}
            value={inputValue || ""}
            onChange={handleChange}
            renderInput={(params) => (
              <TextField
                error={hasError}
                {...params}
                label={t("userProfile.addressOnFile.streetaddress")}
                placeholder={t("userProfile.addressOnFile.streetaddress")}
                InputProps={{
                  ...params.InputProps,
                }}
              />
            )}
          />
        )}
      />
      {hasError && errorMsg && (
        <FormHelperText id={`${fieldName}-error`}>{t(errorMsg)}</FormHelperText>
      )}
    </FormControl>
  )
}
