import React from 'react';
import { useRef, useEffect, useState, useImperativeHandle } from 'react';
import Draggable from 'react-draggable';

const StageDragBar = (props) => {
  // const [position, setPosition] = useState({ x: 0, y: 0 })
  const [dragOffsetX, setDragOffsetX] = useState(0)
  const [dragStartPositionX, setDragStartPositionX] = useState(0)
  const [startPositionX, setStartPositionX] = useState(0)
  const divRef = useRef(null)

  const getRectanglePositionX = () => {
    //var rect = element.getBoundingClientRect();
    //return { x: rect.left, y: rect.top };
    const e = divRef.current
    if (e === undefined) {
      return 0
    }
    const rect = e.getBoundingClientRect()
    const middleX = rect.left + (rect.width / 2)

    const parentE = e.offsetParent
    let parentELeft = 0
    if (parentE !== undefined) {
      const parentRect = parentE.getBoundingClientRect()
      parentELeft = parentRect.left
    }

    //console.info("=====getRectanglePosition: ", middleX);
    return (middleX - parentELeft)
  }

  // useEffect(() => {
    // setDragStartPositionX(getRectanglePositionX())
  // }, [props.x])

  return (
    <Draggable
      bounds={props.bounds}
      position={{x: 0, y: 0}}
      onStart={(e, data) => {
        const mouseX = e.clientX
        // console.info("e.clientX start:", mouseX)
        setStartPositionX(getRectanglePositionX())
        setDragStartPositionX(mouseX)
      }}
      onDrag={(e, data) => {
        const mouseX = e.clientX
        // console.info("e.clientX drag:", mouseX)
        const deltaX = mouseX - dragStartPositionX
        props.positionXChanged(startPositionX + deltaX)
        setDragOffsetX(data.x)
        // console.info("data.x:", data.x)
      }}
      onStop={(e, data) => {
        setDragOffsetX(0)
        props.dragStoped()
      }}>

      <div ref={divRef} style={{
        width: props.width,
        height: props.height*3,
        borderRadius: "2px",
        backgroundColor: "transparent",
        position: "relative",
        left: props.x - dragOffsetX,
        top: props.top,
        zIndex: 0,
      }}>
        <div style={{
          width: props.width,
          height: props.height,
          borderRadius: "2px",
          backgroundColor: props.visibility === "hidden" ? "transparent" : "#4B4B4B",
          position: "absolute",
          left: 0,
          top: props.height
        }}>
        
        <div style={{
          width: props.width,
          height: props.height,
          borderRadius: "2px",
          backgroundColor: "transparent",
          position: "absolute",
          left: 0,
          top: props.height
        }}></div>

        </div>
      </div>
    </Draggable>

  );
};

export default StageDragBar;