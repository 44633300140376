import { ULoading } from '@/ui-component';
import { useOktaAuth } from '@okta/okta-react';
import { FC, useEffect } from 'react';


const Logout: FC = () => {
  const { oktaAuth } = useOktaAuth();
    // Implement logout logic here
    useEffect(() => {
        const logOutRedirect = async () => await oktaAuth.signOut();
        logOutRedirect();
    }, [oktaAuth])
    
return <ULoading isLoading={true}/>
};

export default Logout;
