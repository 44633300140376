import { wasmModule } from "../wasm/wasmModule";
import { stagingManager } from "../stagingdatamanager/stagingmanager";
import { EArchType } from "../../common";

class Report {
  UpReportArrays: Map<number, any> = new Map<number, any>();
  LowReportArrays: Map<number, any> = new Map<number, any>();
  /**
   * Attachment+Auxilliaries
   */
  showAttachmentAuxilliaries() {
    const attachAuxil: Record<number, any> = {};
    // const dataAttachmentUp = casedataParser.getCaseData().datAttachments?.dataAttachmentUp;
    // const dataAttachmentDown = casedataParser.getCaseData().datAttachments?.dataAttachmentDown;
    // const teethData = casedataParser.getCaseData().teethData.teethgroup;
    // for (let i = 1; i < 33; i++) {
    //   if (!teethData[i]) {
    //     attachAuxil[i] = {
    //       toothId: i,
    //       property: 3,
    //       attachment: [],
    //     };
    //   }
    // }
    // Object.keys(teethData).forEach((p) => {
    //   const item: ToothData = teethData[p];
    //   let attachmentInfo = [];
    //   let proper: number = 0;
    //   if (isMissingTeethPullOut(item)) proper = 3;
    //   if (isPullOut(item)) proper = 6;
    //   if (isImplant(item)) proper = 2;
    //   if (isPontic(item)) proper = 1;
    //   if (item.attribute.isBridge) proper = 4;
    //   if (item.attribute.isCrown) proper = 5;
    //   if (dataAttachmentUp) attachmentInfo = this.AttachmentAuxilliaries(dataAttachmentUp, item, attachmentInfo);
    //   if (dataAttachmentDown) attachmentInfo = this.AttachmentAuxilliaries(dataAttachmentDown, item, attachmentInfo);
    //   attachAuxil[item.toothId] = {
    //     toothId: item.toothId,
    //     property: proper,
    //     attachment: attachmentInfo,
    //   };
    // });
    return attachAuxil;
  }
  update() {
    console.log("update toothmovement report");
    if (wasmModule.getArchModel(EArchType.UpArch)) {
      this.UpReportArrays.clear();
      // const toothupIds = wasmModule.mouthModel
      //   .GetArchModel(wasmModule.module.ArchType.UpArch)
      //   .GetToothIds();
      for (
        let i = 0;
        i < stagingManager.wasmStageData.jointUpKeypoints.length;
        i++
      ) {
        const values = wasmModule.ulabwinIns.GetReportResult(
          wasmModule.module.ArchType.UpArch,
          i
        );
        this.computeReport(i, values, 0);
      }
    }

    if (wasmModule.getArchModel(EArchType.LowArch)) {
      this.LowReportArrays.clear();
      // const toothlowIds = wasmModule
      //   .getArchModel(EArchType.LowArch)
      //   .GetToothIds();
      for (
        let i = 0;
        i < stagingManager.wasmStageData.jointLowerKeypoints.length;
        i++
      ) {
        const values = wasmModule.ulabwinIns.GetReportResult(
          wasmModule.module.ArchType.DownArch,
          i
        );
        this.computeReport(i, values, 1);
      }
    }
  }
  computeReport(num: number, values, arch: number) {
    const elementCount = 1 + 6 + 6*3; // toothId + (distance,rotate) + (RGB for value)
    const size = values.size() / elementCount;
    const allReportArrays: any[] = new Array<any>();
    for (let i = 0; i < size; i++) {
      const value: any = {};
      value.id = values.get(i * elementCount);
      const distanceRotate: number[] = [];
      for (let l = 1; l < 7; l++) {
        distanceRotate[l - 1] = values.get(i * elementCount + l);
      }
      const rgbArray: number[] = [];
      for( let l = 7; l < elementCount; ++l ){
        rgbArray[l-7] = values.get( i*elementCount + l );
      }
      value.distanceRotate = distanceRotate;
      value.rgbArray = rgbArray;
      allReportArrays[value.id] = value;
    }
    if (arch === 0) {
      this.UpReportArrays.set(num, allReportArrays);
    } else if (arch === 1) {
      this.LowReportArrays.set(num, allReportArrays);
    }
  }
  /**
   * 获取toothmovement table中牙齿的reports
   * @param jaw
   * @param num
   * @returns
   */
  toothReport(jaw: "upper" | "lower", num: number) {
    if (jaw === "upper") return this.UpReportArrays.get(num);
    return this.LowReportArrays.get(num);
  }
}
export const report = new Report();
