import { createSlice } from "@reduxjs/toolkit"


interface IScansState {
  loading: "idle" | "pending" | "succeeded" | "failed"
  uploadSTL:{
    upper:File|null,
    lower:File|null,
  }
}

const initialState: IScansState = {
  loading: "idle",
  uploadSTL:{
    upper:null,
    lower:null,
  }
}

export const scansServiceSlice = createSlice({
  name: "scansService",
  initialState,
  reducers: {
    setUploadStls:(state,action)=>{
      state.uploadSTL = {...state.uploadSTL,...action.payload}
    },
  },
  extraReducers: (builder) => {
  },
})

export const {
  setUploadStls
} = scansServiceSlice.actions
