export const canvasBox = {
  width: "100%",
  height: 456,
  padding: "10px 136px 68px 136px",
  position: "relative",
}

export const canvas = {
  width: "100%",
  height: "100%",
  position: "absolute",
  left: "0",
  top: "0",
  borderRadius: 4,
}

export const fileBox = {
  width: "100%",
  minHeight: 216,
}

export const tool = {
  position: "absolute",
  top: 0,
  zIndex: 1000,
  marginTop: 1.3,
  width: "100%",
  display: "flex",
  justifyContent: "center",
}

export const editfile = {
  position: "absolute",
  bottom: 0,
}

export const model = {
  position: "relative",
  width: "100%",
  justifyContent: "center",
  height: "100%",
}

export const view = {
  width: 80,
  height: 40,
  border: "1px solid rgba(0, 0, 0, 0.12)",
  borderRadius: 2,
  cursor: "pointer",
  marginRight: 1,
  display: "flex",
}
export const zoom = {
  width: 80,
  height: 40,
  // border: "1px solid rgba(0, 0, 0, 0.12)",
  color: "#707070",
  cursor: "pointer",
  justifyContent: "space-around",
  alignItems: "center",
}

export const viewbox = {
  width: "50%",
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  float: "left",
  userSelect: "none",
}
export const zoomin = {
  width: "50%",
  height: "100%",
  display: "block",
  lineHeight: 1.3,
  textAlign: "center",
  // borderRight: "1px solid #455A64",
  fontSize: 30,
  userSelect: "none",
  backgroundColor: "rgba(240,240,240)",
}
export const zoomout = {
  width: "50%",
  height: "100%",
  display: "block",
  lineHeight: 1.3,
  textAlign: "center",
  fontSize: 30,
  userSelect: "none",
  backgroundColor: "rgba(240,240,240)",
}
