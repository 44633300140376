import { FC } from "react"
import { useTranslation } from "react-i18next"
import { UButton, UModalPopup, UText } from "../components"
import { Box } from "../components/mui.components"
import { useError } from "../Error.context"
import { useMyNavigation } from "@/hooks/useMyNavigation"

interface Error {
  code: number
  message: string
}

const ErrorPage: FC = () => {
  const { error } = useError()
  const { t } = useTranslation("common")
  const navigate = useMyNavigation()
  const getErrorMsg = (code: number): string => {
    switch (code) {
      case 500:
        return "Internal Server Error"
      case 503:
        return "Service temporarily unavailable. Please try again later."
      case 404:
        return "The page you are looking for can’t be found. Please try again."
      case 400:
        return "Bad Request"
      case 401:
        return "You are not authorized to access this page."
      case 403:
        return "You do not have permission to access this page or resource."
      default:
        return "An error occurred while processing your request. Please go back to the Patient Overview and try to load the case again. If this happens repeatedly, please contact Customer Service at +1 (866) 900-8522."
    }
  }

  return (
    <Box
      component="div"
      sx={{
        width: "100vw",
        height: "100vh",
        backgroundImage: "linear-gradient(#215ECD, #16AADC, #35C749)",
      }}
    >
      <UModalPopup
        isAlert={true}
        sxModalProps={{
          ".MuiDialog-paper": {
            maxWidth: 552,
          },
          "#contentCntr": {
            padding: "40px",
          },
        }}
        content={
          <Box component={"div"} id="errorModel">
            <UText
              variant={"h3"}
              component={"div"}
              sxProp={{ my: 2, display: "flex", justifyContent: "center" }}
            >
              {error?.code || "Unknown Error"}
            </UText>
            <UText
              variant={"h6"}
              component={"div"}
              color="text.secondary"
              sxProp={{ display: "flex", justifyContent: "center" }}
            >
              {getErrorMsg(error?.code)}
            </UText>
            <UButton
              key={t("button.gotoPatientlist")}
              variant="contained"
              size="large"
              btnText={t("button.gotoPatientlist")}
              onClickHandler={() => navigate("/patients")}
              sxProp={{
                width: "100%",
                mt: 2,
                height: 42,
              }}
            ></UButton>
          </Box>
        }
        btnList={[]}
      ></UModalPopup>
    </Box>
  )
}

export default ErrorPage
