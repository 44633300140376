import { UDTabs } from "../../custom/UDTabs"
import { UDAuxAtt } from "./UDAuxAtt"
import { UDAuxProtocol } from "./UDAuxProtocol"
import { RightPage } from "../../right/RightPage"
import { Box, Tab } from "@mui/material"
import { UDAuxBiteRamp } from "./UDAuxBiteRamp"
import { useEffect } from "react"
import { wasmModule, attachmentModule } from "@/gluelayer"
import { TabContext, TabList, TabPanel } from "@mui/lab"
import React from "react"
import { UDElastics } from "./UDElastics"

export const UDAux = (props) => {
  useEffect(() => {
    if (wasmModule.isInit) {
      attachmentModule.openAttachmentModule(true)
      attachmentModule.setAttachmentSelectAbleType("NormalAttachment")
      attachmentModule.setAttachBtnDown(true)
    }

    return () => {
      attachmentModule.setAttachmentSelectAbleType("AttachmentAndTeeth")
      attachmentModule.setAttachBtnDown(false)
    }
  }, [])

  const [value, setValue] = React.useState("1")

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue)
  }
  return (
    <RightPage
      title="uDesign Auxillary"
      onClose={props.onClose}
      content={
        <Box sx={{ width: "100%", typography: "body1" }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                <Tab
                  sx={{ textTransform: "none" }}
                  label="Protocol"
                  value="1"
                />
                <Tab
                  sx={{ textTransform: "none" }}
                  label="Attachment"
                  value="2"
                />
                <Tab
                  sx={{ textTransform: "none" }}
                  label="BiteRamp"
                  value="3"
                />
                <Tab
                  sx={{ textTransform: "none" }}
                  label="Elastics"
                  value="4"
                />
              </TabList>
            </Box>
            <TabPanel value="1">
              <UDAuxProtocol />
            </TabPanel>
            <TabPanel value="2">
              <UDAuxAtt />
            </TabPanel>
            <TabPanel value="3">
              <UDAuxBiteRamp />
            </TabPanel>
            <TabPanel value="4">
              <UDElastics />
            </TabPanel>
          </TabContext>
        </Box>

        // <UDTabs labels={["Protocol", "Attachment", "BiteRamp"]}>
        //   <UDAuxProtocol />
        //   <UDAuxAtt />
        //   <UDAuxBiteRamp />
        // </UDTabs>
      }
      actions={<Box sx={{ height: "20px" }}></Box>}
    />
  )
}
