import { FC, useState } from "react"
import { useTranslation } from "react-i18next"
import CloseIcon from "@mui/icons-material/Close"

import { UModalPopup, UText } from "../../../ui-component"
import { Box, IconButton } from "../../../ui-component/mui.components"

const Shortcuts: FC = () => {
  const { t } = useTranslation("common")
  const [showShortcutPopup, setShowShortcutPopup] = useState(true)

  const shortcuts1 = {
    "Undo" : "CTRL + Z",
    "Redo" : "CTRL + Y",
  }

  const shortcuts2 = {
    "Rotate Model" : "Right Click + Drag",
    "Pan Model" : "Shift + Right Click + Drag",
    "Isolated Rotate (Pivot Model)" : "CTRL + Right Click + Drag",
    "Zoom In/Out" : "Mouse Wheel",
  }
  
  const shortcuts3 = {
    "Rotate Model" : "Right Click + Drag",
  }

  const ListItem = ({list}) => {
    return (
    <Box>
      {Object.keys(list).map((listKey, index)=>(
        <Box 
          key={listKey} 
          sx={{
            display: "flex", 
            justifyContent: "flex-start", 
            background: index%2==0 ? "#FFF":"#f4f4f4", 
            padding: "5px 20px"
          }}
        >
          <Box sx={{width:"50%"}}>
            <UText variant={"body2"}>{listKey}</UText>
          </Box>
          <Box sx={{width:"50%"}}>
            <UText variant={"body2"} sxProp={{fontWeight: "600"}}>{list[listKey]}</UText>
          </Box>
          
        </Box>
      ))}
    </Box>
    )
  }

  return (
    <UModalPopup
      data-testid={"shortcuts"}
      title={
        <Box 
          sx={{
            position: "relative",
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <UText
            variant={"h5"}
            sxProp={{
              fontWeight: "600",
            }}
          >
            {t("shortcuts.title")}
          </UText>
          <IconButton
            sx={{
              color:"#707070"
            }}
            onClick={() => {
              setShowShortcutPopup(false)
              window.close()
            }}
          >
            <CloseIcon
              sx={{
                height: "20px",
                width: "20px",
              }}
            />
          </IconButton>
        </Box>
      }
      isAlert={showShortcutPopup}
      content={
        <Box
          sx={{
            background:"#fff",
            padding: "24px 50px",
            display: "flex",
            flexDirection: "column",
            gap: "30px",
          }}
        >
          <UText variant={"h5"} sxProp={{fontWeight:"600"}}>Typography</UText>
          <ListItem list={shortcuts1} />

          <UText variant={"h5"} sxProp={{fontWeight:"600"}}>Typography</UText>
          <ListItem list={shortcuts2} />

          <UText variant={"h5"} sxProp={{fontWeight:"600"}}>Typography</UText>
          <ListItem list={shortcuts3} />

          
        </Box>
      }
      sxModalProps={{
        "& .MuiDialog-paper": {
          minWidth: "640px",
          borderRadius: "8px",
          backgroundColor: "#EBEBEC",

        },
        "#titleCntr": {
          // backgroundColor: "primary.light",
          padding: "24px 30px",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "center",
        },
        "#btnListCntr":{
          height:"20px",
        }
      }}
      btnList={[]}
    />
  )
}

export default Shortcuts
