import { FC, useEffect, useState } from "react"
import { Box } from "@mui/material"
import {
  caseManagement,
  orderView,
  viewControlInTreatment,
  wasmModule,
} from "@/gluelayer"
import { isEmpty } from "lodash"

import { useAppDispatch, useAppSelector } from "../../../core/app/hooks"
import { updateCustomization } from "../../../core/app/slices/order/orderSlice"
import { fetchClinicalSettings } from "../../../core/app/slices/user/userApis"
import { RootState } from "../../../core/app/store"
import { ULoading, UText } from "../../../ui-component"
const Recession: FC<{
  isUpper: boolean
  isLower: boolean
  setzoomValue: (arg: number) => void
  zoomValue
  wasmReady
}> = ({ isUpper, isLower, setzoomValue, zoomValue, wasmReady }) => {
  const { zipList } = useAppSelector(
    (state: RootState) => state.clinicalService,
  )
  // cutting line data
  const {
    userService: {
      clinicalSettings: { CuttingLineCC, CuttingLineCP, CuttingLineOffset },
    },
    orderService: {
      alignerOrderPayload: { recession },
    },
  } = useAppSelector((state: RootState) => state)

  const dispatch = useAppDispatch()

  useEffect(() => {
    // get setting data
    dispatch(fetchClinicalSettings())

    return () => {
      dispatch(
        updateCustomization({
          recession: orderView.getTeethSelected(),
        }),
      )
      // the interface is abandoned
      // orderView.saveScanButtonsInfoToElasticJson(orderView.getTeethSelected())
    }
  }, [])

  useEffect(() => {
    if (wasmReady) {
      viewControlInTreatment.setViewType("front")
      viewControlInTreatment.showArchMode("all")
      orderView.initAndClearOrderView([36 / 255, 46 / 255, 51 / 255])
      orderView.setTeethSelectedColor([52 / 255, 52 / 255, 53 / 255])
      !isEmpty(recession) && orderView.setDefaultTeethSelected(recession)
      viewControlInTreatment.setZoomCallbackInTreatment(setzoomValue)
      // caseManagement.setBackgroundColor(36 / 255, 46 / 255, 51 / 255)
      caseManagement.setBackgroundPic("backgroundvtk.png")
    }
  }, [wasmReady])

  useEffect(() => {
    if (!wasmReady) return
    viewControlInTreatment.zoomWithValueInTreatment(zoomValue)
  }, [zoomValue, wasmReady])

  useEffect(() => {
    if (!wasmReady) return
    viewControlInTreatment.showArchMode(
      isUpper ? "up" : isLower ? "low" : "all",
    )
  }, [isUpper, isLower, wasmReady])
  // Generate CuttinglineSetting.json file and put it in the specified directory of wasm.
  useEffect(() => {
    if (!wasmReady) return
    const cuttingObj = {
      Anterior: CuttingLineCC === 0 ? 1 : 0,
      "Offset Control": CuttingLineOffset === 0 ? 1 : 0,
      Posterior: CuttingLineCP === 0 ? 1 : 0,
    }
    console.log("cuttingObj:", cuttingObj)
    caseManagement
      .saveJsonDataToZip(
        JSON.stringify(cuttingObj),
        "CuttinglineSetting.json",
        "Setting Data2",
      )
      .then((bool) => {
        if (bool) {
          console.log(
            "CuttinglineSetting.json has been successfully deposited to the virtual directory!",
          )
        } else {
          console.log(
            "Failed to store CuttinglineSetting.json to virtual directory!",
          )
        }
      })
  }, [CuttingLineCC, CuttingLineCP, CuttingLineOffset, wasmReady])
  return (
    <>
      <ULoading isLoading={!wasmReady} />
      <Box sx={{ marginBottom: 2, textAlign: "center" }}>
        <UText variant={"caption"}>
          Select tooth/teeth if recession fill is needed
        </UText>
      </Box>
    </>
  )
}
export default Recession
