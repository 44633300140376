import React, { useRef, useEffect } from 'react';
 
const StageAddIcon = (props) => {
  const canvasRef = useRef(null)
  const position = (props.position === undefined || props.position === null ? "unset" : props.position)
  const left = (props.left === undefined || props.left === null ? "unset" : (props.left - 6))
  const top = (props.top === undefined || props.top === null ? "unset" : (props.top))


  const drawImg = () => {
    const canvas = canvasRef.current
    const rect = canvas.getBoundingClientRect()
    const ctx = canvas.getContext('2d')

    ctx.clearRect(0, 0, rect.width, rect.height)
    if(props.visibility === "hidden"){
      return
    }

    if(props.status === "add") {
      // 绘制一个+
      ctx.fillStyle = '#4B4B4B'
      ctx.lineWidth = 1
      ctx.beginPath()
      ctx.moveTo(rect.width / 2, 0)
      ctx.lineTo(rect.width / 2, rect.height)
      ctx.stroke()

      ctx.beginPath()
      ctx.moveTo(0, rect.height / 2)
      ctx.lineTo(rect.width, rect.height / 2)
      ctx.stroke()
    }
    else if(props.status === "del") {
      // 绘制一个x
      ctx.fillStyle = '#4B4B4B'
      ctx.lineWidth = 1
      ctx.beginPath()
      ctx.moveTo(0, 0)
      ctx.lineTo(rect.width, rect.height)
      ctx.stroke()

      ctx.beginPath()
      ctx.moveTo(rect.width, 0)
      ctx.lineTo(0, rect.height)
      ctx.stroke()
    }
    
  }
 
  useEffect(() => {
    drawImg()
  }, [])

  useEffect(() => {
    drawImg()
  }, [props.visibility])

  useEffect(() => {
    drawImg()
  }, [props.status])
 
  return (
    <canvas ref={canvasRef} width="12px" height="12px" onClick={props.onClick} style={{position: position, left: left, top: top}}></canvas>
  )
}
 
export default StageAddIcon;