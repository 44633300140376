import { FC } from "react"

import {
  Menu,
  MenuItem,
  SxProps,
  Theme,
} from "../../../components/mui.components"

interface items {
  name: string
  onClick: () => void
  sx?: SxProps<Theme>
  hideMenu?: boolean
}

interface IProfileOptionsProps {
  menuItems: items[]
  anchorEl: null | HTMLElement
  isOpen: boolean
  menuProps: SxProps
  handleOnClose: () => void
}

const ProfileOptions: FC<IProfileOptionsProps> = ({
  menuItems,
  anchorEl,
  isOpen,
  menuProps,
  handleOnClose,
}) => {
  return (
    <Menu
      sx={{
        ...menuProps,
      }}
      disableAutoFocusItem={true}
      open={isOpen}
      onClose={handleOnClose}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      {menuItems.map((item) => {
        if (!item.hideMenu) {
          return (
            <MenuItem key={item.name} onClick={item.onClick} sx={item.sx}>
              {item.name}
            </MenuItem>
          )
        }
      })}
    </Menu>
  )
}

export default ProfileOptions
