

export function isNodeContains(nodeTypes, nodeType) {
  //console.info("=====nodeTypes: " + nodeTypes.length)
  if (nodeTypes.length > 0) {
    //console.info(nodeTypes[0])
  }
  let index = nodeTypes.indexOf(nodeType)
  //console.info("index:" + index)
  return index >= 0
}

class StageCommon {
  
}

export const stageCommon = new StageCommon();

