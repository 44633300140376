import { FC, useState } from "react"
import { matchPath, useLocation, useParams } from "react-router-dom"
import SettingsIcon from "@mui/icons-material/Settings"
import HomeIcon from "@mui/icons-material/Home"
import {
  Box,
  Breadcrumbs,
  IconButton,
  Link,
  Typography,
} from "@/components/mui.components"
import UText from "@/ui-component/components/UText"
import { useAppSelector } from "@/core/app/hooks"
import { RootState } from "@/core/app/store"
import { useMyNavigation } from "@/hooks/useMyNavigation"
import ProfileOptions from "./ProfileOptions"
import { useOktaAuth } from "@okta/okta-react"

const CsHeader: FC = () => {
  const navigate = useMyNavigation()
  const location = useLocation()
  const { orgId } = useParams()
  const { oktaAuth } = useOktaAuth()
  const logOutRedirect = async () => oktaAuth.signOut()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [isCsProfileOpen, setIsCsProfileOpen] = useState(false)

  const { user } = useAppSelector((state: RootState) => state.userService)
  const { organizations } = useAppSelector(
    (state: RootState) => state.organizationService,
  )
  const getOrgNameByOrgID = () => {
    return organizations.find((org) => org.org_id + "" === user.current_orgId)
  }

  const handleClick = (path: string) => {
    navigate(path)
  }

  const menuItems = [
    {
      name: "Settings",
      id: "SETTINGS",
      showMenu: true,
      onClick: () => {
        setIsCsProfileOpen(false)
        navigate("/settings/userProfile")
      },
    },
    {
      name: "uDesign App",
      id: "UDESIGN_APP",
      showMenu: true,
      onClick: () => {
        setIsCsProfileOpen(false)
        navigate("/UDesignApp")
      },
    },
    {
      name: "Logout",
      showMenu: true,
      onClick: logOutRedirect,
    },
  ]

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        py: "10px",
        px: "24px",
        backgroundColor: "#616161",
      }}
    >
      <Box sx={{ display: "flex", gap: 3, alignItems: "center" }}>
        <UText
          variant={"h6"}
          color="primary.contrastText"
          sxProp={{ mt: "-3px" }}
        >
          {"CS Portal"}
        </UText>

        <Breadcrumbs
          separator="›"
          aria-label="breadcrumb"
          sx={{ color: "primary.contrastText" }}
        >
          <Link
            underline="hover"
            sx={{ color: "primary.contrastText", cursor: "pointer" }}
            onClick={() => handleClick("/organization")}
          >
            <Box sx={{ display: "flex", gap: 1 }}>
              <HomeIcon fontSize="small" sx={{ width: "16px" }} />
              <Typography variant="body1" color="primary.contrastText">
                {"Organizations"}
              </Typography>
            </Box>
          </Link>

          {user?.my_orgId !== user?.current_orgId &&
            !matchPath("/organization", location.pathname) && (
              <Typography variant="body1" color="primary.contrastText">
                {getOrgNameByOrgID()?.name || orgId}
              </Typography>
            )}
        </Breadcrumbs>
      </Box>

      <IconButton
        sx={{ color: "primary.contrastText" }}
        onClick={(e) => {
          setAnchorEl(e.currentTarget)
          setIsCsProfileOpen(!isCsProfileOpen)
        }}
      >
        <SettingsIcon />
      </IconButton>
      <ProfileOptions
        menuItems={menuItems}
        anchorEl={anchorEl}
        isOpen={isCsProfileOpen}
        handleOnClose={() => {
          setAnchorEl(null)
          setIsCsProfileOpen(false)
        }}
        menuProps={{
          ".MuiPaper-root": {
            minWidth: "200px",
          },
        }}
      />
    </Box>
  )
}

export default CsHeader
