// return object
// {
//     hasAnyphotos:boolean,
//     hasPhotos:boolean,
//     hasXrays:boolean
// }
export const getResultByPhotoJson = (photoTypeJson: undefined | object) => {
  const photosTmp = [
    "front_normal",
    "front_smile",
    "lateral_photo",
    "upper",
    "composite",
    "lower",
    "left",
    "right",
    "front",
  ]
  const xraysTmp = ["x_lateral", "x_panorama"]
  const resObj = {
    hasAnyphotos: false,
    hasPhotos: false,
    hasXrays: false,
  }
  if (photoTypeJson && Object.keys(photoTypeJson).length > 1) {
    resObj.hasAnyphotos = true
    for (const key in photoTypeJson) {
      if (photosTmp.includes(photoTypeJson[key])) {
        resObj.hasPhotos = true
      }
      if (xraysTmp.includes(photoTypeJson[key])) {
        resObj.hasXrays = true
      }
    }
  }
  return resObj
}
