import axios, { AxiosProgressEvent } from "axios"

import httpIT from "@/core/global/httpIT"
import HttpS3 from "@/core/global/https3"

import { getResizedFile } from "../../../../../modules/Records/Photograph/photographUtil"
import http from "../../../../global/http"


interface IUploadFileOptions{
  orgId: string,
  patientId: string,
  caseId: string,
  files:File[],
  callback:(progress: AxiosProgressEvent) => void,
  refinementIndex?:number
}

export async function uploadFiles({
  patientId,
  caseId,
  files,
  orgId,
  callback,
}:IUploadFileOptions) {
  return Promise.all(
    files.map( file  => {
      const formData = new FormData()
      formData.append("attachment", file)
      return http.post(
        `/patient-mgmt/v1/org/${orgId}/patients/${patientId}/txplans/${caseId}/cases/${file.name}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: callback,
        },
      )
    }),
  )
}


export const uploadThumbnails = ({
  orgId,
  patientId,
  caseId,
  files,
  callback,
  refinementIndex
}:IUploadFileOptions
)=>{
  return Promise.all(files.map(async file=>{
    const formData =  new FormData();
    formData.append("attachment", file)
    if (refinementIndex) {
      formData.append("is_refinement",refinementIndex?"1":"0")
      formData.append("course_check_number",refinementIndex?`${refinementIndex}`:"0")
    }
    const ret = await http.post(
      `/patient-mgmt/v2/org/${orgId}/patients/${patientId}/txplans/${caseId}/photographs/${file.name}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: callback,
      },
    )
    return ret;
  }))
}


export const uploadOnePhotoFile = (
  orgId:string,
  patientId:string,
  caseId:string,
  fileName:string,
  formData:FormData,
  callback:(progress:AxiosProgressEvent)=>void
) => {
  const url = `/patient-mgmt/v2/org/${orgId}/patients/${patientId}/txplans/${caseId}/photographs/${fileName}`
  try{
    return getResizedFile(formData.get("attachment")).then(
      (resizedFile:File)=>{
        formData.set("attachment",resizedFile)
        const res = http.post(url,formData,{
          headers:{
            "Content-Type":"multipart/form-data"
          },
          onUploadProgress:callback
        })
        return res
      }
    )
  }catch(err){
    return err
  }
}


/**
 * Notice: the file type must be 'image/jpeg' , or else it will get error 400.
 * @param param0 
 * @returns 
 */
export const uploadPhotographFiles = (
  {
    orgId,
    patientId,
    caseId,
    files,
    callback,
    refinementIndex
  }:IUploadFileOptions
) => {
  return Promise.all(files.map(async file=>{
    const newFile = await getResizedFile(file)
    const formData =  new FormData();
    formData.append("attachment", newFile)
    if (refinementIndex) {
      formData.append("is_refinement",refinementIndex?"1":"0")
      formData.append("course_check_number",refinementIndex?`${refinementIndex}`:"0")
    }
    
    return http.post(
      `/patient-mgmt/v2/org/${orgId}/patients/${patientId}/txplans/${caseId}/photographs/${newFile.name}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: callback,
      },
    )
  }))

}

export const getS3FileUrl = ({ caseId, casePhoto }) => {
  return httpIT.post(`/ulab/case/api/v2/case/s3url`, {
    caseId,
    urlType: "upload",
    casePhoto,
  })
}

export const uploadPhoto = ({ url, file }) => {
  const res = HttpS3({ url }).put("", file)
  return res
}

export const downloadPhotographFiles = async(
  orgId: string,
  patientId: string | undefined,
  caseId: string | undefined,
  fileName: string,
  refinementIndex = 0
) => {

  let url = `/patient-mgmt/v2/org/${orgId}/patients/${patientId}/txplans/${caseId}/photographs/${fileName}`

  // if(refinementIndex){
  //   // const dotIndex = fileName.indexOf('.');
  //   const splitArray = fileName.split('.');
  //   const prefix = splitArray[0]//fileName.substring(0,dotIndex) 
  //   const postfix = splitArray[1]//fileName.substring(dotIndex)
  //   console.log("download url::000",prefix,postfix);
  //   const newName = prefix+ (refinementIndex?`_refinement_${refinementIndex}.`:"") + postfix;
  //   url = `/patient-mgmt/v2/org/${orgId}/patients/${patientId}/txplans/${caseId}/photographs/${newName}`
  // }
  //console.log("download url::",url);
  try {
    const res = await http.get(url, {
      responseType: "blob",
    })
    const retFile = new File([res.data],fileName)
    return retFile
  } catch (err) {
    return err
  }
}

export const fetchFileList = (
  orgId: string,
  patientId: string | undefined,
  caseId: string | undefined,
) => {
  const url = `/patient-mgmt/v1/org/${orgId}/patients/${patientId}/txplans/${caseId}/files`
  try {
    const res = http.get(url)
    return res
  } catch (err) {
    return err
  }
}

export const updatePhotoPositionAPI = (
  orgId: string,
  patientId: string | undefined,
  caseId: string | undefined,
  payload: {
    photo_position: string
  },
) => {
  const url = `patient-mgmt/v2/org/${orgId}/patients/${patientId}/txplans/${caseId}/photographs`
  try {
    const res = http.patch(url, payload)
    return res
  } catch (err) {
    return err
  }
}

export const uploadPhotoJSONAPI = (
  orgId: string,
  patientId: string ,
  caseId: string,
  jsonFile:File,
  refinementIndex=0,
  callback?:(progressEvent: AxiosProgressEvent)=>void
)=>{
  const url = `patient-mgmt/v2/org/${orgId}/patients/${patientId}/txplans/${caseId}/photopositiontype/type${refinementIndex?refinementIndex:""}.json`;
  console.log("uploadjsonurl:",url)

  try {
    const formData = new FormData;
    formData.append('attachment',jsonFile);
    // if (refinementIndex) {
    //   formData.append("is_refinement",refinementIndex?"1":"0")
    //   formData.append("course_check_number",refinementIndex?`${refinementIndex}`:"0")
    // }
    const res = http.post(
      url,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: callback,
      },)
    return res
  } catch (err) {
    return err
  }
}



export const downloadPhotoJSONAPI = (
  orgId: string,
  patientId: string ,
  caseId: string,
  refinementIndex = 0,
  callback?:(progressEvent: AxiosProgressEvent)=>void
)=>{
  
  const url = `patient-mgmt/v2/org/${orgId}/patients/${patientId}/txplans/${caseId}/photopositiontype/type${refinementIndex?refinementIndex:""}.json`;
  console.log('downloadjson:::',url)
  
  try {
    const res = http.get(
      url,
      {
        onDownloadProgress: callback,
      },)
    return res
  } catch (err) {
    return err
  }

}

export const deletePhotographFile = async(
  orgId: string,
  patientId: string | undefined,
  caseId: string | undefined,
  fileName: string,
) => {
  const url = `/patient-mgmt/v2/org/${orgId}/patients/${patientId}/txplans/${caseId}/photographs/${fileName}`
  try {
    const res = await http.delete(url)
    const retFile = new File([res.data],fileName)
    return retFile
  } catch (err) {
    return err
  }
}