import { FC, useEffect } from "react"
import { ControlProps } from "@jsonforms/core"
import { withJsonFormsControlProps } from "@jsonforms/react"

import { UText } from "../../../components"
import { Box } from "../../../components/mui.components"

const USummary: FC<ControlProps> = ({
  schema,
  uischema,
  errors,
  path,
  label,
  data,
  handleChange,
}) => {
  const summaryStyle = {
    width: 316,
    ml: 1,
    lineHeight: "16px",
    color: "text.primary",
    whiteSpace: "break-spaces",
  }

  const findLabelByValue = (data: any, schema: object) => {
    const output = []

    schema.checkbox.forEach((item2) => {
      const value2 = data[item2.value]

      if (
        (data &&
          Array.isArray(data) &&
          data.length &&
          data.includes(item2.value)) ||
        (data &&
          Array.isArray(data.value) &&
          data.value.length &&
          data.value.includes(item2.value))
      ) {
        const label2 = item2.label
        output.push(`${label2} ${value2 ? "\n" + value2 : ""}`)
      }
    })
    return output.join("\n")
  }

  return (
    <>
      <Box
        className="summary-data-row"
        sx={{ py: 1, mb: "4px", display: "flex", flexDirection: "row", pl: 1 }}
      >
        <UText
          variant={"body2"}
          sxProp={{
            width: 200,
            color: "rgba(0, 0, 0, 0.60)",
          }}
        >
          {label}
        </UText>
        <Box>
          {data && (
            <>
              <UText
                component={"div"}
                variant={"subtitle2"}
                sxProp={{
                  ...summaryStyle,
                  fontWeight: schema.fontweight,
                }}
              >
                {typeof data === "string"
                  ? data
                  : typeof data === "object" && typeof data.value === "string"
                  ? data.checkbox
                    ? data.value +
                      "\n" +
                      findLabelByValue(data.checkbox, schema)
                    : data.value
                  : Array.isArray(data.value) || Array.isArray(data)
                  ? findLabelByValue(data, schema)
                  : ""}
                {(data.instructions || data.stage) && "\n"}
                {data.instructions || data.stage}
              </UText>
            </>
          )}
        </Box>
      </Box>
    </>
  )
}

export default withJsonFormsControlProps(USummary)
