import React, { useCallback, useEffect, useState, useRef } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { Trans, useTranslation } from "react-i18next"
import { useAppSelector, useAppDispatch } from "@/core/app/hooks"
import { Typography, Button, Box, Alert } from "@mui/material"
import Divider from "@mui/material/Divider"
import {
  EArchTreatmentType,
  EArchType,
  caseManagement,
  preview,
  resourcesSynchronization,
  wasmModule,
} from "@/gluelayer"
import { UButton, UFileBox, UFileProcessingCard, UText } from "@/ui-component"
import {
  uploadFiles,
  downloadFiles,
  requestCloudPresetup,
  testForLoading,
} from "@/core/app/slices/v2/v2apis"
import { resetCase } from "@/core/app/slices/clinical/clinicalSlice"
import store from "@/core/app/store"
import {
  fetchFileList,
  getCaseInfoApi,
  getTreatmentInfos,
  uploadCaseZips,
} from "@/core/app/slices/clinical/clinicalThunkApi"
import { LeftPage } from "../left/LeftPage"
import { RootState } from "@/core/app/store"
import { updateCaseById } from "@/core/app/slices/case/caseThunkApi"
import oktaAuthClient, { getAccessToken } from "@/core/config/okta.config"
import {
  setShowBottomStatusBar,
  setBottomStatusMsg,
  setBottomProgress,
  setHasUploadSTLs,
} from "../udTreatSlice"

import { UploadCaseDlg } from "./UploadCaseDlg"
import { UploadZips } from "@/core/app/slices/clinical/clinical.types"
import { setOpenReopenCaseDlg } from "../udTreatSlice"
import JSZip from "jszip"

// For uCloud 2.0
export function UploadSTLV2({ onClose }) {
  const dispatch = useAppDispatch()
  const { VITE_WEBSOCKET_URL } = import.meta.env
  const { t } = useTranslation("common")
  const { caseDetail } = useAppSelector((state: RootState) => state.caseService)
  const [upperStl, setUpperStl] = useState({ data: null, action: "init" })
  const [lowerStl, setLowerStl] = useState({ data: null, action: "init" })
  const [caseType, setCaseType] = useState<string>(null)
  const { caseId, patientId } = useParams()
  const orgId = store.getState().userService.user.current_orgId
  const [openSaveCaseDlg, setOpenSaveCaseDlg] = useState(false)
  const [warningMsg, setWarningMsg] = useState("")
  const showUDPresetupBtn = import.meta.env.VITE_NODE_ENV === '"local"'

  const { isCasePreview, ucloud1_1, caseRAtype } = useAppSelector(
    (state: RootState) => state.udTreatService,
  )

  const runAIPresetup = async () => {
    // dispatch(setBottomStatusMsg("Renew token."))
    // const renewToken = await oktaAuthClient.token.renewTokens()
    // await oktaAuthClient.tokenManager.setTokens(renewToken)
    // Upload raw data and do presetup
    dispatch(setBottomStatusMsg("Save STL to MTC."))
    preview.saveStlToMtc(upperStl.data, lowerStl.data)
    // fill holes
    dispatch(setBottomStatusMsg("Fill hole."))
    await preview.fillHole()
    dispatch(setBottomStatusMsg("Get Case Files."))
    const files = await caseManagement.getCaseFiles()
    // upload
    dispatch(setBottomStatusMsg("Upload STL to AWS."))
    dispatch(setBottomProgress(true))

    // const envStr = import.meta.env.VITE_NODE_ENV
    // if (envStr === '"local"' || envStr === '"qausa"') {
    //   const zips = []
    //   for (const k in files) {
    //     zips.push({
    //       fileName: k,
    //       file: files[k],
    //     })
    //   }
    //   const isOkZip = await dispatch(
    //     uploadCaseZips({ patientId, caseId, zips }),
    //   )
    //   console.log("上传成功了吗", isOkZip.payload)
    // } else {
    //   const isOk = await dispatch(uploadFiles({ caseId, zipFiles: files }))
    //   // const downloadUrl = await dispatch(getS3DownloadUrl({caseId,files:['bulk0']}))
    //   console.log("上传成功了吗", isOk.payload)
    // }
    const zips = []
    for (const k in files) {
      zips.push({
        fileName: k,
        file: files[k],
      })
    }
    const isOkZip = await dispatch(uploadCaseZips({ patientId, caseId, zips }))
    console.log("上传成功了吗", isOkZip.payload)

    // update udesign_json
    if (caseDetail) {
      const udesign_json = caseDetail.udesign_json
        ? JSON.parse(caseDetail.udesign_json)
        : {}
      const newUdesignJsonObj = {
        ...udesign_json,
        upperUploaded: upperStl.data ? true : false,
        lowerUploaded: lowerStl.data ? true : false,
      }
      dispatch(setBottomStatusMsg("Update case by Id."))
      dispatch(
        updateCaseById({
          patientId,
          caseId,
          payload: {
            case_extra_attrs: JSON.stringify(newUdesignJsonObj),
          },
        }),
      )
    }
    dispatch(setBottomProgress(false))

    //invoke presetup
    const hasUpper = upperStl.data ? true : false
    const hasLower = lowerStl.data ? true : false
    console.log("do presetup::", hasUpper, hasLower)
    dispatch(setBottomStatusMsg("Running AI presetup."))
    dispatch(setBottomProgress(true))
    const isPresetupOK = await dispatch(
      requestCloudPresetup({
        orgId,
        upper: hasUpper,
        lower: hasLower,
        patientId,
        caseId,
      }),
    )
    dispatch(
      setBottomStatusMsg(
        "AI presetup result:" + isPresetupOK.payload ? "Success" : "Failed",
      ),
    )
    dispatch(setBottomProgress(false))

    console.log("presetup finished?", isPresetupOK)
    if (isPresetupOK.payload === false) {
      console.warn("Presetup is false.")
      return
    }
    dispatch(setShowBottomStatusBar(false))
    dispatch(setOpenReopenCaseDlg(true))
  }

  const runUDAIPresetup = async () => {
    const uploadFiles = []
    dispatch(setBottomStatusMsg("Save STL to MTC."))
    preview.saveStlToMtc(upperStl.data, lowerStl.data)
    dispatch(setBottomStatusMsg("Fill hole."))
    await preview.fillHole()
    if (upperStl.data) uploadFiles.push("arch_u.mtc")
    if (lowerStl.data) uploadFiles.push("arch_l.mtc")
    let connect = false
    dispatch(setBottomStatusMsg("Running AI presetup."))
    const ws = new WebSocket(
      `${VITE_WEBSOCKET_URL}/patient-mgmt/v1/org/${orgId}/patients/${patientId}/txplans/${caseId}/ai-presetup-for-ud`,
      getAccessToken(),
    )
    ws.onopen = () => {
      ws.send(
        JSON.stringify({
          type: "presetup_upload",
          body: {
            upload_file: uploadFiles,
          },
        }),
      )
      connect = true
    }
    ws.onmessage = async function ({ data }) {
      try {
        const { body, type } = JSON.parse(data)
        console.log("presetup:::msg::", data)
        switch (type) {
          case "response_presetup_upload":
            if (body.result === 0) {
              const mtcFiles = resourcesSynchronization.getMtcsFromURL([
                "/test/case/Raw/arch_o_u.mtc",
                "/test/case/Raw/arch_o_l.mtc",
              ])
              Promise.all([
                new Promise(function (resolve, reject) {
                  const uploadRqU = new XMLHttpRequest()
                  uploadRqU.open("PUT", body.upload_url["arch_u.mtc"], true)
                  uploadRqU.onload = function () {
                    console.log("upper arch upload success************")
                    resolve(true)
                  }
                  uploadRqU.send(mtcFiles.get("arch_o_u.mtc"))
                }),
                new Promise(function (resolve, reject) {
                  const uploadRqL = new XMLHttpRequest()
                  uploadRqL.open("PUT", body.upload_url["arch_l.mtc"], true)
                  uploadRqL.onload = function () {
                    console.log("lower arch upload success************")
                    resolve(true)
                  }
                  uploadRqL.send(mtcFiles.get("arch_o_l.mtc"))
                }),
              ]).then((results) => {
                if (results[0] && results[1]) {
                  const preBody = {
                    type: "presetup_launch",
                    body: {
                      jaw_upper: "arch_u.mtc",
                      jaw_lower: "arch_l.mtc",
                      orientation: true,
                      desktop_case_format: false,
                    },
                  }
                  ws.send(JSON.stringify(preBody))
                }
              })
            }
            break
          case "response_presetup_launch":
            if (body.result === 2) {
              await dispatch(resetCase())
              caseManagement.closeCase()
              const downResult = new XMLHttpRequest()
              downResult.open("GET", body.download_url, true)
              downResult.responseType = "blob"
              downResult.onload = async function () {
                if (downResult.response && downResult.status == 200) {
                  const resultZip = new JSZip()
                  const zips = []
                  const zipData = await resultZip.loadAsync(downResult.response)
                  for (const [name, file] of Object.entries(zipData.files)) {
                    if (name.endsWith(".zip")) {
                      const zipBlob = await file.async("blob")
                      zips.push({
                        fileName: name,
                        file: new File([zipBlob], name, {
                          type: "application/zip",
                        }),
                      })
                    }
                  }
                  const isOkZip = await dispatch(
                    uploadCaseZips({ patientId, caseId, zips }),
                  )
                  console.log("上传成功了吗", isOkZip.payload)

                  caseManagement.setCallbackAfterOpencase(() => {
                    caseManagement.setBackgroundPic("backgroundvtk.png")
                    dispatch(setHasUploadSTLs([true, true]))
                  })
                  caseManagement.openCaseFromZip(
                    document.getElementById("canvas") as HTMLCanvasElement,
                    downResult.response,
                  )
                  dispatch(setShowBottomStatusBar(false))
                }
              }
              downResult.send()
              ws.close()
            }
            break
        }
      } catch (err) {
        console.error("handle AI message error:", err, data)
      }
    }
  }

  const UploadSTLBoxV2 = () => {
    return (
      <Box
        sx={{
          width: "410px",
          height: "100%",
          background: "#fff",
          padding: 2,
          borderRadius: 1,
        }}
      >
        {warningMsg !== "" && <Alert severity="warning">{warningMsg}</Alert>}
        <Box id="upper">
          <UText variant={"caption"}>Upper</UText>
          {upperStl.action === "stl" || upperStl.action === "download" ? (
            <UFileProcessingCard
              fileName={
                upperStl.action === "stl"
                  ? upperStl.data?.name
                  : "Uploaded Scan"
              }
              fileSize={upperStl.data?.size}
              progressValue={100}
              progressstatus={"complete"}
              onRemove={() => {
                setUpperStl({ data: null, action: "delete" })
              }}
            ></UFileProcessingCard>
          ) : (
            <UFileBox
              id={"Upper"}
              allowedFileExtensions={["stl", "meshinspector"]}
              allowedfileSize={51200}
              selectedFile={(file) => {
                setUpperStl({ data: file[0], action: "stl" })
              }}
              fileAcceptType={".stl"}
              boxSize={{
                width: "100%",
                height: 88,
                padding: "24px 16px",
              }}
              fileBoxContent={{
                flexDirection: "row",
                gap: 2,
              }}
              isRequired={false}
              messages={{
                fileNote: t("records.scans.fileNote"),
                uploadButton: t("records.scans.uploadButton"),
                uploadButtonSuffix: t("records.scans.uploadButtonSuffix"),
                invalidfileFormat: t("records.scans.invalidfileFormat"),
                invalidFileSize: t("records.scans.invalidSTLFileSize"),
              }}
              fileLimit={1}
            ></UFileBox>
          )}
        </Box>
        <Box id="lower">
          <UText variant={"caption"}>Lower</UText>
          {lowerStl.action === "stl" || lowerStl.action === "download" ? (
            <UFileProcessingCard
              fileName={
                lowerStl.action === "stl"
                  ? lowerStl.data?.name
                  : "Uploaded Scan"
              }
              fileSize={lowerStl.data?.size}
              progressValue={100}
              progressstatus={"complete"}
              onRemove={() => {
                setLowerStl({ data: null, action: "delete" })
              }}
            ></UFileProcessingCard>
          ) : (
            <UFileBox
              id={"Lower"}
              allowedFileExtensions={["stl", "meshinspector"]}
              allowedfileSize={101200}
              selectedFile={(file) => {
                setLowerStl({ data: file[0], action: "stl" })
              }}
              fileAcceptType={".stl"}
              boxSize={{
                width: "100%",
                height: 88,
                padding: "24px 16px",
              }}
              fileBoxContent={{
                flexDirection: "row",
                gap: 2,
              }}
              isRequired={false}
              messages={{
                fileNote: t("records.scans.fileNote"),
                uploadButton: t("records.scans.uploadButton"),
                uploadButtonSuffix: t("records.scans.uploadButtonSuffix"),
                invalidfileFormat: t("records.scans.invalidfileFormat"),
                invalidFileSize: t("records.scans.invalidSTLFileSize"),
              }}
              fileLimit={1}
            ></UFileBox>
          )}
        </Box>
        <Button variant="contained" onClick={runAIPresetup}>
          AI Presetup
        </Button>
        {showUDPresetupBtn && (
          <Button variant="contained" onClick={runUDAIPresetup}>
            AI Presetup_UD
          </Button>
        )}
      </Box>
    )
  }
  const cloneFile: File[] = [null, null]
  useEffect(() => {
    let mtcFile = null
    for (let i = 0; i < 2; i++) {
      mtcFile = resourcesSynchronization.getFile(
        "test/case/Raw/arch_o_" + (i == 0 ? "u.mtc" : "l.mtc"),
      )
      if (!mtcFile) {
        mtcFile = resourcesSynchronization.getFile(
          "test/case/Setting Data0/arch_" + (i == 0 ? "u.mtc" : "l.mtc"),
        )
      }
      if (!mtcFile) continue
      // create our clone
      cloneFile[i] = new File([mtcFile], mtcFile.name, {
        type: mtcFile.type,
        lastModified: mtcFile.lastModified,
      })
    }
    if (!isCasePreview) {
      setWarningMsg(
        "AI Presetup has been done for this case. Upload new STLs will replace old ones. ",
      )
      dispatch(resetCase())
      caseManagement.closeCase()
    } else {
      dispatch(setShowBottomStatusBar(true))
      dispatch(setBottomStatusMsg("Create new case."))
      dispatch(setBottomProgress(false))
    }
    // Delay for 0.5s so preview can be initialized
    setTimeout(() => {
      setUpperStl({ data: cloneFile[0], action: "mtc" })
      setLowerStl({ data: cloneFile[1], action: "mtc" })
    }, 1500)
    return () => {
      // dispatch(setShowBottomStatusBar(false))
      // preview.clearPreview()
    }
  }, [])

  useEffect(() => {
    dispatch(setHasUploadSTLs([upperStl.data !== null, lowerStl.data !== null]))
    preview.drawSTL({
      upperArch: upperStl.data,
      lowerArch: lowerStl.data,
      canvas: document.getElementById("canvas") as HTMLCanvasElement,
      zoomRange: [0.25, 4],
    })
  }, [upperStl, lowerStl])

  const SelCaseType = () => {
    return (
      <Box
        sx={{
          margin: "10px 0px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Divider></Divider>
        <UText sxProp={{ margin: "10px 0px" }} variant={"subtitle2"}>
          What type of case is this?
        </UText>
        <UButton
          sxProp={{ margin: "10px 0px", color: "#ffffff" }}
          variant={"contained"}
          btnText={"ALIGNER"}
          onClickHandler={() => {
            setCaseType("Aligner")
          }}
        ></UButton>
        <UButton
          sxProp={{ color: "#ffffff" }}
          variant={"contained"}
          btnText={"RETAINER"}
          onClickHandler={() => {
            setCaseType("Retainer")
          }}
        ></UButton>
      </Box>
    )
  }

  const onClickSaveCaseOK = () => {
    preview.saveStlToMtc(upperStl.data, lowerStl.data)
    const caseType =
      caseRAtype === "alinger"
        ? EArchTreatmentType.Aligner
        : EArchTreatmentType.Retainer
    preview.saveArchType(caseType, caseType)

    caseManagement.getCaseFiles().then((r) => {
      const zips = []
      if (r) {
        for (const k in r) {
          zips.push({
            fileName: k,
            file: r[k],
          })
        }
        const params = {
          patientId: patientId,
          caseId: caseId,
          zips: zips,
        } as UploadZips
        console.log("🚀 ~ caseManagement.getCaseFiles ~ zips:", zips)
        dispatch(uploadCaseZips(params))
        setOpenSaveCaseDlg(false)
      }
    })
  }

  return (
    <>
      <LeftPage
        title="Upload STL and start case"
        onClose={onClose}
        content={<UploadSTLBoxV2 />}
      />
      <UploadCaseDlg
        open={openSaveCaseDlg}
        onCancel={() => {
          setOpenSaveCaseDlg(false)
        }}
        onOK={onClickSaveCaseOK}
      />
    </>
  )
}
