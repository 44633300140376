import { FC, useCallback, useEffect, useState } from "react"
import { FieldValues, SubmitHandler, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useOktaAuth } from "@okta/okta-react"
import { debounce } from "lodash"

import {
  FormAutoComplete,
  FormInput,
  UButton,
  UText,
} from "../../../components"
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
} from "../../../components/mui.components"
import { useAppSelector } from "../../../core/app/hooks"
import { useAppDispatch } from "../../../core/app/hooks"
import { setAlert } from "../../../core/app/slices/alert/alertSlice"
import {
  IBillPayload,
  ICreditCard,
  IGetCardPayload,
  IUpdateCardPayload,
} from "../../../core/app/slices/user/user.type"
import {
  fetchCountryList,
  fetchStateLists,
  getCreditCardDetails,
  saveCreditCard,
  updateCreditCard,
} from "../../../core/app/slices/user/userApis"
import { RootState } from "../../../core/app/store"
import { UI_PERMISSION } from "../../../core/utils/access/permissionConstants"
import usePermissions from "../../../hooks/userPermission"
import { AddressAutocomplete } from "../../../ui-component/components/UAutoComplete/UAddressAutoComplete"

import { ICardDetails } from "./adyen.type"
import { PaymentContainer } from "./AdyenForm"
import { renderUserInfoSection } from "./UserProfile"

const PaymentDetails: FC<{ isCardExpiredModel?: boolean }> = ({
  isCardExpiredModel,
}) => {
  const { hasAny } = usePermissions()
  const { t } = useTranslation("common")
  const {
    control,
    formState: { errors },
    setValue,
    handleSubmit,
    trigger,
  } = useForm({ mode: "onSubmit" })
  const { oktaAuth } = useOktaAuth()
  const logOutRedirect = async () => oktaAuth.signOut()
  const [consentAccepted, setConsentAccepted] = useState<boolean>(false)
  const [editPayment, setEditPayment] = useState<boolean>(false)
  const [cardInfo, setCardInfo] = useState<ICardDetails>({} as ICardDetails)
  const [selectedState, setSelectedState] = useState<string>("")
  const [selectedAddress, setSelectedAddress] = useState(null)
  const [inputValue, setInputValue] = useState("")
  const [stateName, setStateName] = useState(null)
  const dispatch = useAppDispatch()
  const {
    countries,
    states,
    paymentInfo,
    saveOrUpdatecreditCardStatus,
    suggestedAddressData,
  } = useAppSelector((state: RootState) => state.userService)

  const setAddressDetails = () => {
    if (paymentInfo?.billing_address) {
      setValue("country", paymentInfo.billing_address.country)
      setValue("street", paymentInfo.billing_address.address_line)
      setInputValue(paymentInfo.billing_address.address_line)
      setValue("city", paymentInfo.billing_address.city)
      setValue("state", paymentInfo.billing_address.state)
      setSelectedState(paymentInfo.billing_address.state)
      setValue("zip", paymentInfo.billing_address.zip_code)
    }
  }

  useEffect(() => {
    paymentInfo && setAddressDetails()

    if (
      paymentInfo?.billing_address?.country &&
      countries &&
      countries.length
    ) {
      const countryItem = countries.find(
        (item) => item.label === paymentInfo?.billing_address?.country,
      )
      dispatch(fetchStateLists({ countryid: countryItem.id }))
    }
  }, [paymentInfo, countries])

  const setSuggesstionDetails = () => {
    if (selectedAddress) {
      setValue("country", "USA")
      setValue("street", selectedAddress?.street_line)
      setValue("city", selectedAddress?.city)
      if (stateName) {
        setValue("state", stateName)
        setSelectedState(stateName)
      }
      setValue("zip", selectedAddress?.zipcode)
    }
  }

  useEffect(() => {
    setSuggesstionDetails()
  }, [selectedAddress])

  useEffect(() => {
    dispatch(fetchCountryList()).then((res) => {
      const { status, result } = res.payload
      if (result && status === "Success") {
        const [defaultCountry] = result.filter((item) => item.name === "USA")
        defaultCountry && dispatch(fetchStateLists({ countryid: defaultCountry.id }))
      }
    })
  }, [])

  const handleInputChange = (newInputValue: string) => {
    setInputValue(newInputValue)
    if (!newInputValue) {
      setValue("street", "")
      setInputValue("")
      setSelectedAddress(null)
    }
  }

  const handleChange = (event, value) => {
    const selectedObject = suggestedAddressData.find(
      (option) => option.street_address === value,
    )
    const stateObject = states.find(
      (item) => item.code.toString() === selectedObject.state.toString(),
    )
    setStateName(stateObject?.label)
    setSelectedAddress(selectedObject)
    setInputValue(selectedObject?.street_line)
  }

  const updateCardInfo = (cardDetails: ICardDetails) => {
    setCardInfo(cardDetails)
  }

  const alert = useCallback(
    (res: any) => {
      const { payload } = res
      if (payload.response_code === 201) {
        dispatch(getCreditCardDetails())
        !isCardExpiredModel && setEditPayment(false)
        dispatch(
          setAlert({
            message: t("userProfile.paymentSuccess"),
          }),
        )
      } else {
        const errorMessage = payload?.data?.errors[0]?.error_message || t("userProfile.paymentFailed");
        dispatch(
          setAlert({
            message: errorMessage,
            isError: true,
          }),
        )
      }
    },
    [saveOrUpdatecreditCardStatus],
  )
  
  const onSubmit: SubmitHandler<FieldValues> = (data: any) => {
    const comp = window.checkout.components[0]
    if (!comp.state.isValid) {
      return comp.showValidation()
    }
    if (comp.state.isValid && cardInfo) {
      const address: IBillPayload = {
        name: cardInfo.holderName,
        address_line: data.street,
        city: data.city,
        state: data.state,
        zip_code: data.zip,
        country: data.country,
      }
      const card: ICreditCard = {
        card_number: cardInfo.encryptedCardNumber,
        expiration_date: `${cardInfo.encryptedExpiryMonth}/${cardInfo.encryptedExpiryYear}`,
        card_code: cardInfo.encryptedSecurityCode,
      }
      //update card
      if (paymentInfo?.customer_payment_profile_id) {
        const obj: IUpdateCardPayload = {
          customer_profile_id: paymentInfo.customer_profile_id,
          customer_payment_profile_id: paymentInfo.customer_payment_profile_id,
          payment_profile: {
            bill_to: address,
            payment: {
              credit_card: card,
            },
          },
        }
        dispatch(updateCreditCard(obj)).then((res) => {
          setSelectedAddress(null)
          alert(res)
        })
      } else {
        //save card
        const obj: IUpdateCardPayload = {
          payment_profile: {
            bill_to: address,
            payment: {
              credit_card: card,
            },
          },
        }
        dispatch(saveCreditCard(obj)).then((res) => {
          setSelectedAddress(null)
          alert(res)
        })
      }
    }
  }
  useEffect(() => {
    isCardExpiredModel && setEditPayment(true)
  }, [isCardExpiredModel])

  const getAddress = useCallback(() => {
    if (paymentInfo?.billing_address) {
      return (
        <>
          {paymentInfo.billing_address.address_line || ""}
          <br />
          {paymentInfo.billing_address.address_line_1 || ""}
          <br />
          {paymentInfo.billing_address.city || ""}
          {paymentInfo.billing_address.city ? ", " : ""}
          {paymentInfo.billing_address.state || ""}
          {" " + (paymentInfo.billing_address.zip_code || "")}
          <br />
          {paymentInfo.billing_address.country || ""}
        </>
      )
    }
  }, [paymentInfo])

  const renderCardDetailSection = (label: string, value: string) =>
    renderUserInfoSection(label, value || "--")

  const renderCardDetails = (paymentInfo: IGetCardPayload) => (
    <>
      {renderCardDetailSection(
        t("userProfile.addressOnFile.cardDetails.cardNum"),
        paymentInfo?.card_details
          ? "**** **** **** " + paymentInfo.card_details.card_number
          : "",
      )}
      {renderCardDetailSection(
        t("userProfile.addressOnFile.cardDetails.expDate"),
        paymentInfo?.card_details
          ? `${paymentInfo.card_details.expiry_month}/${paymentInfo.card_details.expiry_year}`
          : "",
      )}
      {renderCardDetailSection(
        t("userProfile.addressOnFile.cardDetails.nameOnCard"),
        paymentInfo?.card_details?.holder_name,
      )}
    </>
  )

  const renderActionButtons = () => {
    if (!isCardExpiredModel) {
      return (
        <Box sx={{ display: "flex", gap: "8px" }}>
          <UButton
            variant="outlined"
            btnText={t("button.cancel")}
            sxProp={{ minWidth: 58, height: 36 }}
            onClickHandler={() => {
              setAddressDetails()
              setEditPayment(false)
              setSelectedAddress(null)
            }}
          />
          {hasAny(UI_PERMISSION.UI_PROFILE_UPDATEPAYMENT) && (
            <UButton
              variant="contained"
              btnText={t("button.save")}
              btnType="submit"
              sxProp={{ minWidth: 54, height: 36 }}
            />
          )}
        </Box>
      )
    }

    return (
      <Box
        sx={{
          pt: 2,
          pb: 3,
          px: 4,
          backgroundColor: "#E0E0E0",
          display: "flex",
          justifyContent: "flex-end",
          gap: 3,
        }}
      >
        <UButton
          variant="text"
          btnText={"Logout"}
          onClickHandler={logOutRedirect}
          sxProp={{ minWidth: 54, height: 36 }}
        />
        <UButton
          variant="contained"
          disabled={!consentAccepted}
          btnText={t("button.save")}
          btnType="submit"
          sxProp={{ minWidth: 54, height: 36 }}
        />
      </Box>
    )
  }

  return (
    <Box component={"form"} onSubmit={handleSubmit(onSubmit)}>
      {!isCardExpiredModel ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <UText
            variant={"h6"}
            component={"div"}
            sxProp={{ textAlign: "center" }}
          >
            {t("userProfile.payment")}
          </UText>
          {hasAny(UI_PERMISSION.UI_PROFILE_UPDATEPAYMENT) && !editPayment && (
            <Button
              variant={"text"}
              sx={{
                minWidth: "auto",
                height: "auto",
                width: "auto",
                lineHeight: "22px",
                py: "4px",
                px: "5px",
              }}
              onClick={() => setEditPayment(true)}
            >
              {t("userProfile.edit")}
            </Button>
          )}
        </Box>
      ) : (
        <UText variant="body1" sxProp={{ px: isCardExpiredModel ? 4 : 0 }}>
          {"Please add a payment method to continue"}
        </UText>
      )}
      {editPayment ? (
        <Box sx={{ mt: 2, px: isCardExpiredModel ? 4 : 0 }}>
          <PaymentContainer updateCardInfo={updateCardInfo} />
        </Box>
      ) : (
        renderCardDetails(paymentInfo)
      )}
      <UText
        variant={"body1"}
        component={"div"}
        sxProp={{
          color: "text.secondary",
          pt: 3,
          px: isCardExpiredModel ? 4 : 0,
        }}
      >
        {t("userProfile.addressTitle")}
      </UText>
      {editPayment ? (
        <>
          <Box sx={{ px: isCardExpiredModel ? 4 : 0 }}>
            <Box>
              <FormAutoComplete
                formSxprops={{ mt: 2, mb: 0 }}
                inputLabel={"userProfile.cn"}
                fieldName={"country"}
                isInputLabel={true}
                rules={{
                  required: "userProfile.addressOnFile.countryRequired",
                }}
                options={countries}
                errors={errors}
                callback={(e) => {
                  setValue("country", e.label)
                  setValue("state", "")
                  setSelectedState(null)
                  const countryItem = countries.find(
                    (item) => item.label === e.label,
                  )
                  countryItem &&
                    dispatch(fetchStateLists({ countryid: countryItem.id }))
                  trigger("country")
                }}
                // TODO: get country from card details api and pass to defaultvalue
                defaultValue={
                  paymentInfo?.billing_address
                    ? paymentInfo.billing_address.country
                    : "USA"
                }
                control={control}
              />
              <AddressAutocomplete
                suggestedAddressData={suggestedAddressData}
                handleInput={handleInputChange}
                handleChange={handleChange}
                inputValue={inputValue}
                fieldName={"street"}
                rules={{ required: "userProfile.addressOnFile.streetRequired" }}
                errors={errors}
                control={control}
                formSxprops={{ mt: 2 }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                gap: "8px",
              }}
            >
              <FormInput
                inputLabel={"userProfile.addressOnFile.city"}
                fieldName={"city"}
                fieldType={"text"}
                rules={{ required: "userProfile.addressOnFile.cityRequired" }}
                errors={errors}
                control={control}
              />
              <FormAutoComplete
                inputLabel={"userProfile.addressOnFile.state"}
                fieldName={"state"}
                isInputLabel={true}
                rules={{
                  required: "userProfile.addressOnFile.stateRequired",
                }}
                options={states}
                errors={errors}
                callback={(e) => {
                  if (e) {
                    setValue("state", e.label)
                    setSelectedState(e.label)
                  }
                  trigger("state")
                }}
                control={control}
                // TODO: get state value from card details api and assign it to default value
                defaultValue={selectedState}
              />
              <FormInput
                formSxprops={{ width: "70%", pt: 2 }}
                inputLabel={"userProfile.addressOnFile.zip"}
                fieldName={"zip"}
                fieldType={"text"}
                rules={{ required: "userProfile.addressOnFile.zipRequired" }}
                errors={errors}
                control={control}
              />
            </Box>
          </Box>
          {isCardExpiredModel ? (
            <FormControlLabel
              sx={{
                mx: 4,
                mb: 2,
                alignItems: "flex-start",
                ".MuiTypography-root": {
                  fontSize: "14px",
                  color: "text.primary",
                  fontWeight: 400,
                  lineHeight: "24px",
                },
                ".MuiCheckbox-root": {
                  pt: 0,
                },
              }}
              control={
                <Checkbox
                  checked={consentAccepted}
                  onChange={() => setConsentAccepted(!consentAccepted)}
                />
              }
              label={t("auth.ccConsent")}
            />
          ) : null}
          {renderActionButtons()}
        </>
      ) : (
        <Box>
          <UText variant={"body1"} component={"div"} sxProp={{ color: "#000" }}>
            {getAddress() || "--"}
          </UText>
        </Box>
      )}
    </Box>
  )
}

export default PaymentDetails
