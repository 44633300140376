import React, { FC, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useTheme } from "@mui/material/styles"

import { UText } from "../../components"
import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "../../components/mui.components"
import { BundleItems } from "../../core/model/Pricing"
import { formatCurrency } from "../../core/utils/formatters"

import { PricingTableHeader } from "./Pricing"

import "./pricing.scss"

const PricingTable: FC<{
  bundleHeaders: PricingTableHeader[]
  bundleItems: BundleItems
}> = ({ bundleHeaders, bundleItems }) => {
  const [bundleList, setBundleList] = useState([])

  const theme = useTheme()
  const { t } = useTranslation()

  interface BundleValue {
    bundleItem?: string
    type?: string
    alignerCount?: string
    bundle?: boolean
    retainer_bundle?: boolean
    subtext?: string
    isAligner?: boolean
    isUassistAligner?: boolean
  }
  interface BundleItem extends BundleValue {
    my_price?: number
    all_price?: number
    list_price?: number
  }

  interface AlacarteItem extends BundleValue {
    my_price?: number[]
    all_price?: number[]
    list_price?: number[]
  }

  useEffect(() => {
    if (!bundleItems) return
    const items = []
    const addBundleItem = (
      itemKey: string,
      itemType: string,
      isRetainer = false,
    ) => {
      if (
        (bundleItems && bundleItems[itemKey]) ||
        bundleItems[itemKey + " Bundle"]
      ) {
        const val: BundleItem = {}
        val.bundleItem = bundleItems[itemKey] ? itemKey : itemKey + " Bundle"
        val.type = itemKey
        val.bundle = true
        val.my_price = bundleItems[val.bundleItem]["my_price"]
        val.all_price = bundleItems[val.bundleItem]["all_price"]
        val.list_price = bundleItems[val.bundleItem]["list"]
        if (isRetainer) {
          val.retainer_bundle = true
          val.type = itemType
        } else {
          val.alignerCount = itemType
        }
        items.push(val)
      }
    }
    const addAlaCarteItem = () => {
      const val: BundleItem = {
        type: "A La Carte",
        subtext: "Custom packaging",
        my_price: bundleItems.alacarte.custom_packaging.my_price,
        all_price: bundleItems.alacarte.custom_packaging.all_price,
        list_price: bundleItems.alacarte.custom_packaging.list,
      }
      items.push(val)
    }

    const addAlaCarteUsmile = () => {
      const val: AlacarteItem = {
        type: "A La Carte - uSmile™",
        isAligner: true,
        subtext: "Aligner | Retainer",
        my_price: [
          bundleItems.alacarte.aligner.my_price,
          bundleItems.alacarte.retainer.my_price,
        ],
        all_price: [
          bundleItems.alacarte.aligner.all_price,
          bundleItems.alacarte.retainer.all_price,
        ],
        list_price: [
          bundleItems.alacarte.aligner.list,
          bundleItems.alacarte.retainer.list,
        ],
      }
      items.push(val)
    }

    const addAlacarteUassist = () => {
      const myPrice = bundleItems.alacarte.uassist.my_price
      const allPrice = bundleItems.alacarte.uassist.all_price
      const listPrice = bundleItems.alacarte.uassist.list
      const val: AlacarteItem = {
        type: "A La Carte",
        isUassistAligner: true,
        subtext: "uAssist™ service",
        my_price: [
          myPrice.initial,
          myPrice.refine,
          myPrice.retainer,
          myPrice.bracket_removal,
        ],
        all_price: [
          allPrice.initial,
          allPrice.refine,
          allPrice.retainer,
          allPrice.bracket_removal,
        ],
        list_price: [
          listPrice.initial,
          listPrice.refine,
          listPrice.retainer,
          listPrice.bracket_removal,
        ],
      }
      items.push(val)
    }
    addBundleItem("Mild", t("Pricing.table.alignerCount.mild"))
    addBundleItem("Moderate", t("Pricing.table.alignerCount.moderate"))
    addBundleItem(
      "Comprehensive",
      t("Pricing.table.alignerCount.comprehensive"),
    )
    addBundleItem("Finishing", t("Pricing.table.alignerCount.finishing"))
    addBundleItem("2 Retainer", t("Pricing.table.2retainer"), true)
    addBundleItem("4 Retainer", t("Pricing.table.4retainer"), true)

    if (bundleItems?.alacarte) {
      addAlaCarteUsmile()
    }
    if (bundleItems?.alacarte?.custom_packaging) {
      addAlaCarteItem()
    }
    if (bundleItems?.alacarte?.uassist) {
      addAlacarteUassist()
    }

    if (bundleItems?.backstop) {
      const val: BundleItem = {}
      val.type = "Backstop"
      val.my_price = bundleItems.backstop.my_price
      val.all_price = bundleItems.backstop.all_price
      val.list_price = bundleItems.backstop.list
      items.push(val)
    }
    setBundleList(items)
  }, [bundleItems])

  const getCellContent = (price: string, type: string) => {
    return (
      <>
        <UText
          variant="subtitle2"
          sxProp={{ fontWeight: "500", color: "#000" }}
        >
          {price}
        </UText>
        <UText variant="caption" component={"div"} className="subtext">
          {type}
        </UText>
      </>
    )
  }

  const getGroupCellContent = (
    initial: string,
    refinement: string,
    retainer: string,
    bracketremoval: string,
  ) => {
    return (
      <Grid
        container
        direction={"row"}
        display={"flex"}
        justifyContent={"center"}
        spacing={2}
      >
        <Grid item xs={5}>
          {getCellContent(initial, t("Pricing.table.initial"))}
        </Grid>
        <Grid item xs={7}>
          {getCellContent(refinement, t("Pricing.table.refinement"))}
        </Grid>
        <Grid item xs={5}>
          {getCellContent(retainer, t("Pricing.table.retainer"))}
        </Grid>
        <Grid item xs={7}>
          {getCellContent(bracketremoval, t("Pricing.table.bracketRemoval"))}
        </Grid>
      </Grid>
    )
  }

  const getAlacarteCellContent = (aligner: string, retainer: string) => {
    return (
      <Grid
        direction={"row"}
        display={"flex"}
        justifyContent={"center"}
        gap={4}
      >
        <Grid item>{getCellContent(aligner, t("Pricing.table.aligner"))}</Grid>
        <Grid item>
          {getCellContent(retainer, t("Pricing.table.retainer"))}
        </Grid>
      </Grid>
    )
  }

  return (
    <TableContainer
      component={Paper}
      sx={{ width: "88%", boxShadow: "none", background: "none", mb: 5 }}
    >
      <Table>
        <TableHead>
          <TableRow
            sx={{ ">.MuiTableCell-root": { textAlign: "center", py: 1 } }}
          >
            {bundleHeaders.map((item) => (
              <TableCell
                key={item.text}
                sx={{
                  border: item.showBorder ? "1px solid #E0E0E0" : null,
                  borderTop: item.value ? "1px solid #E0E0E0" : null,
                  background: item.value === "myprice" ? "#F0F4FA" : null,
                }}
              >
                <UText variant="h6" sxProp={{ color: "#000" }}>
                  {item.text}
                </UText>
                {item?.subText && (
                  <UText
                    component={"div"}
                    variant="caption"
                    sxProp={{ fontSize: "8px", textTransform: "uppercase" }}
                  >
                    {item.subText}
                  </UText>
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {bundleList?.length &&
            bundleList.map((data, index) => (
              <TableRow
                key={`${data.type}${index}`}
                sx={{ ">.MuiTableCell-root": { textAlign: "center", py: 1 } }}
              >
                <TableCell
                  sx={{
                    border: "1px solid #E0E0E0",
                    "&.MuiTableCell-root": { textAlign: "left", py: 1 },
                  }}
                >
                  <Box>
                    {data.bundle && !data.retainer_bundle && (
                      <UText
                        variant="caption"
                        color="primary.contrastText"
                        sxProp={{ mb: "2px" }}
                        className="bundle"
                      >
                        {"Bundle"}
                      </UText>
                    )}
                    {data.retainer_bundle && (
                      <UText
                        variant="caption"
                        color="primary.contrastText"
                        sxProp={{ mb: "2px" }}
                        className="retainer_bundle"
                      >
                        {"Bundle"}
                      </UText>
                    )}
                    <UText
                      variant="subtitle2"
                      sxProp={{
                        textWrap: "nowrap",
                      }}
                    >
                      {data.type}
                      {data?.alignerCount && (
                        <>
                          &nbsp;&nbsp;&nbsp;
                          <UText
                            variant="caption"
                            sxProp={{
                              textWrap: "nowrap",
                            }}
                          >
                            {data.alignerCount}
                          </UText>
                        </>
                      )}
                    </UText>
                    {data.subtext && (
                      <UText
                        variant={"caption"}
                        component={"div"}
                        sxProp={{ fontWeight: "300" }}
                      >
                        {data.subtext}
                      </UText>
                    )}
                  </Box>
                </TableCell>
                <TableCell
                  sx={{ border: "1px solid #E0E0E0", background: "#F0F4FA" }}
                >
                  {!data.isAligner && !data.isUassistAligner && (
                    <UText
                      variant="subtitle2"
                      sxProp={{ fontWeight: "500", color: "#000" }}
                    >
                      {formatCurrency(data.my_price)}
                    </UText>
                  )}
                  {data.isAligner &&
                    getAlacarteCellContent(
                      formatCurrency(data.my_price[0]),
                      formatCurrency(data.my_price[1]),
                    )}
                  {data.isUassistAligner &&
                    getGroupCellContent(
                      formatCurrency(data.my_price[0]),
                      formatCurrency(data.my_price[1]),
                      formatCurrency(data.my_price[2]),
                      formatCurrency(data.my_price[3]),
                    )}
                </TableCell>
                <TableCell sx={{ border: "1px solid #E0E0E0" }}>
                  {!data.isAligner && !data.isUassistAligner && (
                    <UText
                      variant="subtitle2"
                      sxProp={{ fontWeight: "500", color: "#000" }}
                    >
                      {formatCurrency(data.all_price)}
                    </UText>
                  )}
                  {data.isAligner &&
                    getAlacarteCellContent(
                      formatCurrency(data.all_price[0]),
                      formatCurrency(data.all_price[1]),
                    )}
                  {data.isUassistAligner &&
                    getGroupCellContent(
                      formatCurrency(data.all_price[0]),
                      formatCurrency(data.all_price[1]),
                      formatCurrency(data.all_price[2]),
                      formatCurrency(data.all_price[3]),
                    )}
                </TableCell>
                <TableCell sx={{ border: "1px solid #E0E0E0" }}>
                  {!data.isAligner && !data.isUassistAligner && (
                    <UText
                      variant="subtitle2"
                      sxProp={{ fontWeight: "500", color: "#000" }}
                    >
                      {formatCurrency(data.list_price)}
                    </UText>
                  )}
                  {data.isAligner &&
                    getAlacarteCellContent(
                      formatCurrency(data.list_price[0]),
                      formatCurrency(data.list_price[1]),
                    )}
                  {data.isUassistAligner &&
                    getGroupCellContent(
                      formatCurrency(data.list_price[0]),
                      formatCurrency(data.list_price[1]),
                      formatCurrency(data.list_price[2]),
                      formatCurrency(data.list_price[3]),
                    )}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default PricingTable
