import { FC, useEffect, useState, useRef } from "react"
import { useTranslation } from "react-i18next"
import { useResizeObserver } from "react-use-observer"
import { Box, Button, Paper, Slider, Tooltip } from "@mui/material"
import {
  EBiteJumpLocationType,
  ENodeType,
  refinement,
  stageControl,
  stagingManager,
} from "@/gluelayer"
import { number } from "prop-types"

import attachmentInstall from "../../assets/bottomToolBar/stage/attachmentInstall.svg"
import attachmentRemove from "../../assets/bottomToolBar/stage/attachmentRemove.svg"
import iprDown from "../../assets/bottomToolBar/stage/iprDown.svg"
import iprUp from "../../assets/bottomToolBar/stage/iprUp.svg"
import refinementTip from "../../assets/bottomToolBar/stage/refinementTip.svg"
import { useAppDispatch, useAppSelector } from "../../../core/app/hooks"
import { RootState } from "../../../core/app/store"
import ToolButton from "../Toolbar/toolButton"
import StageArch from "./stageArch"

import { stageBgColor, stageConfig } from "./column"

import "./stageBar.scss"
import { max } from "moment-timezone"
import { Height } from "@mui/icons-material"

import StageDragBar from "./stageDragBar"
import { setStageObjFunc, udTreatSlice } from "@/UDTreat/udTreatSlice"
import DialogBox from "@/modules/Clinical/components/DialogBox/dialogBox"
import { UButton } from "@/components/index"
import { setCurrentStep, setSwitchViewEdit } from "@/UDTreat/udTreatSlice"
import PassiveAligner from "./passiveAligner"
import useLatest from "./useLatest"
import { isNodeContains } from "./stageCommon"

const { setStageCallback, setStageStep, getWasmStageData } = stageControl
interface retouchType {
  showStage: string // 显示的stage点
  postStage: number // 本段的stage点
  postNum: number // 段数
  currentNum: number // 回传的数字
  cirPoint: boolean // 圆点标记
  iprPoint: boolean // 菱形点标记
  quickPoint: boolean // quick3标记
  passiveAlignerPoint: boolean // passiveAlignerPoint
}
// stage单位长度
const uniNumber = 32
let runStep_up = 0
let runStep_down = 0
let up_current = 0
let down_current = 0
let timer: any = null
let setStepTimer: any = null
// label array
let upLabels = []
let downLabels = []

const StageBar: FC = ({
  preparation,
  btnObj,
  setBtnObj,
  isFold,
  setStageObj,
  setIsRefinement,
  isRefinement,
  attachmentObj,
  setRefinementKey,
  refinementKey,
  setStagebarHeight,
  setStageBarWidth,
  maxRefine,
  setMaxRefine,
  attachStage,
  stageBarWidth,
  getTxPlans,
  uiShowType,
  stageMaxWidth,
}) => {
  const singleStageWidth = 23
  const StageDiv = 4
  const stageMinWidth = 600
  const dispatch = useAppDispatch()
  const { currentStep, switchViewEdit, updateStaging, updateSetup} =
    useAppSelector((state: RootState) => state.udTreatService)
  const {
    clinicalSettings: { AttachmentStage },
  } = useAppSelector((state: RootState) => state.userService)
  const { t } = useTranslation("common")
  const [countOfFoldGroup, setCountOfFoldGroup] = useState<number>(5)
  const [actualSingleStageWidth, setActualSingleStageWidth] =
    useState<number>(singleStageWidth)
  // 设置stageData数据
  const [upList, setUpList] = useState<any[]>([])
  const [downList, setDownList] = useState<any[]>([])

  const [upNodes, setUpNodes] = useState<any[]>([])
  const [downNodes, setDownNodes] = useState<any[]>([])
  // marks
  const [up_step_Marks, setUpStepMarks] = useState([])
  const [down_step_Marks, setDownStepMarks] = useState([])
  // one step unit length
  const [unitLen, setUnitLen] = useState<number>(0)
  // up stage length
  const [upSteps, setUpSteps] = useState<number>(0)
  // down stage length
  const [downSteps, setDownSteps] = useState<number>(0)
  // stageBar type
  const [stageType, setStageType] = useState<string>("less40")
  // useResizeObserver listener width change
  const [resizeObserverRef, resizeObserverEntry] = useResizeObserver()
  const [boxWidth, setBoxWidth] = useState(
    resizeObserverEntry.contentRect?.width || stageMinWidth,
  )
  const [boxHeight, setBoxHeight] = useState(
    resizeObserverEntry.contentRect?.height || stageMinWidth,
  )

  // refinement segmentation data
  const [upRefineData, setUpRefineData] = useState({})
  const [downRefineData, setDownRefineData] = useState({})
  // refinement buttons
  const [refineBtns, setRefineBtns] = useState([])
  // refinement is misalign
  const [isRefinementAlign, setIsRefinementAlign] = useState<boolean>(true)
  const [upRefineNum, setUpRefineNum] = useState<number>(0)
  const [downRefineNum, setDownRefineNum] = useState<number>(0)

  const [dragBarLeft, setDragBarLeft] = useState<number>(0)
  const [stageArchLeft, setStageArchLeft] = useState<number>(0)
  const [stageArchRight, setStageArchRight] = useState<number>(0)
  const [upStageCurrentStep, setUpStageCurrentStep] = useState<number>(0)
  const [downStageCurrentStep, setDownStageCurrentStep] = useState<number>(0)
  const [gmPercent, setGmPercent] = useState(0.0);
  const [isGmClicked, setIsGmClicked] = useState(false);
  const [upQuick3Index, setUpQuick3Index] = useState<number>(-1);
  const [downQuick3Index, setDownQuick3Index] = useState<number>(-1);

  const [
    showDeleteAllPassiveAlignerNodeWarning,
    setShowDeleteAllPassiveAlignerNodeWarning,
  ] = useState<boolean>(false)

  const stageUpArchRef = useRef(null)
  const stageDownArchRef = useRef(null)
  const stageDragBarRef = useRef(null)
  const stageMaxWidthLatest = useLatest(stageMaxWidth)

  // get stage marks
  const getMark = (direction: string, data: retouchType[]) => {
    if (direction === "up") {
      upLabels = []
    } else {
      downLabels = []
    }
    const isupLong =
      upList.length > downList.length || upList.length === downList.length
    const isSmall = upList.length - 1 < 11 && downList.length - 1 < 11
    const percentNum = stageType === "less40" ? 5 : 10
    const tmp: Mark[] = []
    for (let i = 0; i < data.length; i++) {
      const moreThan10 =
        i === 0 || i === data.length - 1 || i % percentNum === 0
          ? "block"
          : direction === "up"
          ? isupLong
            ? i % percentNum === 0
              ? "block"
              : "none"
            : "none"
          : isupLong
          ? "none"
          : i % percentNum === 0
          ? "block"
          : "none"
      const less10 =
        i === 0 || i === data.length - 1 || i % percentNum === 0
          ? "block"
          : direction === "up"
          ? isupLong
            ? "block"
            : "none"
          : isupLong
          ? "none"
          : "block"
      const labelDisplay = isSmall ? less10 : moreThan10

      if (
        direction === "up" &&
        labelDisplay === "block" &&
        !upLabels.includes(data[i].currentNum)
      ) {
        upLabels.push(data[i].currentNum)
      }
      if (
        direction === "down" &&
        labelDisplay === "block" &&
        !downLabels.includes(data[i].currentNum)
      ) {
        downLabels.push(data[i].currentNum)
      }
      const mark = {
        value: data[i].currentNum,
        label: (
          <>
            {/* refinement 长条 */}

            {upRefineData[data[i].showStage] && direction === "up" && (
              <div
                className={"refineStrack"}
                style={{
                  width:
                    unitLen * upRefineData[data[i].showStage].len +
                    stageConfig[stageType].hoverWidthNum,
                  background:
                    stageBgColor[upRefineData[data[i].showStage].colorIndex],
                  top: "44.9px",
                  left: "-2px",
                }}
              ></div>
            )}
            {downRefineData[data[i].showStage] && direction === "down" && (
              <div
                className={"refineStrack"}
                style={{
                  width:
                    unitLen * downRefineData[data[i].showStage].len +
                    stageConfig[stageType].hoverWidthNum,
                  background:
                    stageBgColor[downRefineData[data[i].showStage].colorIndex],
                  top: "86px",
                  left: "-2px",
                }}
              ></div>
            )}
            {/* attachment point first stage install, last stage remove */}
            {!(maxRefine && !refinementKey.includes(maxRefine.toString())) ? (
              <>
                {data[i].currentNum === attachStage.upStageIndex &&
                  direction === "up" &&
                  attachmentObj.up && (
                    <Tooltip
                      placement="top"
                      title={t("treat.help.attachmentInstallation")}
                      arrow
                    >
                      <div
                        className={"attachmentPoint"}
                        style={{
                          backgroundImage: `url(${attachmentInstall})`,
                          display: "block",
                          top: "63px",
                          left: "50%",
                          marginLeft: "-6.25px",
                        }}
                      ></div>
                    </Tooltip>
                  )}
                {data[i].currentNum === attachStage.lowerStageIndex &&
                  direction === "down" &&
                  attachmentObj.down && (
                    <Tooltip
                      placement="bottom"
                      title={t("treat.help.attachmentInstallation")}
                      arrow
                    >
                      <div
                        className={"attachmentPoint"}
                        style={{
                          backgroundImage: `url(${attachmentInstall})`,
                          display: "block",
                          top: "74px",
                          left: "50%",
                          marginLeft: "-6.25px",
                        }}
                      ></div>
                    </Tooltip>
                  )}
                {i === data.length - 1 && direction === "up" && (
                  <Tooltip
                    placement="top"
                    title={t("treat.help.attachmentRemoval")}
                    arrow
                  >
                    <div
                      className={"attachmentPoint"}
                      style={{
                        backgroundImage: `url(${attachmentRemove})`,
                        display: attachmentObj.up ? "block" : "none",
                        top: "64px",
                        left: "50%",
                        marginLeft: "-6.25px",
                      }}
                    ></div>
                  </Tooltip>
                )}
                {i === data.length - 1 && direction === "down" && (
                  <Tooltip
                    placement="bottom"
                    title={t("treat.help.attachmentRemoval")}
                    arrow
                  >
                    <div
                      className={"attachmentPoint"}
                      style={{
                        backgroundImage: `url(${attachmentRemove})`,
                        display: attachmentObj.down ? "block" : "none",
                        top: "73px",
                        left: "50%",
                        marginLeft: "-6.25px",
                      }}
                    ></div>
                  </Tooltip>
                )}
              </>
            ) : (
              <></>
            )}

            {/* Ovals on the left and right */}
            {i === 0 && (
              <div
                style={{
                  left: "50%",
                  marginLeft: "-9px",
                  top: direction === "down" ? "85.5px" : "44.5px",
                }}
                className={"leftProtrusions"}
              ></div>
            )}
            {i === data.length - 1 && (
              <div
                style={{
                  left: "50%",
                  top: direction === "down" ? "85.5px" : "44.5px",
                  background: !isRefinement
                    ? "#546E7A"
                    : direction === "up"
                    ? stageBgColor[upRefineData.lastColorIndex]
                    : stageBgColor[downRefineData.lastColorIndex],
                }}
                className={"rightProtrusions"}
              ></div>
            )}
            {/* Common points other than first and last */}
            {direction === "up" && !data[i].passiveAlignerPoint && (
              <Tooltip
                placement="top"
                title={data[i].showStage}
                arrow
                classes={{ popper: "upStageTip" }}
              >
                <div
                  style={{
                    width: `${stageConfig[stageType].hoverWidth}`,
                    left: `${stageConfig[stageType].hoverLeft}`,
                    top: "44.5px",
                  }}
                  className={"normalBox"}
                >
                  <div
                    style={{
                      width:
                        stageType === "over40" &&
                        (i === 0 || i === data.length - 1 || i % 10 === 0)
                          ? "8px"
                          : `${stageConfig[stageType].pointWidth}`,
                      height:
                        stageType === "over40" &&
                        (i === 0 || i === data.length - 1 || i % 10 === 0)
                          ? "8px"
                          : `${stageConfig[stageType].pointWidth}`,
                      left:
                        stageType === "over40" &&
                        (i === 0 || i === data.length - 1 || i % 10 === 0)
                          ? "calc(50% - 4px)"
                          : `${stageConfig[stageType].normalCirLeft}`,
                      top:
                        stageType === "over40" &&
                        (i === 0 || i === data.length - 1 || i % 10 === 0)
                          ? "4px"
                          : `${stageConfig[stageType].normalCirTop}`,
                      background: isRefinement
                        ? !refinementKey.includes(maxRefine.toString())
                          ? "#ECEFF14D"
                          : "#ECEFF1"
                        : "#ECEFF1",
                    }}
                    className={data[i].cirPoint ? "keyframeCir" : "normalCir"}
                  ></div>
                </div>
              </Tooltip>
            )}
            {direction === "down" && !data[i].passiveAlignerPoint && (
              <Tooltip
                placement="bottom"
                title={data[i].showStage}
                arrow
                classes={{ popper: "downStageTip" }}
              >
                <div
                  style={{
                    width: `${stageConfig[stageType].hoverWidth}`,
                    left: `${stageConfig[stageType].hoverLeft}`,
                    top: "85px",
                  }}
                  className={"normalBox"}
                >
                  <div
                    style={{
                      width:
                        stageType === "over40" &&
                        (i === 0 || i === data.length - 1 || i % 10 === 0)
                          ? "8px"
                          : `${stageConfig[stageType].pointWidth}`,
                      height:
                        stageType === "over40" &&
                        (i === 0 || i === data.length - 1 || i % 10 === 0)
                          ? "8px"
                          : `${stageConfig[stageType].pointWidth}`,
                      left:
                        stageType === "over40" &&
                        (i === 0 || i === data.length - 1 || i % 10 === 0)
                          ? "calc(50% - 4px)"
                          : `${stageConfig[stageType].normalCirLeft}`,
                      top:
                        stageType === "over40" &&
                        (i === 0 || i === data.length - 1 || i % 10 === 0)
                          ? "4px"
                          : `${stageConfig[stageType].normalCirTop}`,
                      background: isRefinement
                        ? !refinementKey.includes(maxRefine.toString())
                          ? "#ECEFF14D"
                          : "#ECEFF1"
                        : "#ECEFF1",
                    }}
                    className={data[i].cirPoint ? "keyframeCir" : "normalCir"}
                  ></div>
                </div>
              </Tooltip>
            )}
            {/* Common scale */}
            <div
              style={{
                display:
                  data[i].showStage.includes("R") ||
                  (i - 1 > -1 && data[i - 1].showStage.includes("R"))
                    ? "block"
                    : isSmall
                    ? less10
                    : moreThan10,
                marginTop: isRefinement
                  ? direction === "up"
                    ? "4px"
                    : "50px"
                  : direction === "up"
                  ? "10px"
                  : "3px",
                background: data[i].showStage.includes("R")
                  ? direction === "up"
                    ? stageBgColor[upRefineData[data[i].showStage].colorIndex]
                    : stageBgColor[downRefineData[data[i].showStage].colorIndex]
                  : "unset",
                borderRadius: "4px",
                padding: "0 2px",
              }}
              className={"label"}
            >{`${data[i].showStage}`}</div>
            {/* ipr */}
            {data[i].iprPoint && (
              <Tooltip placement={"top"} title={"IPR"} arrow>
                <img
                  src={direction === "up" ? iprUp : iprDown}
                  style={{
                    position: "absolute",
                    left: `calc(50% - 3.5px + ${unitLen / 2}px)`,
                    top: direction === "up" ? "36px" : "106px",
                  }}
                />
              </Tooltip>
            )}
            {/*passiveAlignerPoint */}
            {direction === "up" && data[i].passiveAlignerPoint && (
              <Tooltip placement="top" title={"Passive aligner"} arrow>
                <div
                  className={"alignerBox"}
                  style={{
                    top: "44.5px",
                    width: `${stageConfig[stageType].hoverWidth}`,
                    left: `${stageConfig[stageType].hoverLeft}`,
                  }}
                >
                  <div
                    style={{
                      width:
                        stageType === "over40" &&
                        (i === 0 || i === data.length - 1 || i % 10 === 0)
                          ? "8px"
                          : `${stageConfig[stageType].pointWidth}`,
                      height:
                        stageType === "over40" &&
                        (i === 0 || i === data.length - 1 || i % 10 === 0)
                          ? "8px"
                          : `${stageConfig[stageType].pointWidth}`,
                      left:
                        stageType === "over40" &&
                        (i === 0 || i === data.length - 1 || i % 10 === 0)
                          ? "calc(50% - 4px)"
                          : `${stageConfig[stageType].normalCirLeft}`,
                      top:
                        stageType === "over40" &&
                        (i === 0 || i === data.length - 1 || i % 10 === 0)
                          ? "4px"
                          : `${stageConfig[stageType].normalCirTop}`,
                    }}
                    className={"alignerCir"}
                  ></div>
                </div>
              </Tooltip>
            )}
            {direction === "down" && data[i].passiveAlignerPoint && (
              <Tooltip placement="bottom" title={"Passive aligner"} arrow>
                <div
                  className={"alignerBox"}
                  style={{
                    top: "85px",
                    width: `${stageConfig[stageType].hoverWidth}`,
                    left: `${stageConfig[stageType].hoverLeft}`,
                  }}
                >
                  <div
                    style={{
                      width:
                        stageType === "over40" &&
                        (i === 0 || i === data.length - 1 || i % 10 === 0)
                          ? "8px"
                          : `${stageConfig[stageType].pointWidth}`,
                      height:
                        stageType === "over40" &&
                        (i === 0 || i === data.length - 1 || i % 10 === 0)
                          ? "8px"
                          : `${stageConfig[stageType].pointWidth}`,
                      left:
                        stageType === "over40" &&
                        (i === 0 || i === data.length - 1 || i % 10 === 0)
                          ? "calc(50% - 4px)"
                          : `${stageConfig[stageType].normalCirLeft}`,
                      top:
                        stageType === "over40" &&
                        (i === 0 || i === data.length - 1 || i % 10 === 0)
                          ? "4px"
                          : `${stageConfig[stageType].normalCirTop}`,
                    }}
                    className={"alignerCir"}
                  ></div>
                </div>
              </Tooltip>
            )}
          </>
        ),
        ...data[i],
      }
      tmp.push(mark)
    }
    if (direction === "up") {
      setUpStepMarks(tmp)
    } else {
      setDownStepMarks(tmp)
    }
  }

  //arch load finished
  const stageArchLoadFinished = (archType: number) => {
    setDragBarStep(Math.max(up_current, down_current))
  }

  // get unitLen
  const setUnit = (
    upNum: number,
    downNum: number,
    upData: retouchType[],
    downData: retouchType[],
  ) => {
    // let unitCount = 0 // 标记是否被更改了的单位长度
    // if (Number.isFinite(+upNum)) {
    //   if (downNum >= upNum && downNum > 26) {
    //     setUnitLen((uniNumber * 26) / downNum)
    //     unitCount = (uniNumber * 26) / downNum
    //   } else if (unitCount === 0) {
    //     setUnitLen(uniNumber)
    //   }
    // } else if (downNum > 26) {
    //   setUnitLen((uniNumber * 26) / downNum)
    //   unitCount = (uniNumber * 26) / downNum
    // } else if (unitCount === 0) {
    //   setUnitLen(uniNumber)
    // }
    // if (Number.isFinite(+downNum)) {
    //   if (downNum < upNum && upNum > 26) {
    //     setUnitLen((uniNumber * 26) / upNum)
    //     unitCount = (uniNumber * 26) / upNum
    //   } else if (unitCount === 0) {
    //     setUnitLen(uniNumber)
    //   }
    // } else if (upNum > 26) {
    //   setUnitLen((uniNumber * 26) / upNum)
    //   unitCount = (uniNumber * 26) / upNum
    // } else if (unitCount === 0) {
    //   setUnitLen(uniNumber)
    // }
    let width =
      document.getElementsByClassName("stageBarContainerBottomWrap")[0]
        .offsetWidth - 256
    if (upNum - 1 < 5 && downNum - 1 < 5) {
      setUnitLen(96)
      return
    }
    if (upNum - 1 >= 5 || downNum - 1 >= 5) {
      if (window.screen.width > 1920) {
        width = width - 30
        setUnitLen(
          downNum >= upNum
            ? Math.floor(Math.min(width, 1530) / (downNum - 1))
            : Math.floor(Math.min(width, 1530) / (upNum - 1)),
        )
      } else {
        setUnitLen(
          downNum >= upNum
            ? Math.floor(width / (downNum - 1))
            : Math.floor(width / (upNum - 1)),
        )
      }

      return
    }
  }

  const execSetStepAsync = async () => {
    // 这里可以是异步的操作，例如数据获取或其他异步任务
    setStageStep(up_current, down_current)
  }

  const dragBarPositionXChanged = (x) => {
    console.info("=====drag bar position x: ", x)
    let stepIndex = stageUpArchRef.current?.getStageStepByPositionX(x)
    if (stepIndex === -1) {
      stepIndex = stageDownArchRef.current?.getStageStepByPositionX(x)
    }
    console.info("=====drag bar step: ", stepIndex)
    if (stepIndex < 0) {
      return
    }

    setDragBarStep(stepIndex)
    dispatch(setCurrentStep(stepIndex))

    updateCurrentStepValue(stepIndex)
    execSetStepAsync()
    setUpStageCurrentStep(up_current)
    setDownStageCurrentStep(down_current)

    // if(updateCurrentStepValue(stepIndex)) {
    //   execSetStepAsync()
    //   setUpStageCurrentStep(up_current)
    //   setDownStageCurrentStep(down_current)

    // }
  }

  const dragBarStoped = () => {
    setDragBarStep(Math.max(up_current, down_current))
  }

  const getStageMinX = () => {
    let minX = 0
    if (upNodes.length > 0) {
      minX = stageUpArchRef.current?.getStagePositionX(upNodes.length - 1)
    } else if (downNodes.length > 0) {
      minX = stageDownArchRef.current?.getStagePositionX(downNodes.length - 1)
    }
    return minX
  }

  const getStageMaxX = () => {
    let maxRight = 0
    if (upNodes.length > downNodes.length) {
      maxRight = stageUpArchRef.current?.getStagePositionX(upNodes.length - 1)
    } else {
      maxRight = stageDownArchRef.current?.getStagePositionX(
        downNodes.length - 1,
      )
    }
    return maxRight
  }

  // useEffect(() => {
  //   updateCurrentStepValue(currentStep)
  //   execSetStepAsync()
  //   setUpStageCurrentStep(up_current)
  //   setDownStageCurrentStep(down_current)
  // }, [currentStep])

  useEffect(() => {
    const data = getWasmStageData()
    updateAll(data)
  }, [updateStaging])
  useEffect(() => {
    console.log("updateSetup!", updateSetup)
    setTimeout(() => {
      setBtnObj({
        type: "icEnd",
        isBool: !btnObj.isBool,
      })
    }, 100) // dealy for 0.5s then click "icEnd" */
  }, [updateSetup])

  const updateCurrentStepValue = (stepValue) => {
    let changed = false

    const upStepValue = Math.min(stepValue, upNodes.length - 1)
    if (up_current !== upStepValue) {
      up_current = upStepValue
      changed = true
    }

    const downStepValue = Math.min(stepValue, downNodes.length - 1)
    if (down_current !== downStepValue) {
      down_current = downStepValue
      changed = true
    }

    console.info("updateCurrentStepValue changed:", changed)

    return changed
  }

  const updateDefaultStepValue = (archType, stepValue) => {
    let changed = false

    if (archType === 0) {
      const upStepValue = Math.min(stepValue, upNodes.length - 1)
      if (upStepValue >= 0 && up_current !== upStepValue) {
        up_current = upStepValue
        changed = true
      }
    } else if (archType === 1) {
      const downStepValue = Math.min(stepValue, downNodes.length - 1)
      if (downStepValue >= 0 && down_current !== downStepValue) {
        down_current = downStepValue
        changed = true
      }
    }

    if (changed) {
      if (archType === 0) {
        setUpStageCurrentStep(up_current)
      } else if (archType === 1) {
        setDownStageCurrentStep(down_current)
      }
      setDragBarStep(Math.max(up_current, down_current))
      execSetStepAsync()
    }

    return changed
  }

  const setDragBarStep = (stepIndex) => {
    if (
      stageUpArchRef.current !== undefined &&
      stageDownArchRef.current !== undefined
    ) {
      const upMiddleX = stageUpArchRef.current?.getStagePositionX(stepIndex)
      const downMiddleX = stageDownArchRef.current?.getStagePositionX(stepIndex)
      const maxMiddleX = Math.max(upMiddleX, downMiddleX)
      // console.info("=====stage:", stepIndex, "maxMiddleX:", maxMiddleX)
      //stageDragBarRef.current?.resetDragBar()
      setDragBarLeft(maxMiddleX)
    }
  }

  const stepChangeHandler = (archType: number, stageCurrentStep: number) => {
    const max_step = Math.max(upNodes.length - 1, downNodes.length - 1)
    if (max_step < 0) {
      return
    }

    // updateCurrentStepValue(stageCurrentStep)
    // setUpStageCurrentStep(up_current)
    // setDownStageCurrentStep(down_current)
    // setDragBarStep(Math.max(up_current, down_current))

    dispatch(setCurrentStep(stageCurrentStep))

    updateCurrentStepValue(stageCurrentStep)
    execSetStepAsync()
    setUpStageCurrentStep(up_current)
    setDownStageCurrentStep(down_current)
    setDragBarStep(Math.max(up_current, down_current))
  }
  // change stage
  // const handleChange = (direction: string, step: number) => {
  //   console.log("direction:", `${direction} ${step}`)
  //   if (upList.length && downList.length) {
  //     if (step <= upList.length - 1) {
  //       setUpCurrent(step)
  //       runStep_up = step
  //     } else {
  //       setUpCurrent(upList.length - 1)
  //       runStep_up = upList.length - 1
  //     }
  //     if (step <= downList.length - 1) {
  //       setDownCurrent(step)
  //       runStep_down = step
  //     } else {
  //       setDownCurrent(downList.length - 1)
  //       runStep_down = downList.length - 1
  //     }
  //   } else if (upList.length && !downList.length) {
  //     if (step <= upList.length - 1) {
  //       setUpCurrent(step)
  //       runStep_up = step
  //     }
  //   } else if (!upList.length && downList.length) {
  //     if (step <= downList.length - 1) {
  //       setDownCurrent(step)
  //       runStep_down = step
  //     }
  //   }
  // }

  //load arch timer set
  const setStepDelay = () => {
    if (setStepTimer !== null) {
      clearTimeout(setStepTimer)
    }

    setStepTimer = setTimeout(() => {
      execSetStepAsync()
    }, 50)
  }

  // play or stop stage
  const playOrStop = () => {
    clearInterval(timer)
    const upLen = upList.length - 1
    const downLen = downList.length - 1
    runStep_up = upStageCurrentStep
    runStep_down = downStageCurrentStep
    if(runStep_up >= upLen && runStep_down >= downLen) {
      runStep_up = 0
      runStep_down = 0
    }
    else if(runStep_up >= upLen) {
      runStep_up = downStageCurrentStep
      runStep_down = downStageCurrentStep
    }
    else if(runStep_down >= downLen) {
      runStep_up = upStageCurrentStep
      runStep_down = upStageCurrentStep
    }
    //clear gm button percent
    setGmPercent(0.0)
    if (btnObj.type === "play") {
      timer = setInterval(() => {
        if (runStep_up <= upLen || runStep_down <= downLen) {
          if (runStep_up <= upLen) {
            //setUpCurrent(runStep_up)
            up_current = runStep_up
            runStep_up++
          }
          if (runStep_down <= downLen) {
            // setDownCurrent(runStep_down)
            if (runStep_down) down_current = runStep_down
            runStep_down++
          }
          setUpStageCurrentStep(up_current)
          setDownStageCurrentStep(down_current)
          setDragBarStep(Math.max(up_current, down_current))
          execSetStepAsync()
        } else {
          runStep_up = 0
          runStep_down = 0
          clearInterval(timer)
          setBtnObj({
            type: "stop",
            isBool: !btnObj.isBool,
          })
          const biteJumpType = stageControl.getBiteJumpType()
          if (biteJumpType === EBiteJumpLocationType["EBiteJumpLocation_End"]) {
            console.log("playGMAnimation playOrStop")
            stageControl.playGMAnimation()
          }
        }
      }, 200)
    }
  }
  // switch refinement
  const switchRefinement = (key: string) => {
    stagingManager.showRefinementHistoryRangeType = key
    refinement.setRefinementDisplayRange(key)
    setRefinementKey(key)
  }

  const deleteAllHandle = () => {
    setShowDeleteAllPassiveAlignerNodeWarning(true)
  }

  const upDownEqualHandle = () => {
    stagingManager.addPassiveAligner()
  }

  const isDownArchBiteMoved = () => {
    //return true
    return stageControl.isDownArchBiteMoved()
  }

  const gmFrontButtonClickHandle = () => {
    setIsGmClicked(false)
    stageControl.setBiteJumpTypePlayGM(
         EBiteJumpLocationType["EBiteJumpLocation_Start"],
    )
    // if (!isGmClicked) {
    //   setIsGmClicked(true)
    //   stageControl.setBiteJumpTypePlayGM(
    //     EBiteJumpLocationType["EBiteJumpLocation_Start"],
    //   )
    // } else {
    //   setIsGmClicked(false)
    //   setGmPercent(0.0)
    //   stageControl.setBiteJumpTypePlayGM(
    //     EBiteJumpLocationType["EBiteJumpLocation_All"],
    //   )
    // }
  }

  const gmBackButtonClickHandle = () => {
    setIsGmClicked(false)
    stageControl.setBiteJumpTypePlayGM(
      EBiteJumpLocationType["EBiteJumpLocation_End"],
    )
    // if (!isGmClicked) {
    //   setIsGmClicked(true)
    //   stageControl.setBiteJumpTypePlayGM(
    //     EBiteJumpLocationType["EBiteJumpLocation_End"],
    //   )
    // } else {
    //   setIsGmClicked(false)
    //   setGmPercent(0.0)
    //   stageControl.setBiteJumpTypePlayGM(
    //     EBiteJumpLocationType["EBiteJumpLocation_All"],
    //   )
    // }
  }

  const updateGMProgress = (percent: number) => {
    console.info("updateGMProgress: " + percent)
    setGmPercent(percent)
  }

  useEffect(() => {
    getMark("up", upList)
    getMark("down", downList)
    setUnit(upList.length, downList.length, upList, downList)
  }, [
    upList,
    downList,
    unitLen,
    boxWidth,
    attachmentObj.up,
    attachmentObj.down,
  ])
  useEffect(() => {
    if (isRefinement && maxRefine) {
      //set refinemet key by default
      const key = `refinement${maxRefine}`
      setRefinementKey(key)
    }
  }, [isRefinement, maxRefine])

  const calcMaxArchWidth = (
    countOfFoldGroup,
    upStageCount,
    downStageCount,
    singleStageWidth,
  ) => {
    let upMaxArchWidth = 0
    let downMaxArchWidth = 0
    if (countOfFoldGroup <= 1) {
      upMaxArchWidth = upStageCount * (singleStageWidth + StageDiv)
      downMaxArchWidth = downStageCount * (singleStageWidth + StageDiv)
    } else {
      upMaxArchWidth = upStageCount * (singleStageWidth+1)
      downMaxArchWidth = downStageCount * (singleStageWidth+1)
    }

    const passiveAlignerWidth = uiShowType === "passive_aligner" ? 80 : 0
    const biteJumpWidth =
      isDownArchBiteMoved() && ((upStageCount > 0) || (downStageCount > 0)) ? 60 : 0

    upMaxArchWidth = upMaxArchWidth + passiveAlignerWidth
    downMaxArchWidth = downMaxArchWidth + passiveAlignerWidth + biteJumpWidth

    return Math.max(upMaxArchWidth, downMaxArchWidth)
  }

  const calcStageCount = (upStageCount, downStageCount) => {
    const maxCountOfFoldGroup = 20
    const minActualSingleStageWidth = 5

    let tmpCountOfFoldGroup = 1
    let tmpActualSingleStageWidth = singleStageWidth
    for (let i = 0; i < 1000; i++) {
      const maxArchWidth = calcMaxArchWidth(
        tmpCountOfFoldGroup,
        upStageCount,
        downStageCount,
        tmpActualSingleStageWidth,
      )
      if (maxArchWidth <= stageMaxWidthLatest.current - 60) {
        break
      }

      if (tmpCountOfFoldGroup === 1) {
        tmpCountOfFoldGroup = 5
      } else if (tmpCountOfFoldGroup > 1) {
        //single stage, max width 23
        if (tmpCountOfFoldGroup === 5) {
          if (tmpActualSingleStageWidth <= singleStageWidth - 6) {
            tmpCountOfFoldGroup = tmpCountOfFoldGroup * 2
            tmpActualSingleStageWidth = singleStageWidth
          } else {
            tmpActualSingleStageWidth--
          }
        } else if (tmpCountOfFoldGroup === 10) {
          if (tmpActualSingleStageWidth <= singleStageWidth - 13) {
            tmpCountOfFoldGroup = tmpCountOfFoldGroup * 2
            tmpActualSingleStageWidth = singleStageWidth
          } else {
            tmpActualSingleStageWidth--
          }
        } else if (tmpCountOfFoldGroup === 20) {
          if (tmpActualSingleStageWidth <= minActualSingleStageWidth) {
            tmpCountOfFoldGroup = tmpCountOfFoldGroup * 2
            tmpActualSingleStageWidth = singleStageWidth
          } else {
            tmpActualSingleStageWidth--
          }
        }

        if (
          tmpCountOfFoldGroup >= maxCountOfFoldGroup &&
          tmpActualSingleStageWidth <= minActualSingleStageWidth
        ) {
          break
        }
      }
    }

    setCountOfFoldGroup(tmpCountOfFoldGroup)
    setActualSingleStageWidth(tmpActualSingleStageWidth)
  }

  // useEffect(() => {
  //   console.info("stageMaxWidth:", stageMaxWidth)
  // }, [stageMaxWidth, ])

  useEffect(() => {
    //const maxStateCount = Math.max(upNodes.length, downNodes.length)
    calcStageCount(upNodes.length, downNodes.length)
  }, [stageMaxWidth])

  const updateAll = (data) => {
    console.log("stageData:", data)

    // getTxPlans()

    const {
      curLowerNodeIndex,
      curUpNodeIndex,
      refinementHistoryDownNumber,
      refinementHistoryUpNumber,
      jointUpKeypoints,
      jointLowerKeypoints,
    } = data
    //set nodes
    setUpNodes(jointUpKeypoints)
    setDownNodes(jointLowerKeypoints)
    //judge the up quick3
    let upQuick3Index = -1
    jointUpKeypoints.map((item, index) => {
      if(isNodeContains(item.nodeType, 6)) {
        upQuick3Index = index
      }
    })
    setUpQuick3Index(upQuick3Index)
    //judge the down quick3
    let downQuick3Index = -1
    jointLowerKeypoints.map((item, index) => {
      if(isNodeContains(item.nodeType, 6)) {
        downQuick3Index = index
      }
    })
    setDownQuick3Index(downQuick3Index)

    // 设置外用数据--stageObj
    const stageObj = {
      upList: [],
      downList: [],
      up_current: 0,
      down_current: 0,
    }
    const upArr = []
    const downArr = []
    jointUpKeypoints.map((i) => {
      const obj = { showStage: i.name }
      upArr.push(obj)
    })
    jointLowerKeypoints.map((i) => {
      const obj = { showStage: i.name }
      downArr.push(obj)
    })
    stageObj.upList = upArr
    stageObj.downList = downArr
    dispatch(setStageObjFunc(stageObj))

    const maxStateCount = Math.max(
      jointUpKeypoints.length,
      jointLowerKeypoints.length,
    )
    calcStageCount(jointUpKeypoints.length, jointLowerKeypoints.length)

    //Assembly segment data
    if (refinementHistoryUpNumber || refinementHistoryDownNumber) {
      if (jointUpKeypoints.length > 1) {
        const tmp = []
        const refineObj = {}
        jointUpKeypoints.map((item, j) => {
          const obj = {}
          const { name } = item
          if (name.includes("R") || name === "0") {
            obj.startIndex = j
            obj.name = name
            obj.colorIndex =
              name === "0"
                ? 0
                : +name.split("R")[1] > 6
                ? 6
                : +name.split("R")[1]
            tmp.push(obj)
          }
        })
        tmp.map((item, j) => {
          const lastIndex = tmp.length - 1
          if (j < lastIndex) {
            item.endIndex = tmp[j + 1].startIndex
            item.isLast = false
            item.len = item.endIndex - item.startIndex
          } else {
            item.endIndex = jointUpKeypoints.length - 1
            item.isLast = true
            item.len = item.endIndex - item.startIndex
          }
        })
        tmp.map((item) => {
          refineObj[item.name] = item
        })
        const lastColorIndex = tmp[tmp.length - 1].colorIndex
        refineObj["lastColorIndex"] = lastColorIndex
        setUpRefineData(refineObj)
      }
      if (jointLowerKeypoints.length > 1) {
        const tmp = []
        const refineObj = {}
        jointLowerKeypoints.map((item, j) => {
          const obj = {}
          const { name } = item
          if (name.includes("R") || name === "0") {
            obj.startIndex = j
            obj.name = name
            obj.colorIndex =
              name === "0"
                ? 0
                : +name.split("R")[1] > 6
                ? 6
                : +name.split("R")[1]
            tmp.push(obj)
          }
        })
        tmp.map((item, j) => {
          const lastIndex = tmp.length - 1
          if (j < lastIndex) {
            item.endIndex = tmp[j + 1].startIndex
            item.isLast = false
            item.len = item.endIndex - item.startIndex
          } else {
            item.endIndex = jointLowerKeypoints.length - 1
            item.isLast = true
            item.len = item.endIndex - item.startIndex
          }
        })
        tmp.map((item) => {
          refineObj[item.name] = item
        })
        const lastColorIndex = tmp[tmp.length - 1].colorIndex
        refineObj["lastColorIndex"] = lastColorIndex
        setDownRefineData(refineObj)
      }
      const refineButtonTmp = [
        {
          name: "ALL History",
          key: "all",
        },
        {
          name: "Original",
          key: "original",
        },
      ]
      const maxRefineNum = Math.max(
        refinementHistoryUpNumber,
        refinementHistoryDownNumber,
      )
      setMaxRefine(maxRefineNum)
      for (let i = 1; i < +maxRefineNum + 1; i++) {
        refineButtonTmp.push({
          name: `Course Refinement ${i}`,
          key: `refinement${i}`,
        })
      }
      setRefineBtns(refineButtonTmp)
      setUpRefineNum(refinementHistoryUpNumber)
      setDownRefineNum(refinementHistoryDownNumber)
    }

    setIsRefinement(refinementHistoryUpNumber || refinementHistoryDownNumber)
    const upStageList: any[] = []
    const downStageList: any[] = []
    // const urTmp: number[] = []
    // const urArr: any[] = []
    // const drTmp: number[] = []
    // const drArr: any[] = []
    const upNodes = data.jointUpKeypoints
    const lowerNodes = data.jointLowerKeypoints
    const type =
      upNodes.length - 1 >= 40 || lowerNodes.length - 1 >= 40
        ? "over40"
        : "less40"
    setStageType(type)
    // 处理上颌的点nodes
    if (upNodes?.length > 1) {
      for (let u = 0; u < upNodes.length; u++) {
        const obj: any = {}
        obj.showStage = upNodes[u].name
        obj.currentNum = upNodes[u].stepindex
        obj.postNum = 0
        obj.cirPoint = false
        obj.iprPoint = false
        obj.quickPoint = false
        obj.passiveAlignerPoint = false
        // point 分装
        upNodes[u].nodeType.map((i) => {
          if (i === ENodeType["NodeNormal"]) {
            obj.cirPoint = true
          } else if (i === ENodeType["NodeIPR"]) {
            obj.iprPoint = true
          } else if (i === ENodeType["NodeQuick3"]) {
            obj.quickPoint = true
          } else if (i === ENodeType["NodePassiveAligner"]) {
            obj.passiveAlignerPoint = true
          }
          return i
        })
        upStageList.push(obj)
        // if (typeof upNodes[u].stepindex !== "number") {
        //   urTmp.push(u)
        // }
      }
      // if (urTmp.length) {
      //   const arr: any[] = []
      //   urTmp.unshift(0)
      //   for (let ur = 0; ur < urTmp.length; ur++) {
      //     arr.push(upStageList.slice(urTmp[ur], urTmp[ur + 1]))
      //   }
      //   arr.map((item, bigIndex) => {
      //     item.map((e) => {
      //       e.postNum = bigIndex
      //       urArr.push(e)
      //       return e
      //     })
      //     return item
      //   })
      // }
    }
    // 处理下颌的点nodes
    if (lowerNodes?.length > 1) {
      for (let u = 0; u < lowerNodes.length; u++) {
        const obj: any = {}
        obj.showStage = lowerNodes[u].name
        obj.currentNum = lowerNodes[u].stepindex
        obj.postNum = 0
        obj.cirPoint = false
        obj.iprPoint = false
        obj.quickPoint = false
        obj.passiveAlignerPoint = false
        // point 分装
        lowerNodes[u].nodeType.map((i) => {
          if (i === ENodeType["NodeNormal"]) {
            obj.cirPoint = true
          } else if (i === ENodeType["NodeIPR"]) {
            obj.iprPoint = true
          } else if (i === ENodeType["NodeQuick3"]) {
            obj.quickPoint = true
          } else if (i === ENodeType["NodePassiveAligner"]) {
            obj.passiveAlignerPoint = true
          }
        })
        downStageList.push(obj)
        // if (typeof lowerNodes[u].stepindex !== "number") {
        //   drTmp.push(u)
        // }
      }
      // if (drTmp.length) {
      //   const arr: any[] = []
      //   drTmp.unshift(0)
      //   for (let dr = 0; dr < drTmp.length; dr++) {
      //     arr.push(downStageList.slice(drTmp[dr], drTmp[dr + 1]))
      //   }
      //   arr.map((item, bigIndex) => {
      //     item.map((e) => {
      //       e.postNum = bigIndex
      //       drArr.push(e)
      //       return e
      //     })
      //     return item
      //   })
      // }
    }
    setUpList(upStageList)
    setDownList(downStageList)
    up_current = curUpNodeIndex
    down_current = curLowerNodeIndex
    if (up_current > upNodes.length - 1) {
      up_current = upNodes.length - 1
    }
    if (up_current < 0) {
      up_current = 0
    }
    if (down_current > lowerNodes.length - 1) {
      down_current = lowerNodes.length - 1
    }
    if (down_current < 0) {
      down_current = 0
    }
    setUpStageCurrentStep(up_current)
    setDownStageCurrentStep(down_current)
    setDragBarStep(Math.max(up_current, down_current))

    dispatch(setCurrentStep(Math.max(up_current, down_current)))
  }

  useEffect(() => {
    //set the gm to final at first time
    //stagingManager.SetGMToFinal()
    // stage data callback
    setStageCallback((data) => {
      updateAll(data)
    })
    stagingManager.setGMProgressCallBack = updateGMProgress
  }, [])
  //useEffect(() => {
  //setStageStep(up_current, down_current)
  //}, [up_current, down_current])
  useEffect(() => {
    clearInterval(timer)
    runStep_up = 0
    runStep_down = 0
    if (btnObj.type === "icStart") {
      //setUpCurrent(0)
      //setDownCurrent(0)
      up_current = 0
      down_current = 0
      execSetStepAsync()
    } else if (btnObj.type === "icEnd") {
      if (upList.length) {
        //setUpCurrent(upList.length - 1)
        up_current = upList.length - 1
      }
      if (downList.length) {
        //setDownCurrent(downList.length - 1)
        down_current = downList.length - 1
      }
      execSetStepAsync()
    } else {
      // stageControl.setBiteJumpType(
      //   EBiteJumpLocationType["EBiteJumpLocation_End"],
      // )

      if (
        stageControl.getBiteJumpType() ===
          EBiteJumpLocationType["EBiteJumpLocation_Start"] &&
        btnObj.type === "play"
      )
      {
        const runTime = stageControl.playGMAnimation() + 50
        console.log("playGMAnimation setTimeout ", runTime)
        setTimeout(playOrStop, runTime)
      }
      else 
      {
        playOrStop()
      }
    }
  }, [btnObj])
  useEffect(() => {
    const upSlider = document.getElementById("sliderUp")
    const downSlider = document.getElementById("sliderDown")
    // if (upSlider) {
    //   const upLabel = upSlider.getElementsByClassName("MuiSlider-markLabel")
    //   if (upLabel[0]) {
    //     upLabel[0].style.zIndex = "unset"
    //   }
    //   if (upLabel.length) {
    //     upLabel[upLabel.length - 1].style.zIndex = "unset"
    //   }
    // }
    // if (downSlider) {
    //   const downLabel = downSlider.getElementsByClassName("MuiSlider-markLabel")
    //   if (downLabel[0]) {
    //     downLabel[0].style.zIndex = "unset"
    //   }
    //   if (downLabel.length) {
    //     downLabel[downLabel.length - 1].style.zIndex = "unset"
    //   }
    // }
  }, [isFold, unitLen])
  // useEffect(() => {
  //   setStageObj({ upList, downList, up_current, down_current })
  // }, [upList, downList, up_current, down_current])
  useEffect(() => {
    if (preparation) {
      up_current = upList.length ? upList.length - 1 : 0
      down_current = downList.length ? downList.length - 1 : 0
      //setStageStep(up_current, down_current)
      //setUpCurrent(upList.length ? upList.length - 1 : 0)
      //setDownCurrent(downList.length ? downList.length - 1 : 0)
    }
  }, [preparation])
  useEffect(() => {
    let { width = 0 } = resizeObserverEntry.contentRect || {}
    width = Math.max(width + 80, stageMinWidth)
    width = Math.min(width, stageMaxWidthLatest.current)
    setBoxWidth(width)
    setStageBarWidth(width)
    setDragBarStep(Math.max(up_current, down_current))
    // if (width !== boxWidth) {
    //   setBoxWidth(width)
    //   setStageBarWidth(width)
    //   setDragBarStep(Math.max(up_current, down_current))
    // }
    const { height = 0 } = resizeObserverEntry.contentRect || {}
    if (height !== boxHeight) {
      setStagebarHeight(height)
    }
  })

  return (
    <Paper
      className={"stageBarBackground"}
      sx={{
        minWidth: stageBarWidth,
        height: "116px",
        display: "flex",
        justifyContent: "center",
        background: "#FFFFFFCC",
        borderTopLeftRadius: "0px",
        borderTopRightRadius: "6px",
        borderBottomLeftRadius: "6px",
        borderBottomRightRadius: "6px",
      }}
    >
      <Box className={"stageBar"} ref={resizeObserverRef}>
        <div
          className="refineStageContainer"
          style={{
            display: "flex",
            flexDirection: "row",
            // flexDirection:
            // !upList.length || !downList.length ? "unset" : "column",
            position: "relative",
          }}
        >
          {uiShowType === "passive_aligner" ? (
            <PassiveAligner
              onDeleteAllHandle={deleteAllHandle}
              onUpDownEqualHandle={upDownEqualHandle}
            />
          ) : (
            <></>
          )}

          <div
            className="refineStageContainer"
            style={{
              display: "flex",
              flexDirection: "column",
              // flexDirection:
              // !upList.length || !downList.length ? "unset" : "column",
              position: "relative",
            }}
          >
            <div
              className={"stageContainer"}
              style={{
                // display: upList.length ? "flex" : "none",
                //marginTop: isRefinement ? "20px" : "50px",
                //
                //marginBottom: isRefinement ? "20px" : "unset"
                display: "flex",
                marginTop: "4px",
                zIndex: 0,
              }}
            >
              <div
                className="upStageTitleContainer"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  position: "relative",
                  marginTop: "12px",
                }}
              >
                {/* <Box sx={{width: "1px", height: "20px"}}/> */}
                <div
                  className={"directName"}
                  style={{
                    width: "40px",
                    color: "#000000",
                    fontSize: "36px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    userSelect: "none",
                  }}
                >
                  U
                </div>
              </div>

              <StageArch
                onRef={stageUpArchRef}
                stageCount={upSteps}
                nodes={upNodes}
                archName="Up"
                archType={0}
                stepChangeHandler={stepChangeHandler}
                currentStep={upStageCurrentStep}
                loadFinished={stageArchLoadFinished}
                countOfFoldGroup={countOfFoldGroup}
                setDefaultStep={updateDefaultStepValue}
                uiShowType={uiShowType}
                actualSingleStageWidth={actualSingleStageWidth}
                viewEdit={switchViewEdit}
                isDownArchBiteMoved={isDownArchBiteMoved}
                gmPercent={gmPercent}
                setGmPercent={setGmPercent}
                isGmClicked={isGmClicked}
                setIsGmClicked={setIsGmClicked}
                gmFrontButtonClickHandle={gmFrontButtonClickHandle}
                gmBackButtonClickHandle={gmBackButtonClickHandle}
                upQuick3Index={upQuick3Index}
                downQuick3Index={downQuick3Index}
              />
            </div>

            <div
              className={"stageContainer"}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "7px",
                width: "50px",
                zIndex: 0,
                // background: "gray",
              }}
            >
              <StageDragBar
                onRef={stageDragBarRef}
                visibility={
                  upNodes.length > 0 || downNodes.length > 0 ? "" : "hidden"
                }
                positionXChanged={dragBarPositionXChanged}
                dragStoped={dragBarStoped}
                width={50}
                height={6}
                top={-6}
                x={dragBarLeft - 25 - (countOfFoldGroup === 1 ? 1.5 : 0)}
                bounds={{ left: -30000, right: 30000, top: 0, bottom: 0 }}
              />

              {/* <Box sx={{width: "1px", height: "1px"}}></Box> */}
            </div>

            <div
              className={"stageContainer"}
              style={{
                // display: downList.length ? "flex" : "none",
                display: "flex",
                marginTop: "unset", //!isRefinement ? upList.length ? "unset" : "70px" : "unset",
              }}
            >
              <div
                className="downStageTitleContainer"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  position: "relative",
                  marginBottom: "5px",
                }}
              >
                {/* <Box sx={{width: "1px", height: "20px"}}/> */}
                <div
                  className={"directName"}
                  style={{
                    width: "40px",
                    color: "#000000",
                    fontSize: "36px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    userSelect: "none",
                  }}
                >
                  L
                </div>
              </div>

              <StageArch
                className={"stageSlider"}
                onRef={stageDownArchRef}
                stageCount={downSteps}
                nodes={downNodes}
                archName="Down"
                archType={1}
                stepChangeHandler={stepChangeHandler}
                currentStep={downStageCurrentStep}
                loadFinished={stageArchLoadFinished}
                countOfFoldGroup={countOfFoldGroup}
                setDefaultStep={updateDefaultStepValue}
                uiShowType={uiShowType}
                actualSingleStageWidth={actualSingleStageWidth}
                viewEdit={switchViewEdit}
                isDownArchBiteMoved={isDownArchBiteMoved}
                gmPercent={gmPercent}
                setGmPercent={setGmPercent}
                isGmClicked={isGmClicked}
                setIsGmClicked={setIsGmClicked}
                gmFrontButtonClickHandle={gmFrontButtonClickHandle}
                gmBackButtonClickHandle={gmBackButtonClickHandle}
                upQuick3Index={upQuick3Index}
                downQuick3Index={downQuick3Index}
              />
            </div>
          </div>
        </div>
      </Box>

      <DialogBox
        open={showDeleteAllPassiveAlignerNodeWarning}
        title="Warning"
        content={<>{"Do you want to delete all passive aligners?"}</>}
        actions={
          <>
            <UButton
              onClickHandler={() => {
                setShowDeleteAllPassiveAlignerNodeWarning(false)
                stagingManager.delPassiveAligner()
              }}
              variant={"text"}
              btnText={"Yes"}
            />
            <UButton
              onClickHandler={() => {
                setShowDeleteAllPassiveAlignerNodeWarning(false)
              }}
              variant={"text"}
              btnText={"Cancel"}
            />
          </>
        }
      ></DialogBox>
    </Paper>
  )
}
export default StageBar
