import { FC, useEffect, useRef, useState } from "react"
import { useParams } from "react-router-dom"
import { ReactSVG } from "react-svg"
import {
  Alert,
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  InputBase,
  Paper,
  SvgIcon,
  TextField,
  Typography,
} from "@mui/material"
import { isEmpty } from "lodash"
import moment from "moment-timezone"
import SendIcon from '@mui/icons-material/Send';
import { LeftPage } from "@/UDTreat/left/LeftPage"


import logoSvg from "../../../../assets/images/ulablogo.svg"
import close from "../../../../assets/svgs/close.svg"
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import warning from "../../../../assets/svgs/warning.svg"
import SvgBtn from "../../../../components/DarkMode/SvgButton/svgbtn"
import {
  useAppDispatch,
  useAppSelector,
  useDeepEffect,
} from "../../../../core/app/hooks"
import {
  clinicalRequestBasicType,
  messageBody,
} from "../../../../core/app/slices/clinical/clinical.types"
import {
  getMessageApi,
  postMessageApi,
  postModiApi,
  updateMessageApi,
} from "../../../../core/app/slices/clinical/clinicalThunkApi"
import { RootState } from "../../../../core/app/store"
import { UText } from "../../../../ui-component"
import MessagePopup from "../CloseCase/messagePopup"

import "./message.scss"
import { setSwitchViewEdit } from "@/UDTreat/udTreatSlice"
import { getCaseById } from "@/core/app/slices/case"

const Message: FC<{
  isEdit: boolean
  isSubmitModification
  setisSubmitModification
  showPrompt: boolean | (() => void)
  confirmNavigation: boolean | (() => void)
  cancelNavigation: boolean | (() => void)
  updateCaseFun
  setisSubmitReply
  isSubmitReply
  onClosePage
}> = ({
  isEdit,
  isSubmitModification,
  setisSubmitModification,
  setisSubmitReply,
  isSubmitReply,
  updateCaseFun,
  onClosePage,
}) => {
    const { patientId, caseId } = useParams()

    const [holdplace, setholdplace] = useState("")

    const inputref = useRef()

    const [alert, setalert] = useState(false)

    const [alertText, setalertText] = useState("")

    const [selectedPlan, setselectedPlan] = useState({
      name: undefined,
      id: undefined,
    })

    const [dialog, setdialog] = useState(false)

    const [inputValue, setinputValue] = useState("")
    const [modifPlan, setmodifPlan] = useState(0)

    const [isreadMore, setisreadMore] = useState<boolean[]>([])

    const [massageComponent, setmassageComponent] = useState(<></>)
    const [historyComponent, sethistoryComponent] = useState(<></>)

    const dispatch = useAppDispatch()

    const getCurrentTimeByTimeZoom = (time) => {
      if (!time) return
      const current_time = moment
        .tz(time, Intl.DateTimeFormat().resolvedOptions().timeZone)
        .format("MM/DD/YY hh:mm A")
      return current_time
    }

    const messageEnd = useRef(null)

    const left = (
      { firstname, lastname, time, message, role, messageStatus },
      index,
      ishistory,
      modif_num,
    ) => {
      const avatarComponent = (
        <Avatar
          variant="circular"
          sx={{
            bgcolor: "#16AADC",
            width: 32,
            height: 32,
            fontSize: 18,
            color: "#FFF",
          }}
        >
          {lastname[0] + firstname[0]}
        </Avatar>
      )
      return (
        <Box
          id="message-item"
          key={index}
          sx={{
            color: "#FFF",
            fontWeight: 400,
            marginTop: 2,
          }}
        >
          <Box id="message-title" sx={{ display: "flex" }}>
            {/* <Avatar
              sx={{ bgcolor: "#FFF", width: 32, height: 32 }}
              src={logoSvg}
            /> */}
            {avatarComponent}

            <Box sx={{ padding: "0 8px" }}>
              <Box
                id="message-name"
                display={"flex"}
                alignItems={"center"}
              >
                <Typography variant="body2" color={"rgba(0, 0, 0, 0.87)"}>{lastname + " " + firstname}</Typography>
                <Divider orientation="vertical" variant="middle" flexItem sx={{ margin: "0 8px" }} />
                <Typography variant="overline" color={"rgba(0, 0, 0, 0.60)"}>{role}</Typography>

              </Box>
              <Box id="message-time" >
                <Typography variant="caption" color={"rgba(0, 0, 0, 0.87)"}> {getCurrentTimeByTimeZoom(time)}</Typography>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              marginLeft: 4,
              width: 320,
            }}
          >
            <Box
              id="message-text"
              sx={{
                borderRadius: 0.5,
                background: "#EEE",
                width: "100%",
                padding: "16px",
                color: "rgba(0, 0, 0, 0.87)",
              }}
            >
              <Typography variant="body2" sx={{ fontWeight: 700, color: "#0288D1" }}>{message.subject}</Typography>
              {message.title && (
                <Box
                  sx={
                    {
                      fontWeight: 700,
                      color: messageStatus === "MESSAGE_MODIFICATION_REQUEST" ?
                        "#01579B"
                        : "#215ECD"
                    }
                  }
                >
                  {message.title}
                </Box>
              )}
              <Box
                sx={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: isreadMore[index] && ishistory ? 3 : "",
                  "-webkit-box-orient": "vertical",
                  color: "rgba(0, 0, 0, 0.87)"
                }}
              >
                {message.text}
              </Box>
              {isreadMore[index] && ishistory && (
                <Button
                  size="small"
                  variant="text"
                  sx={{ marginTop: 1, marginLeft: 1 }}
                  onClick={() => {
                    const res = isreadMore
                    res[index] = false
                    setisreadMore([...res])
                  }}
                >
                  READ MORE
                </Button>
              )}
            </Box>
            {/* {index === 0 && !ishistory && (
              <Box
                sx={{
                  color: "#215ECD",
                  fontSize: 12,
                  padding: "0px 14px",
                }}
              >
                New
              </Box>
            )} */}
            {messageStatus === "MESSAGE_SELECTED" &&
              selectedPlan.id === message.id && (
                <Box
                  sx={{
                    color: "#0288D1",
                    fontSize: "12px",
                    padding: "6px 8px 0 0",
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row-reverse",
                    width: 320,
                  }}
                >
                  <SvgIcon fontSize="small" sx={{ marginLeft: 1 }}>
                    <CheckCircleIcon style={{ color: "rgba(2, 136, 209, 1)" }} />
                  </SvgIcon>
                  Selected

                </Box>
              )}
            {modif_num > 0 && (
              <Box display={"flex"} sx={{ width: "100%", justifyContent: "flex-end", marginTop: 1 }}>
                <Button
                  size="small"
                  color="info"
                  variant="contained"
                  disabled={

                    (messageStatus === "MESSAGE_SELECTED" &&
                      selectedPlan.id &&
                      selectedPlan.id !== message.id) ||
                    (case_disposition !== "REVIEW_PLAN" &&
                      case_disposition !== "REVIEW_MODIFICATION")
                  }
                  endIcon={true}
                  onClick={() => {
                    setselectedPlan({ name: message.title, id: message.id })
                    setisSubmitModification(false)
                  }}
                >
                  start modification request
                  <SvgIcon sx={{ marginLeft: 0.5, fontSize: "18px" }}><ArrowForwardIcon /></SvgIcon>
                </Button>
              </Box>

            )}
            {messageStatus === "MESSAGE_WITH_REPLY" && (
              <Button
                size="medium"
                color="info"
                variant="contained"
                sx={{ marginLeft: 30, marginTop: 1 }}
                onClick={() => {
                  setselectedPlan({ name: message.title, id: message.id })
                  setisSubmitReply(false)
                }}
              >
                Reply
              </Button>
            )}
          </Box>
        </Box>
      )
    }

    const right = (
      { firstname, lastname, photo, time, message, color, role, messageStatus },
      index,
      ishistory,
    ) => {
      const logo = photo || lastname[0] + firstname[0]
      // const avatarComponent = (
      //   <Avatar
      //     variant="circular"
      //     sx={{
      //       bgcolor: color,
      //       width: 32,
      //       height: 32,
      //       fontSize: 18,
      //       color: "#FFF",
      //     }}
      //     src={logo}
      //   >
      //     {logo}
      //   </Avatar>
      // )
      const nameComponent = (
        <Box sx={{ padding: "0 8px" }}>
          {/* <Box
            id="message-name"
            display={"flex"}
            alignItems={"center"}
          >
            <Typography variant="body2" color={"rgba(0, 0, 0, 0.87)"}>{lastname + " " + firstname}</Typography>
            <Divider orientation="vertical" variant="middle" flexItem sx={{ margin: "0 8px" }} />
            <Typography variant="overline" color={"rgba(0, 0, 0, 0.60)"}>{role}</Typography>

          </Box> */}
          <Box id="message-time" >
            <Typography variant="caption" color={"rgba(0, 0, 0, 0.87)"}> {getCurrentTimeByTimeZoom(time)}</Typography>
          </Box>
        </Box >
      )
      return (
        <Box
          id="message-item"
          key={index}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            width: "100%",
            marginTop: "16px"
          }}
        >
          <Box
            id="message-title"
            sx={{ display: "flex", flexDirection: "row-reverse" }}
          >
            {/* {avatarComponent} */}
            {nameComponent}
          </Box>

          <Box
            id="message-text"
            sx={{
              padding: "16px",
              background: "rgba(33, 150, 243, 0.08)",
              borderRadius: 0.5,
              width: 320
            }}
          >
            <Box sx={{ fontWeight: 700, color: "#01579B", fontSize: "14px" }}>{message.subject}</Box>
            <Box sx={{ fontWeight: 700, color: "#01579B", fontSize: "14px" }}>{message.title}</Box>
            <Box
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: isreadMore[index] && ishistory ? 3 : "",
                "-webkit-box-orient": "vertical",
                color: "rgba(0, 0, 0, 0.87)"
              }}
            >
              {message.text}
            </Box>

            {isreadMore[index] && ishistory && (
              <Button
                size="small"
                variant="text"
                sx={{ marginTop: 1, marginLeft: 1 }}
                onClick={() => {
                  const res = isreadMore
                  res[index] = false
                  setisreadMore([...res])
                }}
              >
                READ MORE
              </Button>
            )}
          </Box>

          {messageStatus === "WARNING_BANNER" && (
            <Box
              id="message-error"
              sx={{
                width: 320,
                height: 48,
                padding: "6px 16px",
                background: "#F44336",
                borderRadius: 0.5,
                marginLeft: 4.5,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <ReactSVG
                src={warning}
                afterInjection={(svg) => {
                  const path = svg.getElementsByTagName("path")[0]
                  path?.setAttribute("fill", "#FFF")
                }}
                style={{
                  width: 22,
                  height: "22px",
                }}
              ></ReactSVG>
              <UText
                variant={"caption"}
                sxProp={{
                  fontSize: 12,
                  fontWeight: 400,
                  letterSpacing: 0.4,
                  color: "#FFF",
                }}
              >
                Comment failed to sent.
              </UText>
              <Button
                variant="text"
                size="small"
                sx={{
                  padding: "4px 5px",
                  fontSize: 13,
                  fontWeight: 500,
                  letterSpacing: 0.46,
                  background: "#F44336",
                  color: "#FFF",
                }}
                onClick={() => {
                  postMessageFun({
                    patientId,
                    caseId,
                    txName: selectedPlan.name,
                    content: message,
                  })
                }}
              >
                TRY AGAIN
              </Button>
            </Box>
          )}
          {messageStatus === "MESSAGE_MODIFICATION_REQUEST_SUBMITTED" && (
            <Box
              id="message-success"
              sx={{
                width: 320,
                padding: "6px 8px 0 0",
                borderRadius: 0.5,
                display: "flex",
                flexDirection: "row-reverse",
                alignItems: "center",
              }}
            >
              <SvgIcon fontSize="small" sx={{ marginLeft: 1 }}>
                <CheckCircleIcon style={{ color: "rgba(2, 136, 209, 1)" }} />
              </SvgIcon>
              <UText variant="body2" sxProp={{ color: "rgba(2, 136, 209, 1)", fontSize: "12px" }}>
                Modification request submitted
              </UText>

            </Box>
          )}
        </Box>
      )
    }

    const postMessageFun = ({
      patientId,
      caseId,
      txName,
      content,
      cb,
    }: messageBody) => {
      dispatch(postMessageApi({ patientId, caseId, txName, content, cb }))
    }

    const postStartModiFun = ({
      patientId,
      caseId,
      txName,
      content,
      is_modification_requested,
      cb,
    }: messageBody) => {
      dispatch(
        postModiApi({
          patientId,
          caseId,
          txName,
          content,
          is_modification_requested,
          cb,
        }),
      )
    }

    const getMessageFun = ({ patientId, caseId }: clinicalRequestBasicType) => {
      dispatch(getMessageApi({ patientId, caseId }))
    }

    const {
      messageList,
      historyList,
    } = useAppSelector((state: RootState) => state.clinicalService)

    const {
      caseDetail: { caseDisposition: case_disposition },
    } = useAppSelector((state: RootState) => state.caseService)

    const initReadMore = () => {
      const res: boolean[] = []
      historyList.forEach(({ message: { text } }) => {
        res.push(text.length > 120)
      })
      setisreadMore([...res])
    }

    const EmptyComponent = (
      <Box
        id="no-message"
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: "32px 8px",
          height: "100%",
          marginBottom: 14.5,
        }}
      >
        <Box sx={{ marginTop: 3, padding: 1.3, color: "#000" }}>
          <Box
            sx={{
              marginBottom: 1.3,
              fontSize: 20,
              fontWeight: 500,
              textAlign: "center",
            }}
          >
            No comment
          </Box>
          <Box
            sx={{
              fontSize: 14,
              fontWeight: 400,
              textAlign: "center",
            }}
          >
            You can send modification request to uAssist once your plan is ready
            to review
          </Box>
        </Box>
      </Box>
    )

    useDeepEffect(() => {
      if (isEmpty(messageList)) return
      let modif = 0
      const res = messageList.map(
        (
          {
            firstname,
            lastname,
            photo,
            time,
            message,
            role,
            color,
            messageStatus,
          },
          index,
        ) => {
          if (messageStatus === "MESSAGE_MODIFICATION_REQUEST") {
            modif = modif + 1
          }
          if (messageStatus === "MESSAGE_SELECTED") {
            setselectedPlan({ name: message.title, id: message.id })
          }
          if (role === "UASSIST TEAM") {
            return left(
              { firstname, lastname, time, message, role, messageStatus },
              index,
              false,
              modif,
            )
          } else {
            return right(
              {
                firstname,
                lastname,
                photo,
                time,
                message,
                color,
                role,
                messageStatus,
              },
              index,
              false,
            )
          }
        },
      )
      setmodifPlan(modif)
      setmassageComponent(res)
    }, [messageList, selectedPlan, case_disposition])

    useDeepEffect(() => {
      if (isEmpty(historyList)) return

      sethistoryComponent(
        <>
          {/* <Divider
          sx={{
            margin: 2,
            color: "rgba(255, 255, 255, 0.38)",
            fontSize: 12,
            fontWeight: 400,
          }}
        >
          History
        </Divider> */}
          {historyList.map(
            (
              {
                firstname,
                lastname,
                photo,
                time,
                message,
                role,
                color,
                messageStatus,
              },
              index,
            ) => {
              if (role === "UASSIST TEAM") {
                return left(
                  { firstname, lastname, time, message, role, messageStatus },
                  index,
                  true,
                  0,
                )
              } else {
                return right(
                  {
                    firstname,
                    lastname,
                    photo,
                    time,
                    message,
                    color,
                    role,
                    messageStatus,
                  },
                  index,
                  true,
                )
              }
            },
          )}
        </>,
      )
    }, [historyList])

    const postMessage = () => {
      if (!isSubmitModification) {
        postStartModiFun({
          patientId,
          caseId,
          txName: selectedPlan.name,
          content: inputValue,
          is_modification_requested: 1,
          cb: () => {
            dispatch(
              updateMessageApi({
                patientId,
                caseId,
                messageId: selectedPlan.id,
                selected: true,
                cb: () => {
                  getMessageFun({ patientId, caseId })
                  dispatch(getCaseById({ patientId, caseId }))
                  dispatch(setSwitchViewEdit(false))
                },
              }),
            )
          },
        })

        setisSubmitModification(true)
      } else if (!isSubmitReply) {
        postStartModiFun({
          patientId,
          caseId,
          content: inputValue,
          is_modification_requested: 0,
          cb: () => {
            dispatch(
              updateMessageApi({
                patientId,
                caseId,
                messageId: selectedPlan.id,
                selected: true,
                cb: () => {
                  getMessageFun({ patientId, caseId })
                  dispatch(getCaseById({ patientId, caseId }))
                },
              }),
            )
          },
        })
        setisSubmitReply(true)
      } else {
        postMessageFun({
          patientId,
          caseId,
          txName: selectedPlan.name,
          content: inputValue,
          cb: () => {
            getMessageFun({ patientId, caseId })
          },
        })
      }
      setinputValue("")
    }

    const changeTxPopup = () => (
      <Dialog
        open={dialog}
        onClose={() => {
          setdialog(false)
        }}
      >
        <Box sx={{ width: 444 }}>
          <DialogTitle>{selectedPlan.name} is selected</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Do you want to continue with this plan?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setdialog(false)
                setselectedPlan({ name: undefined, id: undefined })
                setinputValue("")
              }}
            >
              CANCEL
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                postMessage()
                setdialog(false)
              }}
              autoFocus
            >
              Yes, CONTINUE
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    )

    useEffect(() => {
      getMessageFun({ patientId, caseId })
      if (isEmpty(messageList)) return

      dispatch(
        updateMessageApi({
          patientId,
          caseId,
          messageId: messageList[0].message.id,
          read: 1,
        }),
      )
    }, [])

    useEffect(() => {
      if (messageList.length === 0 ||
        case_disposition === "MODIFICATION_IN_PROGRESS" ||
        ("ATTENTION_NEEDED" === case_disposition ? (isSubmitReply
          ? !selectedPlan.name
          : isSubmitReply) : false))
        return

      setTimeout(() => {
        inputref.current?.childNodes[0].focus()
      }, 0);

    }, [
      messageList,
      case_disposition,
      isSubmitReply
    ])

    useEffect(() => {
      if (case_disposition === "MODIFICATION_REQUESTED") {
        setholdplace("Disabled while uAssist is working on your case.")
      } else {
        setholdplace("Write your comments to submit the modification request.")
      }

      if (modifPlan === 2 && case_disposition === "REVIEW_PLAN") {
        setalertText(
          "Make minor modifications as needed and order, or send modification request to uAssist for plan 1 or plan 2.",
        )
      }

      if (modifPlan === 1 && case_disposition === "REVIEW_PLAN") {
        setalertText(
          "Make minor modifications as needed and order, or send modification request to uAssist.",
        )
      }
    }, [case_disposition, modifPlan])

    useEffect(() => {
      if (messageList.length === 0 && historyList.length === 0) {
        setholdplace("Write your comments to submit the modification request.")
        return
      }
      if (messageEnd && messageEnd.current) {
        messageEnd.current.scrollTop = messageEnd.current.scrollHeight
      }
    }, [messageList, historyList])

    useEffect(() => {
      if (!historyList || historyList.length === 0) return
      initReadMore()
    }, [historyList])

    useEffect(() => {
      if (!alertText) {
        setalert(false)
        return
      }
      setalert(true)
    }, [alertText])

    return (
      <LeftPage
        sx={{
          Width: "536px",
          position: "relative",
          zIndex: 1000
        }}
        title="Messages"
        onClose={onClosePage}
        content={(
          <>
            {messageList.length === 0 && historyList.length === 0 && EmptyComponent}
            {(messageList.length !== 0 || historyList.length !== 0) && (
              <Box
                id="message"
                sx={{
                  width: "100%",
                  overflow: "auto",
                  flex: "1 1 auto",
                  "::-webkit-scrollbar": { display: "none" },
                  maxHeight: window.innerHeight - 450,
                  minHeight: 400
                }}
                ref={messageEnd}
              >
                {case_disposition === "MODIFICATION_REQUESTED" && (
                  <Alert severity={"info"} sx={{ marginTop: 1 }}>
                    <Typography variant="body2" color={"#014361"}>Planning in progress</Typography>
                  </Alert>
                )}

                {alert && (
                  <Alert
                    id="message-alert"
                    severity="info"
                  >
                    <Typography variant="body2" color={"#014361"}>
                      {alertText}
                    </Typography>
                  </Alert>
                )}
                {massageComponent}
                {historyComponent}
              </Box>
            )}
            {changeTxPopup()}
          </>
        )
        }
        actions={(
          <Box id="message-foot" sx={{ padding: "16px 32px" }}>
            <Paper
              sx={{ p: '8px', display: 'flex', alignItems: 'center', width: "100%" }}
            >

              <InputBase
                sx={{
                  width: "100%",
                }}
                ref={inputref}
                placeholder={"Enter message or modification request here"}
                disabled={
                  messageList.length === 0 ||
                  case_disposition === "MODIFICATION_IN_PROGRESS" ||
                  ("ATTENTION_NEEDED" === case_disposition ? (isSubmitReply
                    ? !selectedPlan.name
                    : isSubmitReply) : false)
                }
                value={inputValue}
                onChange={({ target: { value } }) => {
                  setinputValue(value)
                }}
              />

              <IconButton
                aria-label="directions"
                disabled={
                  messageList.length === 0 ||
                  case_disposition === "MODIFICATION_IN_PROGRESS" ||
                  ("ATTENTION_NEEDED" === case_disposition ? (isSubmitReply
                    ? !selectedPlan.name
                    : isSubmitReply) : false)
                }
                onClick={() => {
                  if (!inputValue) return
                  if (modifPlan === 2 && selectedPlan?.id) {
                    setdialog(true)
                    return
                  }

                  postMessage()
                }}
              >
                <SendIcon />
              </IconButton>
            </Paper>
          </Box>
        )}
      />
    )
  }

export default Message
