import { FC, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import { Box, Divider, IconButton } from "../../components/mui.components"
import { Close } from "../../components/mui.icons"
import { useAppDispatch, useAppSelector } from "../../core/app/hooks"
import {
  deleteShippingAddress,
  fetchShippingAddressList,
} from "../../core/app/slices/user/userApis"
import { RootState } from "../../core/app/store"
import { UButton, UModalPopup, UText } from "../../ui-component/components"

import { AddAddressForm } from "./AddAddressForm"
import { IShippingAddressTo } from "@/core/model/interface/IShippingAddress"
import WarningIcon from "@mui/icons-material/Warning"
import usePermissions from "@/hooks/userPermission"
import { UI_PERMISSION } from "@/core/utils/access/permissionConstants"

export const AddOrgAddress: FC = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { hasAny } = usePermissions()
  const [openAddressForm, setOpenAddressForm] = useState(false)
  const [selectedAddress, setSelectedAddress] = useState(null)
  const [showDeletePopup, setShowDeletePopup] = useState(false)

  const { orgShippingAddressList } = useAppSelector(
    (state: RootState) => state.userService,
  )

  useEffect(() => {
    dispatch(fetchShippingAddressList())
  }, [dispatch])

  const renderShippingfield = (key: string, val: string) => {
    return (
      <Box component={"div"} sx={{ pb: 1, display: "flex" }}>
        <UText
          variant={showDeletePopup ? "body2" : "body1"}
          sxProp={{
            fontWeight: "500",
            width: "230px",
          }}
        >
          {key}
        </UText>
        <UText variant={showDeletePopup ? "body2" : "body1"}>{val}</UText>
      </Box>
    )
  }

  const getShippingAddress = (item: IShippingAddressTo) => {
    return (
      <Box component={"div"}>
        {renderShippingfield(
          t("uLabAccount.shipping.businessName"),
          item.business_name,
        )}
        {renderShippingfield(t("uLabAccount.shipping.address"), item.line_1)}
        {renderShippingfield(t("uLabAccount.shipping.line2"), item.line_2)}
        {renderShippingfield(t("uLabAccount.shipping.city"), item.city)}
        {renderShippingfield(t("uLabAccount.shipping.state"), item.region_name)}
        {renderShippingfield(t("uLabAccount.shipping.zip"), item.zip_code)}
        {renderShippingfield(
          t("uLabAccount.shipping.country"),
          item.country_name,
        )}
        {renderShippingfield(t("uLabAccount.shipping.phone"), item.phone)}
      </Box>
    )
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        padding: 4,
        backgroundColor: "background.default",
        boxShadow: 1,
        borderRadius: 2,
        width: "100%",
      }}
    >
      <>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <UText variant="h6">{"Company Address"}</UText>
          {hasAny(UI_PERMISSION.UI_ACCOUNT_SHIPPINGADDRESS_CREATE) && (
            <UButton
              variant={"contained"}
              btnText={t("uLabAccount.shipping.btn")}
              onClickHandler={() => {
                setSelectedAddress(null)
                setOpenAddressForm(true)
              }}
            />
          )}
        </Box>
        {orgShippingAddressList?.length > 0 &&
          orgShippingAddressList.map((item, index) => {
            return (
              <>
                <Box
                  key={item.id}
                  display={"flex"}
                  justifyContent={"space-between"}
                  sx={{ pt: 2, pb: 1 }}
                >
                  {getShippingAddress(item)}
                  <Box sx={{ display: "flex", columnGap: 3 }}>
                    {hasAny(UI_PERMISSION.UI_ACCOUNT_SHIPPINGADDRESS_DELETE) &&
                      orgShippingAddressList?.length > 1 && (
                        <UButton
                          variant="text"
                          btnText={t("button.delete")}
                          onClickHandler={() => {
                            setSelectedAddress(item)
                            setShowDeletePopup(true)
                          }}
                        />
                      )}
                    {hasAny(UI_PERMISSION.UI_ACCOUNT_SHIPPINGADDRESS_UPDATE) && (
                      <UButton
                        data-testid={`${item.id}`}
                        variant="text"
                        btnText={t("button.edit")}
                        onClickHandler={() => {
                          setSelectedAddress(item)
                          setOpenAddressForm(true)
                        }}
                      />
                    )}
                  </Box>
                </Box>
                {index !== orgShippingAddressList.length - 1 && (
                  <Divider sx={{ my: 1 }} />
                )}
              </>
            )
          })}
      </>
      <UModalPopup
        sxModalProps={{
          "#titleCntr": {
            pt: 3,
            px: 3,
            pb: 2,
            backgroundColor: "divider",
            display: "block",
          },
          ".MuiDialog-paper": {
            borderRadius: "8px",
          },
        }}
        title={
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <UText variant={"h6"}>
              {selectedAddress && selectedAddress.id
                ? t("uLabAccount.shipping.editAddress")
                : t("uLabAccount.shipping.addcompanyAddress")}
            </UText>
            <IconButton
              aria-label={"close"}
              sx={{ "&:hover": { backgroundColor: "transparent" } }}
              onClick={() => setOpenAddressForm(false)}
            >
              <Close
                fontSize={"small"}
                sx={{
                  color: "rgba(0, 0, 0, 0.56)",
                }}
              />
            </IconButton>
          </Box>
        }
        content={
          <AddAddressForm
            setOpenAddressForm={setOpenAddressForm}
            address={selectedAddress}
          />
        }
        isAlert={openAddressForm}
        btnList={[]}
      />
      <UModalPopup
        sxModalProps={{
          "#titleCntr": {
            pt: 3,
            px: 3,
            pb: 2,
            backgroundColor: "divider",
            display: "block",
          },
          ".MuiDialog-paper": {
            width: 600,
            borderRadius: "8px",
          },
        }}
        title={
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <UText variant={"h6"}>
              {t("uLabAccount.shipping.delete.title")}
            </UText>
            <IconButton
              aria-label={"close"}
              sx={{ "&:hover": { backgroundColor: "transparent" } }}
              onClick={() => setShowDeletePopup(false)}
            >
              <Close
                fontSize={"small"}
                sx={{
                  color: "rgba(0, 0, 0, 0.56)",
                }}
              />
            </IconButton>
          </Box>
        }
        content={
          <>
            <Box sx={{ pt: 2, px: 4, pb: 3 }}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <WarningIcon sx={{ color: "error.main", mr: 2 }} />
                <Box>
                  <UText variant="h6" component="div">
                    {t("uLabAccount.shipping.delete.note1")}
                  </UText>
                  <UText variant="caption" component="div">
                    {t("uLabAccount.shipping.delete.note2")}
                  </UText>
                </Box>
              </Box>
              <Box
                sx={{
                  p: 3,
                  backgroundColor: "#F5F5F5",
                  borderRadius: 2,
                  mt: "10px",
                }}
              >
                {selectedAddress && getShippingAddress(selectedAddress)}
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                backgroundColor: "divider",
                justifyContent: "flex-end",
                pb: 3,
                pt: 2,
                px: 4,
                gap: 3,
              }}
            >
              <UButton
                sxProp={{ width: 69, height: "36px" }}
                variant={"text"}
                btnType={"button"}
                onClickHandler={() => setShowDeletePopup(false)}
                btnText={t("button.cancel")}
              />
              <UButton
                sxProp={{
                  width: 69,
                  height: "36px",
                }}
                btnColor="error"
                variant={"contained"}
                btnText={t("button.delete")}
                onClickHandler={() => {
                  dispatch(deleteShippingAddress(selectedAddress.id)).then(
                    () => {
                      dispatch(fetchShippingAddressList())
                      setShowDeletePopup(false)
                    },
                  )
                }}
              />
            </Box>
          </>
        }
        isAlert={showDeletePopup}
      />
    </Box>
  )
}
