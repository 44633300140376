import { useEffect, useState } from "react"
import { Trans, useTranslation } from "react-i18next"
import CloseIcon from "@mui/icons-material/Close"
import ExpandLessIcon from "@mui/icons-material/ExpandLess"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { Box, IconButton, Link } from "@mui/material"

import customBox from "../../../assets/images/custom-box.png"
import { useAppDispatch, useAppSelector } from "../../../core/app/hooks"
import {
  getCustomPackagingList,
  uploadNewCustomBoxImage,
} from "../../../core/app/slices/customPackaging/customPackagingThunkApi"
import { RootState } from "../../../core/app/store"
import { convertMBtoKB } from "../../../core/utils/formatters"
import {
  UButton,
  UFileBox,
  UImagePreview,
  UModalPopup,
  UText,
  UTextField,
} from "../../../ui-component"

const NewPackackingModal = ({
  showAddModal,
  setShowAddModal,
  setTopImage,
  setSideImage,
  topImage,
  sideImage,
}) => {
  const { t } = useTranslation("common")
  const dispatch = useAppDispatch()
  const [isBtnDisabled, setIsBtnDisabled] = useState(true)
  const [previewUrl, setPreviewUrl] = useState(null)
  const [previewSideUrl, setPreviewSideUrl] = useState(null)
  const [inputValue, setInputValue] = useState("")
  const { myCustomPackagingList } = useAppSelector(
    (state: RootState) => state.customPackagingService,
  )

  useEffect(() => {
    setInputValue(`Custom Packaging ${myCustomPackagingList.length + 1}`)
  }, [myCustomPackagingList])

  useEffect(() => {
    if ((topImage || sideImage) && inputValue) {
      setIsBtnDisabled(false)
    } else {
      setIsBtnDisabled(true)
    }
  }, [topImage, sideImage, inputValue])

  const uploadNewCustomBox = () => {
    if (topImage || sideImage) {
      const formData = new FormData()
      topImage && formData.append("top", topImage)
      sideImage && formData.append("side", sideImage)
      formData.append("custom_packaging_name", inputValue)
      dispatch(uploadNewCustomBoxImage(formData)).then((res) => {
        if (res?.payload?.data?.status === "Success") {
          dispatch(getCustomPackagingList())
          setShowAddModal(false)
        }
      })
    }
  }

  const selectedTopImage = (files: FileList): void => {
    setTopImage(files[0])
    setPreviewUrl(URL.createObjectURL(files[0]))
  }

  const selectedSideImage = (files: FileList): void => {
    setSideImage(files[0])
    setPreviewSideUrl(URL.createObjectURL(files[0]))
  }

  const handleTopImageDelete = () => {
    setTopImage(undefined)
    setPreviewUrl([])
  }

  const handleSideImageDelete = () => {
    setSideImage(undefined)
    setPreviewSideUrl([])
  }

  const handleInputChange = (value: string) => {
    setInputValue(value)
  }

  const btnList = [
    <UButton
      sxProp={{
        width: "100%",
        fontSize: "15px",
        textTransform: "uppercase",
        letterSpacing: "0.46px",
        color: "primary.main",
        "&:hover": {
          color: "primary.main",
          backgroundColor: "#F6F6F6",
        },
      }}
      btnText="Cancel"
      variant="text"
      onClickHandler={() => {
        setShowAddModal(false)
        setTopImage(undefined)
        setSideImage(undefined)
      }}
      key={t("customPackaging.newPackagingModal.cancelBtn")}
    />,
    <UButton
      disabled={isBtnDisabled}
      onClickHandler={uploadNewCustomBox}
      sxProp={{
        width: "100%",
        fontSize: "15px",
        letterSpacing: "0.46px",
        textTransform: "uppercase",
        backgroundColor: "primary.main",
        "&:hover": {
          backgroundColor: "primary.main",
        },
      }}
      btnText="Submit"
      variant="contained"
      key={t("customPackaging.newPackagingModal.uploadBtn")}
    />,
  ]

  return (
    <UModalPopup
      sxModalProps={{
        ".MuiDialog-paper": {
          maxWidth: "700px",
          borderRadius: "8px",
        },
        ".MuiDialogTitle-root": {
          width: "100%",
        },
        ".MuiDialogContent-root": {
          px: "32px",
          py: "24px !important",
        },
        "#btnListCntr": {
          ">.MuiGrid-item": {
            display: "flex",
            color: "primary.main",
          },
          px: "32px",
          py: "16px",
          gap: 2,
          color: "primary.main",
          background: "#EEEEEE",
        },
      }}
      title={
        <ModalTitle
          setShowAddModal={setShowAddModal}
          setTopImage={setTopImage}
          setSideImage={setSideImage}
        />
      }
      btnList={btnList}
      content={
        <ModalContent
          sideImage={sideImage}
          topImage={topImage}
          previewUrl={previewUrl}
          previewSideUrl={previewSideUrl}
          handleTopImageDelete={handleTopImageDelete}
          handleSideImageDelete={handleSideImageDelete}
          selectedSideImage={selectedSideImage}
          selectedTopImage={selectedTopImage}
          inputValue={inputValue}
          handleInputChange={handleInputChange}
        />
      }
      isAlert={showAddModal}
    />
  )
}

const ModalTitle = ({ setShowAddModal, setTopImage, setSideImage }) => {
  const { t } = useTranslation("common")

  return (
    <Box sx={{ display: "flex", width: "100%", background: "#EEEEEE" }}>
      <UText sxProp={{ p: 2, width: "90%" }} variant={"h6"}>
        {t("customPackaging.newPackagingModal.title")}
      </UText>
      <Box
        sx={{
          p: 2,
          width: "90%%",
        }}
      >
        <IconButton
          onClick={() => {
            setTopImage(undefined)
            setSideImage(undefined)
            setShowAddModal(false)
          }}
          size={"small"}
        >
          <CloseIcon sx={{ height: "24px", width: "24px" }} />
        </IconButton>
      </Box>
    </Box>
  )
}

const ModalContent = ({
  topImage,
  selectedTopImage,
  previewUrl,
  previewSideUrl,
  selectedSideImage,
  handleTopImageDelete,
  handleSideImageDelete,
  sideImage,
  inputValue,
  handleInputChange,
}) => {
  const { t } = useTranslation("common")
  const [showAdditionalNote, setShowAdditionalNote] = useState(false)

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box>
          <img alt="logo" src={customBox} />
        </Box>
        <Box sx={{ pl: 2 }}>
          <Box>
            <UText variant={"body1"}>
              {t("customPackaging.newPackagingModal.instruction1")}
            </UText>
          </Box>
          <Box sx={{ pt: "7px" }}>
            <UText
              variant={"body2"}
              color={"text.secondary"}
              sxProp={{ lineHeight: 1.25 }}
            >
              <Trans
                components={{ strong: <strong style={{ fontWeight: 600 }} /> }}
              >
                {t("customPackaging.newPackagingModal.preferredFileFormat")}
              </Trans>
            </UText>
          </Box>
          <Box sx={{ py: "7px" }}>
            <UText
              variant={"body2"}
              color={"text.secondary"}
              sxProp={{ lineHeight: 1.25 }}
            >
              <Trans
                components={{ strong: <strong style={{ fontWeight: 600 }} /> }}
              >
                {t("customPackaging.newPackagingModal.otherAcceptedFormats")}
              </Trans>
            </UText>
          </Box>
          <UText
            variant={"body2"}
            color={"text.secondary"}
            sxProp={{ lineHeight: 1.25 }}
          >
            {t("customPackaging.newPackagingModal.fileFormat.text3")}
          </UText>
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          py: "16px",
          height: sideImage || topImage ? "320px" : "290px",
        }}
      >
        {topImage ? (
          <Box sx={{ width: "300px", height: "216px" }}>
            <UText
              variant={"h6"}
              sxProp={{
                textAlign: "center",
                mb: 1,
              }}
              component={"div"}
            >
              {t("customPackaging.newPackagingModal.topImage")}
            </UText>
            <UImagePreview
              imageHeight={216}
              imageWidth={300}
              files={[
                {
                  src: previewUrl,
                  name: topImage.name,
                },
              ]}
              deleteImage={handleTopImageDelete}
              showFileName
              sxImageProp={{ mt: 0 }}
            />
          </Box>
        ) : (
          <UFileUploadBox
            title={"customPackaging.newPackagingModal.topImage"}
            selectedFile={selectedTopImage}
            t={t}
          />
        )}
        {sideImage ? (
          <Box sx={{ width: "300px", height: "216px" }}>
            <UText
              variant={"h6"}
              component={"div"}
              sxProp={{
                textAlign: "center",
                mb: 1,
              }}
            >
              {t("customPackaging.newPackagingModal.sideImage")}
            </UText>
            <UImagePreview
              imageHeight={216}
              imageWidth={300}
              files={[
                {
                  src: previewSideUrl,
                  name: sideImage.name,
                },
              ]}
              deleteImage={handleSideImageDelete}
              showFileName
              sxImageProp={{ mt: 0 }}
            />
          </Box>
        ) : (
          <UFileUploadBox
            title={"customPackaging.newPackagingModal.sideImage"}
            selectedFile={selectedSideImage}
            t={t}
          />
        )}
      </Box>
      <Box component={"div"}>
        <UText variant={"body2"}>
          <Trans
            components={{
              MailLink: <Link href="mailto: artwork@ulabsystems.com" />,
            }}
          >
            {"customPackaging.newPackagingModal.instruction2"}
          </Trans>
        </UText>
      </Box>
      <Box component={"div"} sx={{ my: 2 }}>
        <UText variant={"body1"} sxProp={{ color: "text.secondary" }}>
          {t("customPackaging.newPackagingModal.textLabelCustom")}
        </UText>
        <Box component={"div"} sx={{ width: "100%", height: "56px", mt: 1 }}>
          <UTextField
            fieldType={"text"}
            fieldName={"search"}
            value={inputValue}
            onInputchange={handleInputChange}
          />
        </Box>
      </Box>
      <Box
        key={"statement"}
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          mt: "16px",
          background: "#EEEEEE",
        }}
      >
        <UButton
          sxProp={{
            width: "100%",
            justifyContent: "space-between",
            color: "text.primary",
            fontSize: "12px",
            fontWeight: "400",
            padding: "16px 24px",
          }}
          disableRipple
          onClickHandler={() => {
            setShowAdditionalNote(!showAdditionalNote)
          }}
          endIcon={showAdditionalNote ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          variant={"text"}
          btnText={t(
            "customPackaging.newPackagingModal.statementOnBranding.title",
          )}
        />
        {showAdditionalNote && (
          <Box sx={{ padding: "0px 24px 16px 24px" }}>
            <UText variant={"caption"}>
              {t(
                "customPackaging.newPackagingModal.statementOnBranding.content",
              )}
            </UText>
          </Box>
        )}
      </Box>
    </>
  )
}

const UFileUploadBox = ({ title, selectedFile, t }) => {
  const FILE_ALLOWED_SIZE_VALUE = 20 //in MB
  const FILE_ALLOWED_LIMIT_VALUE = 1
  const ALLOWED_FILE_TYPES = ["png", "jpg", "jpeg", "bmp"]

  return (
    <Box sx={{ width: "300px" }}>
      <UText
        variant={"h6"}
        component={"div"}
        sxProp={{
          textAlign: "center",
          mb: 1,
        }}
      >
        {t(title)}
      </UText>
      <UFileBox
        boxSize={{ height: "216px", mt: "0 !important", padding: "24px 8px" }}
        allowedFileExtensions={ALLOWED_FILE_TYPES}
        allowedfileSize={convertMBtoKB(FILE_ALLOWED_SIZE_VALUE)}
        fileAcceptType={"image/*"}
        id="composite"
        selectedFile={selectedFile}
        fileLimit={FILE_ALLOWED_LIMIT_VALUE}
        isRequired={false}
        messages={{
          fileNote: t("records.file.filenote", {
            fileTypes: "JPG, PNG, BMP",
            fileSize: FILE_ALLOWED_SIZE_VALUE,
          }),
          uploadButton: t("records.file.btnclicktoupload"),
          uploadButtonSuffix: t("records.file.uploadbuttonsuffix"),
          invalidfileFormat: (
            <Trans components={{ newLine: <br /> }}>
              {"records.photograph.invalidfileformat"}
            </Trans>
          ),
          invalidFileSize: t("records.file.invalidfilesize", {
            fileSize: FILE_ALLOWED_SIZE_VALUE,
          }),
          invalidFileLimit: t("records.file.invalidfilelimit", {
            noOfFiles: FILE_ALLOWED_LIMIT_VALUE,
          }),
        }}
      />
    </Box>
  )
}

export default NewPackackingModal
